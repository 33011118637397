import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoryProject } from 'src/app/models/projects-pdf/projects-pdf';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpDownloadPdfService {

  constructor(private http: HttpClient) {}

  getCategoryProject(account: string) {
    return this.http.get<any>(`${environment.apiUrl}categoryproject/${account}`);
  }

  createCategoryProject(category: CategoryProject) {
    return this.http.post<any>(`${environment.apiUrl}categoryproject`, category);
  }

  updateCategoryProject(category){
    return this.http.put<any>(`${environment.apiUrl}categoryproject`, category);
  }

  deleteCategoryProject(account: string, id: string) {
    return this.http.delete<any>(`${environment.apiUrl}categoryproject?account=${account}&id=${id}`);
  }

  getDownloadProject(id: string, type: string) {
    return this.http.get(`${environment.apiUrl}categoryproject/document/${id}?documentType=${ type }`, {
      headers: {
        "Accept": "application/pdf"
      },
      responseType: 'blob'
    });
  }

}
