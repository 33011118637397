import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Notification } from 'src/app/models/notification/notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  constructor(private notificationsService: NotificationService) { }

  ngOnInit(): void {
  }

  get notifications(): Array<Notification> {
    return this.notificationsService.notifications;
  }

  deleteNotification(event) {
    this.notificationsService.deleteNotification(event);
  }

}
