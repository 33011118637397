import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Frimware } from 'src/app/models/tv/tv';
import { TvService } from 'src/app/services/tv/tv.service';

@Component({
  selector: 'app-firmware-details',
  templateUrl: './firmware-details.component.html',
  styleUrls: ['./firmware-details.component.scss'],
})
export class FirmwareDetailsComponent implements OnInit {
  id: string;
  firmware: Frimware = new Frimware();
  constructor(
    private route: ActivatedRoute,
    private tvService: TvService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.getFirmware();
      }
    });
  }

  getFirmware() {
    this.tvService.getFirmware(this.id).subscribe((data) => {
      this.firmware = data;
    });
  }

  saveFirmware() {
    if (!this.firmware || this.firmware.name === undefined || this.firmware.name.length <= 0 || this.firmware.link === undefined
      || this.firmware.link.length <= 0) {
      alert('Errore inserire tutte le informazioni');
    } else {
      if (this.id) {
        this.updateFirmware();
      } else {
        this.createFirmware();
      }
    }
  }

  createFirmware() {
    this.tvService.createFirmware(this.firmware).subscribe((data) => {
      this.router.navigate(['firmware']);
    });
  }

  updateFirmware() {
    this.tvService.updateFirmware(this.firmware).subscribe((data) => {
      this.router.navigate(['firmware']);
    });
  }
}
