import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryTree, Category, Service } from 'src/app/models/categoryTree/category-tree';
import { CategoryProject } from 'src/app/models/projects-pdf/projects-pdf';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-download-pdf-category-selection',
  templateUrl: './download-pdf-category-selection.component.html',
  styleUrls: ['./download-pdf-category-selection.component.scss']
})
export class DownloadPdfCategorySelectionComponent implements OnInit {

  @Input() projectSelected: CategoryProject;
  @Output() goBackEvent = new EventEmitter<null>();
  @Output() projectSelectedEvent = new EventEmitter<CategoryProject | null>();

  categories: CategoryTree;
  subcategories: Category[][] | Service[][] = [];
  idDownloadStore: string[] = [];
  projectName: string = '';
  projectDescr: string = '';


  constructor(
    private categoriesService: CategoriesService,
    private spinner: SpinnerService,
    private translateService: TranslateService ) { }

  ngOnInit(): void {
    this.getCategories()
  }

  getCategories() {
    this.spinner.showSpinner();
    this.categoriesService
      .getVendorTree(localStorage.getItem('HechRomeoAccount'))
      .subscribe((data: CategoryTree ) => {
        this.categories = data;
        this.categories.categories.map((data) => data['isSelected'] = false);
        this.categories.categories.map((data) => data['isDownloadable'] = false);
        if( this.projectSelected ) {
          this.projectName = this.projectSelected.title;
          this.projectDescr = this.projectSelected.description;
          this.idDownloadStore = [...this.projectSelected.categoryServiceIds]
          this.projectOpen(this.categories.categories);
        }
        this.spinner.hideSpinner()
      });
  }

  projectOpen(category){
    if( category.length && this.projectSelected.categoryServiceIds.length ) {
      category.forEach( element => {
        this.projectSelected.categoryServiceIds.forEach( (id, index) => {
          if( id === element.id ) {
            element.isDownloadable = true;
            this.projectSelected.categoryServiceIds.splice(index, 1);
            return;
          };
        })
        if( element.categories?.length ) this.projectOpen(element.categories)
        else if( element.services?.length ) this.projectOpen(element.services);
      })
    }
  }

  selectItem( element: Category, level: number){
      //  level === 0 ?
      //   this.categories.categories.map((data) => data['isSelected'] = false)
      //   : (this.subcategories[level -1]  as Array<Category|Service>).map( ( item: Category | Service )  => item['isSelected'] = false)

      this.subcategories.forEach((subcategory, index) => {
        if( index > level ) this.subcategories[index] = [];
      });

      element.isSelected = true;
      if(element?.categories?.length || element?.services?.length ){
        this.subcategories[level] = element?.categories?.length ? element.categories : element?.services;
       ( this.subcategories[level] as Array<Category|Service>).map( (subCategory : Category | Service ) => subCategory['type'] = !!!element.categories.length )
      };
  }

  checkValue(isChecked: boolean, element: Category){
    this.setCheckInput(element, isChecked)
      console.log(this.idDownloadStore, JSON.stringify(this.idDownloadStore))
  }

  setCheckInput(element, isChecked){
    this.setIdDownloadStore(element, isChecked)
    element.isDownloadable = isChecked;
    const type = element?.categories?.length ? 'categories' : 'services';
    if( element?.categories?.length | element?.services?.length) element[type].map( category => this.setCheckInput(category, isChecked));
  }

  setIdDownloadStore(category, isChecked){
    if( isChecked ) {
      const idIndex = this.idDownloadStore.indexOf(category.id)
      const isNotIdDuplicate = idIndex === -1;
      if ( isNotIdDuplicate ) this.idDownloadStore = [...this.idDownloadStore, category.id]
    } else {
      this.idDownloadStore = this.idDownloadStore.filter( element => element !== category.id )
    }
  }

  // downloadSelectCategories(){
  //   if( !this.projectName ) alert( this.translateService.instant('Manca il nome del progetto'))
  //     else if( !this.projectDescr ) alert( this.translateService.instant('Manca la descrizione'))
  //       else if ( !this.idDownloadStore.length ) alert( this.translateService.instant('Selezionare categorie da scaricare') )
  //         else console.log(` il Progetto ${this.projectName} contiene questi elementi ${ JSON.stringify(this.idDownloadStore) } `)

  // }

  saveProject(){
    if( !this.projectName && this.projectDescr && !this.idDownloadStore.length) alert(this.translateService.instant('progetto vuoto'))
      else {
        if( !this.projectSelected ) {
          this.projectSelected = {
            account: localStorage.getItem('HechRomeoAccount'),
            title: this.projectName,
            description: this.projectDescr,
            categoryServiceIds: this.idDownloadStore
          }
        } else {
          this.projectSelected.title = this.projectName;
          this.projectSelected.description = this.projectDescr;
          this.projectSelected.categoryServiceIds = this.idDownloadStore;
        }
        this.projectSelectedEvent.emit( this.projectSelected );
    }
  }

  goBack(){
    this.goBackEvent.emit()
  }

}
