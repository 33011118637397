import { Injectable } from '@angular/core';
import {
  Notification,
  generateId,
} from 'src/app/models/notification/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications: Array<Notification> = new Array<Notification>();

  constructor() {}

  addNotification(notification: Notification) {
    if (this.notifications.find((n) => n.id === notification.id)) {
      notification.id = generateId();
      this.addNotification(notification);
    } else {
      this.notifications.push(notification);
    }
  }

  deleteNotification(id: string) {
    this.notifications = this.notifications.filter((n) => n.id !== id);
  }
}
