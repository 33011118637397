export const BROADCAST = [
  {
    id: 'ATSC',
    value: 'ATSC'
  },
  {
    id: 'DVB',
    value: 'DVB'
  },
  {
    id: 'ISDB',
    value: 'ISDB'
  }
];

export const CHANNELTYPE = [
  {
    id: 'ATV',
    value: 'ATV'
  },
  {
    id: 'DTV',
    value: 'DTV'
  },
  {
    id: 'CATV',
    value: 'CATV'
  },
  {
    id: 'CDTV',
    value: 'CDTV'
  },
  {
    id: 'SDTV',
    value: 'SDTV'
  },
  {
    id: 'IPTV',
    value: 'IPTV'
  }
];

export const BANDWIDTH = [
  {
    id: '6Mhz',
    value: '6Mhz'
  },
  {
    id: '7Mhz',
    value: '7Mhz'
  },
  {
    id: '8Mhz',
    value: '8Mhz'
  }
];

export const MODULATION = [
  { id: 'UNKNOWN', value: 'UNKNOWN' },
  { id: 'QAM16', value: 'QAM16' },
  { id: 'QAM64', value: 'QAM64' },
  { id: 'QAM128', value: 'QAM128' },
  { id: 'QAM256', value: 'QAM256' },
  { id: 'VSB8', value: 'VSB8' },
  { id: 'VSB16', value: 'VSB16' },
  { id: 'NTSC', value: 'NTSC' },
  { id: 'QPSK', value: 'QPSK' },
  { id: 'PAL', value: 'PAL' },
  { id: 'DMBT', value: 'DMBT' },
  { id: 'OFDM', value: 'OFDM' },
  { id: 'ISDB_T', value: 'ISDB_T' },
  { id: 'ISDB_S', value: 'ISDB_S' },
  { id: 'GB_S', value: 'GB_S' },
  { id: 'GB_M', value: 'GB_M' },
  { id: 'QAM32', value: 'QAM32' },
  { id: '8PSK', value: '8PSK' },
  { id: '16APSK', value: '16APSK' },
  { id: '32APSK', value: '32APSK' },
  { id: 'OFDM', value: 'OFDM' },
  { id: 'ATSC3', value: 'ATSC3' },
  { id: 'AUTO_QAM', value: 'AUTO_QAM' }
];

export const SATELLITE = [
  {id: 'ASTRA_19_2E', value: 'ASTRA_19_2E'},
  {id: 'ASTRA_23_5E', value: 'ASTRA_23_5E'},
  {id: 'ASTRA_28_2E', value: 'ASTRA_28_2E'},
  {id: 'ASTRA_31_5E', value: 'ASTRA_31_5E'},
  {id: 'ASTRA_4_8E', value: 'ASTRA_4_8E'},
  {id: 'EUTELSAT_10E', value: 'EUTELSAT_10E'},
  {id: 'EUTELSAT_16E', value: 'EUTELSAT_16E'},
  {id: 'EUTELSAT_21_5E', value: 'EUTELSAT_21_5E'},
  {id: 'EUTELSAT_25_5E', value: 'EUTELSAT_25_5E'},
  {id: 'EUTELSAT_28_5E', value: 'EUTELSAT_28_5E'},
  {id: 'EUTELSAT_33E', value: 'EUTELSAT_33E'},
  {id: 'EUTELSAT_36E', value: 'EUTELSAT_36E'},
  {id: 'EUTELSAT_3E', value: 'EUTELSAT_3E'},
  {id: 'EUTELSAT_70_5E', value: 'EUTELSAT_70_5E'},
  {id: 'EUTELSAT_70E', value: 'EUTELSAT_70E'},
  {id: 'EUTELSAT_7E', value: 'EUTELSAT_7E'},
  {id: 'EUTELSAT_8_WEST_C', value: 'EUTELSAT_8_WEST_C'},
  {id: 'EUTELSAT_9E', value: 'EUTELSAT_9E'},
  {id: 'EUTELSAT12_WESTA', value: 'EUTELSAT12_WESTA'},
  {id: 'EUTELSAT5_WESTA', value: 'EUTELSAT5_WESTA'},
  {id: 'EUTELSAT7_WESTA', value: 'EUTELSAT7_WESTA'},
  {id: 'EUTELSAT8_WESTA', value: 'EUTELSAT8_WESTA'},
  {id: 'EUTELSATW1_10E', value: 'EUTELSATW1_10E'},
  {id: 'EUTELSATW2_16E', value: 'EUTELSATW2_16E'},
  {id: 'EUTELSATW3A_7E', value: 'EUTELSATW3A_7E'},
  {id: 'EUTELSATW4_W7_36E', value: 'EUTELSATW4_W7_36E'},
  {id: 'EUTELSATW5_70_5E', value: 'EUTELSATW5_70_5E'},
  {id: 'EUTELSATW6_21_5E', value: 'EUTELSATW6_21_5E'},
  {id: 'ABS_2_75E', value: 'ABS_2_75E'},
  {id: 'ABS_7', value: 'ABS_7'},
  {id: 'AFGHANSAT_1', value: 'AFGHANSAT_1'},
  {id: 'AMAZONAS_61W', value: 'AMAZONAS_61W'},
  {id: 'AMOS_4W', value: 'AMOS_4W'},
  {id: 'APSTAR', value: 'APSTAR'},
  {id: 'ARABSAT_30_5E', value: 'ARABSAT_30_5E'},
  {id: 'ARABSAT_5C_20E', value: 'ARABSAT_5C_20E'},
  {id: 'ASIASAT_5', value: 'ASIASAT_5'},
  {id: 'ASIASAT_7', value: 'ASIASAT_7'},
  {id: 'ATLANTICBIRD_5W', value: 'ATLANTICBIRD_5W'},
  {id: 'ATLANTICBIRD1_12_5W', value: 'ATLANTICBIRD1_12_5W'},
  {id: 'ATLANTICBIRD2_8W', value: 'ATLANTICBIRD2_8W'},
  {id: 'AZERSPACE_1_AFRICASAT_1A', value: 'AZERSPACE_1_AFRICASAT_1A'},
  {id: 'BADR_26E', value: 'BADR_26E'},
  {id: 'BONUM1_56E', value: 'BONUM1_56E'},
  {id: 'EUROBIRD_25_5E', value: 'EUROBIRD_25_5E'},
  {id: 'EUROBIRD_28_5E', value: 'EUROBIRD_28_5E'},
  {id: 'EUROBIRD_33E', value: 'EUROBIRD_33E'},
  {id: 'EUROBIRD_4E', value: 'EUROBIRD_4E'},
  {id: 'EUROBIRD_9E', value: 'EUROBIRD_9E'},
  {id: 'EXPRESS_AM22', value: 'EXPRESS_AM22'},
  {id: 'EXPRESS_AM44', value: 'EXPRESS_AM44'},
  {id: 'EXPRESS_AM5_140E', value: 'EXPRESS_AM5_140E'},
  {id: 'EXPRESS_AT1_56E', value: 'EXPRESS_AT1_56E'},
  {id: 'EXPRESSA4_14W', value: 'EXPRESSA4_14W'},
  {id: 'EXPRESSAM1_40E', value: 'EXPRESSAM1_40E'},
  {id: 'HELLAS_39E', value: 'HELLAS_39E'},
  {id: 'HISPASAT_30W', value: 'HISPASAT_30W'},
  {id: 'HOTBIRD_13E', value: 'HOTBIRD_13E'},
  {id: 'INSAT_4B_93_3E', value: 'INSAT_4B_93_3E'},
  {id: 'INTELSAT_10', value: 'INTELSAT_10'},
  {id: 'INTELSAT_45E', value: 'INTELSAT_45E'},
  {id: 'INTELSAT_902', value: 'INTELSAT_902'},
  {id: 'INTELSAT_904_60E', value: 'INTELSAT_904_60E'},
  {id: 'INTELSAT15_85_2E', value: 'INTELSAT15_85_2E'},
  {id: 'INTELSAT1R_45W', value: 'INTELSAT1R_45W'},
  {id: 'INTELSAT3R_43W', value: 'INTELSAT3R_43W'},
  {id: 'INTELSAT7_10_68_5E', value: 'INTELSAT7_10_68_5E'},
  {id: 'INTELSAT705_50W', value: 'INTELSAT705_50W'},
  {id: 'INTELSAT707_53W', value: 'INTELSAT707_53W'},
  {id: 'INTELSAT801_31_5W', value: 'INTELSAT801_31_5W'},
  {id: 'INTELSAT805_55_5W', value: 'INTELSAT805_55_5W'},
  {id: 'INTELSAT9_58W', value: 'INTELSAT9_58W'},
  {id: 'INTELSAT901_18W', value: 'INTELSAT901_18W'},
  {id: 'INTELSAT903_34_5W', value: 'INTELSAT903_34_5W'},
  {id: 'INTELSAT905_24_5W', value: 'INTELSAT905_24_5W'},
  {id: 'INTELSAT907_27_5W', value: 'INTELSAT907_27_5W'},
  {id: 'MEASAT_3B_91_5E', value: 'MEASAT_3B_91_5E'},
  {id: 'NILESAT_102_7W', value: 'NILESAT_102_7W'},
  {id: 'NILESAT_201_7W', value: 'NILESAT_201_7W'},
  {id: 'NILESAT_7W', value: 'NILESAT_7W'},
  {id: 'NSS_6_SES_8_95E', value: 'NSS_6_SES_8_95E'},
  {id: 'NSS12_57E', value: 'NSS12_57E'},
  {id: 'NSS7_22W', value: 'NSS7_22W'},
  {id: 'NSS806_40_5W', value: 'NSS806_40_5W'},
  {id: 'OPTUS_D1_160E', value: 'OPTUS_D1_160E'},
  {id: 'PAKSAT_38E', value: 'PAKSAT_38E'},
  {id: 'PALAPA_D_113_5E', value: 'PALAPA_D_113_5E'},
  {id: 'RASCOM_QAF1R_2_8E', value: 'RASCOM_QAF1R_2_8E'},
  {id: 'SES_1_101W', value: 'SES_1_101W'},
  {id: 'SES_2_87_2W', value: 'SES_2_87_2W'},
  {id: 'SES_4_22W', value: 'SES_4_22W'},
  {id: 'SES_5_5E', value: 'SES_5_5E'},
  {id: 'SES_6_40_5W', value: 'SES_6_40_5W'},
  {id: 'SES_7_108_2E', value: 'SES_7_108_2E'},
  {id: 'SES_8_95W', value: 'SES_8_95W'},
  {id: 'SES_9_5E', value: 'SES_9_5E'},
  {id: 'SIGMA_SAT_1_85_2E', value: 'SIGMA_SAT_1_85_2E'},
  {id: 'SINOSAT_1_110_5E', value: 'SINOSAT_1_110_5E'},
  {id: 'SKYNET_76E', value: 'SKYNET_76E'},
  {id: 'ST_2_88E', value: 'ST_2_88E'},
  {id: 'SYNCOM2_180E', value: 'SYNCOM2_180E'},
  {id: 'TELECOM_2B_5W', value: 'TELECOM_2B_5W'},
  {id: 'TELKOM_1_108E', value: 'TELKOM_1_108E'},
  {id: 'TELSTAR_12_15W', value: 'TELSTAR_12_15W'},
  {id: 'THAICOM_5_78_5E', value: 'THAICOM_5_78_5E'},
  {id: 'THAICOM_6_78_5E', value: 'THAICOM_6_78_5E'},
  {id: 'THAICOM_7_120E', value: 'THAICOM_7_120E'},
  {id: 'THAICOM_8_78_5E', value: 'THAICOM_8_78_5E'},
  {id: 'TURKSAT_2A_42E', value: 'TURKSAT_2A_42E'},
  {id: 'TURKSAT_3A_42E', value: 'TURKSAT_3A_42E'},
  {id: 'TURKSAT_4A_42E', value: 'TURKSAT_4A_42E'},
  {id: 'TURKSAT3A_45E', value: 'TURKSAT3A_45E'},
  {id: 'TURKSAT4B_50E', value: 'TURKSAT4B_50E'},
  {id: 'USER_SAT_1', value: 'USER_SAT_1'},
  {id: 'YAMAL_202_49E', value: 'YAMAL_202_49E'},
  {id: 'YAMAL_300K_183E', value: 'YAMAL_300K_183E'},
  {id: 'YAMAL_402_54_9E', value: 'YAMAL_402_54_9E'},
  {id: 'YAMAL_402_55E', value: 'YAMAL_402_55E'},
  {id: 'YAMAL_49E', value: 'YAMAL_49E'},
  {id: 'YAMAL_90E', value: 'YAMAL_90E'},
  {id: 'OTHER', value: 'OTHER'}
];

export const POLARIZATION = [
  {
    id: 'UNKNOWN',
    value: 'UNKNOWN'
  },
  {
    id: 'POL_HL',
    value: 'POL_HL'
  },
  {
    id: 'POL_VR',
    value: 'POL_VR'
  }
];
