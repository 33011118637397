import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  constructor(private spinnerService: SpinnerService) {}

  ngOnInit(): void {}

  get spinner(): boolean {
    return this.spinnerService.spinner;
  }
}
