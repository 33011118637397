<div class="container my-4">
  <div class="row mx-0">
    <h3 class="color-theme font-weight-bold mb-0">{{"Gestione TV" | translate}} - {{"Tv" | translate}}</h3>
  </div>

  <div class="row mx-0 my-3">
    <div class="container d-flex m-0 p-0">
      <div class="col-6 pl-0">
        <div class="form-group">
          <label for="roomnumber">Numero camera *</label>
          <input type="text" class="form-control" id="roomnumber" name="roomnumber" aria-describedby="roomnumber"
                 [(ngModel)]="tv.roomnumber" />
        </div>
        <div class="form-group">
          <label for="tvlocation">Posizione della tv</label>
          <input type="url" class="form-control" id="tvlocation" name="tvlocation" aria-describedby="tvlocation"
                 [(ngModel)]="tv.tvlocation" />
        </div>
        <div class="form-group">
          <label for="macaddress">Indirizzo mac *</label>
          <input type="url" class="form-control" id="macaddress" name="macaddress" aria-describedby="macaddress"
                 [(ngModel)]="tv.macaddress" />
        </div>
        <div class="form-group">
          <label for="model">Modello</label>
          <input type="url" class="form-control" id="model" name="model" aria-describedby="model" [(ngModel)]="tv.model" />
        </div>
        <div class="form-group">
          <label for="serial">Seriale</label>
          <input type="url" class="form-control" id="serial" name="serial" aria-describedby="serial"
                 [(ngModel)]="tv.serial" />
        </div>
        <div class="form-group">
          <label for="check-in">Check In</label>
          <input type="date" class="form-control" id="check-in" name="check-in" aria-describedby="check-in"
                 [(ngModel)]="tv.checkin" />
        </div>
        <div class="form-group">
          <label for="check-out">Check Out</label>
          <input type="date" class="form-control" id="check-out" name="check-out" aria-describedby="check-out"
                 [(ngModel)]="tv.checkout" />
        </div>
      </div>

      <div class="col-6 pr-0">
        <div class="form-group">
          <label for="channelid">Lista canali</label>
          <select name="channelid" id="channelid" class="form-control" [(ngModel)]="tv.channelid">
            <option *ngFor="let element of channels" [value]="element.id">
              {{ element.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="cloneid">Clone</label>
          <select name="cloneid" id="cloneid" class="form-control" [(ngModel)]="tv.cloneid">
            <option *ngFor="let element of clones" [value]="element.id">
              {{ element.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="firmwareid">Firmware</label>
          <select name="firmwareid" id="firmwareid" class="form-control" [(ngModel)]="tv.firmwareid">
            <option *ngFor="let element of firmwares" [value]="element.id">
              {{ element.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="settingid">Impostazioni *</label>
          <select name="settingid" id="settingid" class="form-control" [(ngModel)]="tv.settingid">
            <option *ngFor="let element of settings" [value]="element.id">
              {{ element.name }}
            </option>
          </select>
        </div>
        <div class="wrapper_welcome-msg form-group">
          <label for="name-guest">Nome</label>
          <input type="text" class="form-control" id="name-guest" name="name-guest" aria-describedby="name-guest" [(ngModel)]="tv.nameguest" />
          <label class="mt-3" for="surname-guest">Cognome</label>
          <input type="text" class="form-control" id="surname-guest" name="surname-guest" aria-describedby="surname-guest" [(ngModel)]="tv.surnameguest" />
          <label class="mt-3" for="surname-guest">Messaggio</label>
          <editor
              name="Messaggio"
              apiKey="1sdv0g430kwfa3fbpe8x98vr7eyu3x9il416pnmm3mjo3s52"
              [init]="{
                font_formats: 'Barlow=Barlow; Roboto=Roboto; Barlow Condensed=Barlow-condensed; Gilda Display=Gilda-display; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MSX=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva' ,
                plugins: ['lists link image paste help wordcount'],
                toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
              }"
              [(ngModel)]="tv.message"
            ></editor>
            <diV class="form-group mt-3 d-flex justify-content-between align-items-center">
              <div class="checkbox-container  ml-4">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="accepttermpolicy"
                  name="accepttermpolicy"
                  [(ngModel)]="tv.visible"
                />
                <label class="form-check-label" for="accepttermpolicy">
                  {{ "Visibile" | translate }}
                </label>
              </div>
              <button class="btn btn-primary" (click)="archivia()">Archivia</button>
            </diV>
        </div>
        <div class="d-flex justify-content-end">
          <div class="px-2">
            <button class="btn btn-primary" (click)="saveTv()">{{ "Salva" | translate }}</button>
          </div>
          <button type="button" routerLink="/tvList" class="btn btn-secondary"><i class="bi bi-arrow-90deg-left"></i> {{ "Annulla" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
