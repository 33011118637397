import {Component, OnInit} from '@angular/core';
import {AccountService} from 'src/app/services/account/account.service';
import {AccountConfiguration, ChangePwdObj, UpdetableAccount, VendorTvManagement} from 'src/app/models/account/account';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TYPOGRAPHY} from '../../../constants/typography.enum';
import * as moment from 'moment';
import { MediaService } from 'src/app/services/media/media.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit {
  accountConfiguration: AccountConfiguration = new AccountConfiguration();
  vendorData: UpdetableAccount = new UpdetableAccount();
  tvManagement: VendorTvManagement = new VendorTvManagement();
  changePwdObj: ChangePwdObj = new ChangePwdObj();
  confirmNewPwd = '';
  admin: boolean =
    localStorage.getItem('HechRomeoRole') !== 'Manager' ? false : true;
  registerForm: FormGroup;
  emailForm: FormGroup;
  changePasswordForm: FormGroup;
  TYPOGRAPHY = TYPOGRAPHY;
  tmp;
  vendorEmailTmp;
  urlTmp: string;

  mediaobject: string;

  constructor(
    private accountService: AccountService,
    private mediaService: MediaService ) {
  }

  ngOnInit(): void {
    this.initializeFormGroups();
    this.getVendorData();
    this.getAccountConfiguration();
  }

  getVendorData() {
    this.accountService.getVendorData().subscribe((data: UpdetableAccount) => {
      this.vendorData = data;
      this.vendorEmailTmp = this.vendorData.email;
      this.urlTmp = this.vendorData.urlhechromeo;
      this.initializeFormGroups();
      this.disableInputIfAdmin(!this.admin, this.registerForm);
      if ( data?.image ) this.mediaService
        .getMediaObject(data.image)
        .subscribe((data) => this.mediaobject = data.mediaobject)
    });

    this.accountService.getVendorTvManagement().subscribe((data) => {
      this.tvManagement = data;
      this.tvManagement.expiration = moment(this.tvManagement.expiration, 'DD/MM/YYYY').format('YYYY-MM-DD');
    });
  }

  async updateVendorData() {
    if (this.admin) {
      for (const controlName in this.registerForm.controls) {
        if (controlName) {
          const control = this.registerForm.controls[controlName];
          if (control) {
            control.markAsDirty();
            control.updateValueAndValidity();
          }
        }
      }
      if (this.registerForm.valid) {
        for (const controlName in this.registerForm.controls) {
          if (controlName) {
            const control = this.registerForm.controls[controlName];
            if (control) {
              this.vendorData[controlName] = '' + control.value;
            }
          }
        }
        await this.mediaService
        .addImagesBulk(localStorage.getItem('HechRomeoAccount'), [this.mediaobject] )
        .then((data) =>  this.vendorData.image = data[0] );
        await this.accountService.updateVendorData(this.vendorData).subscribe(
          () => {},
          (error) => {}
        );
      } else {
        alert(TYPOGRAPHY.INPUT_ERROR_COMPLETE_ALL_FIELDS);
      }
    } else {
      alert(TYPOGRAPHY.INPUT_ERROR_ADMIN_CANT_MODIFY_VALUES);
    }
  }

  updateVendorTvManagement() {
    if (this.admin) {
      let tvVendor: VendorTvManagement = { ...this.tvManagement };

      tvVendor.account = this.vendorData.account;
      tvVendor.expiration = moment(tvVendor.expiration, 'YYYY-MM-DD').format('DD/MM/YYYY');

      this.accountService.updateVendorTvManagement(tvVendor).subscribe(() => {});
    }
  }

  changeVendorEmail() {
    if (this.admin && this.emailForm.valid) {
      let vendorMail = {
        account: this.vendorData.account,
        oldmail: this.vendorData.email,
        newmail: this.vendorEmailTmp,
      };
      this.accountService.changeVendorMail(vendorMail).subscribe(() => {});
    }
  }

  changeVendorUrl() {
    if (this.admin) {
      let vendorUrl = {
        account: this.vendorData.account,
        url: this.urlTmp,
      };

      this.accountService.changeVendorUrl(vendorUrl).subscribe(() => {
      });
    }
  }

  getAccountConfiguration() {
    this.accountService.getAccountConfiguration()
      .subscribe((data) => {
        this.accountConfiguration = data;

        this.accountConfiguration.account = this.accountConfiguration.account ? this.accountConfiguration.account : localStorage.getItem('HechRomeoAccount');

        if (!this.accountConfiguration.configuraton.romeo) {
          this.accountConfiguration.configuraton.romeo = false;
        }
      });
  }

  async setAccountConfiguration() {
    if (!this.accountConfiguration.configuraton.romeo) {
      this.accountConfiguration.configuraton.caching = undefined;
      this.accountConfiguration.configuraton.urlmiddleware = '';
      this.accountConfiguration.configuraton.urlcaching = '';
    } else {
      if (this.accountConfiguration.configuraton.caching === true) {
        this.accountConfiguration.configuraton.urlmiddleware = '';
      } else {
        if (this.accountConfiguration.configuraton.caching === false) {
          this.accountConfiguration.configuraton.urlcaching = '';
        } else  {
          this.accountConfiguration.configuraton.urlmiddleware = '';
          this.accountConfiguration.configuraton.urlcaching = '';
        }
      }
    }

    let isCorrect = true;

    if (this.accountConfiguration.configuraton.romeo) {
      if (this.accountConfiguration.configuraton.caching === true) {
        if (!this.accountConfiguration.configuraton.urlcaching
          || this.accountConfiguration.configuraton.urlcaching.length <= 0) {
          isCorrect = false;
          alert('Errore inserire tutte le informazioni');
        } else {
          if (!new RegExp('^(?!0)(?!.*\\.$)((1?\\d?\\d|25[0-5]|2[0-4]\\d)(\\.|$)){4}$')
            .test(this.accountConfiguration.configuraton.urlcaching)) {
            isCorrect = false;
            alert('Errore l\'ip in formato corretto');
          }
        }
      } else {
        if (this.accountConfiguration.configuraton.caching === false) {
          if (!this.accountConfiguration.configuraton.urlmiddleware
            || this.accountConfiguration.configuraton.urlmiddleware.length <= 0) {
            isCorrect = false;
            alert('Errore inserire tutte le informazioni');
          } else {
            if (!new RegExp('^(http(s):\\/\\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$')
              .test(this.accountConfiguration.configuraton.urlmiddleware)) {
              isCorrect = false;
              alert('Errore l\'url in formato corretto');
            }
          }
        }
      }
    }

    if (isCorrect) {
      await this.accountService.setAccountConfiguration(this.accountConfiguration)
        .subscribe(() => {
          this.getAccountConfiguration();
        });
    }
  }

  private initializeFormGroups() {
    this.registerForm = new FormGroup({
      referrername: new FormControl(this.vendorData.referrername, [
        Validators.required,
      ]),
      referrerlastname: new FormControl(this.vendorData.referrerlastname, [
        Validators.required,
      ]),
      factoryname: new FormControl(this.vendorData.factoryname, [
        Validators.required,
      ]),
      piva: new FormControl(this.vendorData.piva, [Validators.required]),
      address: new FormControl(this.vendorData.address, [Validators.required]),
      city: new FormControl(this.vendorData.city, [Validators.required]),
      state: new FormControl(this.vendorData.state, [Validators.required]),
      zipcode: new FormControl(this.vendorData.zipcode, [Validators.required]),
      country: new FormControl(this.vendorData.country, [Validators.required]),
      banner: new FormControl(this.vendorData.banner, [Validators.required]),
      billingcode: new FormControl(this.vendorData.billingcode, [
        Validators.required,
      ]),
      urlhechromeo: new FormControl(this.vendorData.urlhechromeo),
      phone: new FormControl(this.vendorData.phone),
      bannerbackgroundcolor: new FormControl(this.vendorData.bannerbackgroundcolor),
      bannercolor: new FormControl(this.vendorData.bannercolor),
    });
    if (!this.admin) {
      for (let element in this.registerForm.controls) {
        this.registerForm.controls[element].disable();
      }
      this.changePasswordForm = new FormGroup({
        password: new FormControl(this.changePwdObj.oldpwd, [
          Validators.required,
          Validators.pattern(
            /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
          ),
        ]),
        newpassword: new FormControl(this.changePwdObj.newpwd, [
          Validators.required,
          Validators.pattern(
            /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
          ),
        ]),
        confirmnewpassword: new FormControl(this.confirmNewPwd, [
          Validators.required,
          Validators.pattern(
            /^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/
          ),
        ]),
      });
    } else {
      this.emailForm = new FormGroup({
        changemail: new FormControl(this.vendorEmailTmp, [
          Validators.required,
          Validators.pattern(
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
          ),
        ]),
      });
    }
  }

  private disableInputIfAdmin(admin, formGroup: FormGroup) {
    if (admin) {
      for (const formControlLabel in formGroup.controls) {
        if (formControlLabel) {
          const formControl = formGroup.controls[formControlLabel];
          if (formControl) {
            formControl.disable({onlySelf: true});
          }
        }
      }
    }
  }

  encodeImageFileAsURL(element) {
  //   this.fileToLoad = undefined;
    const file = element[0];
    const reader = new FileReader();
    reader.onloadend = () => this.getPureB64(reader.result.toString());
    reader.readAsDataURL(file);

  }

  deleteImage(element: HTMLInputElement ) {
    element.value = ''
    this.mediaobject = '';
  }

  getPureB64(element: string ) {
    const index = element.indexOf(',');
    element = element.slice(index + 1, element.length);
    this.mediaobject = element;
  }
}
