<div class="container my-4">
  <div class="row mx-0">
    <h3 class="color-theme font-weight-bold mb-0">{{"Gestione TV" | translate}} - {{"Canali tv" | translate}}</h3>
  </div>

  <div class="row mx-0 my-3">
    <div class="container d-flex m-0 p-0">
      <div class="col-6 pl-0">
        <div class="form-group">
          <label for="name">{{"Nome" | translate}} *</label>
          <input
            type="text"
            class="form-control"
            id="name"
            name="name"
            aria-describedby="name"
            [(ngModel)]="channel.name"
          />
        </div>

        <div *ngIf="!id" class="form-group">
          <label for="clone">File *</label>
          <input
            type="file"
            class="form-control"
            id="clone"
            name="clone"
            aria-describedby="clone"
            [(ngModel)]="channel.file"
          />
        </div>

        <div class="form-group">
          <label for="">{{"Lingue" | translate}} *</label>
          <div>
            <div *ngFor="let element of channel.languages" class="d-flex">
              <input
                type="text"
                class="form-control col-3"
                [value]="getLanguageName(element)"
              />
              <button
                class="btn btn-secondary"
                [disabled]="element == channel.defaultlanguage"
                (click)="removeLanguage(element)"
              >
                {{"Elimina" | translate}}
              </button>
            </div>
            <button
              data-toggle="modal"
              data-target="#languageModal"
              class="btn btn-primary"
            >
              {{"Aggiungi lingua" | translate}}
            </button>
          </div>
        </div>

        <div class="form-group">
          <label for="defaultLanguage">{{"Lingua predefinita" | translate}} *</label>
          <select
            class="form-control"
            id="defaultLanguage"
            [(ngModel)]="channel.defaultlanguage"
          >
            <option *ngFor="let element of arraySelectedLanguages" [value]="element.abr">
              {{ element.language }}
            </option>
          </select>
        </div>
      </div>

      <div *ngIf="id" class="col-6 pr-0">
        <div class="form-group">
          <label for="clone">{{"File" | translate}}</label>
          <input
            type="file"
            class="form-control"
            id="clone"
            name="clone"
            aria-describedby="clone"
            [(ngModel)]="channel.file"
          />
        </div>

        <button data-toggle="modal" data-target="#uploadModal" class="btn btn-primary">
          {{"Carica Nuovo File" | translate}}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="id">
    <div class="d-flex justify-content-start" style="float: left;">
      <button (click)="autoSort()" class="btn btn-primary my-3">
        {{"Ordinamento automatico" | translate}}
      </button>
    </div>

    <div class="d-flex justify-content-end">
      <button data-toggle="modal" data-target="#newChannelModal" class="btn btn-primary my-3">
        {{"Aggiungi canale" | translate}}
      </button>
    </div>

    <div class="table">
      <ag-grid-angular
        style="width: 100%; height: 100%"
        class="ag-theme-alpine"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="channel.details"
        [rowSelection]="'multiple'"
        [animateRows]="true"
        (cellClicked)="onCellClicked($event)"
        [rowDragManaged]="true"
        [suppressRowClickSelection]="true"
      ></ag-grid-angular>
    </div>
  </div>

  <div [ngClass]="{'col-6': !id}" class="py-3 d-flex justify-content-end">
    <button class="btn btn-primary" (click)="exportToExcel()">{{ "Esporta in Excel" | translate }}</button>

    <div class="px-2">
      <button class="btn btn-primary" (click)="saveChannel()">{{ "Salva" | translate }}</button>
    </div>

    <button type="button" routerLink="/tvChannels" class="btn btn-secondary"> {{ "Annulla" | translate }}</button>
  </div>
</div>

<!-- Add language modal -->
<div
  class="modal fade"
  id="languageModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="languageModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Aggiungi lingue" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column">
          <div class="form-group">
            <label for="categorytype">{{
              "Seleziona lingua" | translate
              }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="categorytype"
                [(ngModel)]="selectedLanguage"
              >
                <ng-container *ngFor="let item of languages">
                  <option *ngIf="filterLanguage(item.abr)" [value]="item.abr">
                    {{ item.language }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="addLanguage()"
        >
          {{ "Salva" | translate }}
        </button>

        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add upload modal -->
<div
  class="modal fade"
  id="uploadModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="uploadModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Carica nuova lista" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        {{ "Dettagli carica nuova lista" | translate }}
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="uploadNewFile()"
        >
          {{ "Carica" | translate }}
        </button>

        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add new channel modal -->
<div
  class="modal fade"
  id="newChannelModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newChannelModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Aggiungi nuovo canale" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row mx-0">
          <div class="form-group col-6">
            <label>
              {{ "Major" | translate}}
            </label>

            <input [(ngModel)]="newChannelDetail.major" type="number" min="1" class="form-control"/>
          </div>

          <div class="form-group col-6">
            <label>
              {{ "Nome" | translate}}
            </label>

            <input [(ngModel)]="newChannelDetail.name" type="text" class="form-control"/>
          </div>
        </div>

        <div class="row mx-0">
          <div class="form-group col-6">
            <label>
              {{ "Broadcast" | translate}}
            </label>

            <select
              class="form-control"
              id="channelBroadcast"
              [(ngModel)]="newChannelDetail.broadcastStandard"
            >
              <option *ngFor="let element of BROADCAST" [value]="element.id">
                {{ element.value }}
              </option>
            </select>
          </div>

          <div class="form-group col-6">
            <label>
              {{ "Canale" | translate}}
            </label>

            <select
              class="form-control"
              id="channelType"
              [(ngModel)]="newChannelDetail.channelType"
            >
              <option *ngFor="let element of CHANNELTYPE" [value]="element.id">
                {{ element.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mx-0">
          <div class="form-group col-6">
            <label>
              {{ "Programma" | translate}}
            </label>

            <input [(ngModel)]="newChannelDetail.programNumber" type="number" min="1" class="form-control"/>
          </div>

          <div class="form-group col-6">
            <label>
              {{ "Frequenza" | translate}}
            </label>

            <input [disabled]="newChannelDetail.channelType === 'IPTV'" [(ngModel)]="newChannelDetail.frequency" type="number" min="1" class="form-control"/>
          </div>
        </div>

        <div class="row mx-0">
          <div class="form-group col-6">
            <label>
              {{ "Modulazione" | translate}}
            </label>

            <select
              class="form-control"
              id="channelModulation"
              [(ngModel)]="newChannelDetail.modulationType"
              [disabled]="newChannelDetail.channelType === 'IPTV'"
            >
              <option *ngFor="let element of MODULATION" [value]="element.id">
                {{ element.value }}
              </option>
            </select>
          </div>

          <div class="form-group col-6">
            <label>
              {{ "Bandwidth" | translate}}
            </label>

            <select
              class="form-control"
              id="channelBandwidth"
              [(ngModel)]="newChannelDetail.bandwidth"
              [disabled]="newChannelDetail.channelType === 'IPTV'"
            >
              <option *ngFor="let element of BANDWIDTH" [value]="element.id">
                {{ element.value }}
              </option>
            </select>
          </div>
        </div>


        <div class="row mx-0">
          <div class="form-group col-6">
            <label>
              {{ "SymbolRate" | translate}}
            </label>

            <input [disabled]="newChannelDetail.channelType === 'IPTV'" [(ngModel)]="newChannelDetail.symbolRate" type="number" min="1" class="form-control"/>
          </div>

          <div  class="form-group col-6">
            <label>
              {{ "Ip" | translate}}
            </label>

            <input [disabled]="newChannelDetail.channelType !== 'IPTV'" [(ngModel)]="newChannelDetail.ip" type="text" class="form-control"/>
          </div>
        </div>


        <div class="row mx-0">
          <div class="form-group col-6">
            <label>
              {{ "Polarizzazione" | translate}}
            </label>

            <select
              class="form-control"
              id="channelPolarization"
              [(ngModel)]="newChannelDetail.polarization"
              [disabled]="newChannelDetail.channelType === 'IPTV'"
            >
              <option *ngFor="let element of POLARIZATION" [value]="element.id">
                {{ element.value }}
              </option>
            </select>
          </div>

          <div class="form-group col-6">
            <label>
              {{ "Satellite" | translate}}
            </label>

            <select
              class="form-control"
              id="channelSatellite"
              [(ngModel)]="newChannelDetail.satelliteId"
              [disabled]="newChannelDetail.channelType === 'IPTV'"
            >
              <option *ngFor="let element of SATELLITE" [value]="element.id">
                {{ element.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mx-0">
          <div class="form-group col-6">
            <label>
              {{ "Lingua" | translate}}
            </label>

            <select
              class="form-control"
              id="channelLang"
              [(ngModel)]="newChannelDetail.language"
            >
              <option *ngFor="let element of arraySelectedLanguages" [value]="element.abr">
                {{ element.language }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="saveChannelDetail()"
        >
          {{ "Aggiungi" | translate }}
        </button>

        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
