<div class="container-category-projects ">
  <div class="mx-0 w-100 d-flex justify-content-between align-items-center pt-1">
    <div class="form-group col-1 p-0">
      <div>
        <label for="search">{{ "Cerca" | translate }}:</label>
        <input id="search" [(ngModel)]="searchValue" />
      </div>
    </div>

    <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
      <button class="btn btn-primary mb-4 w-100" (click)="addNewProject()">
        + {{ "Aggiungi Progetto" | translate }}
      </button>
    </div>
  </div>

    <div class="card w-100" *ngIf="projectList">
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ "Nome" | translate }}</th>
              <th class="col-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let project of projectList | filter : searchValue" >
              <td class="col"> {{ project.title }} </td>
              <td class="col-auto d-flex">
                <div class="modifyImg mr-1" (click)="downloadProjects(project, 'pdf')">
                  <img
                    src="../../../../../../assets/img/download-pdf-icon.png"
                    style="width: 30px"
                    alt="" />
                </div>
                <div class="modifyImg mr-1" (click)="downloadProjects(project, 'docx')">
                  <img
                    src="../../../../../../assets/img/download-doc-icon.png"
                    style="width: 30px"
                    alt="" />
                </div>
                <div class="modifyImg mr-1" (click)="editProject(project)">
                  <img
                    src="../../../../../../assets/img/Pittogramma modifica.png"
                    style="width: 30px"
                    alt="" />
                </div>
                  <div class="deleteImg" (click)="deleteProject(project)">
                    <img
                      src="../../../../../../assets/img/Pittogramma elimina - cestino.png"
                      style="width: 30px"
                      alt="" />
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

</div>
