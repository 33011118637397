<div class="container my-4">
  <div class="row mx-0">
    <h3 class="color-theme font-weight-bold mb-0">{{"Gestione TV" | translate}} - {{"Firmware" | translate}}</h3>
  </div>

  <div class="row mx-0 my-3">
    <div class="container d-flex m-0 p-0">
      <div class="col-6 pl-0">
        <div class="form-group">
          <label for="name">{{"Nome" | translate}} *</label>
          <input
            type="text"
            class="form-control"
            id="name"
            name="name"
            aria-describedby="name"
            [(ngModel)]="firmware.name"
          />
        </div>

        <div class="form-group">
          <label for="link-firmware">{{"Link" | translate}} *</label>
          <input
            type="url"
            class="form-control"
            id="link-firmware"
            name="link-firmware"
            aria-describedby="link-firmware"
            [(ngModel)]="firmware.link"
          />
        </div>

        <div class="d-flex justify-content-end">
          <div class="px-2">
            <button class="btn btn-primary" (click)="saveFirmware()">{{ "Salva" | translate }}</button>
          </div>

          <button type="button" routerLink="/firmware" class="btn btn-secondary"><i class="bi bi-arrow-90deg-left"></i> {{ "Annulla" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
