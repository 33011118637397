import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Account, ChangePwdObj } from 'src/app/models/account/account';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}
  login(account: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}platform/account/login`, {
      account,
      password,
    });
  }

  register(account: any) {
    account.successMessage = 'Fornitore registrato con successo';
    return this.http.post<any>(
      `${environment.apiUrl}platform/account/register`,
      account
    );
  }

  resetPassword(account: string, email: string, oldpsw = '', newpsw = '') {
   
    return this.http.post<any>(
      `${environment.apiUrl}platform/account/password/reset`,
      { 
        account,
        email,
        oldpsw,
        newpsw,
        successMessage: 'Password resettata con successo',
      }
    );
  }

  changePassword(changePwdObj: any) {
    changePwdObj.successMessage = 'Password cambiata con successo';
    return this.http.post<any>(
      `${environment.apiUrl}platform/account/password/change`,
      changePwdObj
    );
  }
}
