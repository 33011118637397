import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadPdfComponent } from './download-pdf.component';
import { DownloadPdfCategorySelectionComponent } from './components/download-pdf-category-selection/download-pdf-category-selection.component';
import { DownloadPdfProjectsComponent } from './components/download-pdf-projects/download-pdf-projects.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DownloadPdfRoutingModule } from './download-pdf-routing.module';



@NgModule({
  declarations: [
    DownloadPdfComponent,
    DownloadPdfProjectsComponent,
    DownloadPdfCategorySelectionComponent
  ],
  imports: [
    CommonModule,
    DownloadPdfRoutingModule,
    TranslateModule,
    FormsModule,
    Ng2SearchPipeModule
  ]
})
export class DownloadPdfModule { }
