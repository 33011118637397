import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: HttpClient) {}

  getCalendar(account, notbefore, notafter) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/reservation/get/calendar`,
      {
        account,
        notbefore,
        notafter,
      }
    );
  }

  getDaily(account, notbefore, notafter) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/reservation/get/daily`,
      {
        account,
        notbefore,
        notafter,
      }
    );
  }

  changeReservationStatus(reservation) {
    reservation.successMessage =
      'Stato della prenotazione cambiato con successo';
    return this.http.post<any>(
      `${environment.apiUrl}platform/reservation/set/worked`,
      reservation
    );
  }

  cancelCart(reservetion) {
    reservetion.successMessage = 'Carrello annullato con sucesso';
    return this.http.post<any>(
      `${environment.apiUrl}platform/reservation/storno`,
      reservetion
    );
  }

  cancelReservation(
    owner,
    reservationid,
    servicedate,
    servicetime,
    accountemail,
    serviceid,
    ordertype,
    isadvanced,
    slottext
  ) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/reservation/storno`,
      {
        owner,
        reservationid,
        servicedate,
        servicetime,
        accountemail,
        serviceid,
        ordertype,
        isadvanced,
        slottext,
        // successMessage: 'Prenotazione annullata con successo',
      }
    );
  }

  changeStatusCart(cartid, account, OrderStatus, advanced?) {
    let successMessage = 'Stato del carrello cambiato con successo';
    if (!advanced) {
      return this.http.post<any>(
        `${environment.apiUrl}platform/reservation/set/cart/status`,
        { cartid, account, OrderStatus, successMessage }
      );
    } else {
      return this.http.post<any>(
        `${environment.apiUrl}platform/reservation/set/cart-advanced/status`,
        { cartid, account, OrderStatus, successMessage }
      );
    }
  }

  searchRequest(filters) {
    filters.loader = true;
    return this.http.post<any>(`${environment.apiUrl}category/search`, filters);
  }

  getReservationDetails(obj) {
    return this.http.post<any>(`${environment.apiUrl}reservation/details`, obj);
  }

  getShoppingCartDetails(obj) {
    return this.http.post<any>(
      `${environment.apiUrl}shoppingcart/details`,
      obj
    );
  }

  makeReservation(reservation) {
    return this.http.post<any>(
      `${environment.apiUrl}public/category/service/reservation`,
      reservation
    );
  }

  insertShoppingCart(cart) {
    return this.http.post<any>(`${environment.apiUrl}public/uploadcart`, cart);
  }

  insertAdvancedShoppingCart(cart) {
    return this.http.post<any>(
      `${environment.apiUrl}public/uploadcart-advanced`,
      cart
    );
  }

  getAdvancedServicePeriod(account, serviceid, from, to) {
    return this.http.get<any>(
      `${environment.apiUrl}platform/service/advanced/reservation/get`,
      {
        params: {
          account: account,
          serviceid: serviceid,
          from: from,
          to: to,
          loader: 'true',
        },
      }
    );
  }

  changeReservationStatusAdvancedService(data) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/service/advanced/reservation/status/change`,
      data
    );
  }

  advancedServicesMenagerReservation(data) {
    return this.http.post<any>(
      `${environment.apiUrl}category/service/advanced/manager/reservation`,
      data
    );
  }

  setAvailabilityForAdvancedServices(data) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/service/advanced/slot/set/status`,
      data
    );
  }

  myReportSearch(filters) {
    filters.loader = true;
    return this.http.post<any>(
      `${environment.apiUrl}my-report/search`,
      filters
    );
  }

  reportSearch(filters) {
    filters.loader = true;
    return this.http.post<any>(`${environment.apiUrl}report/search`, filters);
  }

  myReportAdvancedSearch(filters) {
    filters.loader = true;
    return this.http.post<any>(
      `${environment.apiUrl}my-report-advanced/search`,
      filters
    );
  }

  reportAdvancedSearch(filters) {
    filters.loader = true;
    return this.http.post<any>(
      `${environment.apiUrl}report-advanced/search`,
      filters
    );
  }

  getAdvancedCartDetails(searchObj) {
    return this.http.post<any>(
      `${environment.apiUrl}shoppingcart-advanced/details`,
      searchObj
    );
  }

  downloadCsv(filters) {
    filters.loader = true;
    return this.http.post<any>(`${environment.apiUrl}platform/export`, filters);
  }

}
