export class Account {
  account = '';
  referrername = '';
  referrerlastname = '';
  email = '';
  password = '';
  factoryname = '';
  piva = '';
  address = '';
  city = '';
  state = '';
  zipcode = '';
  country = '';
  banner = '';
  billingcode = '';
  accepttermpolicy: boolean;
  acceptdatapolicy: boolean;
  adminCreated: boolean;
  urlhechromeo: string = '';
  phone: string = '';
  vendoractive?: boolean = true;
}

export class AccountConfiguration {
  id = '';
  account = '';
  configuraton = {
    enebleregistration: false,
    cartrefiddescription: {},
    policy: false,
    detailpolicy: {},
    romeo: false,
    caching: false,
    urlcaching: '',
    urlmiddleware: '',
    servicewithcategoryname: false
  };
  payments = {
    paypalid: '',
  };
}

export class UpdetableAccount {
  account = '';
  email = '';
  referrername = '';
  referrerlastname = '';
  factoryname = '';
  piva = '';
  address = '';
  city = '';
  state = '';
  zipcode = '';
  country = '';
  banner = '';
  billingcode = '';
  urlhechromeo = '';
  phone = '';
  vendoractive?: boolean = true;
  code: string = '';
  bannerbackgroundcolor: string = '#fff';
  bannercolor: string = '#000';
  image: string = '';
}

export class VendorTvManagement {
  account: string = '';
  expiration: string = '';
  authorizedtvs: number = undefined;
}

export class ChangePwdObj {
  email = '';
  oldpwd = '';
  newpwd = '';
}

export class VendorToken {
  account: string = '';
  active: boolean = true;
  canreserve: boolean = false;
  token: string = '';
}
