<div class="mb-4">
  <h3 class="color-theme font-weight-bold mb-0">
    {{ "Impostazioni icone" | translate }}
  </h3>
</div>

<div class="card">
  <div class="card-body">
    <div class="card-body-icons">
      <img
        [ngClass]="{
          defaultIconImg: true,
          selected: icon.mediaid == selectedDefaultIcon?.mediaid
        }"
        *ngFor="let icon of defaultIcons"
        [src]="
          _DomSanitizationService.bypassSecurityTrustUrl(
            'data:image/svg+xml;base64,' + icon.webaddress
          )
        "
        (click)="selectedDefaultIcon = icon"
        alt=""
      />
    </div>
    <div class="manage-toolbar">
      <button
        class="pl-4 pr-4 mt-2 btn btn-secondary"
        data-toggle="modal"
        data-target="#iconModal"
      >
        + {{ "Aggiungi Icona" | translate }}
      </button>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="iconModal"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-labelledby="iconModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="iconModalLabel">
          {{ "Aggiungi unicona" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <div *ngIf="iconToLoad" class="mb-3">
            <img
              class="backGroundImg"
              *ngIf="iconToLoad"
              [src]="
                _DomSanitizationService.bypassSecurityTrustUrl(
                  'data:image/svg+xml;base64,' + iconToLoad
                )
              "
              style="width: 100px"
              alt=""
            />
          </div>

          <div style="display: flex; flex-direction: row">
            <input
              type="file"
              accept=".svg"
              (change)="encodeImageFileAsURL($event.target.files)"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          data-dismiss="modal"
          type="button"
          (click)="saveIcon()"
          class="btn btn-primary ml-2 pl-4 pr-4"
        >
          {{ "Salva" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
