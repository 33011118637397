import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { Language } from 'src/app/models/languages/language';

@Component({
  selector: 'app-manage-languages',
  templateUrl: './manage-languages.component.html',
  styleUrls: ['./manage-languages.component.scss'],
})
export class ManageLanguagesComponent implements OnInit {
  allLanguages: Language[] = [];
  newLanguage: Language = new Language();
  languageToDelete: Language;
  constructor(private categoriesService: CategoriesService) {}

  ngOnInit(): void {
    this.getLanguages();
  }

  getLanguages() {
    this.categoriesService.getLanguages().subscribe((data) => {
      this.allLanguages = data.languages;
      console.log(this.allLanguages);
    });
  }

  saveNewLanguage() {
    if (this.newLanguage.language != '' && this.newLanguage.language != '') {
      this.allLanguages.push(this.newLanguage);
      this.newLanguage = new Language();
      this.updateLanguages();
    }
  }

  setLanguageToDelete(language: Language) {
    this.languageToDelete = language;
  }

  deleteLanguage(language: Language) {
    this.allLanguages = this.allLanguages.filter(
      (obj) => obj.abr != language.abr
    );
    this.updateLanguages();
  }

  updateLanguages() {
    this.categoriesService.updateLanguages(this.allLanguages).subscribe(() => {
      this.getLanguages();
    });
  }
}
