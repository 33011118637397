export class CategoryTree {
  id: string = '';
  account: string = '';
  categories: Category[] = [];
  services: Service[] = [];
  categoriesServices: CategoryService[] = [];
}

export class Category {
  id: string = '';
  zorder: number = 0;
  title: object = { it: '' };
  description: object = { it: '' };
  categories: Category[] = [];
  services: Service[] = [];
  image: string = '';
  imagetype: number = 0;
  imageType: number = 0;
  categorytype: string = '';
  categoryType: string = '';
  deleted: boolean = false;
  icon: string = '';
  disablebooking: boolean = true;
  disableBooking: boolean = true;
  alignment: number = 0;
  visible?: boolean = true;
  isvirtual?: boolean = false;
  parentaccount?: string = '';
  parentAccount?: string = '';
  parentcategory?: string = '';
  parentCategory?: string = '';
  parentfee?: number = undefined;
  parentFee?: number = undefined;
  isdiscount?: boolean = false;
  isDiscount?: boolean = false;
  categorystartid?: string = '';
  livestream: boolean = false;
  liveStream: boolean = false;
  isVirtual?: boolean = false;
  parentcategoryid?: string = '';
  account?: string = '';
  scroll?: number = 0;
  isSelected?:boolean = false;
  isDownloadable?:boolean = false;
  type?: boolean = false;
}
export class Service {
  id: string = '';
  zorder: number = 0;
  zOrder?: number = 0;
  title: object = { it: '' };
  description: object = { it: '' };
  mlnote: object = { it: '' };
  mlNote: object = { it: '' };
  visible: boolean = true;
  image: string = '';
  imagetype: number = 0;
  imageType: number = 0;
  servicetype: number = 0;
  serviceType: number = 0;
  paymenttype: number = 0;
  paymentType: number = 0;
  amount: number = 0.0;
  maxquantity: number = 0;
  maxQuantity?: number = 0;
  refidtitle: object = { it: '' };
  enableqty: boolean = false;
  enableQty?: boolean = false;
  decreasequantity: boolean = false;
  decreaseQuantity: boolean = false;
  availabledate: string = '';
  availableDate?: string = '';
  availabletime: string = '';
  availableTime?: string = '';
  expirationdate: string = '';
  expirationDate?: string = '';
  expirationtime: string = '';
  expirationTime?: string = '';
  deleted: boolean = false;
  cartenabled: boolean = false;
  cartEnabled: boolean = false;
  idlabels: Array<string> = [];
  idLabels: Array<string> = [];
  departments: DepartmentsInService[] = [];
  soldout: boolean = false;
  soldOut: boolean = false;
  alignment: number = 0;
  isadvanced: boolean = false;
  tree?: string;
  categoryCalendar?: boolean;
  ismultiband: boolean = false;
  isMultiband: boolean = false;
  livestream: boolean = false;
  liveStream: boolean = false;
  periodBooking: boolean = true;
  labels: Array<ServiceLabel> = [];
  parentaccount?: string;
  accepted: boolean = false;
  account: string = '';
  parentcategoryid: string = '';
  isAdvanced?: boolean = false;
  isguest?: boolean = false;
  type?: boolean = false;
  isSelected?:boolean = false;
  isDownloadable?:boolean = false;
}

export class CategoryService {
  id: string = '';
  zorder: number = 0;
  title: object = { it: '' };
  description: object = { it: '' };
  categories: Category[] = [];
  services: Service[] = [];
  image: string = '';
  imagetype: number = 0;
  imageType: number = 0;
  categorytype: string = '';
  categoryType: string = '';
  deleted: boolean = false;
  icon: string = '';
  disablebooking: boolean = true;
  disableBooking: boolean = true;
  alignment: number = 0;
  visible?: boolean = true;
  isvirtual?: boolean = false;
  parentaccount?: string = '';
  parentAccount?: string = '';
  parentcategory?: string = '';
  parentCategory?: string = '';
  parentfee?: number = undefined;
  parentFee?: number = undefined;
  isdiscount?: boolean = false;
  isDiscount?: boolean = false;
  categorystartid?: string = '';
  livestream: boolean = false;
  liveStream: boolean = false;
  isVirtual?: boolean = false;
  parentcategoryid?: string = '';
  account?: string = '';
  scroll?: number = 0;
  zOrder?: number = 0;
  mlnote: object = { it: '' };
  mlNote: object = { it: '' };
  servicetype: number = 0;
  serviceType: number = 0;
  paymenttype: number = 0;
  paymentType: number = 0;
  amount: number = 0.0;
  maxquantity: number = 0;
  maxQuantity?: number = 0;
  refidtitle: object = { it: '' };
  enableqty: boolean = false;
  enableQty?: boolean = false;
  decreasequantity: boolean = false;
  decreaseQuantity: boolean = false;
  availabledate: string = '';
  availableDate?: string = '';
  availabletime: string = '';
  availableTime?: string = '';
  expirationdate: string = '';
  expirationDate?: string = '';
  expirationtime: string = '';
  expirationTime?: string = '';
  cartenabled: boolean = false;
  cartEnabled: boolean = false;
  idlabels: Array<string> = [];
  idLabels: Array<string> = [];
  departments: DepartmentsInService[] = [];
  soldout: boolean = false;
  soldOut: boolean = false;
  isadvanced: boolean = false;
  tree?: string;
  categoryCalendar?: boolean;
  ismultiband: boolean = false;
  isMultiband: boolean = false;
  periodBooking: boolean = true;
  labels: Array<ServiceLabel> = [];
  accepted: boolean = false;
  isAdvanced?: boolean = false;
  iscategory: boolean = false;
  categoriesServices: CategoryService[] = [];
}

export class AdvancedService {
  account: string = '';
  serviceperiods: Period[] = [];
}

export class AdvancedServiceGet {
  serviceid: string = '';
  periods: PeriodGet[] = [];
  title: string = '';
  unavailableperiod: Array<any> = [];
}

export class Period {
  period: PeriodOptions = new PeriodOptions();
  servicedata: ServiceData = new ServiceData();
  id?: string;
}

export class PeriodGet {
  edited?: boolean;
  delete?: boolean;
  index?: number;
  from: string = '';
  to: string = '';
  perioddata: ServiceData = new ServiceData();
  periodid: string = '';
  hideendtime: boolean = false;
  closereservationtime?: string = '';
  availablity?: Array<availablityAdvancedServices>;
}

export class PeriodOptions {
  serviceid: string = '';
  from: string = '';
  to: string = '';
  account?: string;
  id?: string;
  hideendtime: boolean = false;
  closereservationtime?: string = '';
}

export class ServiceData {
  maxservicequantity: number = 0;
  note: string = '';
  enableGlobalQty: boolean = true;
  enableSlotQty: boolean = false;
  enablePricing: boolean = false;
  timeslots: TimeSlots[] = [];
  periodid?: string;
  id?: string;
  repeat: boolean = false;
  weekly: boolean = true;
  days: number[] = [];
}

export class TimeSlots {
  id?: string;
  timeslottext: string = '';
  duration: number = 0;
  maxquantity: number = 0;
  price: number = 0;
  note: string = '';
  localizednote: Object = {};
  isavailable: boolean = true;
  index?: number;
  timefrommidnight?: number;
  enablespecialprice: boolean = false;
  weekly: boolean = true;
  days: Array<number> = [];
  specialprice: number = 0;
}

export class DepartmentsInService {
  iddepartments: string = '';
  sendnotification: boolean = true;
}

export class availablityAdvancedServices {
  account?: string;
  serviceid?: string;
  date: string;
  allday: boolean = false;
  timeslottext: string;
  setunavailable: boolean = true;
}

export class ServiceLabel {
  labelid: string = '';
  mandatory: boolean = false;
  label?;
  title?;
  id?;
}
