<div class="row mx-0">
  <div class="col-12 my-2">
    <div class="row mx-0 w-100">
      <div class="form-group col-1 p-0">
        <div>
          <label for="search"> {{ "Cerca" | translate }}: </label>
          <input id="search" [(ngModel)]="searchValue" />
        </div>
      </div>

      <div class="col-7 col-sm-7 col-ml-7 col-xl-9"></div>

      <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
        <button
          data-toggle="modal"
          data-target="#add-admin"
          class="btn btn-primary mb-4 w-100"
        >
          + {{ "Aggiungi Utente" | translate }}
        </button>
      </div>
    </div>

    <div class="row mx-0">
      <div class="card w-100">
        <div class="card-body" style="overflow-y: auto">
          <table class="table">
            <thead>
              <tr>
                <th *ngIf="admin" scope="col">Account</th>
                <th scope="col">Email</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let adminUser of adminList | filter: searchValue">
                <td
                  *ngIf="admin"
                  style="
                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    width: 30%;
                  "
                >
                  {{ adminUser.account }}
                </td>
                <td
                  *ngIf="admin"
                  style="
                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    width: 65%;
                  "
                >
                  {{ adminUser.email }}
                </td>
                <td
                  *ngIf="!admin"
                  style="
                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    width: 95%;
                  "
                >
                  {{ adminUser.email }}
                </td>
                <td
                  style="
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    padding: 0;
                    cursor: pointer;
                    width: 5%;
                  "
                >
                  <div class="d-flex flex-row justify-content-end w-100">
                    <div
                      data-toggle="modal"
                      data-target="#delete-admin"
                      class="delete-img"
                      (click)="
                        adminDeleteEmail.email = adminUser.email;
                        adminDeleteEmail.account = adminUser.account
                      "
                    >
                      <img
                        src="assets/img/Pittogramma%20elimina%20-%20cestino.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="add-admin"
  data-backdrop="static"
  data-keybord="false"
  tabindex="-1"
  role="dialog"
  aria-label="add-admin"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "Aggiungi un utente" | translate }}</h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div *ngIf="admin" class="row mx-0">
          <div class="col-12 form-group">
            <label for="account"> Account </label>

            <input
              [(ngModel)]="adminCreate.account"
              name="account"
              type="text"
              class="form-control"
              id="account"
              aria-describedby="email"
            />
          </div>
        </div>

        <div class="row mx-0">
          <div class="col-12 form-group">
            <label for="email"> Email </label>

            <input
              [(ngModel)]="adminCreate.email"
              name="email"
              type="text"
              class="form-control"
              id="email"
              aria-describedby="email"
            />
          </div>
        </div>

        <div class="row mx-0">
          <div class="col-12 form-group">
            <label for="password"> Password </label>

            <input
              [(ngModel)]="adminCreate.password"
              name="password"
              type="password"
              class="form-control"
              id="password"
              aria-describedby="password"
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>

        <button
          data-dismiss="modal"
          type="button"
          class="btn btn-primary"
          (click)="saveAdmin()"
        >
          {{ "Salva" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="delete-admin"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delete-admin"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "Confermare eliminazione" | translate }}</h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        {{
          "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
            | translate
        }}
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>

        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="deleteAdmin()"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
