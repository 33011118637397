import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Clone } from 'src/app/models/tv/tv';
import { TvService } from 'src/app/services/tv/tv.service';

@Component({
  selector: 'app-clone-details',
  templateUrl: './clone-details.component.html',
  styleUrls: ['./clone-details.component.scss'],
})
export class CloneDetailsComponent implements OnInit {
  id: string;
  clone: Clone = new Clone();
  constructor(
    private route: ActivatedRoute,
    private tvService: TvService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.getClone();
      }
    });
  }

  getClone() {
    this.tvService.getClone(this.id).subscribe((data) => {
      this.clone = data;
    });
  }

  saveClone() {
    const fileElement = document.getElementById('clone') as HTMLInputElement;

    if (this.clone === undefined || this.clone.name === undefined || this.clone.name.length <= 0 || fileElement === undefined
      || fileElement.files.length <= 0 || fileElement.files[0] === undefined || fileElement.files[0].size <= 0) {
      alert('Errore inserire tutte le informazioni');
    } else {
      this.clone.account = localStorage.getItem('HechRomeoAccount');

      let clone = new FormData();

      clone.append('file', fileElement.files[0]);
      clone.append('name', this.clone.name);
      clone.append('account', this.clone.account);

      if (this.id) {
        clone.append('id', this.id);
        this.updateClone(clone);
      } else {
        this.createClone(clone);
      }
    }
  }

  createClone(clone) {
    this.tvService.createClone(clone).subscribe((data) => {
      this.router.navigate(['clones']);
    });
  }

  updateClone(clone) {
    this.tvService.updateClone(clone).subscribe((data) => {
      this.router.navigate(['clones']);
    });
  }
}
