import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CategoriesService } from 'src/app/services/categories/categories.service';
@Component({
  selector: 'app-manage-category-types',
  templateUrl: './manage-category-types.component.html',
  styleUrls: ['./manage-category-types.component.scss'],
})
export class ManageCategoryTypesComponent implements OnInit {
  categoryTypes = [];
  newCategoryType: string = '';
  typeToDelete: string;
  constructor(private categoriesService: CategoriesService) {}

  ngOnInit(): void {
    this.getCategoryTypes();
  }

  getCategoryTypes() {
    this.categoriesService.getCategoryTypes().subscribe((data) => {
      this.categoryTypes = data.tipologies;
    });
  }

  saveNewCategoryType() {
    console.log(this.newCategoryType);
    if (this.newCategoryType != '') {
      this.categoryTypes.push(this.newCategoryType);
      this.newCategoryType = '';
      this.updateCategoryTypes();
    }
  }

  setTypeToDelete(type: string) {
    this.typeToDelete = type;
  }

  deleteType(type: string) {
    this.categoryTypes = this.categoryTypes.filter(
      (obj) => obj != type
    );
    this.updateCategoryTypes();
  }

  updateCategoryTypes() {
    this.categoriesService
      .updateCategoryTypes(this.categoryTypes)
      .subscribe(() => {
        this.getCategoryTypes();
      });
  }
}
