<div class="container my-4">
  <div class="row mx-0">
    <h3 class="color-theme font-weight-bold mb-0">{{"Gestione TV" | translate}} - {{"Impostazioni" | translate}}</h3>
  </div>

  <div class="row mx-0 my-3">
    <div class="container d-flex m-0 p-0">
      <div class="col-6 pl-0">
        <div class="form-group">
          <label for="name">{{"Nome" | translate}} *</label>
          <input
            type="text"
            class="form-control"
            id="name"
            name="name"
            aria-describedby="name"
            [(ngModel)]="tvSettings.name"
          />
        </div>

        <div class="form-group">
          <label for="">{{"Lite" | translate}} *</label>
          <div class="form-check">
            <input
              [disabled]="!isAdmin"
              class="form-check-input"
              type="radio"
              name="isliteproject"
              id="isliteproject1"
              [value]="true"
              [(ngModel)]="tvSettings.isliteproject"
            />
            <label class="form-check-label" for="isliteproject1"> Sì </label>
          </div>
          <div class="form-check">
            <input
              [disabled]="!isAdmin"
              class="form-check-input"
              type="radio"
              name="isliteproject"
              id="isliteproject2"
              [value]="false"
              [(ngModel)]="tvSettings.isliteproject"
            />
            <label class="form-check-label" for="isliteproject2"> No </label>
          </div>
        </div>
        <div class="form-group">
          <label for="image">{{ "Foto o Video" | translate }} Homepage</label>
          <small>(Max 50Mb)</small>
          <div>
            <!-- titpi 1: immagine, 2: video, 3: icona e string in b64-->
            <div>
              <div class="form-check">
                <input
                  [value]="1"
                  [(ngModel)]="tvSettings.imageType"
                  (change)="fileToLoad = undefined"
                  type="radio"
                  class="form-check-input"
                  id="imagetype"
                  name="imagetype"
                />
                <label for="form-check-label">{{
                  "Immagine" | translate
                  }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="2"
                  [(ngModel)]="tvSettings.imageType"
                  (change)="fileToLoad = undefined"
                  type="radio"
                  class="form-check-input"
                  id="imagetype"
                  name="imagetype"
                />
                <label for="form-check-label">{{ "Video" | translate }}</label>
              </div>
            </div>
            <div *ngIf="tvSettings.imageType == 1">
              <div class="mb-2">
                <img
                  *ngIf="imageToLoad.image"
                  [src]="'data:image/jpg;base64,' + imageToLoad.image"
                  style="width: 450px"
                  alt=""
                />
              </div>
              <div class="mb-3" style="display: flex; flex-direction: row">
                <input
                  type="file"
                  accept=".jpg"
                  (change)="encodeImageFileAsURL($event.target.files, 1, 0)"
                />
                <button
                  [disabled]="!tvSettings.welcomeproject"
                  type="button"
                  (click)="deleteImage(0)"
                  class="btn btn-danger ml-2"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>

            <div *ngIf="tvSettings.imageType == 2">
              <div class="mb-3">
                <youtube-player
                  [ngClass]="{ hideElement: id == '' || id == undefined }"
                  id="playerService"
                  (ready)="loadPlayer()"
                >
                </youtube-player>

                <video
                  [ngClass]="{ hideElement: id != '' && id != undefined }"
                  width="450px"
                  controls
                  *ngIf="videoToLoad"
                  [src]="'data:video/mp4;base64,' + videoToLoad"
                >
                </video>
              </div>
              <div class="youtubeInput">
                <div class="row mx-0 w-100">
                  <div class="col-8 p-0">
                    <input
                      [(ngModel)]="url"
                      type="text"
                      class="form-control"
                      id="url"
                      name="url"
                      placeholder="Inserisci un link di youtube"
                    />
                  </div>
                  <div class="col-4 p-0">
                    <button
                      [disabled]="url == ''"
                      type="button"
                      class="ml-2 mr-2 btn btn-secondary"
                      (click)="loadNewYoutubeVideo()"
                    >
                      {{ "Carica" | translate }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      (click)="deleteYoutubeVideo()"
                      [disabled]="playerLoaded == false"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>
                </div>
              </div>
              <small *ngIf="urlError" class="errorUrl">{{
                "L'url non è valida" | translate
                }}</small>
              <div class="mt-2 mb-2">
                <span>{{ "Oppure" | translate }}</span>
              </div>

              <div style="display: flex; flex-direction: row">
                <input
                  type="file"
                  accept=".mp4"
                  (change)="encodeImageFileAsURL($event.target.files, 2)"
                />
                <button
                  [disabled]="!videoToLoad"
                  type="button"
                  (click)="
                  videoToLoad = undefined;
                  tvSettings.imageType = undefined;
                  tvSettings.welcomeproject = undefined;
                  id = undefined;
                  url = undefined;
                "
                  class="btn btn-danger ml-2"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="banner" style="margin-bottom: 0px;">Banner</label>
          <small>(Max 50Mb)</small>
          <div style="margin-bottom: 10px; margin-top: -5px;">
            <small>{{ "Max 8 immagini" | translate }}</small>
          </div>
          <div>
            <div *ngFor="let element of bannerToLoad; index as i">
              <div class="mb-2">
                <img
                  *ngIf="element.image"
                  [src]="'data:image/jpg;base64,' + element.image"
                  style="width: 450px"
                  alt=""
                />
              </div>
              <div class="mb-3" style="display: flex; flex-direction: row">
                <input
                  type="file"
                  accept=".jpg"
                  (change)="encodeImageFileAsURLBanner($event.target.files, i)"
                />
                <button
                  [disabled]="!element.image"
                  type="button"
                  (click)="deleteBanner(i)"
                  class="btn btn-danger ml-2"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="primarycolor">{{ "Colore Background" | translate }}</label>

          <br/>

          <input [(ngModel)]="tvSettings.primarycolor" type="color" style="width: 20%;" />
        </div>

        <div class="form-group">
          <label for="secondarycolor">{{ "Colore background selected" | translate }}</label>

          <br/>

          <input [(ngModel)]="tvSettings.secondarycolor" type="color" style="width: 20%;" />
        </div>

        <div class="form-group">
          <label for="textprimarycolor">{{ "Colore testo titolo" | translate }}</label>

          <br/>

          <input [(ngModel)]="tvSettings.textprimarycolor" type="color" style="width: 20%;" />
        </div>

        <div class="form-group">
          <label for="textsecondarycolor">{{ "Colore testo descrizione" | translate }}</label>

          <br/>

          <input [(ngModel)]="tvSettings.textsecondarycolor" type="color" style="width: 20%;" />
        </div>

        <div class="form-group">
          <label for="texttertiarycolor">{{ "Colore testo menù" | translate }}</label>

          <br/>

          <input [(ngModel)]="tvSettings.texttertiarycolor" type="color" style="width: 20%;" />
        </div>

        <div class="form-group">
          <label for="texttertiarycolorselected">{{ "Colore testo menù selezionato" | translate }}</label>

          <br/>

          <input [(ngModel)]="tvSettings.texttertiarycolorselected" type="color" style="width: 20%;" />
        </div>

        <div class="form-group">
          <label for="">{{ "Lingue" | translate }} *</label>
          <div>
            <div *ngFor="let element of tvSettings.languages" class="d-flex">
              <input
                type="text"
                class="form-control col-3"
                [value]="getLanguageName(element)"
              />
              <button
                class="btn btn-secondary"
                [disabled]="element == tvSettings.defaultlanguage"
                (click)="removeLanguage(element)"
              >
                {{ "Elimina" | translate }}
              </button>
            </div>
            <button
              data-toggle="modal"
              data-target="#languageModal"
              class="btn btn-primary"
            >
              {{ "Aggiungi lingua" | translate }}
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="defaultLanguage">{{ "Lingua predefinita" | translate }} *</label>
          <select
            class="form-control"
            id="defaultLanguage"
            [(ngModel)]="tvSettings.defaultlanguage"
          >
            <option *ngFor="let element of arraySelectedLanguages" [value]="element.abr">
              {{ element.language }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-6 pr-0">
        <div class="form-group">
          <label for="">{{ "Applicazioni" | translate }}</label>
          <div class="form-group">
            <label for="">Chromecast *</label>
            <div class="form-check">
              <input
                [disabled]="!isAdmin"
                class="form-check-input"
                type="radio"
                name="chromecast"
                id="chromecast1"
                [value]="true"
                [(ngModel)]="tvSettings.applications.chromecast"
              />
              <label class="form-check-label" for="chromecast1"> Sì </label>
            </div>
            <div class="form-check">
              <input
                [disabled]="!isAdmin"
                class="form-check-input"
                type="radio"
                name="chromecast"
                id="chromecast2"
                [value]="false"
                [(ngModel)]="tvSettings.applications.chromecast"
              />
              <label class="form-check-label" for="chromecast2"> No </label>
            </div>
          </div>
          <div class="form-group" *ngIf="tvSettings.applications.chromecast">
            <label for="">{{"Versione" | translate}} *</label>
            <div class="form-check">
              <input
                [disabled]="!isAdmin"
                class="form-check-input"
                type="radio"
                name="newchromecastmodel"
                id="newchromecastmodel1"
                [value]="false"
                [(ngModel)]="tvSettings.newchromecast"
              />
              <label class="form-check-label" for="sky1"> {{"Generazione3" | translate}}</label>
            </div>
            <div class="form-check">
              <input
                [disabled]="!isAdmin"
                class="form-check-input"
                type="radio"
                name="newchromecastmodel"
                id="newchromecastmodel2"
                [value]="true"
                [(ngModel)]="tvSettings.newchromecast"
              />
              <label class="form-check-label" for="sky2"> {{"Generazione4" | translate}}</label>
            </div>
          </div>
          <div class="form-group" *ngIf="tvSettings.applications.chromecast">
            <label for="hdmichromecastnumber">{{"Numero porta HDMI" | translate}} *</label>
            <input
              [disabled]="!isAdmin"
              type="text"
              class="form-control"
              id="hdmichromecastnumber"
              name="hdmichromecastnumber"
              aria-describedby="hdmichromecastnumber"
              [(ngModel)]="tvSettings.applications.hdmichromecastnumber"
            />
          </div>
          <div class="form-group">
            <label for="">Sky *</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="sky"
                id="sky1"
                [value]="true"
                [(ngModel)]="tvSettings.applications.sky"
              />
              <label class="form-check-label" for="sky1"> Sì </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="sky"
                id="sky2"
                [value]="false"
                [(ngModel)]="tvSettings.applications.sky"
              />
              <label class="form-check-label" for="sky2"> No </label>
            </div>
          </div>
          <ng-container *ngIf="tvSettings.applications.sky">
            <div class="form-group">
              <label for="">{{"Seleziona pacchetto Sky" | translate}} *</label>
              <div style="display: flex; flex-direction: row">
                <select
                  class="form-control"
                  name="typesky"
                  (change)="selectedTypeSkyChange($event.target.value)"
                  [(ngModel)]="tvSettings.applications.typesky">
                  <option [value]="1">{{"Decoder Sky" | translate}}</option>
                  <option [value]="0">{{"Sky Digital" | translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-group" *ngIf="tvSettings.applications.typesky === 1">
              <label for="hdmisky">{{"Numero porta SKY" | translate}} *</label>
              <input
                type="text"
                class="form-control"
                id="hdmisky"
                name="hdmisky"
                aria-describedby="hdmisky"
                [(ngModel)]="tvSettings.applications.hdmisky"
              />
            </div>
            <div class="form-group" *ngIf="tvSettings.applications.typesky === 0">
              <label for="channelsky">{{"Indicare canale di partenza" | translate}} *</label>
              <input
                type="text"
                class="form-control"
                id="channelsky"
                name="channelsky"
                aria-describedby="channelsky"
                [(ngModel)]="tvSettings.applications.channelsky"
              />
            </div>
          </ng-container>
          <div class="form-group">
            <label for="">Airtime *</label>
            <div class="form-check">
              <input
                [disabled]="!isAdmin"
                class="form-check-input"
                type="radio"
                name="airtime"
                id="airtime1"
                [value]="true"
                [(ngModel)]="tvSettings.applications.airtime"
              />
              <label class="form-check-label" for="airtime1"> Sì </label>
            </div>
            <div class="form-check">
              <input
                [disabled]="!isAdmin"
                class="form-check-input"
                type="radio"
                name="airtime"
                id="airtime2"
                [value]="false"
                [(ngModel)]="tvSettings.applications.airtime"
              />
              <label class="form-check-label" for="airtime2"> No </label>
            </div>
          </div>
          <div class="form-group">
            <label for="">Youtube *</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="youtube"
                id="youtube1"
                [value]="true"
                [(ngModel)]="tvSettings.applications.youtube"
              />
              <label class="form-check-label" for="airtime1"> Sì </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="youtube"
                id="youtube2"
                [value]="false"
                [(ngModel)]="tvSettings.applications.youtube"
              />
              <label class="form-check-label" for="youtube2"> No </label>
            </div>
          </div>
          <div class="form-group">
            <label for="">Browser *</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="browser"
                id="browser1"
                [value]="true"
                [(ngModel)]="tvSettings.applications.browser"
              />
              <label class="form-check-label" for="browser1"> Sì </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="browser"
                id="browser2"
                [value]="false"
                [(ngModel)]="tvSettings.applications.browser"
              />
              <label class="form-check-label" for="browser2"> No </label>
            </div>
          </div>
          <div class="form-group">
            <label for="">Wifi *</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="wifi"
                id="wifi1"
                [value]="true"
                [(ngModel)]="tvSettings.applications.wifi"
              />
              <label class="form-check-label" for="wifi1"> Sì </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="wifi"
                id="wifi2"
                [value]="false"
                [(ngModel)]="tvSettings.applications.wifi"
              />
              <label class="form-check-label" for="wifi2"> No </label>
            </div>
          </div>
          <div class="form-group">
            <label for="additionalsettings">Note Tecniche</label>
            <input
              type="text"
              class="form-control"
              id="additionalsettings"
              name="additionalsettings"
              aria-describedby="additionalsettings"
              [(ngModel)]="tvSettings.applications.additionalsettings"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div class="px-2">
            <button class="btn btn-primary" (click)="saveSettings()">{{ "Salva" | translate }}</button>
          </div>

          <button type="button" routerLink="/tvSettings" class="btn btn-secondary"><i class="bi bi-arrow-90deg-left"></i> {{ "Annulla" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="languageModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="languageModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Aggiungi lingue" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column">
          <div class="form-group">
            <label for="categorytype">{{
              "Seleziona lingua" | translate
              }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="categorytype"
                [(ngModel)]="selectedLanguage"
              >
                <ng-container *ngFor="let item of languages">
                  <option *ngIf="filterLanguage(item.abr)" [value]="item.abr">
                    {{ item.language }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="addLanguage()"
        >
          {{ "Salva" | translate }}
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
