<div *ngIf="selectingService" class="modal-body">
  <div class="w-100 d-flex category-box">
    <div class="card-category-col">
      <div class="w-100 h-100 overflow-auto">
        <div
          *ngFor="let element of categoriesLevel0.categories"
          class="mb-2 listElement"
        >
          <div class="card card-category-text-wrapper">
            <div class="card-category-text" (click)="selectItem(element, 0)">
              <div class="type">
                <span>{{ "Categoria" | translate }}</span>
              </div>

              <div
                class="d-flex flex-center w-100 h-100 align-items-center"
                id="{{ element.id }}"
              >
                <span class="category text-center">{{
                  getTitle(element)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-category-col">
      <div class="w-100 h-100 overflow-auto">
        <div
          *ngFor="let element of categoriesLevel1.categories"
          class="mb-2 listElement"
        >
          <div class="card card-category-text-wrapper">
            <div class="card-category-text" (click)="selectItem(element, 1)">
              <div class="type">
                <span>{{ "Categoria" | translate }}</span>
              </div>

              <div
                class="d-flex flex-center w-100 h-100 align-items-center"
                id="{{ element.id }}"
              >
                <span class="category text-center">{{
                  getTitle(element)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let element of categoriesLevel1.services"
          class="mb-2 listElement"
        >
          <div class="card card-category-text-wrapper">
            <div
              class="card-category-text"
              (click)="selectItem(element, 1, true)"
            >
              <div class="type">
                <span>{{ "Servizio" | translate }}</span>
              </div>

              <div
                class="d-flex flex-center w-100 h-100 align-items-center"
                [ngClass]="{
                  'card-category-non-selectable':
                    bookingCart &&
                    (!element.cartenabled ||
                      bookingAdvanced != element.isadvanced)
                }"
                id="{{ element.id }}"
              >
                <span class="category text-center">{{
                  getTitle(element)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-category-col">
      <div class="w-100 h-100 overflow-auto">
        <div
          *ngFor="let element of categoriesLevel2.categories"
          class="mb-2 listElement"
        >
          <div class="card card-category-text-wrapper">
            <div class="card-category-text" (click)="selectItem(element, 2)">
              <div class="type">
                <span>{{ "Categoria" | translate }}</span>
              </div>

              <div
                class="d-flex flex-center w-100 h-100 align-items-center"
                id="{{ element.id }}"
              >
                <span class="category text-center">{{
                  getTitle(element)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let element of categoriesLevel2.services"
          class="mb-2 listElement"
        >
          <div class="card card-category-text-wrapper">
            <div
              class="card-category-text"
              (click)="selectItem(element, 2, true)"
            >
              <div class="type">
                <span>{{ "Servizio" | translate }}</span>
              </div>

              <div
                class="d-flex flex-center w-100 h-100 align-items-center"
                [ngClass]="{
                  'card-category-non-selectable':
                    bookingCart &&
                    (!element.cartenabled ||
                      bookingAdvanced != element.isadvanced)
                }"
                id="{{ element.id }}"
              >
                <span class="category text-center">{{
                  getTitle(element)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-category-col">
      <div class="w-100 h-100 overflow-auto">
        <div
          *ngFor="let element of categoriesLevel3.categories"
          class="mb-2 listElement"
        >
          <div class="card card-category-text-wrapper">
            <div class="card-category-text" (click)="selectItem(element, 3)">
              <div class="type">
                <span>{{ "Categoria" | translate }}</span>
              </div>

              <div
                class="d-flex flex-center w-100 h-100 align-items-center"
                id="{{ element.id }}"
              >
                <span class="category text-center">{{
                  getTitle(element)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let element of categoriesLevel3.services"
          class="mb-2 listElement"
        >
          <div class="card card-category-text-wrapper">
            <div
              class="card-category-text"
              (click)="selectItem(element, 3, true)"
            >
              <div class="type">
                <span>{{ "Servizio" | translate }}</span>
              </div>

              <div
                class="d-flex flex-center w-100 h-100 align-items-center"
                [ngClass]="{
                  'card-category-non-selectable':
                    bookingCart &&
                    (!element.cartenabled ||
                      bookingAdvanced != element.isadvanced)
                }"
                id="{{ element.id }}"
              >
                <span class="category text-center">{{
                  getTitle(element)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-category-col">
      <div class="w-100 h-100 overflow-auto">
        <div
          *ngFor="let element of categoriesLevel4.categories"
          class="mb-2 listElement"
        >
          <div class="card card-category-text-wrapper">
            <div class="card-category-text" (click)="selectItem(element, 4)">
              <div class="type">
                <span>{{ "Categoria" | translate }}</span>
              </div>

              <div
                class="d-flex flex-center w-100 h-100 align-items-center"
                id="{{ element.id }}"
              >
                <span class="category text-center">{{
                  getTitle(element)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let element of categoriesLevel4.services"
          class="mb-2 listElement"
        >
          <div class="card card-category-text-wrapper">
            <div
              class="card-category-text"
              (click)="selectItem(element, 4, true)"
            >
              <div class="type">
                <span>{{ "Servizio" | translate }}</span>
              </div>

              <div
                class="d-flex flex-center w-100 h-100 align-items-center"
                [ngClass]="{
                  'card-category-non-selectable':
                    bookingCart &&
                    (!element.cartenabled ||
                      bookingAdvanced != element.isadvanced)
                }"
                id="{{ element.id }}"
              >
                <span class="category text-center">{{
                  getTitle(element)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      selectedItem &&
      selectedItem.cartenabled &&
      selectedItem.isadvanced &&
      (!bookingCart || (bookingCart && bookingAdvanced))
    "
    class="d-flex pt-2"
  >
    <div class="form-group col-4">
      <label>{{ "Data inizio" | translate }}</label>
      <div>
        <input
          [(ngModel)]="newAdvancedCart.shoppingcartdatefrom"
          [disabled]="bookingCart && bookingAdvanced"
          (change)="
            advancedServiceChangedDate(
              selectedItem,
              newAdvancedCart.shoppingcartdatefrom,
              newAdvancedCart.shoppingcartdateto
            )
          "
          style="width: 100%"
          type="date"
          class="form-control"
          id="shoppingcartdatefrom"
          name="shoppingcartdatefrom"
        />
      </div>
    </div>
    <div class="form-group col-4">
      <label>{{ "Data fine" | translate }}</label>
      <div>
        <input
          [(ngModel)]="newAdvancedCart.shoppingcartdateto"
          [disabled]="bookingCart && bookingAdvanced"
          (change)="
            advancedServiceChangedDate(
              selectedItem,
              newAdvancedCart.shoppingcartdatefrom,
              newAdvancedCart.shoppingcartdateto
            )
          "
          style="width: 100%"
          type="date"
          class="form-control"
          id="shoppingcartdateto"
          name="shoppingcartdateto"
        />
      </div>
    </div>
    <div class="form-group col-4">
      <label>{{ "Fasce orarie" | translate }}</label>
      <div>
        <select
          style="width: 100% !important"
          id="timeslots"
          multiple="multiple"
        >
          <option
            *ngFor="let element of timeslots"
            [value]="element.timeslottext"
          >
            {{ element.timeslottext }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="cancelSelectingService()"
    >
      {{ "Annulla" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="
        !selectedItem ||
        selectedItem.serviceType == undefined ||
        (bookingCart && !selectedItem.cartEnabled) ||
        (bookingCart &&
          bookingAdvanced &&
          !newAdvancedCart.partner != !selectedItem.parentaccount) ||
        (bookingCart &&
          !bookingAdvanced &&
          !newCart.partner != !selectedItem.parentaccount) ||
        (bookingService &&
          !newReservation.partner != !selectedItem.parentaccount)
      "
      (click)="confirmSelectedItem()"
    >
      {{ "Seleziona" | translate }}
    </button>
  </div>
</div>
<div *ngIf="!selectingService && bookingService">
  <div class="modal-body">
    <div class="d-flex flex-wrap">
      <div class="form-group col-4">
        <label>{{ "Quantità" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newReservation.quantity"
            style="width: 100%"
            type="number"
            class="form-control"
            id="quantity"
            name="quantity"
            #quantity="ngModel"
            required
          />
        </div>
        <small
          *ngIf="
            (quantity.touched || quantity.dirty || checked) &&
            newReservation.quantity <= 0
          "
          id="quantity-check"
          class="text-danger"
          >Maggiore di zero</small
        >
      </div>
      <div class="form-group col-4">
        <label>{{ "Email ospite" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newReservation.email"
            style="width: 100%"
            type="email"
            class="form-control"
            id="customeremail"
            name="customeremail"
            #customeremail="ngModel"
            required
          />
        </div>
        <small
          *ngIf="
            (customeremail.touched || customeremail.dirty || checked) &&
            !newReservation.email
          "
          id="quantity-check"
          class="text-danger"
          >Campo obbligatorio</small
        >
      </div>

      <div class="form-group col-4">
        <label>{{ "Nome ospite" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newReservation.name"
            type="text"
            style="width: 100%"
            type="text"
            class="form-control"
            id="customername"
            name="customername"
            #customername="ngModel"
            required
          />
          <small
            *ngIf="
              (customername.touched || customername.dirty || checked) &&
              !newReservation.name
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>
      <div class="form-group col-4">
        <label>{{ "Cognome ospite" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newReservation.lastname"
            type="text"
            style="width: 100%"
            type="text"
            class="form-control"
            id="customerlastname"
            name="customerlastname"
            #customerlastname="ngModel"
            required
          />
          <small
            *ngIf="
              (customerlastname.touched || customerlastname.dirty || checked) &&
              !newReservation.lastname
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>

      <div class="form-group col-4">
        <label>{{
          selectedItem && selectedItem.isadvanced
            ? ("Data inizio" | translate)
            : ("Data" | translate)
        }}</label>
        <div>
          <input
            [(ngModel)]="newReservation.servicedate"
            (change)="
              advancedServiceChangedDate(
                selectedItem,
                this.newReservation.servicedate,
                this.newReservation.serviceenddate
              )
            "
            style="width: 100%"
            type="date"
            class="form-control"
            id="servicedate"
            name="servicedate"
            #servicedate="ngModel"
            required
          />
          <small
            *ngIf="
              (servicedate.touched || servicedate.dirty || checked) &&
              !newReservation.servicedate
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
          <small
            *ngIf="
              (servicedate.touched || servicedate.dirty || checked) &&
              newReservation.servicedate &&
              dateError == 'isBeforeCurrent'
            "
            id="quantity-check"
            class="text-danger"
            >La data non deve essere antecedente alla data corrente</small
          >
        </div>
      </div>
      <div
        *ngIf="selectedItem && selectedItem.isadvanced"
        class="form-group col-4"
      >
        <label>{{ "Data fine" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newReservation.serviceenddate"
            (change)="
              advancedServiceChangedDate(
                selectedItem,
                this.newReservation.servicedate,
                this.newReservation.serviceenddate
              )
            "
            style="width: 100%"
            type="date"
            class="form-control"
            id="serviceenddate"
            name="serviceenddate"
            #serviceenddate="ngModel"
            required
          />
          <small
            *ngIf="
              (serviceenddate.touched || serviceenddate.dirty || checked) &&
              !newReservation.serviceenddate
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
          <small
            *ngIf="
              (serviceenddate.touched || serviceenddate.dirty || checked) &&
              newReservation.serviceenddate &&
              dateError == 'isBeforeEnd'
            "
            id="quantity-check"
            class="text-danger"
            >La data di fine non deve essere antecedente alla data di
            inizio</small
          >
        </div>
      </div>

      <div
        class="form-group col-4"
        [ngClass]="{
          'd-none': !selectedItem || !selectedItem.isadvanced
        }"
      >
        <label>{{ "Fasce orarie" | translate }}</label>
        <div>
          <select
            style="width: 100% !important"
            id="timeslots"
            multiple="multiple"
          >
            <option
              *ngFor="let element of timeslots"
              [value]="element.timeslottext"
            >
              {{ element.timeslottext }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="form-group col-4"
        *ngIf="!selectedItem || !selectedItem.isadvanced"
      >
        <label>{{ "Orario" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newReservation.servicetime"
            style="width: 100%"
            type="time"
            class="form-control"
            id="servicetime"
            name="servicetime"
            #servicetime="ngModel"
            required
          />
          <!-- <small
            *ngIf="
              (servicetime.touched || servicetime.dirty || checked) &&
              !newReservation.servicetime
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          > -->
        </div>
      </div>
      <!-- <div class="form-group col-4">
            <label for="categorytype">{{
              "Seleziona etichetta" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="selectedLabel"
              >
                <option
                  *ngFor="let element of newReservation.labelinfo"
                  [ngValue]="element"
                >
                  {{ element.name.it }}
                </option>
              </select>
            </div>
          </div> -->
      <div
        class="form-group col-4"
        *ngFor="let element of newReservation.labelinfos"
      >
        <label>{{ getLabelName(element) }}</label>
        <div>
          <input
            [(ngModel)]="element.message"
            style="width: 100%"
            type="text"
            class="form-control"
            id="labelMessage"
            name="labelMessage"
          />
        </div>
      </div>
      <div class="form-group col-4">
        <label>Note</label>
        <div>
          <textarea
            [(ngModel)]="newReservation.note"
            style="width: 100%"
            class="form-control"
            id="serviceNotes"
            name="serviceNotes"
            rows="1"
          >
          </textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">
      {{ "Annulla" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      data-dismiss="modal"
      (click)="makeReservation()"
    >
      {{ "Conferma" | translate }}
    </button>
  </div>
</div>
<div *ngIf="!selectingService && bookingCart && !bookingAdvanced">
  <div class="modal-body">
    <div>
      <div class="col-4 pb-2">
        <button class="btn btn-primary" (click)="changeOrAddService()">
          {{ "Aggiungi un servizio al carrello" | translate }}
        </button>
      </div>

      <div class="d-flex" *ngFor="let element of newCart.items">
        <div class="form-group col-4">
          <label>{{ "Titolo" | translate }}</label>
          <div>
            <input
              [(ngModel)]="element.title"
              style="width: 100%"
              type="text"
              disabled
              class="form-control"
              id="title"
              name="title"
            />
          </div>
        </div>
        <div class="form-group col-4">
          <label>{{ "Quantità" | translate }}</label>
          <div>
            <input
              [(ngModel)]="element.quantity"
              style="width: 100%"
              type="number"
              min="1"
              class="form-control"
              id="quantity"
              name="quantity"
              #quantity="ngModel"
              required
            />
            <small
              *ngIf="
                (quantity.touched || quantity.dirty || checked) &&
                element.quantity <= 0
              "
              id="quantity-check"
              class="text-danger"
              >{{
                "Il minimo accettabile come quantità è uno" | translate
              }}</small
            >
          </div>
        </div>
        <div class="form-group col-4">
          <label for="categorytype" style="opacity: 0">{{
            "Seleziona servizio" | translate
          }}</label>
          <div>
            <button
              class="btn btn-secondary"
              (click)="deleteItemFromCart(element)"
            >
              {{ "Elimina" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="d-flex flex-wrap">
      <div class="form-group col-4">
        <label>{{ "Email ospite" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newCart.email"
            style="width: 100%"
            type="email"
            class="form-control"
            id="customeremail"
            name="customeremail"
            #customeremail="ngModel"
            required
          />
          <small
            *ngIf="
              (customeremail.touched || customeremail.dirty || checked) &&
              !newCart.email
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>
      <div class="form-group col-4">
        <label>{{ "Nome ospite" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newCart.name"
            type="text"
            style="width: 100%"
            type="text"
            class="form-control"
            id="customername"
            name="customername"
            #customername="ngModel"
            required
          />
          <small
            *ngIf="
              (customername.touched || customername.dirty || checked) &&
              !newCart.name
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>
      <div class="form-group col-4">
        <label>{{ "Cognome ospite" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newCart.lastname"
            type="text"
            style="width: 100%"
            type="text"
            class="form-control"
            id="customerlastname"
            name="customerlastname"
            #customerlastname="ngModel"
            required
          />
          <small
            *ngIf="
              (customerlastname.touched || customerlastname.dirty || checked) &&
              !newCart.lastname
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>
      <div class="form-group col-4">
        <label>{{ "Data" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newCart.shoppingcartdate"
            (change)="
              advancedServiceChangedDate(true, newCart.shoppingcartdate)
            "
            style="width: 100%"
            type="date"
            class="form-control"
            id="shoppingcartdate"
            name="shoppingcartdate"
            #shoppingcartdate="ngModel"
            required
          />
          <small
            *ngIf="
              (shoppingcartdate.touched || shoppingcartdate.dirty || checked) &&
              !newCart.shoppingcartdate
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
          <small
            *ngIf="
              (shoppingcartdate.touched || shoppingcartdate.dirty || checked) &&
              newCart.shoppingcartdate &&
              dateError == 'isBeforeEnd'
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>
      <div class="form-group col-4">
        <label>{{ "Orario" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newCart.shoppimgcarttime"
            style="width: 100%"
            type="time"
            class="form-control"
            id="shoppimgcarttime"
            name="shoppimgcarttime"
            #shoppimgcarttime="ngModel"
          />
          <!-- <small
            *ngIf="
              (shoppimgcarttime.touched || shoppimgcarttime.dirty || checked) &&
              !newCart.shoppimgcarttime
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          > -->
        </div>
      </div>
      <div class="form-group col-4" *ngFor="let element of cartLabelsList">
        <label>{{ getLabelName(element) }}</label>
        <div>
          <input
            [(ngModel)]="element.message"
            style="width: 100%"
            type="text"
            class="form-control"
            id="labelMessage"
            name="labelMessage"
          />
        </div>
      </div>
      <div class="form-group col-4">
        <label>Note</label>
        <div>
          <textarea
            [(ngModel)]="newCart.notes"
            style="width: 100%"
            class="form-control"
            id="cartNotes"
            name="cartNotes"
            rows="1"
          >
          </textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">
      {{ "Annulla" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="insertCart()">
      {{ "Conferma" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary d-none"
      id="confirm-cart"
      data-dismiss="modal"
    >
      {{ "Conferma" | translate }}
    </button>
  </div>
</div>
<div *ngIf="!selectingService && bookingCart && bookingAdvanced">
  <div class="modal-body">
    <div>
      <div class="col-4 pb-2">
        <button class="btn btn-primary" (click)="changeOrAddService()">
          {{ "Aggiungi un servizio al carrello" | translate }}
        </button>
      </div>

      <div *ngFor="let element of newAdvancedCart.reservations">
        <label>{{ element.title }}</label>
        <div class="d-flex" *ngFor="let slot of element.slots">
          <div class="form-group col-4">
            <label>{{ "Timeslot" | translate }}</label>
            <div>
              <input
                [(ngModel)]="slot.timeslot"
                style="width: 100%"
                type="text"
                disabled
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>

          <div class="form-group col-4">
            <label>{{ "Quantità" | translate }}</label>
            <div>
              <input
                [(ngModel)]="slot.quantity"
                style="width: 100%"
                type="number"
                min="1"
                class="form-control"
                id="quantity"
                name="quantity"
                #quantity="ngModel"
                required
              />
              <small
                *ngIf="
                  (quantity.touched || quantity.dirty || checked) &&
                  slot.quantity <= 0
                "
                id="quantity-check"
                class="text-danger"
                >{{
                  "Il minimo accettabile come quantità è uno" | translate
                }}</small
              >
            </div>
          </div>

          <div class="form-group col-4">
            <label for="categorytype" style="opacity: 0">{{
              "Seleziona servizio" | translate
            }}</label>
            <div>
              <button
                class="btn btn-secondary"
                (click)="deleteItemFromCart(element, slot)"
              >
                {{ "Elimina" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="d-flex flex-wrap">
      <div class="form-group col-4">
        <label>{{ "Email ospite" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newAdvancedCart.email"
            style="width: 100%"
            type="email"
            class="form-control"
            id="customeremail"
            name="customeremail"
            #customeremail="ngModel"
            required
          />
          <small
            *ngIf="
              (customeremail.touched || customeremail.dirty || checked) &&
              !newAdvancedCart.email
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>
      <div class="form-group col-4">
        <label>{{ "Nome ospite" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newAdvancedCart.name"
            type="text"
            style="width: 100%"
            type="text"
            class="form-control"
            id="customername"
            name="customername"
            #customername="ngModel"
            required
          />
          <small
            *ngIf="
              (customername.touched || customername.dirty || checked) &&
              !newAdvancedCart.name
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>
      <div class="form-group col-4">
        <label>{{ "Cognome ospite" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newAdvancedCart.lastname"
            type="text"
            style="width: 100%"
            type="text"
            class="form-control"
            id="customerlastname"
            name="customerlastname"
            #customerlastname="ngModel"
            required
          />
          <small
            *ngIf="
              (customerlastname.touched || customerlastname.dirty || checked) &&
              !newAdvancedCart.lastname
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>

      <div class="form-group col-4">
        <label>{{ "Data inizio" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newAdvancedCart.shoppingcartdatefrom"
            (change)="
              advancedServiceChangedDate(
                true,
                newAdvancedCart.shoppingcartdatefrom
              )
            "
            style="width: 100%"
            type="date"
            class="form-control"
            id="shoppingcartdatefrom"
            name="shoppingcartdatefrom"
            #shoppingcartdatefrom="ngModel"
            required
            disabled
          />
          <small
            *ngIf="
              (shoppingcartdatefrom.touched ||
                shoppingcartdatefrom.dirty ||
                checked) &&
              !newAdvancedCart.shoppingcartdatefrom
            "
            id="quantity-check"
            class="text-danger"
            >Campo obbligatorio</small
          >
        </div>
      </div>
      <div class="form-group col-4">
        <label>{{ "Data fine" | translate }}</label>
        <div>
          <input
            [(ngModel)]="newAdvancedCart.shoppingcartdateto"
            style="width: 100%"
            type="date"
            class="form-control"
            id="shoppingcartdateto"
            name="shoppingcartdateto"
            disabled
          />
        </div>
      </div>

      <div class="form-group col-4" *ngFor="let element of cartLabelsList">
        <label>{{ getLabelName(element) }}</label>
        <div>
          <input
            [(ngModel)]="element.message"
            style="width: 100%"
            type="text"
            class="form-control"
            id="customeremail"
            name="customeremail"
          />
        </div>
      </div>
      <div class="form-group col-4">
        <label>Note</label>
        <div>
          <textarea
            [(ngModel)]="newAdvancedCart.notes"
            style="width: 100%"
            class="form-control"
            id="cartNotes"
            name="cartNotes"
            rows="1"
          >
          </textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">
      {{ "Annulla" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="insertCart()">
      {{ "Conferma" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary d-none"
      id="confirm-cart"
      data-dismiss="modal"
    >
      {{ "Conferma" | translate }}
    </button>
  </div>
</div>
