<div class="mb-4">
  <h3 class="color-theme font-weight-bold mb-0">
    {{ "Impostazioni tipologia" | translate }}
  </h3>
</div>
<div class="language-container">
  <div class="card">
    <div class="card-body">
      <table class="table manage-table">
        <thead>
          <tr>
            <th scope="col" class="col-languages">
              {{ "Tipologie" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let element of categoryTypes">
            <td>{{ element }}</td>
            <td
              style="
                border-right: 1px solid rgba(0, 0, 0, 0.125);
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                padding: 0;
                width: 0.1%;
              "
            >
              <div
                class="deleteImg"
                data-toggle="modal"
                data-target="#delTypeModal"
                (click)="setTypeToDelete(element)"
              >
                <img
                  src="assets/img/Pittogramma elimina - cestino.png"
                  style="width: 30px"
                  alt=""
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="manage-toolbar">
        <button
          class="pl-4 pr-4 mt-2 btn btn-secondary"
          data-toggle="modal"
          data-target="#categoryTypesModal"
        >
          + {{ "Aggiungi Tipologia" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="categoryTypesModal"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-labelledby="categoryTypesModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="categoryTypesModalLabel">
          {{ "Aggiungi un tipo di categoria" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="language-form">
          <form>
            <div class="form-group">
              <label for="categoryTypes">{{ "Tipologia" | translate }}</label>
              <input
                [(ngModel)]="newCategoryType"
                name="newCategoryType"
                type="text"
                class="form-control"
                id="categoryTypes"
                aria-describedby="categoryTypes"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          data-dismiss="modal"
          type="button"
          class="btn btn-primary ml-2 pl-4 pr-4"
          (click)="saveNewCategoryType()"
        >
          {{ "Salva" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="delTypeModal"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delTypeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="delTypeModalLabel">
          {{ "Elimina una tipologia" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Sei sicuro di voler eliminare questa tipologia? Eliminare una tipologia in uso da parte di categorie o servizi può causare problemi e errori"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          data-dismiss="modal"
          type="button"
          class="btn btn-primary ml-2 pl-4 pr-4"
          data-toggle="modal"
          data-target="#delConfirmTypeModal"
        >
          {{ "Elimina" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="delConfirmTypeModal"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delConfirmTypeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="delConfirmTypeModalLabel">
          {{ "Elimina una tipologia" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Sei davvero sicuro di voler eliminare questa tipologia?" | translate
        }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          data-dismiss="modal"
          type="button"
          class="btn btn-primary ml-2 pl-4 pr-4"
          (click)="deleteType(typeToDelete)"
        >
          {{ "Elimina" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
