import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Language } from 'src/app/models/languages/language';
import { Project } from 'src/app/models/signage/signage';

@Injectable({
  providedIn: 'root',
})
export class SignageService {
  constructor(private http: HttpClient) {}

  createProject(project: any) {
    project.successMessage = 'Progetto creato correttamente';
    project.account = localStorage.getItem('HechRomeoAccount');
    return this.http.post<any>(`${environment.apiUrl}project`, project);
  }

  createPage(projectId: string, page: any) {
    page.successMessage = 'Pagina creata correttamente';
    return this.http.post<any>(
      `${environment.apiUrl}project/${projectId}/page`,
      page
    );
  }

  getProjects(account: string) {
    return this.http.get<any>(`${environment.apiUrl}project`, {
      params: {
        account,
      },
    });
  }

  getProject(id: string) {
    return this.http.get<any>(`${environment.apiUrl}project/${id}`);
  }

  getPage(projectId: string, pageId: string) {
    return this.http.get<any>(
      `${environment.apiUrl}project/${projectId}/page/${pageId}`
    );
  }

  updateProject(project: any) {
    project.successMessage = 'Progetto aggiornato correttamente';
    return this.http.put<any>(`${environment.apiUrl}project`, project);
  }

  updatePage(projectId: string, page: any) {
    page.successMessage = 'Pagina aggiornata correttamente';
    return this.http.put<any>(
      `${environment.apiUrl}project/${projectId}/page`,
      page
    );
  }

  deleteProject(id: string) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { successMessage: 'Progetto eliminato correttamente' },
    };
    return this.http.delete<any>(`${environment.apiUrl}project/${id}`, options);
  }

  deletePage(projectId: string, pageId: string) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { successMessage: 'Pagina eliminata correttamente' },
    };
    return this.http.delete<any>(
      `${environment.apiUrl}project/${projectId}/page/${pageId}`,
      options
    );
  }
}
