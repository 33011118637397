import { availablityAdvancedServices } from './../../models/categoryTree/category-tree';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  CategoryTree,
  Category,
} from 'src/app/models/categoryTree/category-tree';
import { Language } from 'src/app/models/languages/language';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private http: HttpClient) {}

  getVendorTree(vendor?: string) {
    let data = vendor ? vendor : localStorage.getItem('HechRomeoAccount');
    return this.http.get<CategoryTree>(
      `${environment.apiUrl}platform/category/get/${data}`
    );
  }

  updateCategoryTree(tree: any) {
    console.log('tree', tree);
    tree.successMessage = 'Categorie e servizi aggiornati correttamente';
    return this.http.post<CategoryTree>(
      `${environment.apiUrl}platform/category/refresh/tree`,
      tree
    );
  }

  publicCategoryGet(data) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/category/get`,
      data
    );
  }

  addRootRequest(category: any) {
    category.successMessage = 'Categoria root creata correttamente';
    return this.http.post<any>(
      `${environment.apiUrl}platform/category/add/root`,
      category
    );
  }

  updateLanguages(languages: Language[]) {
    return this.http.post<any>(`${environment.apiUrl}media/manage/launguages`, {
      languages,
      successMessage: 'Lingue aggiornate correttamente',
    });
  }
  getLanguages() {
    return this.http.get<any>(`${environment.apiUrl}media/languages`);
  }
  getCategoryTypes() {
    return this.http.get<any>(
      `${environment.apiUrl}platform/category/get/tipology`
    );
  }
  updateCategoryTypes(types: string[]) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/category/manage/tipology`,
      {
        tipologies: types,
        successMessage: 'Tipi di categorie aggiornati correttamente',
      }
    );
  }

  createAdvancedServiceFeatures(data) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/service/advanced/create`,
      data
    );
  }

  updateAdvancedServiceFeatures(data) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/service/advanced/update`,
      data
    );
  }

  setAvailabilityForAdvancedServices(data) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/service/advanced/slot/set/status`,
      data
    );
  }

  advancedServicesManagerReservation(data) {
    return this.http.post<any>(
      `${environment.apiUrl}category/service/advanced/manager/reservation`,
      data
    );
  }

  deleteAdvancedService(serviceId, periodId) {
    return this.http.delete<any>(
      `${environment.apiUrl}platform/service/advanced/delete`,
      {
        params: {
          serviceid: serviceId,
          periodid: periodId,
          account: localStorage.getItem('HechRomeoAccount'),
        },
      }
    );
  }

  getAdvancedServiceFullData(id) {
    return this.http.get<any>(
      `${environment.apiUrl}platform/service/advanced/get/details`,
      {
        params: {
          serviceid: id,
          account: localStorage.getItem('HechRomeoAccount'),
        },
      }
    );
  }

  getAdvancedServiceData(id, date, account?, endDate?) {
    if (!account) {
      account = localStorage.getItem('HechRomeoAccount');
    }
    return this.http.get<any>(
      `${environment.apiUrl}platform/service/advanced/get`,
      {
        params: {
          serviceid: id,
          account: account,
          date,
          endDate,
        },
      }
    );
  }

  advancedServicesBulkDelete(data) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/service/advanced/delete/bulk`,
      data
    );
  }

  advancedServicesBulkUpdate(data) {
    return this.http.post<any>(
      `${environment.apiUrl}platform/service/advanced/update/bulk`,
      data
    );
  }

  setAvailablity(objAvailablity: availablityAdvancedServices) {
    return this.http.post(
      environment.apiUrl + 'platform/service/advanced/slot/set/status',
      objAvailablity
    );
  }

  createVirtualCategory(data: any) {
    data.successMessage = 'Categoria virtuale creata correttamente';
    return this.http.post<any>(
      `${environment.apiUrl}platform/category/add/virtual`,
      data
    );
  }

  updateVirtualCategory(data: any) {
    data.successMessage = 'Categoria virtuale aggiornati correttamente';
    return this.http.post<any>(
      `${environment.apiUrl}platform/category/update/virtual`,
      data
    );
  }

  deleteVirtualCategory(id, account) {
    return this.http.delete<any>(
      `${environment.apiUrl}platform/category/delete/virtual?id=${id}&account=${account}`
    );
  }

  // new service
  createCategory(category) {
    return this.http.post<any>(`${environment.apiUrl}category`, category);
  }

  updateCategory(category) {
    return this.http.put<any>(`${environment.apiUrl}category`, category);
  }

  updateVisibleCategory(category) {
    return this.http.put<any>(`${environment.apiUrl}category/visible`, category);
  }

  deleteCategory(account: string, idParentCategory: string, idCategory: string) {
    return this.http.delete<any>(`${environment.apiUrl}category?account=${account}&parentcategoryid=${idParentCategory}&categoryid=${idCategory}`);
  }

  sortCategory(body: any) {
    return this.http.put<any>(`${environment.apiUrl}category/sort`, body);
  }

  createService(body) {
    return this.http.post<any>(`${environment.apiUrl}category/service`, body);
  }

  updateService(body) {
    return this.http.put<any>(`${environment.apiUrl}category/service`, body);
  }

  deleteService(account: string, idParentCategory: string, serviceId: string) {
    return this.http.delete<any>(`${environment.apiUrl}category/service?account=${account}&parentcategoryid=${idParentCategory}&serviceid=${serviceId}`);
  }

  copyCutCategory(body: any) {
    return this.http.put(`${environment.apiUrl}category/copy-cut`, body);
  }
}
