import { Component, OnInit } from '@angular/core';
import { CategoryProject, DownloadFile } from 'src/app/models/projects-pdf/projects-pdf';
import { HttpDownloadPdfService } from './services/http-download-pdf.service';
import { DownloadPdfToolsService } from './services/download-pdf-tools.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';



@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrls: ['./download-pdf.component.scss']
})
export class DownloadPdfComponent implements OnInit {

  mockProjectPdf: CategoryProject[];
  isDetailPage: boolean = false;
  projectSelected: CategoryProject | null = null;

  constructor(
    private httpService: HttpDownloadPdfService,
    private downloadPdfService: DownloadPdfToolsService,
    private spinner: SpinnerService,
   ) { }

  ngOnInit(): void {
    //mock chiamata
    this.refreshList();
  }

  handlerProjectDeleted( project: CategoryProject){
    this.spinner.showSpinner();
    this.httpService.deleteCategoryProject( project.account, project.id ).subscribe(data => {
      this.refreshList();
      this.spinner.hideSpinner();
    }, error => console.log(error));
  }

  handlerProjectDownload( downloadFile: DownloadFile){
    this.spinner.showSpinner();
    this.httpService.getDownloadProject(downloadFile?.project?.id, downloadFile.type ).subscribe(data => {
      this.downloadPdfService.downloadFile(data, downloadFile?.project.title);
      this.spinner.hideSpinner();
    }, error => {
      console.log(error);
      this.spinner.hideSpinner();
    })
  }

  handlerProjectSelected( project: CategoryProject | null){
    this.isDetailPage = true;
    this.projectSelected = JSON.parse(JSON.stringify(project));
  }

  saveProjectSelected( project: CategoryProject ){
    this.isDetailPage = false;
    this.spinner.showSpinner();
    project.id ?
    this.httpService.updateCategoryProject(project)
    .subscribe( data => {
      this.refreshList();
      this.spinner.hideSpinner();
    }, error => {
      console.log(error);
      this.spinner.hideSpinner();
    }
  )
    :  this.httpService.createCategoryProject(project).subscribe( data => {
      this.refreshList();
      this.spinner.hideSpinner();
    }, error => {
      console.log(error);
      this.spinner.hideSpinner();
    })
  }

  goBack(){
    this.isDetailPage = false;
    this.projectSelected = null;
  }

  refreshList(){
    this.spinner.showSpinner();
    this.httpService.getCategoryProject(localStorage.getItem('HechRomeoAccount')).subscribe(data => {
      this.mockProjectPdf = data;
      this.spinner.hideSpinner();
    }, error => {
      console.log(error);
      this.spinner.hideSpinner();
    })
  }
}
