import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadPdfToolsService {

  constructor() { }

  downloadFile(data, title: string){
    const pdfObjectUrl = URL.createObjectURL(data);

      const filename = title;
      const anchorEl = document.createElement("a");
      anchorEl.href = pdfObjectUrl;
      anchorEl.download = filename;
      anchorEl.click();

      setTimeout(function() { URL.revokeObjectURL(pdfObjectUrl)}, 1000);
  }
}
