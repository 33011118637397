import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminServiceService {
  constructor(private http: HttpClient) {}

  getVedorList(page: number) {
    return this.http.get<any>(
      `${environment.apiUrl}platform/account/get/${page}/3000`
    );
  }

  deleteVendor(username: string) {
    sessionStorage.setItem(
      'SuccessMessageHech',
      'Fornitore eliminato con successo'
    );
    return this.http.get<any>(
      `${environment.apiUrl}platform/account/delete/${username}`
    );
  }

  addManager(manager: any) {
    manager.successMessage = 'Aggiunto utente con successo';

    return this.http.post<any>(
      `${environment.apiUrl}platform/add/manager`,
      manager
    );
  }

  getAllAdmin() {
    return this.http.get<any>(`${environment.apiUrl}account/get/admin`);
  }

  deleteAdmin(account: any) {
    account.successMessage = 'Cancellazione utente con successo';

    let httpParams = new HttpParams();
    httpParams = httpParams.set('account', account.account);

    return this.http.delete<any>(`${environment.apiUrl}account/delete/admin`, {
      params: httpParams,
    });
  }
}
