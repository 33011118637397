<div class="container-fluid p-0 my-2">
  <div class="row mx-0 w-100">
    <div class="col-12 col-xl-4 my-2">
      <app-manage-icons></app-manage-icons>
    </div>

    <div class="col-12 col-xl-4 my-2">
      <app-manage-languages></app-manage-languages>
    </div>

    <div class="col-12 col-xl-4 my-2">
      <app-manage-category-types></app-manage-category-types>
    </div>
  </div>
</div>
