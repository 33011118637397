<!-- Then put toasts within -->
<div #toastNotification *ngIf="notification" class="toast m-3" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <img *ngIf="notification?.image" [src]="notification?.image?.url" class="rounded mr-2" [alt]="notification?.image?.alt">
    <strong *ngIf="notification?.title" class="mr-auto">{{notification?.title}}</strong>
    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
      <span aria-hidden="true" (click)="deleteNotification()">&times;</span>
    </button>
  </div>
  <div *ngIf="notification?.body" class="toast-body">
    {{notification?.body}}
  </div>
</div>