import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/services/media/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-categories-variables',
  templateUrl: './manage-categories-variables.component.html',
  styleUrls: ['./manage-categories-variables.component.scss'],
})
export class ManageCategoriesVariablesComponent implements OnInit {
  defaultIcons: string;
  selectedDefaultIcon;
  iconToLoad;
  loadedNewIcon: boolean = false;
  allLanguages = environment.languages;
  categoryTypes = environment.categoryTypes;

  constructor(
    private mediaService: MediaService,
    public _DomSanitizationService: DomSanitizer
  ) {}

  ngOnInit(): void {}

  getDefaultIcons() {
    this.mediaService.getDefaultIconList().subscribe((data) => {
      this.defaultIcons = data;
    });
  }

  async saveIcon() {
    if (this.iconToLoad) {
      await this.mediaService.addDefaultIcon(this.iconToLoad).then((data) => {
        this.iconToLoad = undefined;
        this.getDefaultIcons();
      });
    }
  }
}
