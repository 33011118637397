import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(private http: HttpClient) {}

  getDepartments() {
    return this.http.get<any>(
      `${environment.apiUrl}platform/department/get/${localStorage.getItem(
        'HechRomeoAccount'
      )}`
    );
  }

  createDepartment(department) {
    department.account = localStorage.getItem('HechRomeoAccount');
    return this.http.post<any>(
      `${environment.apiUrl}platform/department/create`,
      department
    );
  }

  updateDepartment(department) {
    department.account = localStorage.getItem('HechRomeoAccount');
    return this.http.post<any>(
      `${environment.apiUrl}platform/department/update`,
      department
    );
  }

  deleteDepartment(department) {
    // department.account = localStorage.getItem('HechRomeoAccount');
    return this.http.delete<any>(
      `${environment.apiUrl}platform/department/delete/${department}`
    );
  }
}
