import { Component, OnInit } from '@angular/core';
import {
  AccountConfiguration,
  VendorToken,
} from 'src/app/models/account/account';
import { Department } from 'src/app/models/department/department';
import { Label } from 'src/app/models/label/label';
import { AccountService } from 'src/app/services/account/account.service';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { DepartmentService } from 'src/app/services/department/department.service';
import { LabelService } from 'src/app/services/label/label.service';
import {objectKeys} from 'codelyzer/util/objectKeys';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  accountConfiguration: AccountConfiguration = new AccountConfiguration();
  allLanguages = [];
  currentLanguage;
  allDepartments = [];
  allLabels = [];
  allPolicyDetails = [];
  allLangs = [];
  departmentLanguageAssociation = {};
  labelLanguageAssociation = {};
  newDepartment: Department = new Department();
  newLabel: Label = new Label();
  newPolicy = {
    lang: '',
    value: ''
  };
  newItemLanguage = 'it';
  modifying = false;
  modifyingPolicy = false;
  deleteFunction: string = '';
  deleteElement;
  token: VendorToken = new VendorToken();
  admin = localStorage.getItem('HechRomeoRole') == 'Manager';
  enableReservation = false;
  vendorEnabled = true;
  constructor(
    private accountService: AccountService,
    private categoriesService: CategoriesService,
    private departmentService: DepartmentService,
    private labelService: LabelService
  ) {}

  ngOnInit(): void {
    this.accountConfiguration.account =
      localStorage.getItem('HechRomeoAccount');
    this.getLanguages();
    this.getToken();
    this.getVendor();
    this.getAccountConfiguration();
  }

  delete() {
    this[this.deleteFunction](this.deleteElement);
  }

  getLanguages() {
    this.categoriesService.getLanguages().subscribe((data) => {
      // this.savekeepAllLanguages = [...data.languages];
      this.allLanguages = data.languages;

      this.currentLanguage = data.languages[0].abr;
      this.newItemLanguage = this.allLanguages[0].abr;
      this.getAllDepartments();
      this.getAllLabels();
    });
  }

  getAccountConfiguration() {
    sessionStorage.setItem('IgnoreErrorHech', '404');
    this.accountService.getAccountConfiguration().subscribe((data) => {
      this.accountConfiguration = data;

      this.accountConfiguration.account = this.accountConfiguration.account ? this.accountConfiguration.account : localStorage.getItem('HechRomeoAccount');

      this.allPolicyDetails = [];
      this.allLangs = Object.assign(this.allLanguages);

      if (this.accountConfiguration.configuraton.servicewithcategoryname !== true) {
        this.accountConfiguration.configuraton.servicewithcategoryname = false;
      }

      for (const key of objectKeys(this.accountConfiguration.configuraton.detailpolicy)) {
        this.allPolicyDetails.push({
          lang: key,
          value: this.accountConfiguration.configuraton.detailpolicy[key]
        });

        this.allLangs = this.allLangs.filter(l => l.abr !== key);
      }

      this.newPolicy = {
        lang: '',
        value: ''
      };
      this.modifyingPolicy = false;
    });
  }

  getVendor() {
    this.accountService.getVendorData().subscribe((data) => {
      this.vendorEnabled = data.vendoractive;
    });
  }

  setVendorStatus(vendorEnabled) {
    this.accountService
      .setVendorActive(localStorage.getItem('HechRomeoAccount'), vendorEnabled)
      .subscribe((data) => {
        this.getVendor();
      });
  }

  getToken() {
    sessionStorage.setItem('IgnoreErrorHech', '400');
    this.accountService
      .getVendorToken(localStorage.getItem('HechRomeoAccount'))
      .subscribe((data) => {
        this.token = data;
        this.enableReservation = this.token.canreserve;
      });
  }

  generateToken() {
    this.accountService
      .generateVendorToken(localStorage.getItem('HechRomeoAccount'))
      .subscribe((data) => {
        this.getToken();
      });
  }

  setTokenActivation(active: boolean) {
    this.accountService
      .setVendorTokenStatus(localStorage.getItem('HechRomeoAccount'), active)
      .subscribe((data) => {
        this.getToken();
      });
  }

  setEnableReservation(active: boolean) {
    this.accountService
      .setVendorCanReserve(localStorage.getItem('HechRomeoAccount'), active)
      .subscribe((data) => {
        this.getToken();
      });
  }

  setAccountConfiguration() {
    for (let element in this.accountConfiguration.configuraton
      .cartrefiddescription) {
      if (
        this.accountConfiguration.configuraton.cartrefiddescription[element] ==
          '' ||
        undefined
      ) {
        delete this.accountConfiguration.configuraton.cartrefiddescription[
          element
        ];
      }
    }
    this.accountService
      .setAccountConfiguration(this.accountConfiguration)
      .subscribe(() => {
        this.getAccountConfiguration();
      });
  }

  getAllDepartments() {
    this.departmentService.getDepartments().subscribe((data) => {
      this.allDepartments = data.departments;
      for (let element of this.allDepartments) {
        this.departmentLanguageAssociation[element.id] =
          this.allLanguages[0].abr;
      }
    });
  }

  getAllLabels() {
    this.labelService.getLabels().subscribe((data) => {
      this.allLabels = data.labels;
      for (const element of this.allLabels) {
        this.labelLanguageAssociation[element.id] = this.allLanguages[0].abr;
      }
    });
  }

  checkDepartmentValues() {
    let err = false;
    if (this.newDepartment.name.it.length == 0) {
      err = true;
      document.getElementById('newDepartmentName').classList.remove('d-none');
    } else {
      document.getElementById('newDepartmentName').classList.add('d-none');
    }

    if (this.newDepartment.mailaddress.length == 0) {
      err = true;
      document
        .getElementById('newDepartmentMailaddress')
        .classList.remove('d-none');
    } else {
      if (
        !this.newDepartment.mailaddress.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        err = true;
        document
          .getElementById('newDepartmentMailaddressValue')
          .classList.remove('d-none');
      } else {
        document
          .getElementById('newDepartmentMailaddressValue')
          .classList.add('d-none');
      }
      document
        .getElementById('newDepartmentMailaddress')
        .classList.add('d-none');
    }

    if (this.newDepartment.phone.length == 0) {
      err = true;
      document.getElementById('newDepartmentPhone').classList.remove('d-none');
    } else {
      if (!this.newDepartment.phone.match(/^\d+$/)) {
        err = true;
        document
          .getElementById('newDepartmentPhoneValue')
          .classList.remove('d-none');
      } else {
        document
          .getElementById('newDepartmentPhoneValue')
          .classList.add('d-none');
      }
      document.getElementById('newDepartmentPhone').classList.add('d-none');
    }
    return err;
  }

  checkLabelValues() {
    let err = false;
    if (this.newLabel.name.it.length == 0) {
      err = true;
      document.getElementById('newLabelName').classList.remove('d-none');
    } else {
      document.getElementById('newLabelName').classList.add('d-none');
    }
    return err;
  }

  createNewDepartment() {
    let err = this.checkDepartmentValues();
    if (!err) {
      this.departmentService
        .createDepartment(this.newDepartment)
        .subscribe((data) => {
          document.getElementById('departmentDismiss').click();
          this.getAllDepartments();

          this.newDepartment = new Department();
          this.newItemLanguage = this.allLanguages[0].abr;
        });
    }
  }

  createNewLabel() {
    let err = this.checkLabelValues();
    if (!err)
      this.labelService.createLabel(this.newLabel).subscribe((data) => {
        document.getElementById('labelDismiss').click();
        this.getAllLabels();

        this.newLabel = new Label();
        this.newItemLanguage = this.allLanguages[0].abr;
      });
  }

  updateDepartment() {
    let err = this.checkDepartmentValues();
    if (!err)
      this.departmentService
        .updateDepartment(this.newDepartment)
        .subscribe((data) => {
          document.getElementById('departmentDismiss').click();
          this.getAllDepartments();

          this.newDepartment = new Department();
          this.newItemLanguage = this.allLanguages[0].abr;
          this.modifying = false;
        });
  }

  updateLabel() {
    let err = this.checkLabelValues();
    if (!err)
      this.labelService.updateLabel(this.newLabel).subscribe((data) => {
        document.getElementById('labelDismiss').click();
        this.getAllLabels();

        this.newLabel = new Label();
        this.newItemLanguage = this.allLanguages[0].abr;
        this.modifying = false;
      });
  }

  deleteDepartment(element) {
    this.departmentService.deleteDepartment(element.id).subscribe((data) => {
      this.getAllDepartments();
    });
  }

  deleteLabel(element) {
    this.labelService.deleteLabel(element.id).subscribe((data) => {
      this.getAllLabels();
    });
  }

  deletePolicy(element) {
    delete this.accountConfiguration.configuraton.detailpolicy[element.lang];

    this.savePolicy();
  }

  modifyDepartment(element) {
    this.newDepartment = { ...element };
    this.modifying = true;
  }

  modifyLabel(element) {
    this.newLabel = { ...element };
    this.modifying = true;
  }

  clearNewDepartment() {
    this.modifying = false;
    this.newDepartment = new Department();
    this.getAllDepartments();
  }

  clearNewLabel() {
    this.modifying = false;
    this.newLabel = new Label();
    this.getAllLabels();
  }

  createNewPolicy() {
    const err: boolean = !this.newPolicy || !this.newPolicy.lang || !this.newPolicy.value || this.newPolicy.lang.length <= 0
      || this.newPolicy.value.length <= 0;

    if (!err) {
      this.accountConfiguration.configuraton.detailpolicy[this.newPolicy.lang] = this.newPolicy.value;
      document.getElementById('policyDismiss').click();

      this.savePolicy();
    }
  }

  modifyPolicy(element) {
    this.newPolicy = { ...element };
    this.modifyingPolicy = true;
  }

  updatePolicy() {
    const err = !this.newPolicy || !this.newPolicy.lang || !this.newPolicy.value || this.newPolicy.lang.length <= 0
        || this.newPolicy.value.length <= 0;

    if (!err) {
      this.accountConfiguration.configuraton.detailpolicy[this.newPolicy.lang] = this.newPolicy.value;
      document.getElementById('policyDismiss').click();

      this.savePolicy();
    }
  }

  clearNewPolicy() {
    this.modifyingPolicy = false;
    this.newPolicy = {
      lang: '',
      value: ''
    };
  }

  savePolicy(): void {
    this.accountService
        .setAccountConfiguration(this.accountConfiguration)
        .subscribe(() => {
          this.getAccountConfiguration();
        });
  }

  saveReservationName(): void {
    this.accountService
      .setAccountConfiguration(this.accountConfiguration)
      .subscribe(() => {
        this.getAccountConfiguration();
      });
  }
}
