<div class="container-fluid p-0 my-2">
  <div class="row mx-0">
    <div class="col-xl-1"></div>

    <div class="col-12 col-xl-11">
      <img
        src="assets/img/Logotipo Hech Romeo.Guide.png"
        style="width: 250px"
      />
    </div>
  </div>

  <div class="row mx-0">
    <div class="col-xl-1"></div>

    <div class="col-12 col-xl-11">
      <h4
        style="
          margin: 20px 0 60px 0;
          color: rgb(174, 202, 65);
          line-height: normal;
          font-size: 24px;
          font-weight: bold;
        "
      >
        {{
          "Registrati per avere tutti i servizi a portata di click" | translate
        }}!
      </h4>
    </div>
  </div>

  <form [formGroup]="registerForm" class="w-100">
    <div class="row mx-0 w-100">
      <div class="col-xl-1"></div>

      <div
        class="col-6 col-xl-5 w-100"
        style="border-right: 0.1px solid #aeca41"
      >
        <div class="form-group">
          <label for="account">
            {{ "Dai il nome del tuo progetto" | translate }}: Account</label
          >
          <input
            type="text"
            class="form-control"
            id="account"
            name="account"
            placeholder="{{ 'Inserici il nome del tuo account' | translate }}"
            formControlName="account"
            required
            (input)="registerForm.controls['account'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['account'].invalid &&
              (registerForm.controls['account'].dirty ||
                registerForm.controls['account'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>

        <!-- mod rag sociale -->

        <div class="form-group">
          <label for="businessName">{{ "Ragione Sociale" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="businessName"
            name="businessName"
            placeholder="{{ 'Inserisci la tua ragione sociale' | translate }}"
            formControlName="factoryname"
            required
            (input)="
              registerForm.controls['factoryname'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['factoryname'].invalid &&
              (registerForm.controls['factoryname'].dirty ||
                registerForm.controls['factoryname'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>

        <!-- fine mod rag sociale -->

        <!-- mod p.iva -->

        <div class="form-group">
          <label for="Piva">{{ "P.IVA/CF" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="Piva"
            name="Piva"
            placeholder="{{ 'Inserisci la tua partita iva' | translate }}"
            formControlName="piva"
            required
            (input)="registerForm.controls['piva'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['piva'].invalid &&
              (registerForm.controls['piva'].dirty ||
                registerForm.controls['piva'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>

        <!-- fine mod p.iva -->

        <div class="form-group">
          <label for="name">{{ "Nome" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="name"
            name="name"
            placeholder="{{ 'Inserici il tuo nome' | translate }}"
            formControlName="referrername"
            required
            (input)="
              registerForm.controls['referrername'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['referrername'].invalid &&
              (registerForm.controls['referrername'].dirty ||
                registerForm.controls['referrername'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="lastname">{{ "Cognome" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="lastname"
            name="lastname"
            placeholder="{{ 'Inserici il tuo cognome' | translate }}"
            formControlName="referrerlastname"
            required
            (input)="
              registerForm.controls['referrerlastname'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['referrerlastname'].invalid &&
              (registerForm.controls['referrerlastname'].dirty ||
                registerForm.controls['referrerlastname'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="email">{{ "Indirizzo email" | translate }}</label>
          <input
            type="email"
            class="form-control"
            id="email"
            name="email"
            placeholder="{{ 'Inserici il tuo indirizzo email' | translate }}"
            formControlName="email"
            required
            (input)="registerForm.controls['email'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['email'].invalid &&
              (registerForm.controls['email'].dirty ||
                registerForm.controls['email'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="confirmEmail">{{
            "Conferma indirizzo email" | translate
          }}</label>
          <input
            type="email"
            class="form-control"
            id="confirmEmail"
            name="confirmEmail"
            placeholder="{{ 'Conferma il tuo indirizzo email' | translate }}"
            formControlName="confirmEmail"
            required
            (input)="
              registerForm.controls['confirmEmail'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['email'].value !==
              registerForm.controls['confirmEmail'].value
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_ERROR_FIELD_MUST_BE_EQUALS"
            ></app-small-text>
          </ng-container>
          <ng-container
            *ngIf="
              registerForm.controls['confirmEmail'].invalid &&
              (registerForm.controls['confirmEmail'].dirty ||
                registerForm.controls['confirmEmail'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="pasword"
            >{{ "Password" | translate }}&nbsp;<abbr
              title="{{ TYPOGRAPHY.PASSWORD_HINT }}"
              >?</abbr
            ></label
          >
          <input
            type="password"
            class="form-control"
            id="password"
            name="password"
            placeholder="{{ 'Inserici la tua password' | translate }}"
            formControlName="password"
            required
            (input)="registerForm.controls['password'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['password'].invalid &&
              (registerForm.controls['password'].dirty ||
                registerForm.controls['password'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="confirmPasword">{{
            "Conferma password" | translate
          }}</label>
          <input
            type="password"
            class="form-control"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="{{ 'Conferma la tua password' | translate }}"
            formControlName="confirmPassword"
            required
            (input)="
              registerForm.controls['confirmPassword'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['password'].value !==
              registerForm.controls['confirmPassword'].value
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_ERROR_FIELD_MUST_BE_EQUALS"
            ></app-small-text>
          </ng-container>
          <ng-container
            *ngIf="
              registerForm.controls['confirmPassword'].invalid &&
              (registerForm.controls['confirmPassword'].dirty ||
                registerForm.controls['confirmPassword'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>

        <!-- ragione soci -->
        <!--  <div class="form-group">
          <label for="businessName">Ragione sociale</label>
          <input
            type="text"
            class="form-control"
            id="businessName"
            name="businessName"
            placeholder="Inserisci la tua ragione sociale"
            formControlName="factoryname"
            required
            (input)="
                  registerForm.controls['factoryname'].updateValueAndValidity()
                "
          />
          <ng-container
            *ngIf="
                  registerForm.controls['factoryname'].invalid &&
                  (registerForm.controls['factoryname'].dirty ||
                    registerForm.controls['factoryname'].touched)
                "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div> -->

        <!-- ragione soci fine -->

        <!--   <div class="form-group">
          <label for="Piva">P.iva/cf</label>
          <input
            type="text"
            class="form-control"
            id="Piva"
            name="Piva"
            placeholder="Inserisci la tua partita iva"
            formControlName="piva"
            required
            (input)="registerForm.controls['piva'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
                  registerForm.controls['piva'].invalid &&
                  (registerForm.controls['piva'].dirty ||
                    registerForm.controls['piva'].touched)
                "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div> -->
      </div>

      <div class="col-6 col-xl-5 w-100">
        <div class="form-group">
          <label for="legalAddress">{{ "Indirizzo" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="legalAddress"
            name="legalAddress"
            placeholder="{{
              'Inserisci lindirizzo della tua sede legale' | translate
            }}"
            formControlName="address"
            required
            (input)="registerForm.controls['address'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['address'].invalid &&
              (registerForm.controls['address'].dirty ||
                registerForm.controls['address'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="city"> {{ "Città" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="city"
            name="city"
            placeholder="{{
              'Inserisci la città in cui si trova la sede legale' | translate
            }}"
            formControlName="city"
            required
            (input)="registerForm.controls['city'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['city'].invalid &&
              (registerForm.controls['city'].dirty ||
                registerForm.controls['city'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="state">{{ "Regione" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="state"
            name="state"
            placeholder="{{
              'Inserisci la regione in cui si trova la sede legale' | translate
            }}"
            formControlName="state"
            required
            (input)="registerForm.controls['state'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['state'].invalid &&
              (registerForm.controls['state'].dirty ||
                registerForm.controls['state'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="zipcode">Zip/cap</label>
          <input
            type="text"
            class="form-control"
            id="zipcode"
            name="zipcode"
            placeholder="{{
              'Inserisci il codice zip in cui si trova la sede legale'
                | translate
            }}"
            formControlName="zipcode"
            required
            (input)="registerForm.controls['zipcode'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['zipcode'].invalid &&
              (registerForm.controls['zipcode'].dirty ||
                registerForm.controls['zipcode'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="country">{{ "Stato" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="country"
            name="country"
            placeholder="{{
              'Inserisci lo stato in cui si trova la sede legale' | translate
            }}"
            formControlName="country"
            required
            (input)="registerForm.controls['country'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['country'].invalid &&
              (registerForm.controls['country'].dirty ||
                registerForm.controls['country'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="banner">{{ "Insegna" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="banner"
            name="banner"
            placeholder="{{ 'Inserisci la tua insegna' | translate }}"
            formControlName="banner"
            required
            (input)="registerForm.controls['banner'].updateValueAndValidity()"
          />
          <ng-container
            *ngIf="
              registerForm.controls['banner'].invalid &&
              (registerForm.controls['banner'].dirty ||
                registerForm.controls['banner'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group">
          <label for="code"
            >{{ "Codice univoco" | translate }}&nbsp;<abbr
              [title]="TYPOGRAPHY.UNIQUE_OFFICE_CODE"
              >?</abbr
            ></label
          >
          <input
            type="text"
            class="form-control"
            id="code"
            name="code"
            placeholder="{{
              'Inserisci il tuo codice univoco dufficio' | translate
            }}"
            aria-describedby="codeHelp"
            formControlName="billingcode"
            required
            (input)="
              registerForm.controls['billingcode'].updateValueAndValidity()
            "
          />
          <ng-container
            *ngIf="
              registerForm.controls['billingcode'].invalid &&
              (registerForm.controls['billingcode'].dirty ||
                registerForm.controls['billingcode'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div *ngIf="admin" class="form-group">
          <label for="urlhechromeo">URL Hech Romeo</label>
          <input
            type="text"
            class="form-control"
            id="urlhechromeo"
            name="urlhechromeo"
            placeholder="{{ 'Inserisci lURL Hech Romeo' | translate }}"
            formControlName="urlhechromeo"
            (input)="
              registerForm.controls['urlhechromeo'].updateValueAndValidity()
            "
          />
        </div>
        <div class="form-group">
          <label for="phone">{{ "Telefono" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="phone"
            name="phone"
            placeholder="{{ 'Inserisci il numero di telefono' | translate }}"
            formControlName="phone"
            (input)="registerForm.controls['phone'].updateValueAndValidity()"
          />
        </div>
        <div class="form-group form-check" *ngIf="!admin">
          <input
            type="checkbox"
            class="form-check-input"
            id="accepttermpolicy"
            name="accepttermpolicy"
            formControlName="accepttermpolicy"
            required
            (input)="
              registerForm.controls['accepttermpolicy'].updateValueAndValidity()
            "
          />
          <label class="form-check-label" for="accepttermpolicy"
            >{{ "Accetto le" | translate }}&nbsp;</label
          >
          <a
            class="underlined font-weight-bold text-uppercase"
            target="_blank"
            href="https://hech.tv/terms-of-service/"
            >{{ "condizioni Generali del servizio" | translate }}</a
          >
          <ng-container
            *ngIf="
              registerForm.controls['accepttermpolicy'].invalid &&
              (registerForm.controls['accepttermpolicy'].dirty ||
                registerForm.controls['accepttermpolicy'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_ERROR_TERMS_OF_SERVICE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="form-group form-check" *ngIf="!admin">
          <input
            type="checkbox"
            class="form-check-input"
            id="acceptdatapolicy"
            name="acceptdatapolicy"
            formControlName="acceptdatapolicy"
            required
            (input)="
              registerForm.controls['acceptdatapolicy'].updateValueAndValidity()
            "
          />
          <label class="form-check-label" for="acceptdatapolicy">{{
            "Accetto i termini di gestione dei dati" | translate
          }}</label>
          <ng-container
            *ngIf="
              registerForm.controls['acceptdatapolicy'].invalid &&
              (registerForm.controls['acceptdatapolicy'].dirty ||
                registerForm.controls['acceptdatapolicy'].touched)
            "
          >
            <app-small-text
              [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"
            ></app-small-text>
          </ng-container>
        </div>
        <div class="btns-registration">
          <button
            type="button"
            class="pl-4 pr-4 mt-2 btn btn-primary"
            (click)="register()"
          >
            {{ admin ? ("Registra" | translate) : ("Registrati" | translate) }}
          </button>
          <button
            *ngIf="admin"
            type="button"
            class="pl-4 pr-4 mt-2 ml-3 btn btn-secondary"
            (click)="returnToLastLocation()"
          >
            {{ "Annulla" | translate }}
          </button>
        </div>
      </div>

      <div class="col-xl-1"></div>
    </div>
  </form>
</div>

<!--<div class="py-4">-->
<!--  <div-->
<!--    class="d-flex justify-content-center align-items-center"-->
<!--    style="min-height: 100vh;"-->
<!--  >-->
<!--    <div>-->
<!--      <img-->
<!--        src="assets/img/Logotipo Hech Romeo.Guide.png"-->
<!--        style="width: 250px;"-->
<!--      />-->
<!--      <h4-->
<!--        style="-->
<!--          margin: 20px 0 60px 0;-->
<!--          color: rgb(174, 202, 65);-->
<!--          line-height: normal;-->
<!--          font-size: 24px;-->
<!--          font-weight: bold;-->
<!--        "-->
<!--      >-->
<!--        Registrati per avere tutti i servizi a portata di click!-->
<!--      </h4>-->
<!--      <form [formGroup]="registerForm">-->
<!--        <div class="d-flex" style="flex-direction: row;">-->
<!--          <div style="width: 500px;">-->
<!--            <div class="form-group">-->
<!--              <label for="account"-->
<!--                >Account&nbsp;<abbr [title]="TYPOGRAPHY.ACCOUNT_HINT"-->
<!--                  >?</abbr-->
<!--                ></label-->
<!--              >-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="account"-->
<!--                name="account"-->
<!--                placeholder="Inserici il nome del tuo account"-->
<!--                formControlName="account"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['account'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['account'].invalid &&-->
<!--                  (registerForm.controls['account'].dirty ||-->
<!--                    registerForm.controls['account'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="name">Nome</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="name"-->
<!--                name="name"-->
<!--                placeholder="Inserici il tuo nome"-->
<!--                formControlName="referrername"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['referrername'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['referrername'].invalid &&-->
<!--                  (registerForm.controls['referrername'].dirty ||-->
<!--                    registerForm.controls['referrername'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="lastname">Cognome</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="lastname"-->
<!--                name="lastname"-->
<!--                placeholder="Inserici il tuo cognome"-->
<!--                formControlName="referrerlastname"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls[-->
<!--                    'referrerlastname'-->
<!--                  ].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['referrerlastname'].invalid &&-->
<!--                  (registerForm.controls['referrerlastname'].dirty ||-->
<!--                    registerForm.controls['referrerlastname'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="email">Indirizzo email</label>-->
<!--              <input-->
<!--                type="email"-->
<!--                class="form-control"-->
<!--                id="email"-->
<!--                name="email"-->
<!--                placeholder="Inserici il tuo indirizzo email"-->
<!--                formControlName="email"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['email'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['email'].invalid &&-->
<!--                  (registerForm.controls['email'].dirty ||-->
<!--                    registerForm.controls['email'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="confirmEmail">Conferma indirizzo email</label>-->
<!--              <input-->
<!--                type="email"-->
<!--                class="form-control"-->
<!--                id="confirmEmail"-->
<!--                name="confirmEmail"-->
<!--                placeholder="Conferma il tuo indirizzo email"-->
<!--                formControlName="confirmEmail"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['confirmEmail'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['email'].value !==-->
<!--                  registerForm.controls['confirmEmail'].value-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_ERROR_FIELD_MUST_BE_EQUALS"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['confirmEmail'].invalid &&-->
<!--                  (registerForm.controls['confirmEmail'].dirty ||-->
<!--                    registerForm.controls['confirmEmail'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="pasword"-->
<!--                >Password&nbsp;<abbr title="{{ TYPOGRAPHY.PASSWORD_HINT }}"-->
<!--                  >?</abbr-->
<!--                ></label-->
<!--              >-->
<!--              <input-->
<!--                type="password"-->
<!--                class="form-control"-->
<!--                id="password"-->
<!--                name="password"-->
<!--                placeholder="Inserici la tua password"-->
<!--                formControlName="password"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['password'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['password'].invalid &&-->
<!--                  (registerForm.controls['password'].dirty ||-->
<!--                    registerForm.controls['password'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="confirmPasword">Conferma password</label>-->
<!--              <input-->
<!--                type="password"-->
<!--                class="form-control"-->
<!--                id="confirmPassword"-->
<!--                name="confirmPassword"-->
<!--                placeholder="Conferma la tua password"-->
<!--                formControlName="confirmPassword"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls[-->
<!--                    'confirmPassword'-->
<!--                  ].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['password'].value !==-->
<!--                  registerForm.controls['confirmPassword'].value-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_ERROR_FIELD_MUST_BE_EQUALS"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['confirmPassword'].invalid &&-->
<!--                  (registerForm.controls['confirmPassword'].dirty ||-->
<!--                    registerForm.controls['confirmPassword'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="businessName">Ragione sociale</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="businessName"-->
<!--                name="businessName"-->
<!--                placeholder="Inserisci la tua ragione sociale"-->
<!--                formControlName="factoryname"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['factoryname'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['factoryname'].invalid &&-->
<!--                  (registerForm.controls['factoryname'].dirty ||-->
<!--                    registerForm.controls['factoryname'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="Piva">P.iva/cf</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="Piva"-->
<!--                name="Piva"-->
<!--                placeholder="Inserisci la tua partita iva"-->
<!--                formControlName="piva"-->
<!--                required-->
<!--                (input)="registerForm.controls['piva'].updateValueAndValidity()"-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['piva'].invalid &&-->
<!--                  (registerForm.controls['piva'].dirty ||-->
<!--                    registerForm.controls['piva'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="margin: 0 50px;">-->
<!--            <div-->
<!--              style="height: 100%; width: 0; border-right: 0.1px solid #aeca41;"-->
<!--            ></div>-->
<!--          </div>-->
<!--          <div style="width: 500px;">-->
<!--            <div class="form-group">-->
<!--              <label for="legalAddress">Indirizzo</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="legalAddress"-->
<!--                name="legalAddress"-->
<!--                placeholder="Inserisci l'indirizzo della tua sede legale"-->
<!--                formControlName="address"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['address'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['address'].invalid &&-->
<!--                  (registerForm.controls['address'].dirty ||-->
<!--                    registerForm.controls['address'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="city">Città</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="city"-->
<!--                name="city"-->
<!--                placeholder="Inserisci la città in cui si trova la sede legale"-->
<!--                formControlName="city"-->
<!--                required-->
<!--                (input)="registerForm.controls['city'].updateValueAndValidity()"-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['city'].invalid &&-->
<!--                  (registerForm.controls['city'].dirty ||-->
<!--                    registerForm.controls['city'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="state">Regione</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="state"-->
<!--                name="state"-->
<!--                placeholder="Inserisci la regione in cui si trova la sede legale"-->
<!--                formControlName="state"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['state'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['state'].invalid &&-->
<!--                  (registerForm.controls['state'].dirty ||-->
<!--                    registerForm.controls['state'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="zipcode">Zip/cap</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="zipcode"-->
<!--                name="zipcode"-->
<!--                placeholder="Inserisci il codice zip in cui si trova la sede legale"-->
<!--                formControlName="zipcode"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['zipcode'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['zipcode'].invalid &&-->
<!--                  (registerForm.controls['zipcode'].dirty ||-->
<!--                    registerForm.controls['zipcode'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="country">Stato</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="country"-->
<!--                name="country"-->
<!--                placeholder="Inserisci lo stato in cui si trova la sede legale"-->
<!--                formControlName="country"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['country'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['country'].invalid &&-->
<!--                  (registerForm.controls['country'].dirty ||-->
<!--                    registerForm.controls['country'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="banner">Insegna</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="banner"-->
<!--                name="banner"-->
<!--                placeholder="Inserisci la tua insegna"-->
<!--                formControlName="banner"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['banner'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['banner'].invalid &&-->
<!--                  (registerForm.controls['banner'].dirty ||-->
<!--                    registerForm.controls['banner'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="code"-->
<!--                >Codice univoco d'ufficio&nbsp;<abbr-->
<!--                  [title]="TYPOGRAPHY.UNIQUE_OFFICE_CODE"-->
<!--                  >?</abbr-->
<!--                ></label-->
<!--              >-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="code"-->
<!--                name="code"-->
<!--                placeholder="Inserisci il tuo codice univoco d'ufficio"-->
<!--                aria-describedby="codeHelp"-->
<!--                formControlName="billingcode"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls['billingcode'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['billingcode'].invalid &&-->
<!--                  (registerForm.controls['billingcode'].dirty ||-->
<!--                    registerForm.controls['billingcode'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div *ngIf="admin" class="form-group">-->
<!--              <label for="urlhechromeo">URL Hech Romeo</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="urlhechromeo"-->
<!--                name="urlhechromeo"-->
<!--                placeholder="Inserisci l'URL Hech Romeo"-->
<!--                formControlName="urlhechromeo"-->
<!--                (input)="-->
<!--                  registerForm.controls['urlhechromeo'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label for="phone">TELEFONO</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="phone"-->
<!--                name="phone"-->
<!--                placeholder="Inserisci il numero di telefono"-->
<!--                formControlName="phone"-->
<!--                (input)="-->
<!--                  registerForm.controls['phone'].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--            </div>-->
<!--            <div class="form-group form-check" *ngIf="!admin">-->
<!--              <input-->
<!--                type="checkbox"-->
<!--                class="form-check-input"-->
<!--                id="accepttermpolicy"-->
<!--                name="accepttermpolicy"-->
<!--                formControlName="accepttermpolicy"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls[-->
<!--                    'accepttermpolicy'-->
<!--                  ].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <label class="form-check-label" for="accepttermpolicy"-->
<!--                >Accetto i&nbsp;</label-->
<!--              >-->
<!--              <a-->
<!--                class="underlined font-weight-bold text-uppercase"-->
<!--                routerLink="/termsOfService"-->
<!--                target="_blank"-->
<!--                >termini del servizio</a-->
<!--              >-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['accepttermpolicy'].invalid &&-->
<!--                  (registerForm.controls['accepttermpolicy'].dirty ||-->
<!--                    registerForm.controls['accepttermpolicy'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_ERROR_TERMS_OF_SERVICE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="form-group form-check" *ngIf="!admin">-->
<!--              <input-->
<!--                type="checkbox"-->
<!--                class="form-check-input"-->
<!--                id="acceptdatapolicy"-->
<!--                name="acceptdatapolicy"-->
<!--                formControlName="acceptdatapolicy"-->
<!--                required-->
<!--                (input)="-->
<!--                  registerForm.controls[-->
<!--                    'acceptdatapolicy'-->
<!--                  ].updateValueAndValidity()-->
<!--                "-->
<!--              />-->
<!--              <label class="form-check-label" for="acceptdatapolicy"-->
<!--                >Accetto i termini di gestione dei dati</label-->
<!--              >-->
<!--              <ng-container-->
<!--                *ngIf="-->
<!--                  registerForm.controls['acceptdatapolicy'].invalid &&-->
<!--                  (registerForm.controls['acceptdatapolicy'].dirty ||-->
<!--                    registerForm.controls['acceptdatapolicy'].touched)-->
<!--                "-->
<!--              >-->
<!--                <app-small-text-->
<!--                  [message]="TYPOGRAPHY.INPUT_GENERIC_ERROR_MESSAGE"-->
<!--                ></app-small-text>-->
<!--              </ng-container>-->
<!--            </div>-->
<!--            <div class="btns-registration">-->
<!--              <button-->
<!--                type="button"-->
<!--                class="pl-4 pr-4 mt-2 btn btn-primary"-->
<!--                (click)="register()"-->
<!--              >-->
<!--                {{ admin ? "Registra" : "Registrati" }}-->
<!--              </button>-->
<!--              <button-->
<!--                *ngIf="admin"-->
<!--                type="button"-->
<!--                class="pl-4 pr-4 mt-2 ml-3 btn btn-secondary"-->
<!--                (click)="returnToLastLocation()"-->
<!--              >-->
<!--                Annulla-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
