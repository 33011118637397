<div class="mt-2 mb-2">
  <span>
    {{ "Devi modificare la password" | translate }}&nbsp;<abbr
      title="{{ TYPOGRAPHY.PASSWORD_HINT }}"
      >?</abbr
    >
  </span>
  <small class="form-text text-muted text-left">{{
    "Stai usando una password temporanea o la tua password è scaduta"
      | translate
  }}</small>
</div>
<form [formGroup]="changePasswordForm">
  <div class="form-group">
    <input
      placeholder="Nuova password"
      type="password"
      class="form-control"
      id="newpassword"
      name="newpassword"
      formControlName="newpassword"
      required
      (input)="
        changePasswordForm.controls['newpassword'].updateValueAndValidity()
      "
    />
    <ng-container
      *ngIf="
        changePasswordForm.controls['newpassword'].invalid &&
        (changePasswordForm.controls['newpassword'].dirty ||
          changePasswordForm.controls['newpassword'].touched)
      "
    >
      <app-small-text
        [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"
      ></app-small-text>
    </ng-container>
  </div>
  <div class="form-group">
    <input
      placeholder="Conferma nuova password"
      type="password"
      class="form-control"
      id="confirmnewpassword"
      name="confirmnewpassword"
      formControlName="confirmnewpassword"
      required
      (input)="
        changePasswordForm.controls[
          'confirmnewpassword'
        ].updateValueAndValidity()
      "
    />
    <ng-container
      *ngIf="
        changePasswordForm.controls['newpassword'].value !==
        changePasswordForm.controls['confirmnewpassword'].value
      "
    >
      <app-small-text
        [message]="TYPOGRAPHY.INPUT_ERROR_FIELD_MUST_BE_EQUALS"
      ></app-small-text>
    </ng-container>
    <ng-container
      *ngIf="
        changePasswordForm.controls['confirmnewpassword'].invalid &&
        (changePasswordForm.controls['confirmnewpassword'].dirty ||
          changePasswordForm.controls['confirmnewpassword'].touched)
      "
    >
      <app-small-text
        [message]="TYPOGRAPHY.INPUT_INVALID_ERROR_MESSAGE"
      ></app-small-text>
    </ng-container>
  </div>
  <div class="text-center">
    <button
      type="button"
      class="pl-4 pr-4 btn btn-primary"
      (click)="changePassword()"
    >
      Salva
    </button>
  </div>
</form>
