<div
  class="h-100 d-flex flex-column"
  [ngClass]="{
    'header-footer-container-v': layout == 'Vertical',
    'header-footer-container-h': layout == 'Horizontal',
    'justify-content-center': layout == 'Horizontal' && !data.ticker
  }"
>
  <div
    class="px-5 d-flex"
    [ngStyle]="{ height: getContentHeight() }"
    [ngClass]="{
      'justify-content-between': !isOneContent(),
      'justify-content-center': isOneContent(),
      'h-100': !data.ticker
    }"
  >
    <!-- LOGO -->
    <div
      *ngIf="data.logo"
      [ngClass]="{
        'logo-container-v': layout == 'Vertical',
        'logo-container-h': layout == 'Horizontal',
        'h-100': !data.ticker
      }"
    >
      <img
        [src]="
          _DomSanitizationService.bypassSecurityTrustUrl(
            'data:image/png;base64,' + iconToLoad
          )
        "
        alt="Logo"
      />
    </div>

    <div class="position-relative" [ngClass]="{ 'w-100': toReverse() }">
      <div
        class="h-100 w-100 d-flex justify-content-between align-items-center"
        [ngClass]="{ 'flex-row-reverse': toReverse() }"
      >
        <!-- WEATHER -->
        <div
          *ngIf="data.weatherforecast"
          class="d-flex flex-column justify-content-center align-items-center"
          [ngClass]="{
            'weather-container-v': layout === 'Vertical',
            'weather-container-h': layout == 'Horizontal',
            'px-4': data.date || data.time
          }"
        >
          <img src="assets/img/01d.svg" alt="" />
          <div class="text-center">{{ getRoundedWeather() }}°</div>
        </div>

        <!-- DATA E ORA -->
        <div
          *ngIf="data.date || data.time"
          class="d-flex flex-column justify-content-center align-items-center"
          [ngClass]="{
            'date-time-container-v': layout === 'Vertical',
            'date-time-container-h': layout == 'Horizontal'
          }"
        >
          <div *ngIf="data.date" [innerHTML]="date" class="text-center"></div>
          <div
            *ngIf="data.time"
            class="time d-flex justify-content-center text-center"
          >
            <div [innerHTML]="getTimeFormattedValue(hours)"></div>
            <div class="pulse">:</div>
            <div [innerHTML]="getTimeFormattedValue(minutes)"></div>
          </div>
          <div
            *ngIf="data.position"
            [innerHTML]="data.position"
            class="location text-center"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <!-- TICKER -->
  <div *ngIf="data.ticker" [ngStyle]="{ height: getTickerHeight() }">
    <div
      class="w-100"
      [ngClass]="{
        'ticker-separator-v': layout == 'Vertical',
        'ticker-separator-h': layout == 'Horizontal'
      }"
    >
      <div></div>
    </div>
    <div
      class="d-flex align-items-center"
      [ngClass]="{
        'ticker-container-v': layout == 'Vertical',
        'ticker-container-h': layout == 'Horizontal',
        'lock-width': !isMedia && layout == 'Horizontal'
      }"
    >
      <div
        [innerHtml]="data.ticker"
        [ngClass]="{
          'scroll-left': isMedia || layout == 'Vertical',
          'scroll-left-exp': !isMedia && layout == 'Horizontal'
        }"
        [ngStyle]="{ 'animation-duration': getScrollTime() }"
      ></div>
    </div>
  </div>
</div>
