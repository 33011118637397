import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdvancedCart, Cart, CartItems, CartReservation,} from 'src/app/models/cart/cart';
import {CategoryTree, Service,} from 'src/app/models/categoryTree/category-tree';
import {Reservation} from 'src/app/models/reservation/reservation';
import * as moment from 'moment';
import {Label} from 'src/app/models/label/label';
import {CalendarService} from 'src/app/services/calendar/calendar.service';
import {Observable, Subscription} from 'rxjs';
import {CategoriesService} from 'src/app/services/categories/categories.service';

declare var $: any;

@Component({
  selector: 'app-select-service-to-book',
  templateUrl: './select-service-to-book.component.html',
  styleUrls: ['./select-service-to-book.component.scss'],
})
export class SelectServiceToBookComponent implements OnInit {
  private eventsSubscription: Subscription;
  @Input() categoryTree: CategoryTree;
  @Input() events: Observable<void>;
  @Input() allLabels: Label[];
  @Input() virtual: boolean;
  @Output('searchRequest') searchRequest: EventEmitter<any> =
    new EventEmitter();
  selectingService: boolean = true;
  bookingCart: boolean = false;
  bookingAdvanced: boolean = false;
  bookingService: boolean = false;
  checked: boolean = false;
  dateError: string = '';
  serviceToAdd: Service;
  newReservation: Reservation = new Reservation();
  newCart: Cart = new Cart();
  newAdvancedCart: AdvancedCart = new AdvancedCart();
  timeslots = [];
  cartLabelsList = [];
  categoriesLevel0: any = {categories: [], services: []};
  categoriesLevel1: any = {categories: [], services: []};
  categoriesLevel2: any = {categories: [], services: []};
  categoriesLevel3: any = {categories: [], services: []};
  categoriesLevel4: any = {categories: [], services: []};
  selectedItem: Service;
  catalogedItems = [];
  lang = localStorage.getItem('language');

  constructor(
    private calendarService: CalendarService,
    private categoriesService: CategoriesService
  ) {
  }

  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe(() => {
      this.selectServiceForReservationInit();
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  selectServiceForReservationInit() {
    this.categoriesLevel0 = this.categoryTree;
    this.categoriesLevel1 = {categories: [], services: []};
    this.categoriesLevel2 = {categories: [], services: []};
    this.categoriesLevel3 = {categories: [], services: []};
    this.categoriesLevel4 = {categories: [], services: []};
    this.newReservation = new Reservation();
    this.newAdvancedCart = new AdvancedCart();
    this.newCart = new Cart();
    this.selectedItem = undefined;
    this.selectingService = true;
    this.bookingCart = false;
    this.bookingService = false;
    this.bookingAdvanced = false;
    this.checked = false;
    this.dateError = '';
    this.cartLabelsList = [];
  }

  changeOrAddService() {
    this.selectingService = true;
    this.categoriesLevel1 = {categories: [], services: []};
    this.categoriesLevel2 = {categories: [], services: []};
    this.categoriesLevel3 = {categories: [], services: []};
    this.categoriesLevel4 = {categories: [], services: []};
    this.selectedItem = undefined;
  }

  selectItem(item, level, service?) {
    if (service && this.bookingCart && !item.cartEnabled) {
      return;
    }

    const previusElement = document.getElementsByClassName('selected-item' + level)[0];

    if (previusElement) {
      previusElement.classList.remove('selected-item' + level);
    }

    document.getElementById(item.id).classList.add('selected-item' + level);

    this.selectedItem = item;

    if (level < 4 && !service) {
      this['categoriesLevel' + (level + 1)] = JSON.parse(JSON.stringify(item));

      if (level < 3) {
        for (let i = level + 2; i <= 4; i++) {
          this['categoriesLevel' + i] = {categories: [], services: []};
        }
      }
    }

    if (service && item.cartEnabled && item.isAdvanced) {
      this.newAdvancedCart.shoppingcartdatefrom = moment().format('YYYY-MM-DD');
      this.newAdvancedCart.shoppingcartdateto = moment().format('YYYY-MM-DD');

      this.advancedServiceChangedDate(
        item,
        this.newAdvancedCart.shoppingcartdatefrom,
        this.newAdvancedCart.shoppingcartdateto
      );
    }
  }

  advancedServiceChangedDate(service, from?, to?) {
    let err = false;
    if (service) {
      if (moment(from, 'YYYY-MM-DD').isBefore(moment(), 'day')) {
        this.dateError = 'isBeforeCurrent';
      }
      if (service.isadvanced) {
        if (from && to) {
          if (moment(from, 'YYYY-MM-DD').isAfter(moment(to, 'YYYY-MM-DD'))) {
            $('#timeslots').multiselect('dataprovider', []);
            this.dateError = 'isBeforeEnd';
            err = true;
            return;
          }

          this.categoriesService
            .getAdvancedServiceData(
              service.id,
              moment(from, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              service.parentaccount ?? null,
              to ? moment(to, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
            )
            .subscribe(
              (data) => {
                this.timeslots = data.servicedata.timeslots;
                let options = [];
                for (let element of this.timeslots) {
                  element.id = this.generateId();
                  options.push({
                    label: element.timeslottext,
                    title: element.timeslottext,
                    value: element.id,
                  });
                }
                $('#timeslots').multiselect('dataprovider', options);
              },
              (error) => {
                $('#timeslots').multiselect('dataprovider', []);
              }
            );
        }
      }
    }
    if (!err) {
      this.dateError = '';
    }
  }

  confirmSelectedItem() {
    let lang = localStorage.getItem('language');
    if (this.selectedItem.cartenabled) {
      if (this.selectedItem.isadvanced) {
        if (this.selectedItem.parentaccount) {
          this.newAdvancedCart.isfrompartner = true;
          this.newAdvancedCart.partner = this.selectedItem.parentaccount;
        }

        let err = false;
        if (this.selectedItem.labels) {
          if (this.cartLabelsList.length == 0) {
            for (let element of this.selectedItem.labels) {
              this.cartLabelsList.push(element);
            }
          } else {
            const filteredArray = this.selectedItem.labels.filter((obj) => {
              let labelFound = this.cartLabelsList.find(
                (element) =>
                  (element.labelid &&
                    obj.labelid &&
                    element.labelid == obj.labelid) ||
                  (obj.labelid && element.id == obj.labelid) ||
                  (obj.id && element.id == obj.id)
              );
              if (!labelFound && obj.mandatory) {
                err = true;
              }
              return labelFound;
            });
            const rejectedLabels = this.cartLabelsList.filter((obj) =>
              filteredArray.find(
                (element) =>
                  ((!obj.labelid && !element.labelid) ||
                    element.labelid != obj.labelid) &&
                  ((!obj.id && !element.id) || element.id != obj.id)
              )
            );
            for (let element of rejectedLabels) {
              if (element.mandatory) {
                err = true;
              }
            }
            if (filteredArray.length == 0) {
              err = true;
            } else if (!err) {
              this.cartLabelsList = [];
              for (let element of filteredArray) {
                this.cartLabelsList.push(element);
              }
            }
          }
        } else {
          err = true;
        }
        if (err) {
          this.selectingService = true;
          alert(
            'Servizio non compatibile con label dei servizi presenti nel carrello'
          );
        }
        if (!err) {
          let reservation: CartReservation =
            this.newAdvancedCart.reservations.find(
              (obj) => obj.serviceid == this.selectedItem.id
            );
          if (reservation) {
            let selectedIds = $('#timeslots option:selected').map(function(
              a,
              item
            ) {
              return item.value;
            });
            if (selectedIds.length == 0) {
              return;
            }
            for (let element of selectedIds) {
              let tempSlot = this.timeslots.find((obj) => obj.id == element);
              if (
                reservation.slots.find(
                  (slot) => slot.timeslot == tempSlot.timeslottext
                )
              ) {
                reservation.slots.find(
                  (slot) => slot.timeslot == tempSlot.timeslottext
                ).quantity += 1;
              } else {
                reservation.slots.push({
                  timeslot: tempSlot.timeslottext,
                  quantity: 1,
                });
              }
            }
          } else {
            reservation = new CartReservation();
            reservation.serviceid = this.selectedItem.id;
            reservation.title = this.selectedItem.title[lang];
            if (!reservation.title) {
              reservation.title = Object.values(this.selectedItem.title)[0];
            }
            let selectedIds = $('#timeslots option:selected').map(function(
              a,
              item
            ) {
              return item.value;
            });
            if (selectedIds.length == 0) {
              return;
            }
            for (let element of selectedIds) {
              let tempSlot = this.timeslots.find((obj) => obj.id == element);
              reservation.slots.push({
                timeslot: tempSlot.timeslottext,
                quantity: 1,
              });
            }
            this.newAdvancedCart.reservations.push(reservation);
          }
          this.bookingCart = true;
          this.bookingAdvanced = true;
          this.selectingService = false;
        }
      } else {
        let err = false;
        if (this.selectedItem.labels) {
          if (this.cartLabelsList.length == 0) {
            for (let element of this.selectedItem.labels) {
              this.cartLabelsList.push(element);
            }
          } else {
            const filteredArray = this.selectedItem.labels.filter((obj) => {
              let labelFound = this.cartLabelsList.find(
                (element) =>
                  (obj.labelid &&
                    element.labelid &&
                    element.labelid == obj.labelid) ||
                  (obj.id && element.id && element.id == obj.id)
              );
              if (!labelFound && obj.mandatory) {
                err = true;
              }
              return labelFound;
            });
            const rejectedLabels = this.cartLabelsList.filter((obj) =>
              filteredArray.find(
                (element) =>
                  ((!obj.labelid && !element.labelid) ||
                    element.labelid != obj.labelid) &&
                  ((!obj.id && !element.id) || element.id != obj.id)
              )
            );
            for (let element of rejectedLabels) {
              if (element.mandatory) {
                err = true;
              }
            }
            if (filteredArray.length == 0) {
              err = true;
            } else if (!err) {
              this.cartLabelsList = [];
              for (let element of filteredArray) {
                this.cartLabelsList.push(element);
              }
            }
          }
        } else {
          err = true;
        }

        if (err) {
          this.selectingService = true;
          alert(
            'Servizio non compatibile con label dei servizi presenti nel carrello'
          );
        }
        if (!err) {
          if (this.selectedItem.parentaccount) {
            this.newCart.isfrompartner = true;
            this.newCart.partner = this.selectedItem.parentaccount;
          }
          let newItem: CartItems = new CartItems();
          newItem.serviceid = this.selectedItem.id;
          newItem.title = this.selectedItem.title[lang];
          if (!newItem.title) {
            newItem.title = Object.values(this.selectedItem.title)[0];
          }
          this.newCart.items.push(newItem);
          this.selectedItem = undefined;
          this.bookingCart = true;
          this.selectingService = false;
        }
      }
    } else {
      if (this.selectedItem.parentaccount) {
        this.newReservation.isfrompartner = true;
        this.newReservation.partner = this.selectedItem.parentaccount;
      }
      this.bookingService = true;
      this.selectingService = false;
      setTimeout(() => {
        $('#timeslots').multiselect();
        $('#timeslots').multiselect('dataprovider', []);
      }, 10);
      this.newReservation.serviceid = this.selectedItem.id;
      this.newReservation.decreasequantity = this.selectedItem.decreasequantity;

      if (
        this.selectedItem.labels != null &&
        this.selectedItem.labels.length > 0
      ) {
        let availableLabels;
        if (this.virtual) {
          availableLabels = this.selectedItem.labels.filter((obj) => {
            obj.title = {it: obj.label};
            return true;
          });
        } else {
          availableLabels = this.allLabels.filter((obj) => {
            let check = false;
            for (let element of this.selectedItem.labels) {
              if (obj.id == element.labelid) {
                check = true;
              }
            }
            return check;
          });
        }

        this.newReservation.labelinfos = [];
        for (let element of availableLabels) {
          this.newReservation.labelinfos.push({
            labelid: element.id,
            title: element.title,
            message: '',
          });
        }
      } else {
        this.newReservation.labelinfos = [];
      }
    }
  }

  cancelSelectingService() {
    if (this.bookingCart || this.bookingService) {
      this.selectingService = false;
    } else {
      $('#newReservationModal').modal('hide');
      // this.selectingService = false;
    }
  }

  getLabelName(label) {
    let name;
    if (this.allLabels.find((obj) => obj.id == label.labelid)) {
      name = this.allLabels.find((obj) => obj.id == label.labelid).name.it;
    } else if (label.title) {
      name = label.title.it;
    } else if (label.label) {
      name = label.label;
    }
    return name;
  }

  generateId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  checkData() {
    this.checked = true;
    if (this.bookingService) {
      if (
        this.newReservation.email &&
        this.newReservation.name &&
        this.newReservation.lastname &&
        this.newReservation.quantity > 0 &&
        this.newReservation.servicedate
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (this.bookingCart && !this.bookingAdvanced) {
      if (
        this.newCart.email &&
        this.newCart.name &&
        this.newCart.lastname &&
        this.newCart.shoppingcartdate &&
        this.newCart.shoppimgcarttime &&
        !this.dateError
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (this.bookingCart && this.bookingAdvanced) {
      if (
        this.newAdvancedCart.email &&
        this.newAdvancedCart.name &&
        this.newAdvancedCart.lastname &&
        this.newAdvancedCart.shoppingcartdatefrom &&
        !this.dateError
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  makeReservation() {
    if (!this.checkData()) {
      return;
    }
    if (this.newReservation.isfrompartner) {
      this.newReservation.account = this.newReservation.partner;
      this.newReservation.partner = localStorage.getItem('HechRomeoAccount');
    } else {
      this.newReservation.account = localStorage.getItem('HechRomeoAccount');
    }

    if (this.selectedItem.isadvanced) {
      this.newReservation.serviceid = this.selectedItem.id;
      this.newReservation.servicedate = moment(
        this.newReservation.servicedate,
        'YYYY-MM-DD'
      ).format('DD/MM/YYYY');

      if (this.newReservation.serviceenddate) {
        this.newReservation.serviceenddate = moment(
          this.newReservation.serviceenddate,
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
      }
      this.newReservation.reservationdate = moment().format('DD/MM/YYYY');
      this.newReservation.reservationtime = moment().format('HH:mm');
      let selectedIds = $('#timeslots option:selected').map(function(a, item) {
        return item.value;
      });
      let selectedSlots = [];
      for (let element of selectedIds) {
        let tempSlot = this.timeslots.find((obj) => obj.id == element);
        selectedSlots.push({
          timeslot: tempSlot.timeslottext,
          quantity: this.newReservation.quantity,
        });
      }
      let reservation = {
        account: this.newReservation.account,
        serviceid: this.newReservation.serviceid,
        servicedate: this.newReservation.servicedate,
        serviceenddate: this.newReservation.serviceenddate,
        name: this.newReservation.name,
        lastname: this.newReservation.lastname,
        email: this.newReservation.email,
        transactionId: '',
        labelinfos:
          this.newReservation.labelinfos &&
          this.newReservation.labelinfos.length > 0
            ? this.newReservation.labelinfos
            : null,
        slots: selectedSlots,
        isfrompartner: this.newReservation.isfrompartner,
        partner: this.newReservation.partner,
        note: this.newReservation.note,
      };
      this.calendarService
        .advancedServicesMenagerReservation(reservation)
        .subscribe((data) => {
          this.searchRequest.emit();
        });
      // this.calendarService
      //   .makeReservation(this.newReservation)
      //   .subscribe((data) => {
      //     this.resetReservation();
      //     this.searchRequest();
      //   });
    } else {
      this.newReservation.serviceid = this.selectedItem.id;
      this.newReservation.servicedate = moment(
        this.newReservation.servicedate,
        'YYYY-MM-DD'
      ).format('DD/MM/YYYY');

      if (this.newReservation.serviceenddate) {
        this.newReservation.serviceenddate = moment(
          this.newReservation.serviceenddate,
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
      }
      this.newReservation.reservationdate = moment().format('DD/MM/YYYY');
      this.newReservation.reservationtime = moment().format('HH:mm');
      this.calendarService
        .makeReservation(this.newReservation)
        .subscribe((data) => {
          this.searchRequest.emit();
        });
    }
  }

  deleteItemFromCart(item, slot?) {
    if (slot) {
      let reservation = this.newAdvancedCart.reservations.find(
        (obj) => obj.serviceid == item.serviceid
      );
      reservation.slots = reservation.slots.filter(
        (obj) => obj.timeslot != slot.timeslot
      );
      if (reservation.slots.length == 0) {
        this.newAdvancedCart.reservations =
          this.newAdvancedCart.reservations.filter(
            (obj) => obj.serviceid != reservation.serviceid
          );
        if (this.newAdvancedCart.reservations.length == 0) {
          this.selectServiceForReservationInit();
        }
      }
    } else {
      this.newCart.items = this.newCart.items.filter(
        (obj) => obj.serviceid != item.serviceid
      );
      if (this.newCart.items.length == 0) {
        this.selectServiceForReservationInit();
      }
    }
  }

  getTitle(element) {
    return element.title[this.lang] || Object.values(element.title)[0];
  }

  insertCart() {
    if (!this.checkData()) {
      return;
    }
    if (this.bookingAdvanced) {
      if (this.newAdvancedCart.isfrompartner) {
        this.newAdvancedCart.account = this.newAdvancedCart.partner;
        this.newAdvancedCart.partner = localStorage.getItem('HechRomeoAccount');
        for (let element of this.newAdvancedCart.reservations) {
          element.isfrompartner = true;
          element.partner = this.newCart.partner;
        }
      } else {
        this.newAdvancedCart.account = localStorage.getItem('HechRomeoAccount');
      }
      this.newAdvancedCart.labelinfos = [];
      for (let element of this.cartLabelsList) {
        this.newAdvancedCart.labelinfos.push({
          labelid: element.labelid ?? element.id,
          message: element.message ?? '',
        });
      }
      let from = moment(
        this.newAdvancedCart.shoppingcartdatefrom,
        'YYYY-MM-DD'
      );
      let to = moment(this.newAdvancedCart.shoppingcartdateto, 'YYYY-MM-DD');
      moment.fn.toJSON = function() {
        return this.format();
      };
      this.newAdvancedCart.shoppingcartdatefrom = from.toJSON();
      this.newAdvancedCart.shoppingcartdateto = to.toJSON();
      for (let element of this.newAdvancedCart.reservations) {
        delete element.title;
        element.servicedatefrom = from.format('DD/MM/YYYY');
        element.servicedateto = to.format('DD/MM/YYYY');
      }
      this.calendarService
        .insertAdvancedShoppingCart(this.newAdvancedCart)
        .subscribe((data) => {
          this.searchRequest.emit();
        });
      document.getElementById('confirm-cart').click();
    } else {
      let err = false;
      for (let element of this.newCart.items) {
        if (element.quantity < 1) {
          err = true;
          // TODO Capire cosa avevano in mente di fare con questo pezzo di codice
          // document
          //   .getElementById('err-' + element.serviceid)
          //   .classList.remove('d-none');
        } else {
          // TODO Capire cosa avevano in mente di fare con questo pezzo di codice
          // document
          //   .getElementById('err-' + element.serviceid)
          //   .classList.add('d-none');
        }
      }
      if (!err) {
        if (this.newCart.isfrompartner) {
          this.newCart.account = this.newCart.partner;
          this.newCart.partner = localStorage.getItem('HechRomeoAccount');
          for (let element of this.newCart.items) {
            element.isfrompartner = true;
            element.partner = this.newCart.partner;
          }
        } else {
          this.newCart.account = localStorage.getItem('HechRomeoAccount');
        }

        this.newCart.labelinfos = [];
        for (let element of this.cartLabelsList) {
          this.newCart.labelinfos.push({
            labelid: element.labelid ?? element.id,
            message: element.message ?? '',
          });
        }
        this.newCart.shoppingcartdate = moment(
          this.newCart.shoppingcartdate,
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY');
        this.newCart.shoppimgcarttime = moment(
          this.newCart.shoppimgcarttime,
          'H:m'
        ).format('HH:mm');
        this.calendarService
          .insertShoppingCart(this.newCart)
          .subscribe((data) => {
            this.searchRequest.emit();
          });
        document.getElementById('confirm-cart').click();
      }
    }
  }
}
