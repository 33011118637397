<div
  class="modal fade"
  id="delConfirmModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delConfirmModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="deleteVendor(vendorToDelete)"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row mx-0">
  <div class="col-12 my-2">
    <div class="row mx-0 w-100">
      <div class="form-group col-1 p-0">
        <div>
          <label for="search">{{ "Cerca" | translate }}:</label>
          <input id="search" [(ngModel)]="searchValue" />
        </div>
      </div>

      <div class="col-7 col-sm-7 col-ml-7 col-xl-9"></div>

      <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
        <button class="btn btn-primary mb-4 w-100" routerLink="/register">
          + {{ "Aggiungi Fornitore" | translate }}
        </button>
      </div>
    </div>

    <div class="row mx-0">
      <div class="card w-100">
        <div class="card-body" style="overflow-y: auto">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Account</th>
                <th scope="col">Email</th>
                <th scope="col">URL Hech Romeo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let vendor of vendorList | filter: searchValue"
                style="cursor: pointer"
              >
                <td
                  style="
                    border-left: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    width: 40%;
                  "
                  (click)="impersonate(vendor.account)"
                >
                  {{ vendor.account }}
                </td>
                <td
                  style="
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    width: 40%;
                  "
                  (click)="impersonate(vendor.account)"
                >
                  {{ vendor.email }}
                </td>
                <td
                  style="
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    width: 40%;
                  "
                  (click)="impersonate(vendor.account)"
                >
                  {{ vendor.urlhechromeo }}
                </td>
                <td
                  style="
                    border-right: 1px solid rgba(0, 0, 0, 0.125);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    padding: 0;
                    width: 10%;
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      width: 100%;
                    "
                  >
                    <div class="modifyImg" (click)="infoVendor(vendor.account)">
                      <svg
                        class="bi bi-info-circle"
                        width="30px"
                        height="30px"
                        viewBox="0 0 16 16"
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                          d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                        />
                        <circle cx="8" cy="4.5" r="1" />
                      </svg>
                    </div>
                    <div
                      data-toggle="modal"
                      data-target="#delConfirmModal"
                      class="deleteImg"
                      (click)="vendorToDelete = vendor.account"
                    >
                      <img
                        src="assets/img/Pittogramma elimina - cestino.png"
                        style="width: 30px"
                        alt=""
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
