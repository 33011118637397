import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NotificationService } from '../services/notification/notification.service';
import { generateId } from '../models/notification/notification';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth/auth.service';
import { EncryptService } from '../services/utility/encrypt.service';
import { SpinnerService } from '../services/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  errorsMessages = environment.errorsMessages;
  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private encryptService: EncryptService,
    private spinner: SpinnerService,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const ignoreError = sessionStorage.getItem('IgnoreErrorHech');
    let successMessage = sessionStorage.getItem('SuccessMessageHech');
    if (ignoreError) {
      sessionStorage.removeItem('IgnoreErrorHech');
    }
    if (successMessage) {
      sessionStorage.removeItem('SuccessMessageHech');
    }
    return next.handle(request).pipe(
      tap((_) => {
        if (_ instanceof HttpResponse) {
          if ((request.body && request.body.successMessage) || successMessage) {
            this.translate
              .get(
                successMessage ? successMessage : request.body.successMessage
              )
              .subscribe((res: string) => {
                successMessage = res;
                const notification = {
                  id: generateId(),
                  image: undefined,
                  title: 'Riuscito',
                  body: successMessage
                    ? successMessage
                    : request.body.successMessage,
                };
                this.notificationService.addNotification(notification);
              });
          }
          if (
            (request.body && request.body.loader) ||
            (request.params && request.params.get('loader') == 'true')
          ) {
            this.spinner.hideSpinner();
          }
          return _;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (
          (request.body && request.body.loader) ||
          (request.params && request.params.get('loader') == 'true')
        ) {
          this.spinner.hideSpinner();
        }
        if (error.status === 401) {
          sessionStorage.removeItem('HechRomeoToken');
          if (
            localStorage.getItem('HechRomeoEmail') &&
            localStorage.getItem('HechRomeoPassword')
          ) {
            return this.authObservable().pipe(
              switchMap(() => {
                let tokenizedReq = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                      'HechRomeoToken'
                    )}`,
                  },
                });
                return next.handle(tokenizedReq);
              })
            );
          }
          this.router.navigate(['/login']);
        }
        if (error.status && error.status.toString() !== ignoreError) {
          let message;
          message = this.errorsMessages.find(
            (obj) => obj.errorCode.toString() == error.status.toString()
          );

          this.translate
            .get(
              message && message.message
                ? message.message
                : "C'è stato un errore"
            )
            .subscribe((res: string) => {
              successMessage = res;
              const notification = {
                id: generateId(),
                image: undefined,
                title: 'Error: ' + res,
                body: `Url: ${error.url} `,
              };
              this.notificationService.addNotification(notification);
            });
          // alert('Errore: ' + error.message);
        }
        return throwError(error);
      })
    );
  }

  authObservable(): Observable<any> {
    return new Observable((observer) => {
      this.authService
        .login(
          localStorage.getItem('HechRomeoEmail'),
          this.encryptService.decrypt(localStorage.getItem('HechRomeoPassword'))
        )
        .subscribe((data) => {
          sessionStorage.setItem('HechRomeoToken', data.token);
          localStorage.setItem('HechRomeoRole', data.role);

          if (data.role === 'Manager') {
            localStorage.setItem(
              'HechRomeoEmail',
              localStorage.getItem('HechRomeoEmail')
            );
            if (!localStorage.getItem('HechRomeoAccount'))
              localStorage.setItem('HechRomeoAccount', data.vendor);
          } else {
            localStorage.setItem(
              'HechRomeoEmail',
              localStorage.getItem('HechRomeoEmail')
            );
            localStorage.setItem('HechRomeoAccount', data.vendor);
          }

          observer.next();
          observer.complete();
        });
    });
  }
}
