export class Cart {
  account = '';
  name = '';
  lastname = '';
  email = '';
  isfrompartner = false;
  partner = '';
  shoppingcartdate = '';
  shoppimgcarttime = '';
  transactionId = '';
  workedout = false;
  refid = '';
  orderstatus = 0;
  notes = '';
  labelinfos: Array<LabelInfo> = [];
  items: CartItems[] = [];
}

export class CartItems {
  serviceid = '';
  title = '';
  category = '';
  quantity = 1;
  isfrompartner?: boolean;
  partner?: string;
}

export class LabelInfo {
  labelid = '';
  message = '';
}

export class AdvancedCart {
  account: string = '';
  email: string = '';
  isfrompartner: boolean = false;
  labelinfos: Array<LabelInfo> = [];
  labelinfo = null;
  lastname: string = '';
  name: string = '';
  notes: string = '';
  note: string = '';
  orderstatus: number = 0;
  partner: string = '';
  reservations: Array<CartReservation> = [];
  shoppingcartdatefrom: string = '';
  shoppingcartdateto: string = '';
  transactionId: string = '';
  workedout: boolean = false;
}

export class CartReservation {
  servicedatefrom: string = '';
  servicedateto: string = '';
  serviceid: string = '';
  slots: Array<Slot> = [];
  title?: string = '';
  isfrompartner?: boolean;
  partner?: string;
}
export class Slot {
  timeslot: string = '';
  quantity: number = 1;
}
