import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from 'src/app/services/media/media.service';
@Component({
  selector: 'app-manage-icons',
  templateUrl: './manage-icons.component.html',
  styleUrls: ['./manage-icons.component.scss'],
})
export class ManageIconsComponent implements OnInit {
  defaultIcons: string;
  selectedDefaultIcon;
  iconToLoad;
  loadedNewIcon: boolean = false;

  constructor(
    private mediaService: MediaService,
    public _DomSanitizationService: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getDefaultIcons();
  }

  getDefaultIcons() {
    this.mediaService.getDefaultIconList().subscribe((data) => {
      this.defaultIcons = data;
    });
  }

  async saveIcon() {
    if (this.iconToLoad) {
      await this.mediaService.addDefaultIcon(this.iconToLoad).then((data) => {
        this.iconToLoad = undefined;
        this.getDefaultIcons();
      });
    }
  }

  encodeImageFileAsURL(element) {
    this.iconToLoad = undefined;
    console.log(element);
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      this.getPureB64(reader.result.toString());
    };
    reader.readAsDataURL(file);
  }
  getPureB64(element: string) {
    let index = element.indexOf(',');
    element = element.slice(index + 1, element.length);

    this.iconToLoad = element;
    this.loadedNewIcon = true;
  }
}
