import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: `
    <button class="btn btn-primary py-1" (click)="deleteRow()">Elimina</button>
  `
})
export class DeleteButtonRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  deleteRow(): void {
    if (this.params.onClick instanceof Function) {
      this.params.onClick(event, this.params.node);
    }
  }

  refresh(): boolean {
    return false;
  }
}
