import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { EncryptService } from '../services/utility/encrypt.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private encryptService: EncryptService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (sessionStorage.getItem('HechRomeoToken')) return true;
    else {
      if (
        localStorage.getItem('HechRomeoEmail') &&
        localStorage.getItem('HechRomeoPassword')
      ) {
        this.authService
          .login(
            localStorage.getItem('HechRomeoEmail'),
            this.encryptService.decrypt(
              localStorage.getItem('HechRomeoPassword')
            )
          )
          .subscribe((data) => {
            sessionStorage.setItem('HechRomeoToken', data.token);
            localStorage.setItem('HechRomeoRole', data.role);

            if (data.role === 'Manager') {
              localStorage.setItem(
                'HechRomeoEmail',
                localStorage.getItem('HechRomeoEmail')
              );
              if (!localStorage.getItem('HechRomeoAccount'))
                localStorage.setItem('HechRomeoAccount', data.vendor);
            } else {
              localStorage.setItem(
                'HechRomeoEmail',
                localStorage.getItem('HechRomeoEmail')
              );
              localStorage.setItem('HechRomeoAccount', data.vendor);
            }
          });
        return true;
      } else {
        this.router.navigate(['login']);
      }
    }
  }
}
