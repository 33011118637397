<div class="settings-container overflow-auto">
  <h3 class="color-theme font-weight-bold mb-4">
    {{ "Impostazioni" | translate }}
  </h3>
  <div class="my-2">
    <label>Token</label>
    <div class="w-100 row mx-0">
      <div class="col-6 col-xl-3 pl-0 form-group">
        <input
          disabled
          [(ngModel)]="token.token"
          type="text"
          style="width: 100%"
          type="text"
          class="form-control"
          id="token"
          name="token"
          placeholder="{{ 'Token non ancora generato' | translate }}"
        />
      </div>
      <div class="col-6 col-xl-3 form-group">
        <button class="btn btn-primary" (click)="generateToken()" *ngIf="admin">
          {{ "Genera token" | translate }}
        </button>
        <button
          *ngIf="token.token && token.active && admin"
          class="btn btn-secondary"
          [ngClass]="{ 'ml-3': admin }"
          (click)="setTokenActivation(false)"
        >
          {{ "Disattiva token" | translate }}
        </button>
        <button
          *ngIf="token.token && !token.active && admin"
          class="btn btn-primary"
          [ngClass]="{ 'ml-3': admin }"
          (click)="setTokenActivation(true)"
        >
          {{ "Riattiva token" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="my-2" *ngIf="admin">
    <label>{{
      "Imposta se è possibile fare prenotazioni tramite token" | translate
    }}</label>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="reservetionRadio1"
        id="reservetionRadio1"
        [value]="true"
        [(ngModel)]="enableReservation"
      />
      <label class="form-check-label options-label" for="reservetionRadio1">
        {{ "Sì" | translate }}
      </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="reservetionRadio2"
        id="reservetionRadio2"
        [value]="false"
        [(ngModel)]="enableReservation"
      />
      <label class="form-check-label options-label" for="reservetionRadio2">
        No
      </label>
    </div>
    <button
      class="btn btn-primary my-2"
      (click)="setEnableReservation(enableReservation)"
    >
      {{ "Salva" | translate }}
    </button>
  </div>
  <div class="my-2" *ngIf="admin">
    <label>{{
      "Imposta se l'account del fornitore è attivo o bloccato" | translate
    }}</label>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="enableVendor1"
        id="enableVendor1"
        [value]="true"
        [(ngModel)]="vendorEnabled"
      />
      <label class="form-check-label options-label" for="enableVendor1">
        {{ "Attivo" | translate }}
      </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="enableVendor2"
        id="enableVendor2"
        [value]="false"
        [(ngModel)]="vendorEnabled"
      />
      <label class="form-check-label options-label" for="enableVendor2">
        Bloccato
      </label>
    </div>
    <button
      class="btn btn-primary my-2"
      (click)="setVendorStatus(vendorEnabled)"
    >
      {{ "Salva" | translate }}
    </button>
  </div>
  <div class="my-2">
    <label>{{ "Attiva la registrazione per gli utenti" | translate }}</label>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="enebleregistrationRadios"
        id="enebleregistrationRadios1"
        [value]="true"
        [(ngModel)]="accountConfiguration.configuraton.enebleregistration"
      />
      <label class="form-check-label options-label" for="exampleRadios1">
        {{ "Sì" | translate }}
      </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="enebleregistrationRadios"
        id="enebleregistrationRadios2"
        [value]="false"
        [(ngModel)]="accountConfiguration.configuraton.enebleregistration"
      />
      <label class="form-check-label options-label" for="exampleRadios2">
        No
      </label>
    </div>

    <div class="row mx-0 my-2 w-100">
      <div class="col-6 col-xl-3 pl-0 form-group">
        <label for="categorytype">{{ "Seleziona lingua" | translate }}</label>
        <select
          class="form-control"
          name="categorytype"
          [(ngModel)]="currentLanguage"
        >
          <option *ngFor="let item of allLanguages" [value]="item.abr">
            {{ item.language }}
          </option>
        </select>
      </div>

      <!-- TODO Capire perchè soltanto per alcuni account viene generato un errore -->
<!--      <div class="col-6 col-xl-3 form-group">-->
<!--        <label>{{ "Campo note" | translate }}</label>-->
<!--        <input-->
<!--          [(ngModel)]="-->
<!--            accountConfiguration.configuraton.cartrefiddescription[-->
<!--              currentLanguage-->
<!--            ]-->
<!--          "-->
<!--          type="text"-->
<!--          style="width: 100%"-->
<!--          type="text"-->
<!--          class="form-control"-->
<!--          id="label"-->
<!--          name="label"-->
<!--        />-->
<!--      </div>-->
    </div>

    <button class="btn btn-primary mb-2" (click)="setAccountConfiguration()">
      {{ "Salva" | translate }}
    </button>

    <div class="mt-4">
      <h3 class="mb-4">
        {{ "Policy sulla prenotazione" | translate }}
      </h3>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="policyRadios"
          id="policyRadios1"
          [value]="true"
          [(ngModel)]="accountConfiguration.configuraton.policy"
          (change)="savePolicy()"
        />
        <label class="form-check-label options-label" for="policyRadios1">
          {{ "Sì" | translate }}
        </label>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="policyRadios"
          id="policyRadios2"
          [value]="false"
          [(ngModel)]="accountConfiguration.configuraton.policy"
          (change)="savePolicy()"
        />
        <label class="form-check-label options-label" for="policyRadios2">
          No
        </label>
      </div>

      <div id="detailsPolicy">
        <div *ngIf="allPolicyDetails && allPolicyDetails.length > 0">
          <div class="row mx-0 my-2">
            <div class="col-6 col-xl-2 pl-0">
              <label for="categorytype">{{
                "Seleziona lingua" | translate
                }}</label>
            </div>

            <div class="col-6 col-xl-5">
              <label for="categorytype">{{
                "Policy" | translate
                }}</label>
            </div>
          </div>

          <div *ngFor="let detail of allPolicyDetails">
            <div class="row mx-0 my-2">
              <div class="col-6 col-xl-2 pl-0">
                <select
                  class="form-control"
                  name="categorytype"
                  [disabled]="true"
                  [(ngModel)]="detail.lang"
                >
                  <option *ngFor="let item of allLanguages" [value]="item.abr">
                    {{ item.language }}
                  </option>
                </select>
              </div>

              <div class="col-6 col-xl-5">
                <textarea
                  disabled
                  [(ngModel)]="detail.value"
                  type="text"
                  style="width: 100%"
                  class="form-control"
                  id="policy"
                  name="policy"
                  rows="3"
                ></textarea>
              </div>

              <div class="col-2 col-xl-1 btn-container">
                <button
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#policyCreationModal"
                  (click)="modifyPolicy(detail)"
                >
                  {{ "Modifica" | translate }}
                </button>
              </div>

              <div class="col-2 col-xl-1 btn-container">
                <button
                  data-toggle="modal"
                  data-target="#delConfirmModal"
                  class="btn btn-secondary"
                  (click)="
                  deleteElement = detail; deleteFunction = 'deletePolicy'
                "
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <button
          [disabled]="!accountConfiguration.configuraton.policy"
          class="btn btn-primary mt-3"
          data-toggle="modal"
          data-target="#policyCreationModal"
        >
          {{ "Crea nuovo dettagli policy" | translate }}
        </button>
      </div>
    </div>

    <div class="mt-4">
      <h3 class="mb-4">
        {{ "Prenotazioni con il nome della categoria" | translate }}
      </h3>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="categoryNameRadios"
          id="categoryNameRadios1"
          [value]="true"
          [(ngModel)]="accountConfiguration.configuraton.servicewithcategoryname"
        />
        <label class="form-check-label options-label" for="policyRadios1">
          {{ "Sì" | translate }}
        </label>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="categoryNameRadios"
          id="categoryNameRadios2"
          [value]="false"
          [(ngModel)]="accountConfiguration.configuraton.servicewithcategoryname"
        />
        <label class="form-check-label options-label" for="policyRadios2">
          No
        </label>
      </div>
    </div>

    <button class="btn btn-primary mt-2 mb-2" (click)="saveReservationName()">
      {{ "Salva" | translate }}
    </button>

    <div class="mt-4">
      <h3 class="mb-4">{{ "Reparti" | translate }}</h3>
      <div id="departmentContainer">
        <div *ngFor="let element of allDepartments">
          <div
            *ngIf="allLanguages.length > 0"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              /*width: 1300px;*/
            "
          >
            <div class="form-group col-6 col-xl-3 p-0">
              <label for="categorytype">{{
                "Seleziona lingua" | translate
              }}</label>
              <div style="display: flex; flex-direction: row">
                <select
                  class="form-control"
                  name="categorytype"
                  [(ngModel)]="departmentLanguageAssociation[element.id]"
                >
                  <option *ngFor="let item of allLanguages" [value]="item.abr">
                    {{ item.language }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group col-6 col-xl-3">
              <label>{{ "Nome" | translate }}</label>
              <div>
                <input
                  disabled
                  [(ngModel)]="
                    element.name[departmentLanguageAssociation[element.id]]
                  "
                  type="text"
                  style="width: 100%"
                  type="text"
                  class="form-control"
                  id="label"
                  name="label"
                />
              </div>
            </div>
            <div class="form-group col-6 col-xl-3">
              <label>Email</label>
              <div>
                <input
                  disabled
                  [(ngModel)]="element.mailaddress"
                  type="text"
                  style="width: 100%"
                  type="text"
                  class="form-control"
                  id="label"
                  name="label"
                />
              </div>
            </div>
            <div class="form-group col-6 col-xl-3">
              <label>{{ "Numero di telefono" | translate }}</label>
              <div>
                <input
                  disabled
                  [(ngModel)]="element.phone"
                  type="text"
                  style="width: 100%"
                  type="text"
                  class="form-control"
                  id="label"
                  name="label"
                />
              </div>
            </div>
            <div class="form-group col-6 col-xl-3">
              <label>{{ "Stampante" | translate }}</label>
              <div>
                <input
                  disabled
                  [(ngModel)]="element.toner"
                  type="text"
                  style="width: 100%"
                  type="text"
                  class="form-control"
                  id="label"
                  name="label"
                />
              </div>
            </div>
            <div class="form-group col-2 col-xl-1">
              <label>{{ "Notifiche" | translate }}</label>
              <div class="span-container">
                <span>{{
                  element.enablenotification
                    ? ("Attive" | translate)
                    : ("Disattive" | translate)
                }}</span>
              </div>
            </div>
            <div class="form-group col-2 col-xl-2">
              <label>{{ "Stampante attiva" | translate }}</label>
              <div class="span-container">
                <span>{{
                  element.enabletoner
                    ? ("Attiva" | translate)
                    : ("Disattiva" | translate)
                }}</span>
              </div>
            </div>
            <div class="col-2 col-xl-1 btn-container">
              <button
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#departmentCreationModal"
                (click)="modifyDepartment(element)"
              >
                {{ "Modifica" | translate }}
              </button>
            </div>
            <div class="col-2 col-xl-1 btn-container">
              <button
                data-toggle="modal"
                data-target="#delConfirmModal"
                class="btn btn-secondary"
                (click)="
                  deleteElement = element; deleteFunction = 'deleteDepartment'
                "
              >
                {{ "Elimina" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary mt-3"
        data-toggle="modal"
        data-target="#departmentCreationModal"
      >
        {{ "Crea nuovo reparto" | translate }}
      </button>
    </div>

    <div class="mt-4">
      <h3 class="mb-4">{{ "Etichette" | translate }}</h3>
      <div id="departmentContainer">
        <div *ngFor="let element of allLabels">
          <div
            *ngIf="allLanguages.length > 0"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              /*width: 1300px;*/
            "
          >
            <div class="form-group col-6 col-xl-3 pl-0">
              <label for="categorytype">{{
                "Seleziona lingua" | translate
              }}</label>
              <div style="display: flex; flex-direction: row">
                <select
                  class="form-control"
                  name="categorytype"
                  [(ngModel)]="labelLanguageAssociation[element.id]"
                >
                  <option *ngFor="let item of allLanguages" [value]="item.abr">
                    {{ item.language }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group col-6 col-xl-3">
              <label>{{ "Nome" | translate }}</label>
              <div>
                <input
                  disabled
                  [(ngModel)]="
                    element.name[labelLanguageAssociation[element.id]]
                  "
                  type="text"
                  style="width: 100%"
                  type="text"
                  class="form-control"
                  id="label"
                  name="label"
                />
              </div>
            </div>

            <div class="col-2 col-xl-1 btn-container">
              <button
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#labelCreationModal"
                (click)="modifyLabel(element)"
              >
                {{ "Modifica" | translate }}
              </button>
            </div>
            <div class="col-2 col-xl-1 btn-container">
              <button
                data-toggle="modal"
                data-target="#delConfirmModal"
                class="btn btn-secondary"
                (click)="
                  deleteElement = element; deleteFunction = 'deleteLabel'
                "
              >
                {{ "Elimina" | translate }}
              </button>
            </div>
            <div class="col-5"></div>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary mt-3"
        data-toggle="modal"
        data-target="#labelCreationModal"
      >
        {{ "Crea nuova etichetta" | translate }}
      </button>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="departmentCreationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="departmentCreationModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Crea nuovo reparto" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="clearNewDepartment()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="form-group col-6">
            <label for="categorytype">{{
              "Seleziona lingua" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="categorytype"
                [(ngModel)]="newItemLanguage"
              >
                <option *ngFor="let item of allLanguages" [value]="item.abr">
                  {{ item.language }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-6">
            <label>{{ "Nome" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newDepartment.name[newItemLanguage]"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="label"
                name="label"
              />
              <small class="d-none" id="newDepartmentName" style="color: red">{{
                "Il nome per la lingua italiana deve essere compilato"
                  | translate
              }}</small>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group col-6">
            <label>Email</label>
            <div>
              <input
                [(ngModel)]="newDepartment.mailaddress"
                style="width: 100%"
                type="email"
                class="form-control"
                id="label"
                name="label"
              />
              <small
                class="d-none"
                id="newDepartmentMailaddress"
                style="color: red"
                >{{ "Campo obbligatorio" | translate }}</small
              >
              <small
                class="d-none"
                id="newDepartmentMailaddressValue"
                style="color: red"
                >{{ "Email non valida" | translate }}</small
              >
            </div>
          </div>
          <div class="form-group col-6">
            <label>{{ "Numero di telefono" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newDepartment.phone"
                style="width: 100%"
                type="text"
                class="form-control"
                id="label"
                name="label"
              />
              <small
                class="d-none"
                id="newDepartmentPhone"
                style="color: red"
                >{{ "Campo obbligatorio" | translate }}</small
              >
              <small
                class="d-none"
                id="newDepartmentPhoneValue"
                style="color: red"
                >{{ "Numero non valido" | translate }}</small
              >
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group col-6">
            <label>{{ "Stampante" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newDepartment.toner"
                style="width: 100%"
                type="text"
                class="form-control"
                id="label"
                name="label"
              />
              <small
                class="d-none"
                id="newDepartmentMailaddress"
                style="color: red"
                >{{ "Campo obbligatorio" | translate }}</small
              >
              <small
                class="d-none"
                id="newDepartmentMailaddressValue"
                style="color: red"
                >{{ "Email non valida" | translate }}</small
              >
            </div>
          </div>
        </div>
        <div class="form-group col-3">
          <div class="form-check">
            <input
              [(ngModel)]="newDepartment.enablenotification"
              type="checkbox"
              class="form-check-input"
              id="label"
              name="label"
            />
            <label class="form-check-label">{{
              "Notifiche" | translate
            }}</label>
          </div>
        </div>
        <div class="form-group col-3">
          <div class="form-check">
            <input
              [(ngModel)]="newDepartment.enabletoner"
              type="checkbox"
              class="form-check-input"
              id="label"
              name="label"
            />
            <label class="form-check-label">{{
              "Stampante attiva" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="clearNewDepartment()"
        >
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="modifying ? updateDepartment() : createNewDepartment()"
        >
          {{ "Salva" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary d-none"
          data-dismiss="modal"
          id="departmentDismiss"
        >
          {{ "Salva" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="labelCreationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="labelCreationModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Crea nuova etichetta" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="clearNewLabel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="form-group col-6">
            <label for="categorytype">{{
              "Seleziona lingua" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="categorytype"
                [(ngModel)]="newItemLanguage"
              >
                <option *ngFor="let item of allLanguages" [value]="item.abr">
                  {{ item.language }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-6">
            <label>{{ "Nome" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newLabel.name[newItemLanguage]"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="label"
                name="label"
              />
              <small class="d-none" id="newLabelName" style="color: red">{{
                "Il nome per la lingua italiana deve essere compilato"
                  | translate
              }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="clearNewLabel()"
        >
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="modifying ? updateLabel() : createNewLabel()"
        >
          {{ "Salva" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary d-none"
          data-dismiss="modal"
          id="labelDismiss"
        >
          {{ "Salva" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="delConfirmModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delConfirmModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="delete()"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="policyCreationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="policyCreationModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalPolicy">
          {{ "Crea nuovo dettaglio policy" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="clearNewPolicy()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mx-0">
          <div class="form-group">
            <label for="categorytype">{{
              "Seleziona lingua" | translate
              }}</label>
          </div>
        </div>

        <div class="row mx-0">
          <div class="form-group col-6 p-0">
            <select
              class="form-control"
              name="categorytype"
              [(ngModel)]="newPolicy.lang"
              [disabled]="modifyingPolicy"
            >
              <option *ngFor="let item of modifyingPolicy ? allLanguages : allLangs" [value]="item.abr">
                {{ item.language }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mx-0">
          <div class="form-group">
            <label for="categorytype">{{
              "Policy" | translate
              }}</label>
          </div>
        </div>

        <div class="row mx-0">
          <div class="form-group col-12 p-0">
            <textarea [(ngModel)]="newPolicy.value" class="form-control w-100" rows="4"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="clearNewPolicy()"
        >
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="modifyingPolicy ? updatePolicy() : createNewPolicy()"
        >
          {{ "Salva" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary d-none"
          data-dismiss="modal"
          id="policyDismiss"
        >
          {{ "Salva" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
