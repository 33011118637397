import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryProject, DownloadFile } from 'src/app/models/projects-pdf/projects-pdf';

@Component({
  selector: 'app-download-pdf-projects',
  templateUrl: './download-pdf-projects.component.html',
  styleUrls: ['./download-pdf-projects.component.scss']
})
export class DownloadPdfProjectsComponent implements OnInit {

  @Input() projectList: CategoryProject;
  @Output() projectSelectedEvent = new EventEmitter<CategoryProject | null>();
  @Output() projectDeletedEvent = new EventEmitter<CategoryProject>();
  @Output() projectsDownloadEvent = new EventEmitter<DownloadFile>();

  searchValue = '';

  constructor() { }

  ngOnInit(): void {
  }

  addNewProject(){
    this.projectSelectedEvent.emit(null)
  }

  editProject(project: CategoryProject){
    this.projectSelectedEvent.emit(project);
  }

  deleteProject(project: CategoryProject){
    this.projectDeletedEvent.emit(project);
  }

  downloadProjects(project: CategoryProject, type: string){
    this.projectsDownloadEvent.emit({ project: project, type:type});
  }
}
