export enum TYPOGRAPHY {
    ACCOUNT_HINT = 'L\'account deve contenere almeno 2 caratteri di cui il primo deve essere una lettera e il secondo una lettera o un numero o un carattere speciale',
    PASSWORD_HINT = 'La password deve contenere almeno 8 caratteri di cui almeno una lettera maiuscola, un numero e un carattere speciale tra i seguenti: .-_@$!%*?&',
    RESET_PASSWORD_LABEL = 'Resetta la tua password',
    RESET_PASSWORD_BUTTON = 'Reset',
    RESET_PASSWORD_SUCCESS = 'Reset della password avvenuto con successo',
    RESET_PASSWORD_FAILURE = 'Errore nel reset della password',
    MODIFY_PASSWORD_SUCCESS = 'Password modificata con successo',
    MODIFY_PASSWORD_FAILURE_NOT_EQUALS = 'La nuova password non combacia',
    LOGIN_LABEL = 'Accedi al tuo Front Desk',
    LOGIN_BUTTON = 'Login',
    INPUT_GENERIC_ERROR_MESSAGE = 'Questo campo è obbligatorio',
    INPUT_INVALID_ERROR_MESSAGE = 'Il valore inserito non è valido',
    INPUT_ERROR_TERMS_OF_SERVICE = 'Accettare i termini di servizio',
    INPUT_ERROR_FIELD_MUST_BE_EQUALS = 'I campi devono essere uguali',
    INPUT_ERROR_COMPLETE_ALL_FIELDS = 'Completare correttamente tutti i campi',
    INPUT_ERROR_ADMIN_CANT_MODIFY_VALUES = 'Un admin non può modificare i dati di un utente',
    GENERIC_ERROR = 'Errore generico',
    UNIQUE_OFFICE_CODE = 'Codice alfanumerico di sei caratteri necessario ai fini della fatturazione elettronica',
    TERMS_OF_SERVICE = `
        <h1 style="text-align: center;"><span style="color: #aeca41;"><strong>Condizioni generali di contratto di licenza software</strong></span></h1>
        <h2 style="text-align: center;"><span style="color: #aeca41;"><strong>HECH ROMEO</strong></span></h2>
        <p>&nbsp;</p>
        <h3 style="text-align: justify;"><span style="color: #aeca41;"><strong>1. Applicabilit&agrave; delle Condizioni Generali di Contratto</strong></span></h3>
        <p style="text-align: justify;">Le presenti Condizioni Generali di Contratto unitamente alla Proposta d&rsquo;Ordine (come di seguito meglio definita) disciplinano il rapporto contrattuale tra Intech s.r.l., con sede legale in Milano, Via Pasinetti, 7, Cod. Fisc./P.I. 08281340961 iscr. Reg. imprese di Milano (in prosieguo "<strong>INTECH</strong>") ed il cliente indicato nella Proposta d&rsquo;Ordine (in prosieguo il "<strong>Cliente</strong>") avente ad oggetto le prestazioni specificate nella Proposta d&rsquo;Ordine e la concessione del diritto di utilizzare il software la cui disponibilit&agrave; giuridica appartiene a INTECH, le cui funzionalit&agrave; e finalit&agrave; sono meglio descritte nelle presenti Condizioni Generali del Contratto. In prosieguo, con l&rsquo;espressione il &ldquo;<strong>Contratto</strong>&rdquo; si intenderanno le presenti Condizioni Generali di Contratto, la Proposta d&rsquo;Ordine e gli eventuali altri allegati, cos&igrave; come ogni modifica e/o allegato che sia aggiunto o possa essere aggiunto a completamento o modifica del Contratto stesso.</p>
        <p style="text-align: justify;">&nbsp;</p>

        <h3 style="text-align: justify;"><span style="color: #aeca41;"><strong>&nbsp;</strong><strong>Disposizioni generali</strong></span></h3>

        <p style="text-align: justify;">2.1 Le Condizioni Generali, la Proposta d&rsquo;Ordine e gli altri allegati costituiscono parte integrante e sostanziale del Contratto.</p>
        <p style="text-align: justify;">2.1 In caso di difformit&agrave; o contrasto tra quanto previsto nelle presenti Condizioni Generali di Contratto e quanto previsto nella Proposta d&rsquo;Ordine o negli allegati operer&agrave; il seguente ordine di prevalenza: (1) Proposta d&rsquo;Ordine; (2) Condizioni Generali di Contratto; (3) gli allegati.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <h3 style="text-align: justify;"><span style="color: #aeca41;"><strong>3. Definizioni</strong></span></h3>
        <p style="text-align: justify;">In aggiunta ai termini definiti altrove nel Contratto, ai fini del medesimo si definiscono:</p>
        <p style="text-align: justify;">&ldquo;<strong>HECH Romeo</strong>&rdquo;: programma software di titolarit&agrave; di INTECH che consente la fruizione di contenuti multimediali attraverso apparecchi televisori, monitor, smartphone, tablet o altri device tecnologicamene compatibili. Le caratteristiche del software e la compatibilit&agrave; del medesimo con gli apparecchi Televisori, Monitor, Smartphone, Tablet e altri device disponibili sul mercato sono descritte in allegato tecnico consegnato al Cliente. I contenuti multimediali fruibili attraverso HECH ROMEO sono gestiti dal Cliente su server o cloud. Il predetto software viene - per la parte relativa al funzionamento e al caricamento dei contenuti sul server o in cloud - installato da INTECH sul server di propriet&agrave; del Cliente (o nella disponibilit&agrave; di quest&rsquo;ultimo) anche con modalit&agrave; remota e - per la parte relativa alla fruizione dei contenuti dai singoli apparecchi televisori o monitor - reso disponibile al Cliente attraverso i sito internet reperibile all&rsquo;URL hech.romeo.guide oppure hech.tv oppure altro portale di titolarit&agrave; di INTECH, in area accessibile al Cliente a mezzo credenziali che saranno fornite da INTECH al momento della sottoscrizione del Contratto ovvero verr&agrave; installato dal personale di INTECH in conformit&agrave; a quanto concordato tra le parti.;</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">&ldquo;<strong>Servizi Accessori</strong>&rdquo;<strong>:&nbsp;</strong>i servizi ulteriori erogati da INTECH su richiesta del Cliente come descritti nella Proposta d&rsquo;Ordine nonch&eacute; nelle presenti Condizioni Generali;</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">&ldquo;<strong>Durata del Contratto</strong>&rdquo;: il periodo di tempo indicato nella Proposta d&rsquo;Ordine e/o negli allegati che sia relativo a prestazioni non istantanee;</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">&ldquo;<strong>Proposta d&rsquo;Ordine</strong>&rdquo;:il documento che riporta, tra gli altri dati, le specifiche dei Servizi accessori che INTECH fornisce al Cliente.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">&ldquo;<strong>Parte/i</strong>&rdquo;:INTECH e/o il Cliente.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <h3 style="text-align: justify;"><span style="color: #aeca41;"><strong>4. Oggetto del Contratto</strong></span></h3>
        <p style="text-align: justify;">4.1. Attraverso la sottoscrizione della Proposta d&rsquo;Ordine e del Contratto, INTECH mette a disposizione del Cliente il programma HECH ROMEO per il periodo di mesi Ventiquattro (o la diversa durata indicata dalla Proposta d&rsquo;Ordine), affinch&eacute; il Cliente utilizzi tale programma in conformit&agrave; e per gli usi ai quali il programma &egrave; destinato, per il numero di Server, apparecchi Televisori e/o Monitor e/o Smartphone o altri Device tecnologicamente&nbsp; compatibili e nei luoghi indicati nella Proposta d&rsquo;Ordine.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">4.2. HECH ROMEO viene pertanto concesso in licenza d&rsquo;uso ed &egrave; escluso qualsiasi trasferimento anche parziale della propriet&agrave; del software e delle riproduzioni fotografiche o le immagini grafiche facenti parte del programma. Di conseguenza il Cliente non potr&agrave;:</p>
        <p style="padding-left: 30px;">a) utilizzare il programma una volta decorso il termine di validit&agrave; del Contratto;</p>
        <p style="padding-left: 30px;">b) utilizzare il programma per un numero di server, apparecchi televisori e/o monitor ovvero di unit&agrave; di riferimento superiore rispetto a quanto indicato nella Proposta d&rsquo;Ordine ovvero in luoghi diversi rispetto a quello/i indicato/i nella Proposta d&rsquo;Ordine;</p>
        <p style="padding-left: 30px;">c) modificare il programma o parte di esso;</p>
        <p style="padding-left: 30px;">d) cedere il programma o il suo uso a terzi;</p>
        <p style="padding-left: 30px;">e) copiare il programma, se non per comprovati motivi di sicurezza o funzionalit&agrave; operativa;</p>
        <p style="padding-left: 30px;">f) continuare a utilizzare il programma o parti di esso, ivi incluse le riproduzioni fotografiche o le immagini grafiche facenti parte del programma, successivamente al termine di validit&agrave; del Contratto.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">4.2. INTECH effettuer&agrave; la programmazione del server del Cliente e del Cloud necessari al fine di garantire la fruibilit&agrave; dei contenuti audiovisivi e multimediali i cui diritti (anche parziali) di utilizzazione competano al Cliente. La programmazione consister&agrave; nella personalizzazione del software per l&rsquo;adattamento del medesimo ai contenuti e alle informazioni che il Cliente vorr&agrave; fornire ai propri utenti. Per contenuti e informazioni si intendono quelli specificati nell&rsquo;allegato tecnico consegnato al cliente ovvero quelli ulteriori specificati nella Proposta d&rsquo;Ordine o successivamente concordati tra INTECH e il Cliente.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">4.3. Il Cliente potr&agrave; modificare i contenuti messi a disposizione dei propri clienti attraverso il cloud e l&rsquo;eventuale server collocato presso la propria struttura o comunque nella sua disponibilit&agrave; limitatamente ad un accesso &ldquo;user&rdquo;, che non include le funzionalit&agrave; di tipo &ldquo;administrator&rdquo; al sistema il cui accesso &egrave; consentito esclusivamente ai tecnici INTECH, e fermo restando che INTECH non potr&agrave; essere ritenuta responsabile per qualsiasi conseguenza dannosa possa derivare dalle suddette modifiche apportate dai clienti e che le operazioni di ripristino della configurazione esistente al momento della modifica si intenderanno a carico del Cliente.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">4.4. Le modifiche dei contenuti effettuate direttamente dal Cliente sull&rsquo;eventuale server nella disponibilit&agrave; diretta del Cliente risulteranno disponibili agli utenti degli apparecchi televisori e dei monitor presenti presso il Cliente mentre le modifiche effettuate attraverso i portali reperibili agli URL hech.romeo.guide oppure hech.tv oppure altro portale di titolarit&agrave; di INTECH saranno disponibili anche agli utenti che accederanno ai contenuti attraverso la pagina web che rende i contenuti disponibili attraverso smartphone, tablet o altri device.&nbsp;</p>
        <p style="text-align: justify;">&nbsp;</p>
        <h3 style="text-align: justify;"><span style="color: #aeca41;"><strong>5. Servizi Accessori</strong></span></h3>
        <p style="text-align: justify;">5.1. Durante il periodo di efficacia del Contratto INTECH effettuer&agrave; le operazioni di aggiornamento dei contenuti multimediali richiesti dal Cliente e l&rsquo;assistenza all&rsquo;uso di HECH ROMEO. Scaduto il primo anno di efficacia del contratto INTECH oscurer&agrave; il cloud ed effettuer&agrave; le operazioni di aggiornamento a fronte del riconoscimento da parte del Cliente del corrispettivo indicato nella Proposta d&rsquo;Ordine ovvero, ove non diversamente previsto, nella misura di Euro 72,00 (oltre IVA) per ciascuna ora/persona di lavoro necessaria all&rsquo;aggiornamento e all&rsquo;assistenza tecnica necessaria.&nbsp;</p>
        <p style="text-align: justify;">5.2. Salva l&rsquo;ipotesi in cui il Cliente abbia richiesto la messa a disposizione del programma per la sola fruizione attraverso pagina Web, Web App o App, la messa a disposizione agli utenti dei contenuti multimediali del Cliente per il tramite di pagina Web, Web App o App costituisce Servizio Accessorio il cui corrispettivo deve intendersi compreso nel costo della licenza per la durata di efficacia del contratto. Scaduto il periodo di efficacia del contratto il Cliente dovr&agrave; versare a INTECH a fronte della predetta messa a disposizione il corrispettivo indicato nella Proposta d&rsquo;Ordine per lo specifico servizio di mantenimento Cloud, hosting e Softwares.</p>
        <p style="text-align: justify;">5.3. Per il caso in cui il Cliente abbia richiesto assistenza tecnica e informatica on site o in modalit&agrave; remota (al di fuori di quella iniziale e di quella prevista al punto 5.1 delle presenti condizioni generali) INTECH, previa segnalazione circostanziata da parte del Cliente, effettuer&agrave; le prestazioni di assistenza tecnica richieste, assistenza costituita da installazione di materiale hardware e/o software, da manutenzione dei software dei server e degli apparecchi TV, da consulenza sull&rsquo;organizzazione e il funzionamento delle infrastrutture dedicate alla fruizione dei contenuti audiovisivi e multimediali ubicate presso il Cliente o nella sua disponibilit&agrave;. Nello svolgimento del servizio di assistenza on site il Cliente dovr&agrave; assicurare la presenza e la collaborazione di un proprio manutentore e/o di un proprio antennista purch&eacute; certificati presso il circuito denominato &ldquo;Antennisti e Impiantisti TV Hotel&rdquo;. Il corrispettivo che il Cliente dovr&agrave; riconoscere a INTECH a fronte della predetta attivit&agrave; di assistenza sar&agrave; il medesimo indicato al punto 5.1.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <h3 style="text-align: justify;"><span style="color: #aeca41;"><strong>6. Corrispettivo</strong></span></h3>
        <p style="text-align: justify;">6.1. Il Cliente si impegna a pagare a INTECH le somme stabilite nella Proposta d&rsquo;Ordine ovvero dalle presenti Condizioni Generali di Contratto: ove non diversamente stabilito, il corrispettivo dovr&agrave; essere versato all&rsquo;atto della sottoscrizione del Contratto.</p>
        <p style="text-align: justify;">6.2. Oltre al corrispettivo pattuito il Cliente dovr&agrave; versare a INTECH le spese in caso di trasferta necessarie per l&rsquo;esecuzione dei Servizi, previa emissione della relativa fattura da parte di INTECH e salvo il diritto del Cliente alla verifica delle spese di trasferta come fatturate. E&rsquo; facolt&agrave; del Cliente offrire vitto e alloggio al personale di INTECH qualora l&rsquo;intervento di assistenza richieda la permanenza in loco del personale di INTECH - anche considerata l&rsquo;ubicazione geografica del Cliente - superiore alle otto ore.&nbsp;</p>
        <p style="text-align: justify;">6.3. Il Cliente non potr&agrave; per alcun motivo ritardare il pagamento delle somme dovute a INTECH - salvo che in caso di nullit&agrave;, annullabilit&agrave; o rescissione del contratto - fermo restando il diritto di ottenere la restituzione una volta che dovesse essere accertato che tali somme non erano dovute.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <h3 style="text-align: justify;"><span style="color: #aeca41;"><strong>4. Diritti di propriet&agrave; industriale e creativa</strong></span></h3>
        <p style="text-align: justify;">Il presente Contratto non implica, salvo espresse previsioni in esso contenute, il trasferimento ad alcuna delle Parti della titolarit&agrave; di licenze o altri diritti d'uso su marchi, brevetti, copyright od altri diritti di propriet&agrave; intellettuale e/o industriale (i &ldquo;Diritti di Propriet&agrave; Intellettuale&rdquo;) - incluso il know-how e le conoscenze tecnologiche, metodologiche ed organizzative - suscettibili di protezione legale ai sensi delle norme applicabili, che siano stati messi a disposizione o comunque comunicati dall'altra parte nell'ambito del presente rapporto.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <h3 style="text-align: justify;"><span style="color: #aeca41;"><strong> 8. Riservatezza</strong></span></h3>
        <p style="text-align: justify;">8.1. Le Parti si impegnano reciprocamente, anche per il periodo successivo alla cessazione di efficacia del Contratto, a trattare come confidenziali le informazioni tecniche, commerciali, organizzative e finanziarie (le &ldquo;<strong>Informazioni Riservate</strong>&rdquo;) ricevute per il corretto adempimento degli obblighi contenuti nel presente Contratto ed a non rivelarle a terzi senza il previo consenso scritto dell&rsquo;altra Parte n&eacute; ad usarle per fini diversi da quelli previsti nel presente Contratto.</p>
        <p style="text-align: justify;">8.2. Il presente obbligo di riservatezza non si applica alle informazioni che sono generalmente conosciute dal pubblico senza che ci&ograve; sia dipeso da un atto o una omissione della Parte che le ha ricevute, alle informazioni che ciascuna Parte debba comunicare a terzi ai fini dell'esecuzione delle prestazioni del Contratto, n&eacute; alle informazioni la cui divulgazione sia imposta dalla legge e/o da norme regolamentari, da un provvedimento dell&rsquo;Autorit&agrave; Giudiziaria oppure da una richiesta dalla Pubblica Autorit&agrave; in genere.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <h3 style="text-align: justify;"><span style="color: #aeca41;"><strong>9. Legge applicabile e Foro competente</strong></span></h3>
        <p style="text-align: justify;">9.1. Il presente Contratto, cos&igrave; come qualunque altro accordo con esso connesso, sar&agrave; disciplinato dalla legge italiana.</p>
        <p style="text-align: justify;">9.2. Tutte le controversie relative al presente Contratto, comprese quelle inerenti alla sua interpretazione, esecuzione, validit&agrave; ed efficacia, saranno deferite alla competenza esclusiva del Foro di Milano.</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">Ai sensi degli Artt. 1341 e 1342 del cod. civ. il Cliente dichiara di aver letto e di approvare specificamente le clausole di cui ai seguenti articoli delle Condizioni Generali di Contratto: 6.3 (<em>Clausola solve et repete</em>); 9. (<em>Legge applicabile e Foro competente</em>).</p>
        <p style="text-align: justify;">&nbsp;</p>
        <p style="text-align: justify;">&nbsp;</p>
    `,
    TERMS_OF_DATA = 'Non pervenuto',
}
