<div class="container-fluid">
  <div class="row mx-0">
    <div class="card border-0">
      <div class="card-body">
        <span routerLink="/calendar" class="advancedCaledarReturn"
          >< {{ "Torna al calendario generale" | translate }}</span
        >
        <h3 class="color-theme font-weight-bold mb-0">
          {{ "Calendario Prenotazioni Avanzate" | translate }}
        </h3>
        <!-- <label
          class="my-1 mr-2 text-capitalize"
          for="inlineFormCustomSelectPref"
          >Filtro:</label
        >
        <select
          class="custom-select my-1 mr-sm-2"
          id="inlineFormCustomSelectPref"
          [(ngModel)]="filter"
          (change)="updateDaily()"
        >
          <option [value]="undefined">Nessun filtro</option>
          <option [value]="0">In Attesa</option>
          <option [value]="1">Accettata</option>
          <option [value]="2">Rifiutata</option>
          <option [value]="3">Processata</option>
          <option [value]="4">Cancellata</option>
        </select> -->
        <div class="row mx-0">
          <button
            class="btn btn-secondary mr-1 my-2"
            data-toggle="modal"
            data-target="#filtersModal"
            (click)="clearNewFilter()"
          >
            {{ "Filtri" | translate }}
          </button>

          <!-- <button
            class="btn btn-secondary mx-1 my-2"
            data-toggle="modal"
            data-target="#newReservationModal"
            (click)="resetReservation(); selectCurrentService()"
          >
            {{ "Crea nuova prenotazione" | translate }}
          </button> -->
          <!-- <button
            class="btn btn-secondary mx-1 my-2"
            data-toggle="modal"
            data-target="#newCartModal"
            (click)="resetCart()"
          >
            Crea nuovo carrello
          </button> -->
          <button class="btn btn-secondary ml-1 my-2" (click)="print()">
            {{ "Stampa" | translate }}
          </button>
        </div>

        <div class="mt-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              [(ngModel)]="hideCalendar"
              (change)="toggleCalendar()"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ "Nascondi Calendario" | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mx-0 w-100">
    <div class="col-sm-12 col-md-12 col-lg-4 p-0 my-3" id="minicalendar">
      <div class="card border-0">
        <div class="card-body">
          <h3 class="color-theme font-weight-bold mb-0">
            {{ "Le tue prenotazioni" | translate }}
          </h3>
          <div *ngIf="currentAdvancedService" class="my-1">
            <span class="font-weight-bold"
              >{{ "Servizio" | translate }}: {{ currentAdvancedService.tree }} >
              {{ currentAdvancedService.title["it"] }}</span
            >
          </div>
          <div class="my-2">
            <span class="font-weight-bold"
              >{{ "Prenotazioni" | translate }}: {{ totalNumber }} -
              {{ "Quantità" | translate }}: {{ totalServices }} -
              {{ "Totale" | translate }}: € {{ totalSearchRequest }}</span
            >
          </div>
        </div>
      </div>
      <div class="eventDetails">
        <div *ngIf="!isDetailsService">
          <div *ngFor="let searchRequest of listSearchRequest">
            <div
              *ngFor="let reservation of searchRequest.reservations"
              class="card"
            >
              <div class="card-body">
                <strong> {{ "Cliente" | translate }}: </strong
                >{{ reservation.name }} {{ reservation.lastname }} <br />
                <strong>{{ "Servizio" | translate }}:</strong>
                {{ searchRequest.servicename }}
                <br />
                <strong>{{ "Data e ora del servizio" | translate }}:</strong>
                {{ searchRequest.servicedate }} ,
                {{ searchRequest.servicetime }} <br />

                <div *ngIf="reservation.quantity && reservation.quantity > 0">
                  <strong>{{ "Quantità" | translate }}:</strong>
                  {{ reservation.quantity }} <br />
                </div>
                <div *ngIf="reservation.amount && reservation.amount > 0">
                  <strong>{{ "Valore" | translate }}:</strong> €{{
                    reservation.amount ? reservation.amount : 0
                  }}
                  <br />
                </div>

                <strong>{{ "Status" | translate }}:</strong>
                {{ getStatusOrder(reservation.orederstatus) }} <br />
              </div>
            </div>
          </div>

          <div *ngFor="let searchRequest of listSearchRequestShopping">
            <div class="card">
              <div class="card-body">
                <strong *ngIf="!searchRequest.startday"
                  >{{ "Data del servizio" | translate }}:</strong
                >
                <strong *ngIf="searchRequest.startday"
                  >{{ "Data inizio del servizio" | translate }}:</strong
                >
                {{
                  searchRequest.startday
                    ? searchRequest.startday
                    : searchRequest.day
                }}
                <br />
                <strong *ngIf="searchRequest.endday"
                  >{{ "Data fine del servizio" | translate }}:</strong
                >
                {{ searchRequest.endday ? searchRequest.endday : "" }}
                <br *ngIf="searchRequest.endday" />
                <strong>{{ "Cliente" | translate }}:</strong>
                {{ searchRequest.name }} {{ searchRequest.lastname }} <br />
                <strong>Email:</strong> {{ searchRequest.email }} <br />
                <strong>Note:</strong> {{ searchRequest.note }} <br />
                <!-- <strong>Etichetta:</strong> {{ searchRequest.label }} <br /> -->
                <strong>{{ "Valore" | translate }}:</strong> €{{
                  searchRequest.price
                }}
                <br />
                <strong>{{ "Quantità" | translate }}:</strong>
                {{ searchRequest.quantity }} <br />
                <strong>{{ "Fasce orarie" | translate }}:</strong> <br />
                <span class="slot" *ngFor="let element of searchRequest.slots"
                  >{{ element.slottext }} <br
                /></span>
                <!-- <strong>Stato:</strong>
                {{ getStatusOrder(searchRequest.orederstatus) }} <br /> -->
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isDetailsService">
          <div *ngFor="let r of services" class="card">
            <div class="card-body">
              <!-- <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="workedout"
                  name="workedout"
                  [checked]="r.workedout"
                  [(ngModel)]="r.workedout"
                  (change)="changeReservationStatus(r, s)"
                />
                <label class="form-check-label text-capitalize" for="workedout">
                  Completato
                </label>
              </div> -->
              <!-- <strong>Servizio:</strong> {{ services.servicename }} <br /> -->
              <strong *ngIf="!r.startday"
                >{{ "Data del servizio" | translate }}:</strong
              >
              <strong *ngIf="r.startday"
                >{{ "Data inizio del servizio" | translate }}:</strong
              >
              {{ r.startday ? r.startday : r.day }}
              <br />
              <strong *ngIf="r.endday"
                >{{ "Data fine del servizio" | translate }}:</strong
              >
              {{ r.endday ? r.endday : "" }}
              <br *ngIf="r.endday" />
              <strong> {{ "Cliente" | translate }}: </strong>{{ r.name }}
              {{ r.lastname }} <br />
              <strong>Email:</strong> {{ r.email }} <br />
              <div *ngIf="r.quantity && r.quantity > 0">
                <strong>{{ "Quantità" | translate }}:</strong> {{ r.quantity }}
                <br />
              </div>
              <div *ngIf="r.price && r.price > 0">
                <strong>{{ "Valore" | translate }}:</strong> €{{ r.price }}
                <br />
              </div>
              <strong>Note:</strong> {{ r.note }} <br />
              <!-- <strong>Etichetta:</strong> {{ r.label }} <br /> -->
              <!-- <strong>Reparti:</strong>
              <span *ngFor="let element of services.departments">
                {{ element }},</span
              > -->
              <!-- <br /> -->
              <strong>{{ "Data e ora di prenotazione" | translate }}:</strong>
              {{ r.reservationdateFormatted }},
              {{ r.reservationtimeFormatted }}
              <br />
              <!-- <strong>Posizione del servizio:</strong>
              {{ r.categoriestree }}
              <br /> -->
              <strong>{{ "Fasce orarie" | translate }}:</strong> <br />
              <span
                class="slot"
                [ngClass]="{
                  selectedSlot: element.slottext == services.activeSlot
                }"
                *ngFor="let element of r.slots"
                >{{ element.slottext }} <br
              /></span>
              <label
                class="my-1 mr-2 text-capitalize"
                for="inlineFormCustomSelectPref"
                >{{ "Status" | translate }}:</label
              >

              <select
                (change)="changeReservationStatus(r, services, false)"
                class="custom-select my-1 mr-sm-2"
                id="inlineFormCustomSelectPref"
                [(ngModel)]="r.reservationstatus"
              >
                <option [ngValue]="0">{{ "In Attesa" | translate }}</option>
                <option [ngValue]="1">{{ "Accettata" | translate }}</option>
                <option [ngValue]="2">{{ "Rifiutata" | translate }}</option>
                <option [ngValue]="3">{{ "Processata" | translate }}</option>
                <option [ngValue]="4">{{ "Cancellata" | translate }}</option>
              </select>
              <!-- <button
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#delConfirmModal"
                type="button"
                class="btn btn-primary"
                (click)="
                  setReservationToCancel(
                    r.reservationid,
                    s.servicedate,
                    s.servicetime,
                    r.email,
                    s.serviceid
                  )
                "
              >
                Annulla prenotazione
              </button> -->
            </div>
          </div>
          <div *ngIf="services.cart" class="card">
            <div class="card-body">
              <!-- <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="workedout"
                  name="workedout"
                  [checked]="r.workedout"
                  [(ngModel)]="r.workedout"
                />
                <label class="form-check-label text-capitalize" for="workedout">
                  Completato
                </label>
              </div> -->

              <strong>{{ "Data e ora del servizio" | translate }}:</strong>
              {{ services.reservationdate }} , {{ services.reservationtime }}
              <br />
              <strong> : </strong>{{ services.name }} {{ services.lastname }}
              <br />
              <strong>Email:</strong> {{ services.email }} <br />

              <!-- <strong>Ref N°:</strong> {{ r.refid }} <br /> -->
              <strong>Note:</strong> {{ services.notes }} <br />
              <strong>{{ "Etichetta" | translate }}:</strong>
              {{ services.label }} <br />
              <strong>{{ "Valore" | translate }}:</strong> €{{
                services.amount
              }}
              <br />
              <strong>{{ "Servizi" | translate }}:</strong> <br />
              <div
                *ngFor="let s of services.services; index as i"
                class="cartItem"
                [ngClass]="{ cartItem: i != 0, cartItemFirst: i == 0 }"
              >
                <strong>{{ "Titolo" | translate }}:</strong>
                {{ s.servicename }} <br />
                <strong>{{ "Posizione del servizio" | translate }}:</strong>
                {{ s.categoriestree }}
                <br />
                <strong>{{ "Quantità" | translate }}:</strong> {{ s.quantity }}
                <br />
                <strong>{{ "Prezzo" | translate }}:</strong> €{{ s.price }}
                <br />
                <strong>{{ "Reparti" | translate }}:</strong>
                <span *ngFor="let element of s.departments">
                  {{ element }},</span
                >
                <br />
              </div>
              <label
                class="my-1 mr-2 text-capitalize"
                for="inlineFormCustomSelectPref"
                >{{ "Status" | translate }}:</label
              >
              <!-- (change)="changeReservationStatus(r, undefined, true)"
                [disabled]="!r.active" -->
              <select
                class="custom-select my-1 mr-sm-2"
                id="inlineFormCustomSelectPref"
                [(ngModel)]="services.status"
                (change)="changeReservationStatus(services, undefined, true)"
              >
                <option [ngValue]="0">{{ "In Attesa" | translate }}</option>
                <option [ngValue]="1">{{ "Accettata" | translate }}</option>
                <option [ngValue]="2">{{ "Rifiutata" | translate }}</option>
                <option [ngValue]="3">{{ "Processata" | translate }}</option>
                <option [ngValue]="4">{{ "Cancellata" | translate }}</option>
              </select>

              <!-- <button
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#delConfirmModal"
                type="button"
                class="btn btn-primary"
                (click)="
                  setReservationToCancel(
                    r.reservationid,
                    s.servicedate,
                    s.servicetime,
                    r.email,
                    s.serviceid
                  )
                "
              >
                Annulla prenotazione
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-8 my-3" id="calendar1"></div>
  </div>
</div>

<!--<div class="container-calendars">-->
<!--  <div-->
<!--    style="-->
<!--      display: flex;-->
<!--      flex-direction: row;-->
<!--      width: 100%;-->
<!--      justify-content: space-between;-->
<!--    "-->
<!--  >-->
<!--    <div>-->
<!--      <div class="card border-0">-->
<!--        <div class="card-body">-->
<!--          <span>Gestione ospiti</span>-->
<!--          <h3 class="color-theme font-weight-bold mb-0">-->
<!--            Calendario Prenotazioni-->
<!--          </h3>-->
<!--          &lt;!&ndash; <label-->
<!--            class="my-1 mr-2 text-capitalize"-->
<!--            for="inlineFormCustomSelectPref"-->
<!--            >Filtro:</label-->
<!--          >-->
<!--          <select-->
<!--            class="custom-select my-1 mr-sm-2"-->
<!--            id="inlineFormCustomSelectPref"-->
<!--            [(ngModel)]="filter"-->
<!--            (change)="updateDaily()"-->
<!--          >-->
<!--            <option [value]="undefined">Nessun filtro</option>-->
<!--            <option [value]="0">In Attesa</option>-->
<!--            <option [value]="1">Accettata</option>-->
<!--            <option [value]="2">Rifiutata</option>-->
<!--            <option [value]="3">Processata</option>-->
<!--            <option [value]="4">Cancellata</option>-->
<!--          </select> &ndash;&gt;-->
<!--          <div class="d-flex flex-row">-->
<!--            <button-->
<!--              class="btn btn-secondary"-->
<!--              data-toggle="modal"-->
<!--              data-target="#filtersModal"-->
<!--              (click)="clearNewFilter()"-->
<!--            >-->
<!--              Filtri-->
<!--            </button>-->

<!--            <button-->
<!--              class="btn btn-secondary ml-3"-->
<!--              data-toggle="modal"-->
<!--              data-target="#newReservationModal"-->
<!--              (click)="resetReservation()"-->
<!--            >-->
<!--              Crea nuova prenotazione-->
<!--            </button>-->
<!--            <button-->
<!--              class="btn btn-secondary ml-3"-->
<!--              data-toggle="modal"-->
<!--              data-target="#newCartModal"-->
<!--              (click)="resetCart()"-->
<!--            >-->
<!--              Crea nuovo carrello-->
<!--            </button>-->
<!--            <button-->
<!--              class="btn btn-secondary ml-3"-->
<!--              (click)="print()"-->
<!--            >-->
<!--              Stampa-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="mt-3">-->
<!--            <div class="form-check">-->
<!--              <input-->
<!--                class="form-check-input"-->
<!--                type="checkbox"-->
<!--                value=""-->
<!--                id="flexCheckDefault"-->
<!--                [(ngModel)]="hideCalendar"-->
<!--                (change)="toggleCalendar()"-->
<!--              />-->
<!--              <label class="form-check-label" for="flexCheckDefault">-->
<!--                Nascondi Calendario-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row">-->
<!--    <div class="col-sm-12 col-md-12 col-lg-4" id="minicalendar">-->
<!--      <div class="card border-0">-->
<!--        <div class="card-body">-->
<!--          <h3 class="color-theme font-weight-bold mb-0">Le tue prenotazioni</h3>-->

<!--          <div class="my-2">-->
<!--            <span class="font-weight-bold">Servizi: {{ totalNumber }} - Quantità: {{ totalServices }} - Totale: € {{ totalSearchRequest }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="eventDetails">-->
<!--        <div *ngIf="!isDetailsService">-->
<!--          <div *ngFor="let searchRequest of listSearchRequest">-->
<!--            <div *ngFor="let reservation of searchRequest.reservations" class="card">-->
<!--              <div class="card-body">-->
<!--                <strong> Cliente: </strong>{{ reservation.name }} {{ reservation.lastname }} <br />-->
<!--                <strong>Servizio:</strong> {{ searchRequest.servicename }} <br />-->
<!--                <strong>Data e ora del servizio:</strong>-->
<!--                {{ searchRequest.servicedate }} , {{ searchRequest.servicetime }} <br />-->

<!--                <div *ngIf="reservation.quantity && reservation.quantity > 0">-->
<!--                  <strong>Quantità:</strong> {{ reservation.quantity }} <br />-->
<!--                </div>-->
<!--                <div *ngIf="reservation.amount && reservation.amount > 0">-->
<!--                  <strong>Valore:</strong> €{{ reservation.amount ? reservation.amount : 0 }} <br />-->
<!--                </div>-->

<!--                <strong>Stato:</strong> {{ getStatusOrder(reservation.orederstatus) }} <br />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div *ngFor="let searchRequest of listSearchRequestShopping">-->
<!--              <div class="card">-->
<!--                <div class="card-body">-->
<!--                  <strong>Data e ora del servizio:</strong>-->
<!--                  {{ searchRequest.shoppingcartdate }} , {{ searchRequest.shoppimgcarttime }} <br />-->
<!--                  <strong>Cliente:</strong> {{ searchRequest.name }} {{ searchRequest.lastname }} <br />-->
<!--                  <strong>Email:</strong> {{ searchRequest.email }} <br />-->
<!--                  <strong>Note:</strong> {{ searchRequest.notes }} <br />-->
<!--                  <strong>Etichetta:</strong> {{ searchRequest.label }} <br />-->
<!--                  <strong>Valore:</strong> €{{ searchRequest.amount }} <br />-->
<!--                  <strong>Stato:</strong> {{ getStatusOrder(searchRequest.orederstatus) }} <br />-->
<!--                </div>-->
<!--              </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div *ngIf="isDetailsService">-->
<!--          <div *ngFor="let r of services.reservations" class="card">-->
<!--            <div class="card-body">-->
<!--              &lt;!&ndash; <div class="form-check">-->
<!--                <input-->
<!--                  class="form-check-input"-->
<!--                  type="checkbox"-->
<!--                  value=""-->
<!--                  id="workedout"-->
<!--                  name="workedout"-->
<!--                  [checked]="r.workedout"-->
<!--                  [(ngModel)]="r.workedout"-->
<!--                  (change)="changeReservationStatus(r, s)"-->
<!--                />-->
<!--                <label class="form-check-label text-capitalize" for="workedout">-->
<!--                  Completato-->
<!--                </label>-->
<!--              </div> &ndash;&gt;-->
<!--              <strong>Servizio:</strong> {{ services.servicename }} <br />-->
<!--              <strong>Data e ora del servizio:</strong>-->
<!--              {{ services.servicedate }} , {{ services.servicetime }} <br />-->
<!--              <strong> Cliente: </strong>{{ r.name }} {{ r.lastname }} <br />-->
<!--              <strong>Email:</strong> {{ r.email }} <br />-->
<!--              <div *ngIf="r.quantity && r.quantity > 0">-->
<!--                <strong>Quantità:</strong> {{ r.quantity }} <br />-->
<!--              </div>-->
<!--              <div *ngIf="r.amount && r.amount > 0">-->
<!--                <strong>Valore:</strong> €{{ r.amount }} <br />-->
<!--              </div>-->
<!--              <strong>Etichetta:</strong> {{ r.label }} <br />-->
<!--              <strong>Reparti:</strong>-->
<!--              <span *ngFor="let element of services.departments">-->
<!--                {{ element }},</span-->
<!--              >-->
<!--              <br />-->
<!--              <strong>Data e ora di prenotazione:</strong>-->
<!--              {{ r.reservationdate }} ,-->
<!--              {{ r.reservationtime }}-->
<!--              <br />-->
<!--              <strong>Posizione del servizio:</strong>-->
<!--              {{ r.categoriestree }}-->
<!--              <br />-->
<!--              <label-->
<!--                class="my-1 mr-2 text-capitalize"-->
<!--                for="inlineFormCustomSelectPref"-->
<!--                >Stato:</label-->
<!--              >-->

<!--              <select-->
<!--                (change)="changeReservationStatus(r, services, false)"-->
<!--                class="custom-select my-1 mr-sm-2"-->
<!--                id="inlineFormCustomSelectPref"-->
<!--                [(ngModel)]="r.status"-->
<!--              >-->
<!--                <option [ngValue]="0">In Attesa</option>-->
<!--                <option [ngValue]="1">Accettata</option>-->
<!--                <option [ngValue]="2">Rifiutata</option>-->
<!--                <option [ngValue]="3">Processata</option>-->
<!--                <option [ngValue]="4">Cancellata</option>-->
<!--              </select>-->
<!--              &lt;!&ndash; <button-->
<!--                data-dismiss="modal"-->
<!--                data-toggle="modal"-->
<!--                data-target="#delConfirmModal"-->
<!--                type="button"-->
<!--                class="btn btn-primary"-->
<!--                (click)="-->
<!--                  setReservationToCancel(-->
<!--                    r.reservationid,-->
<!--                    s.servicedate,-->
<!--                    s.servicetime,-->
<!--                    r.email,-->
<!--                    s.serviceid-->
<!--                  )-->
<!--                "-->
<!--              >-->
<!--                Annulla prenotazione-->
<!--              </button> &ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--          <div *ngIf="services.cart" class="card">-->
<!--          <div class="card-body">-->
<!--            &lt;!&ndash; <div class="form-check">-->
<!--              <input-->
<!--                class="form-check-input"-->
<!--                type="checkbox"-->
<!--                value=""-->
<!--                id="workedout"-->
<!--                name="workedout"-->
<!--                [checked]="r.workedout"-->
<!--                [(ngModel)]="r.workedout"-->
<!--              />-->
<!--              <label class="form-check-label text-capitalize" for="workedout">-->
<!--                Completato-->
<!--              </label>-->
<!--            </div> &ndash;&gt;-->

<!--            <strong>Data e ora del servizio:</strong>-->
<!--            {{ services.reservationdate }} , {{ services.reservationtime }}-->
<!--            <br />-->
<!--            <strong> Cliente: </strong>{{ services.name }}-->
<!--            {{ services.lastname }} <br />-->
<!--            <strong>Email:</strong> {{ services.email }} <br />-->

<!--            &lt;!&ndash; <strong>Ref N°:</strong> {{ r.refid }} <br /> &ndash;&gt;-->
<!--            <strong>Note:</strong> {{ services.notes }} <br />-->
<!--            <strong>Etichetta:</strong> {{ services.label }} <br />-->
<!--            <strong>Valore:</strong> €{{ services.amount }} <br />-->
<!--            <strong>Servizi:</strong> <br />-->
<!--            <div-->
<!--              *ngFor="let s of services.services; index as i"-->
<!--              class="cartItem"-->
<!--              [ngClass]="{ cartItem: i != 0, cartItemFirst: i == 0 }"-->
<!--            >-->
<!--              <strong>Titolo:</strong> {{ s.servicename }} <br />-->
<!--              <strong>Posizione del servizio:</strong> {{ s.categoriestree }}-->
<!--              <br />-->
<!--              <strong>Quantità:</strong> {{ s.quantity }} <br />-->
<!--              <strong>Prezzo:</strong> €{{ s.price }} <br />-->
<!--              <strong>Reparti:</strong>-->
<!--              <span *ngFor="let element of s.departments"> {{ element }},</span>-->
<!--              <br />-->
<!--            </div>-->
<!--            <label-->
<!--              class="my-1 mr-2 text-capitalize"-->
<!--              for="inlineFormCustomSelectPref"-->
<!--              >Stato:</label-->
<!--            >-->
<!--            &lt;!&ndash; (change)="changeReservationStatus(r, undefined, true)"-->
<!--              [disabled]="!r.active" &ndash;&gt;-->
<!--            <select-->
<!--              class="custom-select my-1 mr-sm-2"-->
<!--              id="inlineFormCustomSelectPref"-->
<!--              [(ngModel)]="services.status"-->
<!--              (change)="changeReservationStatus(services, undefined, true)"-->
<!--            >-->
<!--              <option [ngValue]="0">In Attesa</option>-->
<!--              <option [ngValue]="1">Accettata</option>-->
<!--              <option [ngValue]="2">Rifiutata</option>-->
<!--              <option [ngValue]="3">Processata</option>-->
<!--              <option [ngValue]="4">Cancellata</option>-->
<!--            </select>-->

<!--            &lt;!&ndash; <button-->
<!--              data-dismiss="modal"-->
<!--              data-toggle="modal"-->
<!--              data-target="#delConfirmModal"-->
<!--              type="button"-->
<!--              class="btn btn-primary"-->
<!--              (click)="-->
<!--                setReservationToCancel(-->
<!--                  r.reservationid,-->
<!--                  s.servicedate,-->
<!--                  s.servicetime,-->
<!--                  r.email,-->
<!--                  s.serviceid-->
<!--                )-->
<!--              "-->
<!--            >-->
<!--              Annulla prenotazione-->
<!--            </button> &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-sm-12 col-md-12 col-lg-8" id="calendar"></div>-->
<!--  </div>-->
<!--</div>-->

<div
  class="modal fade"
  id="delConfirmModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delConfirmModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="
            cancelReservation(
              reservationToCancel.reservationid,
              reservationToCancel.servicedate,
              reservationToCancel.servicetime,
              reservationToCancel.email,
              reservationToCancel.serviceid,
              0
            )
          "
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="filtersModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="filtersModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Filtra calendario" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="form-group col-4">
            <label>{{ "Da" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilterDate.from"
                style="width: 100%"
                type="date"
                class="form-control"
                id="datefrom"
                name="datefrom"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "A" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilterDate.to"
                style="width: 100%"
                type="date"
                class="form-control"
                id="dateto"
                name="dateto"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Status prenotazione" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="newFilters.orderstatus"
              >
                <option [ngValue]="-1">
                  {{ "Nessun filtro" | translate }}
                </option>
                <option [ngValue]="0">{{ "In Attesa" | translate }}</option>
                <option [ngValue]="1">{{ "Accettata" | translate }}</option>
                <option [ngValue]="2">{{ "Rifiutata" | translate }}</option>
                <option [ngValue]="3">{{ "Processata" | translate }}</option>
                <option [ngValue]="4">{{ "Cancellata" | translate }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group col-4">
            <label>{{ "Nome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilters.customername"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Cognome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilters.customerlastname"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customerlastname"
                name="customerlastname"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Email ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newFilters.customeremail"
                style="width: 100%"
                type="email"
                class="form-control"
                id="customeremail"
                name="customeremail"
              />
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group col-6">
            <label>{{ "Etichetta" | translate }}</label>
            <div>
              <select
                id="filterLabel"
                class="form-control"
                name="filterLabel"
                [(ngModel)]="newFilters.label"
              >
                <option [ngValue]="''">
                  {{ "Nessun filtro" | translate }}
                </option>
                <option
                  *ngFor="let element of allLabels"
                  [ngValue]="element.id"
                >
                  {{ element.name.it }}
                </option>
              </select>
            </div>
          </div>
          <!-- <div class="form-group col-6">
            <label>Reparto</label>
            <div>
              <select
                id="department"
                class="form-control"
                name="filterDepartment"
                [(ngModel)]="newFilters.department"
              >
                <option [ngValue]="''">Nessun filtro</option>
                <option
                  *ngFor="let element of allDepartments"
                  [ngValue]="element.id"
                >
                  {{ element.name.it }}
                </option>
              </select>
            </div>
          </div> -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="clearFilter()">
          {{ "Resetta Filtri" | translate }}
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="searchRequest()"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="newReservationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newReservationModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Nuova prenotazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span *ngIf="selectedService"
          >{{ "Posizione del servizio selezionato" | translate }}:
          {{ selectedService.tree }} > {{ selectedService.title.it }}</span
        >
        <div class="d-flex">
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Seleziona servizio" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select disabled class="form-control" name="orderstatusFilter">
                <option *ngIf="currentAdvancedService" selected="true">
                  {{ currentAdvancedService.title["it"] }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Quantità" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.quantity"
                style="width: 100%"
                type="number"
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Email ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.email"
                style="width: 100%"
                type="email"
                class="form-control"
                id="customeremail"
                name="customeremail"
              />
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group col-4">
            <label></label>
            <div>
              <input
                [(ngModel)]="newReservation.name"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Cognome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.lastname"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customerlastname"
                name="customerlastname"
              />
            </div>
          </div>

          <div class="form-group col-4">
            <label>{{ "Data" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newReservation.servicedate"
                (change)="newReservationChangedDate()"
                style="width: 100%"
                type="date"
                class="form-control"
                id="categoryroot"
                name="categoryroot"
              />
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group col-4">
            <label>{{ "Fasce orarie" | translate }}</label>
            <div>
              <!-- <input
                style="width: 100%"
                type="time"
                class="form-control"
                id="categoryL1"
                name="categoryL"
              /> -->
              <select
                style="width: 100% !important"
                id="timeslots"
                multiple="multiple"
              >
                <option
                  *ngFor="let element of timeslots"
                  [value]="element.timeslottext"
                >
                  {{ element.timeslottext }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Seleziona etichetta" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="selectedLabel"
              >
                <option
                  *ngFor="let element of availableLabels"
                  [ngValue]="element"
                >
                  {{ element.name.it }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Messaggio etichetta" | translate }}</label>
            <div>
              <!-- <input
                [(ngModel)]="newReservation.labelinfo.message"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customeremail"
                name="customeremail"
              /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="makeReservation()"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="newCartModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newCartModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Nuovo carrello" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span *ngIf="selectedService"
          >{{ "Posizione del servizio selezionato" | translate }}:
          {{ selectedService.tree }} > {{ selectedService.title.it }}</span
        >
        <div class="d-flex">
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Seleziona servizio" | translate
            }}</label>
            <div>
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="selectedService"
              >
                <option
                  *ngFor="let element of cartServicesNotActivated"
                  [ngValue]="element"
                >
                  {{ element.title.it }}
                </option>
              </select>
              <small style="color: red" id="err-label" class="d-none">{{
                "Il servizio non è compatibile con i servizi selezionati o non ha etichette"
                  | translate
              }}</small>
            </div>
          </div>
          <div class="form-group col-4">
            <label for="categorytype" style="opacity: 0">{{
              "Seleziona servizio" | translate
            }}</label>
            <div>
              <button class="btn btn-primary" (click)="addItemToCart()">
                {{ "Aggiungi" | translate }}
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <div class="d-flex" *ngFor="let element of newCart.items">
            <div class="form-group col-4">
              <label>{{ "Titolo" | translate }}</label>
              <div>
                <input
                  [(ngModel)]="element.title"
                  style="width: 100%"
                  type="text"
                  disabled
                  class="form-control"
                  id="customername"
                  name="customername"
                />
              </div>
            </div>
            <div class="form-group col-4">
              <label>{{ "Quantità" | translate }}</label>
              <div>
                <input
                  [(ngModel)]="element.quantity"
                  style="width: 100%"
                  type="number"
                  min="1"
                  class="form-control"
                  id="customername"
                  name="customername"
                />
                <small
                  style="color: red"
                  id="err-{{ element.serviceid }}"
                  class="d-none"
                  >{{
                    "Il minimo accettabile come quantità è uno" | translate
                  }}</small
                >
              </div>
            </div>
            <div class="form-group col-4">
              <label for="categorytype" style="opacity: 0">{{
                "Seleziona servizio" | translate
              }}</label>
              <div>
                <button
                  class="btn btn-secondary"
                  (click)="deleteItemFromCart(element)"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div class="d-flex">
          <div class="form-group col-4">
            <label>{{ "Email ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newCart.email"
                style="width: 100%"
                type="email"
                class="form-control"
                id="customeremail"
                name="customeremail"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Nome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newCart.name"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Cognome ospite" | translate }}</label>
            <div>
              <input
                [(ngModel)]="newCart.lastname"
                type="text"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customerlastname"
                name="customerlastname"
              />
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group col-4">
            <label for="categorytype">{{
              "Seleziona etichetta" | translate
            }}</label>
            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="selectedLabel"
              >
                <option
                  *ngFor="let element of cartLabelsList"
                  [ngValue]="element"
                >
                  {{ getLabelName(element) }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-4">
            <label>{{ "Messaggio etichetta" | translate }}</label>
            <div>
              <!-- <input
                [(ngModel)]="newCart.labelinfo.message"
                style="width: 100%"
                type="text"
                class="form-control"
                id="customeremail"
                name="customeremail"
              /> -->
            </div>
          </div>
          <div class="form-group col-4">
            <label>Note</label>
            <div>
              <textarea
                [(ngModel)]="newCart.notes"
                style="width: 100%"
                class="form-control"
                id="cartNotes"
                name="cartNotes"
                rows="1"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="insertCart()">
          {{ "Conferma" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary d-none"
          id="confirm-cart"
          data-dismiss="modal"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
