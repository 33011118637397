import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Notification } from 'src/app/models/notification/notification';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent implements OnInit, AfterViewInit {
  @Input() notification: Notification;
  @Output() toDelete: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('toastNotification') toastNotification: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.showNotification();
    setTimeout(() => {
      this.deleteNotification();
    }, 10000);
  }

  deleteNotification() {
    this.toastNotification.nativeElement.hidden = true;
    this.toDelete.emit(this.notification.id);
  }

  private showNotification() {
    this.toastNotification.nativeElement.hidden = false;
  }
}
