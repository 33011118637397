<div aria-live="polite" aria-atomic="true" style="position: relative;">
  <!-- Position it -->
  <div style="position: absolute; top: 0; right: 0;">
    <!-- Then put toasts within -->
    <app-notification-message
      *ngFor="let notification of notifications"
      [notification]="notification"
      (toDelete)="deleteNotification($event)"
    ></app-notification-message>
  </div>
</div>
