<div class="container-fluid my-4">
  <div class="row mx-0">
    <h3 class="color-theme font-weight-bold mb-0">
      {{ "Gestione ospiti" | translate }} -
      {{ "Calendario Prenotazioni" | translate }}
    </h3>
  </div>

  <div class="row mx-0">
    <button
      class="btn btn-secondary mr-1 my-2"
      data-toggle="modal"
      data-target="#filtersModal"
      (click)="clearNewFilter()"
    >
      {{ "Filtri" | translate }}
    </button>

    <button
      class="btn btn-secondary mx-1 my-2"
      data-toggle="modal"
      data-target="#newReservationModal"
      (click)="reservationModalOpened()"
    >
      {{ "Crea nuova prenotazione" | translate }}
    </button>

    <button class="btn btn-secondary ml-1 my-2" (click)="print()">
      {{ "Stampa" | translate }}
    </button>

    <button class="btn btn-secondary ml-1 my-2" (click)="downloadCsv()">
      {{ "Download csv" | translate }}
    </button>
  </div>

  <div class="container-fluid my-2 p-0">
    <div class="row mx-0">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          [(ngModel)]="hideCalendar"
          (change)="toggleCalendar()"
        />

        <label class="form-check-label" for="flexCheckDefault">
          {{ "Nascondi Calendario" | translate }}
        </label>
      </div>
    </div>

    <div class="row mx-0">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="deleteReservation"
          [(ngModel)]="viewDeleteReservation"
          (ngModelChange)="searchRequest()"
        />

        <label class="form-check-label" for="deleteReservation">
          {{ "Visualizza prenotazioni cancellate" | translate }}
        </label>
      </div>
    </div>
  </div>

  <div class="container-fluid p-0">
    <div class="row mx-0 w-100">
      <div id="minicalendar" class="col-sm-12 col-md-12 col-lg-4 p-0 my-3">
        <div class="row mx-0">
          <h3 class="color-theme font-weight-bold mb-0">
            {{ "Le tue prenotazioni" | translate }}
          </h3>
        </div>

        <div class="row mx-0">
          <span class="font-weight-bold my-2">
            {{ "Servizi" | translate }}: {{ totalNumber }} -
            {{ "Quantità" | translate }}: {{ totalServices }} -
            {{ "Totale" | translate }}: €
            {{ totalSearchRequest }}
          </span>
        </div>

        <div class="container-fluid p-0 eventDetails">
          <div *ngIf="!isDetailsService">
            <div *ngFor="let searchRequest of listSearchRequest">
              <div
                *ngFor="let reservation of searchRequest.reservations"
                class="card"
              >
                <div class="card-body">
                  <strong> {{ "Cliente" | translate }}: </strong>

                  {{ reservation.name }} {{ reservation.lastname }}

                  <br />

                  <strong> {{ "Servizio" | translate }}: </strong>

                  {{ searchRequest.servicename }}

                  <br />

                  <strong *ngIf="!searchRequest.serviceenddate">
                    {{ "Data e ora del servizio" | translate }}:
                  </strong>

                  <strong *ngIf="searchRequest.serviceenddate">
                    {{ "Data inizio del servizio" | translate }}:
                  </strong>

                  {{ searchRequest.servicedate }}

                  {{
                    !searchRequest.serviceenddate
                      ? ", " + searchRequest.servicetime
                      : ""
                  }}

                  <br />

                  <strong *ngIf="searchRequest.serviceenddate">
                    {{ "Data fine del servizio" | translate }}:
                  </strong>

                  {{
                    searchRequest.serviceenddate
                      ? searchRequest.serviceenddate
                      : ""
                  }}

                  <br *ngIf="searchRequest.serviceenddate" />

                  <strong> {{ "Note" | translate }}: </strong>

                  {{ searchRequest.note || reservation.note }}

                  <br />

                  <div *ngIf="reservation.quantity && reservation.quantity > 0">
                    <strong> {{ "Quantità" | translate }}: </strong>

                    {{ reservation.quantity }}

                    <br />
                  </div>

                  <div *ngIf="reservation.amount && reservation.amount > 0">
                    <strong> {{ "Valore" | translate }}: </strong>

                    € {{ reservation.amount ? reservation.amount : 0 }}

                    <br />
                  </div>

                  <strong> {{ "Status" | translate }}: </strong>

                  {{ getStatusOrder(reservation.orederstatus) }}

                  <br />
                </div>
              </div>
            </div>

            <div *ngFor="let searchRequest of listSearchRequestShopping">
              <div class="card">
                <div class="card-body">
                  <strong>
                    {{ "Data e ora del servizio" | translate }}:
                  </strong>

                  {{ searchRequest.shoppingcartdate }} ,{{
                    searchRequest.shoppimgcarttime
                  }}

                  <br />

                  <strong> {{ "Cliente" | translate }}: </strong>

                  {{ searchRequest.name }} {{ searchRequest.lastname }}

                  <br />

                  <strong> {{ "Email" | translate }}: </strong>

                  {{ searchRequest.email }}

                  <br />

                  <strong> {{ "Note" | translate }}: </strong>

                  {{ searchRequest.notes }}

                  <br />

                  <strong> {{ "Etichette" | translate }}: </strong>

                  <br />

                  <div *ngFor="let element of searchRequest.labels">
                    <span> &nbsp;&nbsp;&nbsp;{{ element }} </span>
                  </div>

                  <strong> {{ "Valore" | translate }}: </strong>

                  €{{ searchRequest.amount }}

                  <br />

                  <strong> {{ "Status" | translate }}: </strong>

                  {{ getStatusOrder(searchRequest.orederstatus) }}

                  <br />
                </div>
              </div>
            </div>

            <div
              *ngFor="let searchRequest of listSearchRequestAdvancedShopping"
            >
              <div class="card">
                <div class="card-body">
                  <strong>
                    {{ "Data inizio del servizio" | translate }}:
                  </strong>

                  {{ searchRequest.shoppingcartdatefrom }}

                  <br />

                  <strong> {{ "Data fine del servizio" | translate }}: </strong>

                  {{ searchRequest.shoppingcartdateto }}

                  <br />

                  <strong> {{ "Cliente" | translate }}: </strong>

                  {{ searchRequest.name }} {{ searchRequest.lastname }}

                  <br />

                  <strong> {{ "Email" | translate }}: </strong>

                  {{ searchRequest.email }}

                  <br />

                  <strong> {{ "Note" | translate }}: </strong>

                  {{ searchRequest.notes }}

                  <br />

                  <strong> {{ "Etichette" | translate }}: </strong>

                  <br />

                  <div *ngFor="let element of searchRequest.labels">
                    <span> &nbsp;&nbsp;&nbsp;{{ element }} </span>
                  </div>

                  <strong> {{ "Valore" | translate }}: </strong>

                  € {{ searchRequest.amount }}

                  <br />

                  <strong> {{ "Status" | translate }}: </strong>

                  {{ getStatusOrder(searchRequest.orederstatus) }}

                  <br />
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="isDetailsService">
            <div *ngIf="!services.cart">
              <div *ngFor="let r of services.reservations" class="card">
                <div class="card-body">
                  <strong> {{ "Servizio" | translate }}: </strong>

                  {{ services.servicename }}

                  <br />

                  <strong *ngIf="!services.serviceenddate">
                    {{ "Data e ora del servizio" | translate }}:
                  </strong>

                  <strong *ngIf="services.serviceenddate">
                    {{ "Data inizio del servizio" | translate }}:
                  </strong>

                  {{
                    services.servicestartdate ? services.servicestartdate : ""
                  }}

                  {{
                    !services.servicestartdate
                      ? ", " + services.servicetime
                      : ""
                  }}

                  <br />

                  <strong *ngIf="services.serviceenddate">
                    {{ "Data fine del servizio" | translate }}:
                  </strong>

                  {{ services.serviceenddate ? services.serviceenddate : "" }}

                  <br *ngIf="services.serviceenddate" />

                  <strong> {{ "Cliente" | translate }}: </strong>

                  {{ r.name }} {{ r.lastname }}

                  <br />

                  <strong> {{ "Email" | translate }}: </strong>

                  {{ r.email }}

                  <br />

                  <div *ngIf="r.quantity && r.quantity > 0">
                    <strong> {{ "Quantità" | translate }}: </strong>

                    {{ r.quantity }}

                    <br />
                  </div>

                  <div *ngIf="r.amount && r.amount > 0">
                    <strong> {{ "Valore" | translate }}: </strong>

                    €{{ r.amount }}

                    <br />
                  </div>

                  <strong> {{ "Etichette" | translate }}: </strong>

                  <br />

                  <div *ngFor="let element of r.labels">
                    <span> &nbsp;&nbsp;&nbsp;{{ element }} </span>
                  </div>

                  <strong> {{ "Reparti" | translate }}: </strong>

                  <span *ngFor="let element of services.departments">
                    {{ element }},
                  </span>

                  <br />

                  <strong> {{ "Note" | translate }}: </strong>

                  {{ services.isadvanced ? services.note : r.note }}

                  <br />

                  <strong>
                    {{ "Data e ora di prenotazione" | translate }}:
                  </strong>

                  {{ r.reservationdate }} , {{ r.reservationtime }}

                  <br />

                  <strong> {{ "Posizione del servizio" | translate }}: </strong>

                  {{ r.categoriestree }}

                  <br />

                  <label
                    *ngIf="!services.isadvanced"
                    class="my-1 mr-2 text-capitalize"
                    for="inlineFormCustomSelectPref"
                  >
                    {{ "Status" | translate }}:
                  </label>

                  <select
                    *ngIf="!services.isadvanced"
                    (change)="changeReservationStatus(r, services, false)"
                    class="custom-select my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    [(ngModel)]="r.status"
                  >
                    <option [ngValue]="0">
                      {{ "In Attesa" | translate }}
                    </option>

                    <option [ngValue]="1">
                      {{ "Accettata" | translate }}
                    </option>

                    <option [ngValue]="2">
                      {{ "Rifiutata" | translate }}
                    </option>

                    <option [ngValue]="3">
                      {{ "Processata" | translate }}
                    </option>

                    <option [ngValue]="4">
                      {{ "Cancellata" | translate }}
                    </option>
                  </select>

                  <div class="mt-2">
                    <button
                      *ngIf="services.isadvanced"
                      routerLink="/calendarAdvanced"
                      [queryParams]="{
                        service: services.serviceid,
                        day: services.servicedate
                      }"
                      class="btn btn-primary"
                    >
                      {{ "Dettagli servizio avanzato" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="services.cart" class="card">
              <div *ngIf="services.advancedshoppingcartid" class="card-body">
                <strong> {{ "Data inizio del servizio" | translate }}: </strong>

                {{ services.shoppingcartdatefrom }}

                <br />

                <strong> {{ "Data fine del servizio" | translate }}: </strong>

                {{ services.shoppingcartdateto }}

                <br />

                <strong> {{ "Cliente" | translate }}: </strong>

                {{ services.name }} {{ services.lastname }}

                <br />

                <strong> {{ "Email" | translate }}: </strong>

                {{ services.email }}

                <br />

                <strong> {{ "Note" | translate }}: </strong>

                {{ services.notes }}

                <br />

                <strong> {{ "Etichette" | translate }}: </strong>

                <br />

                <div *ngFor="let element of services.labels">
                  <span> &nbsp;&nbsp;&nbsp;{{ element }} </span>
                </div>

                <strong> {{ "Valore" | translate }}: </strong>

                €{{ services.amount }}

                <br />

                <strong> {{ "Servizi" | translate }}: </strong>

                <br />
                <div
                  *ngFor="let s of services.reservations; index as i"
                  class="cartItem"
                  [ngClass]="{ cartItem: i != 0, cartItemFirst: i == 0 }"
                >
                  <strong> {{ "Titolo" | translate }}: </strong>

                  {{ s.servicename }}

                  <br />

                  <strong> {{ "Posizione del servizio" | translate }}: </strong>

                  {{ s.categoriestree }}

                  <br />

                  <strong> Slots: </strong>

                  <div
                    class="cartItem cartItemFirst"
                    *ngFor="let slot of s.slots"
                  >
                    <strong> Slot: </strong>

                    {{ slot.timeslot }}

                    <br />

                    <strong> {{ "Quantità" | translate }}: </strong>

                    {{ slot.quantity }}

                    <br />
                  </div>
                </div>

                <label
                  class="my-1 mr-2 text-capitalize"
                  for="inlineFormCustomSelectPref"
                >
                  {{ "Status" | translate }}:
                </label>

                <select
                  class="custom-select my-1 mr-sm-2"
                  id="inlineFormCustomSelectPref"
                  [(ngModel)]="services.orederstatus"
                  (change)="changeReservationStatus(services, undefined, true)"
                >
                  <option [ngValue]="0">
                    {{ "In Attesa" | translate }}
                  </option>

                  <option [ngValue]="1">
                    {{ "Accettata" | translate }}
                  </option>

                  <option [ngValue]="2">
                    {{ "Rifiutata" | translate }}
                  </option>

                  <option [ngValue]="3">
                    {{ "Processata" | translate }}
                  </option>

                  <option [ngValue]="4">
                    {{ "Cancellata" | translate }}
                  </option>
                </select>
              </div>

              <div *ngIf="services.shoppingcartid" class="card-body">
                <strong> {{ "Data" | translate }}: </strong>

                {{ services.reservationdate }}

                <br />

                <strong> {{ "Orario" | translate }}: </strong>

                {{ services.reservationtime }}

                <br />

                <strong> {{ "Cliente" | translate }}: </strong>

                {{ services.name }} {{ services.lastname }}

                <br />

                <strong> Email: </strong>

                {{ services.email }}

                <br />

                <strong> Note: </strong>

                {{ services.notes }}

                <br />

                <strong> {{ "Etichette" | translate }}: </strong>

                <br />

                <div *ngFor="let element of services.labels">
                  <span> &nbsp;&nbsp;&nbsp;{{ element }} </span>
                </div>

                <strong> {{ "Valore" | translate }}: </strong>

                €{{ services.amount }}

                <br />

                <strong> {{ "Servizi" | translate }}: </strong>

                <br />

                <div
                  *ngFor="let s of services.services; index as i"
                  class="cartItem"
                  [ngClass]="{ cartItem: i != 0, cartItemFirst: i == 0 }"
                >
                  <strong> {{ "Titolo" | translate }}: </strong>

                  {{ s.servicename }}

                  <br />

                  <strong> {{ "Posizione del servizio" | translate }}: </strong>

                  {{ s.categoriestree }}

                  <br />

                  <strong>
                    {{ "Quantità" | translate }}
                  </strong>

                  {{ s.quantity }}
                </div>

                <label
                  class="my-1 mr-2 text-capitalize"
                  for="inlineFormCustomSelectPref"
                >
                  {{ "Status" | translate }}:
                </label>

                <select
                  class="custom-select my-1 mr-sm-2"
                  id="inlineFormCustomSelectPref"
                  [(ngModel)]="services.status"
                  (change)="changeReservationStatus(services, undefined, true)"
                >
                  <option [ngValue]="0">
                    {{ "In Attesa" | translate }}
                  </option>

                  <option [ngValue]="1">
                    {{ "Accettata" | translate }}
                  </option>

                  <option [ngValue]="2">
                    {{ "Rifiutata" | translate }}
                  </option>

                  <option [ngValue]="3">
                    {{ "Processata" | translate }}
                  </option>

                  <option [ngValue]="4">
                    {{ "Cancellata" | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="calendar" class="col-sm-12 col-md-12 col-lg-8 my-3">
        <ejs-schedule
          #schedulerObj
          width="100%"
          height="900px"
          [eventSettings]="eventSettings"
          [selectedDate]="selectedDate"
          [readonly]="true"
          (navigating)="onNavigateChange($event)"
          (eventClick)="onEventClick($event)"
          (eventRendered)="onEventRendered($event)"
        >
          <e-views>
            <e-view option="Day"></e-view>
            <e-view option="TimelineDay"></e-view>
            <e-view option="Week"></e-view>
            <e-view option="TimelineWeek"></e-view>
            <e-view option="Month"></e-view>
            <e-view option="TimelineMonth"></e-view>
          </e-views>
        </ejs-schedule>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="filtersModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="filtersModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Filtra calendario" | translate }}
        </h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="d-flex">
          <div class="form-group col-4">
            <label>
              {{ "Da" | translate }}
            </label>

            <div>
              <input
                [(ngModel)]="newFilterDate.from"
                style="width: 100%"
                type="date"
                class="form-control"
                id="datefrom"
                name="datefrom"
              />
            </div>
          </div>

          <div class="form-group col-4">
            <label>
              {{ "A" | translate }}
            </label>

            <div>
              <input
                [(ngModel)]="newFilterDate.to"
                style="width: 100%"
                type="date"
                class="form-control"
                id="dateto"
                name="dateto"
              />
            </div>
          </div>

          <div class="form-group col-4">
            <label for="categorytype">
              {{ "Status prenotazione" | translate }}
            </label>

            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="orderstatusFilter"
                [(ngModel)]="newFilters.orderstatus"
              >
                <option [ngValue]="-1">
                  {{ "Nessun filtro" | translate }}
                </option>

                <option [ngValue]="0">
                  {{ "In Attesa" | translate }}
                </option>

                <option [ngValue]="1">
                  {{ "Accettata" | translate }}
                </option>

                <option [ngValue]="2">
                  {{ "Rifiutata" | translate }}
                </option>

                <option [ngValue]="3">
                  {{ "Processata" | translate }}
                </option>

                <option [ngValue]="4">
                  {{ "Cancellata" | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group col-4">
            <label>
              {{ "Nome ospite" | translate }}
            </label>

            <div>
              <input
                [(ngModel)]="newFilters.customername"
                type="text"
                style="width: 100%"
                class="form-control"
                id="customername"
                name="customername"
              />
            </div>
          </div>

          <div class="form-group col-4">
            <label>
              {{ "Cognome ospite" | translate }}
            </label>

            <div>
              <input
                [(ngModel)]="newFilters.customerlastname"
                type="text"
                style="width: 100%"
                class="form-control"
                id="customerlastname"
                name="customerlastname"
              />
            </div>
          </div>

          <div class="form-group col-4">
            <label>
              {{ "Email ospite" | translate }}
            </label>

            <div>
              <input
                [(ngModel)]="newFilters.customeremail"
                style="width: 100%"
                type="email"
                class="form-control"
                id="customeremail"
                name="customeremail"
              />
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group col-3">
            <label>
              {{ "Categoria root" | translate }}
            </label>

            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="categoryFilter0"
                [(ngModel)]="newCategoriesSelect.selectedCategory0"
                (change)="selectCategory(0)"
              >
                <option [ngValue]="undefined">
                  {{ "Nessun filtro" | translate }}
                </option>

                <option
                  *ngFor="
                    let element of newCategoriesSelect.categoryObj0.categories
                  "
                  [ngValue]="element.id"
                >
                  {{ element.title.it }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group col-3">
            <label> {{ "Categoria" | translate }} 1 </label>

            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="categoryFilter1"
                [(ngModel)]="newCategoriesSelect.selectedCategory1"
                (change)="selectCategory(1)"
              >
                <option [ngValue]="undefined">
                  {{ "Nessun filtro" | translate }}
                </option>

                <option
                  *ngFor="
                    let element of newCategoriesSelect.categoryObj1.categories
                  "
                  [ngValue]="element.id"
                >
                  {{ element.title.it }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group col-3">
            <label> {{ "Categoria" | translate }} 2 </label>

            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="categoryFilter2"
                [(ngModel)]="newCategoriesSelect.selectedCategory2"
                (change)="selectCategory(2)"
              >
                <option [ngValue]="undefined">
                  {{ "Nessun filtro" | translate }}
                </option>

                <option
                  *ngFor="
                    let element of newCategoriesSelect.categoryObj2.categories
                  "
                  [ngValue]="element.id"
                >
                  {{ element.title.it }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group col-3">
            <label> {{ "Categoria" | translate }} 3 </label>

            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="categoryFilter3"
                [(ngModel)]="newCategoriesSelect.selectedCategory3"
                (change)="selectCategory(3)"
              >
                <option [ngValue]="undefined">
                  {{ "Nessun filtro" | translate }}
                </option>

                <option
                  *ngFor="
                    let element of newCategoriesSelect.categoryObj3.categories
                  "
                  [ngValue]="element.id"
                >
                  {{ element.title.it }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group col-4">
            <label>
              {{ "Servizio" | translate }}
            </label>

            <div style="display: flex; flex-direction: row">
              <select
                class="form-control"
                name="categoryFilter1"
                [(ngModel)]="newCategoriesSelect.selectedServiceInList"
              >
                <option [ngValue]="undefined">
                  {{ "Nessun filtro" | translate }}
                </option>

                <option
                  *ngFor="let element of newCategoriesSelect.currentServiceList"
                  [ngValue]="element.id"
                >
                  {{ element.title.it }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group col-4">
            <label>
              {{ "Etichetta" | translate }}
            </label>

            <div>
              <select
                id="filterLabel"
                class="form-control"
                name="filterLabel"
                [(ngModel)]="newFilters.label"
              >
                <option [ngValue]="''">
                  {{ "Nessun filtro" | translate }}
                </option>

                <option
                  *ngFor="let element of allLabels"
                  [ngValue]="element.id"
                >
                  {{ element.name.it }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group col-4">
            <label>
              {{ "Reparto" | translate }}
            </label>

            <div>
              <select
                id="department"
                class="form-control"
                name="filterDepartment"
                [(ngModel)]="newFilters.department"
              >
                <option [ngValue]="''">
                  {{ "Nessun filtro" | translate }}
                </option>

                <option
                  *ngFor="let element of allDepartments"
                  [ngValue]="element.id"
                >
                  {{ element.name.it }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="clearFilter()">
          {{ "Resetta Filtri" | translate }}
        </button>

        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>

        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="searchRequest()"
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="newReservationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newReservationModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 90vw">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Nuova prenotazione" | translate }}
        </h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <app-select-service-to-book
        [categoryTree]="categoryTree"
        [events]="eventsSubject.asObservable()"
        [allLabels]="allLabels"
        (searchRequest)="searchRequest()"
      ></app-select-service-to-book>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="delConfirmModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delConfirmModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        {{
          "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
            | translate
        }}
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>

        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="
            cancelReservation(
              reservationToCancel.reservationid,
              reservationToCancel.servicedate,
              reservationToCancel.servicetime,
              reservationToCancel.email,
              reservationToCancel.serviceid,
              0
            )
          "
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modifyReservationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modifyReservationModal"
  aria-hidden="true"
>
  <div class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Modifica la prenotazione" | translate }}
        </h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="col-12 d-flex flex-wrap" *ngIf="reservationToModify">
          <div class="form-group col-6">
            <label for="editReservationName">
              {{ "Nome" | translate }}
            </label>

            <input
              [(ngModel)]="reservationToModify.name"
              type="text"
              class="form-control"
              id="editReservationName"
              aria-describedby="editReservationLastname"
              name="editReservationName"
            />
          </div>

          <div class="form-group col-6">
            <label for="editReservationLastname">
              {{ "Cognome" | translate }}
            </label>

            <input
              [(ngModel)]="reservationToModify.lastname"
              type="text"
              class="form-control"
              id="editReservationLastname"
              aria-describedby="editReservationLastname"
              name="editReservationLastname"
            />
          </div>

          <div class="form-group col-6">
            <label for="editReservationDate">
              {{ "Data inizio" | translate }}
            </label>

            <input
              [(ngModel)]="reservationToModify.servicedate"
              type="date"
              class="form-control"
              id="editReservationDate"
              aria-describedby="editReservationDate"
              name="editReservationDate"
            />
          </div>

          <div class="form-group col-6">
            <label for="editReservationEndDate">
              {{ "Data fine" | translate }}
            </label>

            <input
              [(ngModel)]="reservationToModify.serviceenddate"
              type="date"
              class="form-control"
              id="editReservationEndDate"
              aria-describedby="editReservationEndDate"
              name="editReservationEndDate"
            />
          </div>

          <div class="form-group col-6">
            <label for="editReservationTime">
              {{ "Orario" | translate }}
            </label>

            <input
              [(ngModel)]="reservationToModify.reservationtime"
              type="time"
              class="form-control"
              id="editReservationTime"
              aria-describedby="editReservationTime"
              name="editReservationTime"
            />
          </div>

          <div class="form-group col-6">
            <label for="editReservationQuantity">
              {{ "Quantità" | translate }}
            </label>

            <input
              [(ngModel)]="reservationToModify.quantity"
              type="number"
              class="form-control"
              id="editReservationQuantity"
              aria-describedby="editReservationQuantity"
              name="editReservationQuantity"
            />
          </div>

          <div class="form-group col-6">
            <label for="editReservationNotes">
              {{ "Note" | translate }}
            </label>

            <input
              [(ngModel)]="reservationToModify.note"
              type="text"
              class="form-control"
              id="editReservationNotes"
              aria-describedby="editReservationNotes"
              name="editReservationNotes"
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>

        <button type="button" class="btn btn-primary" data-dismiss="modal">
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
