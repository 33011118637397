import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  constructor(private http: HttpClient) {}

  getLabels() {
    return this.http.get<any>(
      `${environment.apiUrl}platform/label/get/${localStorage.getItem(
        'HechRomeoAccount'
      )}`
    );
  }

  createLabel(label) {
    label.account = localStorage.getItem('HechRomeoAccount');
    return this.http.post<any>(
      `${environment.apiUrl}platform/label/create`,
      label
    );
  }

  updateLabel(label) {
    label.account = localStorage.getItem('HechRomeoAccount');
    return this.http.post<any>(
      `${environment.apiUrl}platform/label/update`,
      label
    );
  }

  deleteLabel(label) {
    // label.account = localStorage.getItem('HechRomeoAccount');
    return this.http.delete<any>(
      `${environment.apiUrl}platform/label/delete/${label}`
    );
  }
}
