export class Filters {
  account = '';
  period = {
    from: '',
    to: '',
  };
  orderstatus: number = -1;
  categoryroot = '';
  categoryL1 = '';
  categoryL2 = '';
  categoryL3 = '';
  customername = '';
  customerlastname = '';
  customeremail = '';
  label = '';
  department = '';
  service = '';
  vendor?;
  type?;
  hidden?: boolean = false;
}
