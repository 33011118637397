import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TvSettings } from 'src/app/models/tv/tv';
import { TvService } from 'src/app/services/tv/tv.service';

@Component({
  selector: 'app-tv-settings',
  templateUrl: './tv-settings.component.html',
  styleUrls: ['./tv-settings.component.scss'],
})
export class TvSettingsComponent implements OnInit {
  settings: Array<TvSettings> = [];
  contextmenu: string;
  contextmenuX = 0;
  contextmenuY = 0;
  copiedElement;
  contextElement;
  searchValue: string;
  settingToDelete;

  constructor(private tvService: TvService, private router: Router) {}

  ngOnInit(): void {
    this.getSettings();
  }

  getSettings() {
    this.tvService.getSettings().subscribe((data) => {
      this.settings = data;
    });
  }

  editSettings(id) {
    this.router.navigate(['editTvSettings'], { queryParams: { id } });
  }

  deleteSetting() {
    this.tvService
      .deleteSettings(this.settingToDelete.id)
      .subscribe((data) => {
        this.getSettings();
      });
  }

  //activates the menu with the coordinates
  onrightClick(event, element?, project?) {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    console.log('context menu', element);
    if (element) this.contextmenu = 'copy';
    else this.contextmenu = 'paste';
    this.contextElement = element;
    if (this.contextElement) {
      this.contextElement.vendor = localStorage.getItem('HechRomeoAccount');
      if (project) this.contextElement.project = true;
    }
  }

  disableContextMenu() {
    console.log('disable');
    this.contextmenu = undefined;
  }
}
