import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private http: HttpClient) {}

  addMediaContent(account: string, type: number, mediaobject: string) {
    return this.http
      .post<any>(`${environment.apiUrl}media/add`, {
        account,
        type,
        mediaobject,
        successMessage: 'Media caricato con successo',
      })
      .toPromise();
  }
  getMediaObject(id: string) {
    return this.http.get<any>(`${environment.apiUrl}media/get/${id}`);
  }

  getDefaultIconList() {
    return this.http.get<any>(`${environment.apiUrl}media/default/icon/get`);
  }

  addDefaultIcon(mediaobject: string) {
    return this.http
      .post<any>(`${environment.apiUrl}media/default/icon/add`, {
        account: '',
        type: 3,
        mediaobject,
        successMessage: 'Icona caricata con successo',
      })
      .toPromise();
  }
  getImagesBulk(images: string) {
    return this.http.get<any>(`${environment.apiUrl}media/get/images/bulk`, {
      params: { images },
    });
  }

  addImagesBulk(account: string, images) {
    return this.http
      .post<any>(`${environment.apiUrl}media/add/iamges/bulk`, {
        account,
        images,
        successMessage: 'Media caricati con successo',
      })
      .toPromise();
  }
}
