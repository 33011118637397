import { Image } from '../image/image';

export interface Notification {
  id: string;
  image: Image;
  title: string;
  body: string;
}
export function generateId() {
  return '0' + Math.random().toString(36).slice(2);
}
