<div class="container h-100">
  <app-download-pdf-projects
    *ngIf="!isDetailPage"
    [projectList]="mockProjectPdf"
    (projectsDownloadEvent)="handlerProjectDownload($event)"
    (projectDeletedEvent)="handlerProjectDeleted($event)"
    (projectSelectedEvent)="handlerProjectSelected($event)">
  </app-download-pdf-projects>

  <app-download-pdf-category-selection
    *ngIf="isDetailPage"
    class="d-flex justify-content-center align-items-center h-100"
    [projectSelected]="projectSelected"
    (projectSelectedEvent)="saveProjectSelected($event)"
    (goBackEvent)="goBack()">
  </app-download-pdf-category-selection>

</div>



