import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TYPOGRAPHY } from 'src/app/constants/typography.enum';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent implements OnInit {
  terms: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.terms =
      this.route.snapshot.url[this.route.snapshot.url.length - 1].path ===
      'termsOfService'
        ? TYPOGRAPHY.TERMS_OF_SERVICE
        : this.route.snapshot.url[this.route.snapshot.url.length - 1].path ===
          'termsOfData'
        ? TYPOGRAPHY.TERMS_OF_DATA
        : TYPOGRAPHY.GENERIC_ERROR;
  }
}
