import {availablityAdvancedServices, CategoryService} from './../../../models/categoryTree/category-tree';
import {Component, OnInit, ViewChild} from '@angular/core';
import {CategoriesService} from 'src/app/services/categories/categories.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AdvancedService, AdvancedServiceGet, Category, CategoryTree, Period, Service,} from 'src/app/models/categoryTree/category-tree';
import {MediaService} from 'src/app/services/media/media.service';
import {DomSanitizer} from '@angular/platform-browser';
import * as $ from 'jquery';
import {DepartmentService} from 'src/app/services/department/department.service';
import {LabelService} from 'src/app/services/label/label.service';
import {Department} from 'src/app/models/department/department';
import {Label} from 'src/app/models/label/label';
import {NotificationService} from 'src/app/services/notification/notification.service';
import {generateId} from 'src/app/models/notification/notification';
import * as moment from 'moment';
import {AdminServiceService} from 'src/app/services/admin/admin-service.service';

class NavObj {
  id: string;
  title: string;
  index: number;
  obj?: Category;
}

class Language {
  abr: string;
  language: string;
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @ViewChild('closeButton') public closeButton;

  count: number = 0;
  player: YT.Player;
  playerLoaded: boolean = false;
  id: string = '';
  url: string = '';
  urlError: boolean = false;
  savedUrl: string;
  categoryTree: CategoryTree = new CategoryTree();
  detailsCategory: CategoryService = undefined;
  menagingServices: boolean = false;
  menagingCategories: boolean = false;
  serviceToBeMenaged: Service = new Service();
  backupService: Service;
  categoryToBeMenaged: Category = new Category();
  backupcategory: Category;
  categorytypes: string[] = [];
  navigate: NavObj[] = [];
  partnerCounter: number = 0;
  isPartnerSelected: boolean = false;
  allLanguages = [];
  savekeepAllLanguages = [];
  activatedLanguages = [{ abr: 'it', language: 'Italiano' }];
  languageToAdd;
  currentLanguage = 'it';
  fileToLoad;
  imageToLoad;
  videoToLoad;
  iconToLoad;
  selectedDefaultIcon;
  objConfirmDel;
  typeToDel;
  newElement: number;
  object = Object;
  departments: Department[] = [];
  labels: Label[] = [];

  callAtServer: any[];

  loadedNewIcon: boolean = false;
  loadedNewFile: boolean = false;
  standardIcon: boolean = undefined;
  defaultIcons = [];
  contextmenu: string;
  contextmenuX = 0;
  contextmenuY = 0;
  copiedElement;
  contextElement;
  advancedService: AdvancedServiceGet;
  deletedImage: boolean;
  admin: boolean =
    localStorage.getItem('HechRomeoRole') == 'Manager' ? true : false;
  idMap = new Map();
  isVirtualCategory: boolean = false;
  vendorList = [];
  virtualCategory = {
    parentaccount: undefined,
    parentcategory: undefined,
    categoryPosition: undefined,
    isdiscount: false,
    parentfee: 0,
    categorystartid: undefined,
  };
  otherCategoryTree = [];
  disableSaveButton: boolean = false;

  constructor(
    private categoriesService: CategoriesService,
    private mediaService: MediaService,
    public _DomSanitizationService: DomSanitizer,
    private departmentService: DepartmentService,
    private labelService: LabelService,
    public notificationService: NotificationService,
    private adminService: AdminServiceService
  ) {}

  ngOnInit(): void {
    this.getCategotyTypes();
    this.getLanguages();
    this.getCategoryTree();
    this.getDefaultIcons();
    this.getDepartments();
    this.getLabels();
  }

  //activates the menu with the coordinates
  onrightClick(event, element?, type?) {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    if (element) this.contextmenu = 'copy';
    else this.contextmenu = 'paste';
    this.contextElement = element;
    if (this.contextElement) this.contextElement.type = type;
  }
  //disables the menu
  disableContextMenu() {
    this.contextmenu = undefined;
  }

  copyCategory(cut: boolean) {
    this.copiedElement = {
      cut: cut,
      vendor: localStorage.getItem('HechRomeoAccount'),
      categoryCopyId: this.contextElement.id,
      isCategory: this.contextElement.iscategory
    };

    sessionStorage.setItem(
      'CopiedCategoryOrService',
      JSON.stringify(this.copiedElement)
    );

    this.disableContextMenu();
  }

  pasteCategory() {
    if (sessionStorage.getItem('CopiedCategoryOrService')) {
      let copiedElement = JSON.parse(
        sessionStorage.getItem('CopiedCategoryOrService')
      );

      copiedElement.account = localStorage.getItem('HechRomeoAccount');
      copiedElement.parentCategoryPasteId = this.detailsCategory.id;

      this.categoriesService.copyCutCategory(copiedElement)
        .subscribe(() => {
          const notification = {
            id: generateId(),
            image: undefined,
            title: 'Riuscito',
            body: 'Modifica categoria',
          };

          this.notificationService.addNotification(notification);

          this.getCategoryTree();
        });

      console.log('test', JSON.stringify(copiedElement));
    }

    this.disableContextMenu();
  }

  // Copy or cut function for categories and services, probably doesn't work for virtual categories and advanced services
  copyElement(cut?) {
    this.copiedElement = JSON.parse(JSON.stringify(this.contextElement));
    if (cut) {
      this.copiedElement.cut = true;
      let parentObject: Category;
      let array: Array<any> = this.categoryTree.categoriesServices;
      let i = 0;
      for (let element of this.navigate) {
        if (i == this.navigate.length - 1)
          parentObject = array.find((obj) => obj.id == element.id);
        else array = array.find((obj) => obj.id == element.id).categoriesServices;
        i++;
      }
      // !!!!!!!!TOGLIERE COMMENTO QUANDO IN PRODUZIONE!!!!!!!
      if (this.copiedElement.type == 'category') {
        parentObject.categories = parentObject.categories.filter(
          (obj) => obj.id != this.copiedElement.id
        );
      } else {
        parentObject.services = parentObject.services.filter(
          (obj) => obj.id != this.copiedElement.id
        );
      }
    } else this.copiedElement.id = '';
    if (this.copiedElement.type == 'category') {
      let levels = { level: -1 };
      levels = this.cleanId(this.copiedElement, levels, cut);
      this.copiedElement.levels = levels;
    }
    this.copiedElement.vendor = localStorage.getItem('HechRomeoAccount');
    sessionStorage.setItem(
      'CopiedCategoryOrService',
      JSON.stringify(this.copiedElement)
    );
    this.disableContextMenu();
  }
  // It removes ids so that when uploaded the server creates new ones, this is necessary to not have double ids... Yeah, the backend doesn't check for double ids, beats me why it doesn't... The checkForDoubleIds function was created because some ids weren't purged correctly, have fun with this mess...
  cleanId(category: Category, levels, cut?, newVendor?) {
    let currentLevel;
    let highestLevel;
    if (!newVendor) {
      levels.level = levels.level + 1;
      currentLevel = levels.level;
      highestLevel = levels.level;
    }

    for (let element of category.services) {
      if (!cut || newVendor) element.id = '';
      if (newVendor) {
        element.departments = [];
        element.labels = [];
      }
    }
    for (let element of category.categories) {
      if (!cut || newVendor) element.id = '';
      if (!newVendor)
        levels.level = this.cleanId(element, levels, cut, newVendor);
      else this.cleanId(element, levels, cut, newVendor);
      if (!newVendor) {
        if (highestLevel < levels.level) {
          highestLevel = levels.level;
        }
        levels.level = currentLevel;
      }
    }
    if (!newVendor) return highestLevel;
    else return;
  }
  // This functions pastes the copied element and checks that you can actually paste it in the selected category. You don't get more levels than what I set you slimy user!
  pasteElement() {
    if (sessionStorage.getItem('CopiedCategoryOrService')) {
      let copiedElement = JSON.parse(
        sessionStorage.getItem('CopiedCategoryOrService')
      );
      if (localStorage.getItem('HechRomeoAccount') != copiedElement.vendor) {
        delete copiedElement.vendor;
        if (copiedElement.type == 'service') {
          copiedElement.id = '';
          copiedElement.departments = [];
          copiedElement.labels = [];
        } else this.cleanId(copiedElement, undefined, false, true);
      }
      let cut = copiedElement.cut;
      if (cut) {
        delete copiedElement.cut;
      }
      if (copiedElement.type == 'service') {
        delete copiedElement.type;
        if (this.detailsCategory.services.length > 0)
          copiedElement.zorder =
            this.detailsCategory.services[
              this.detailsCategory.services.length - 1
            ].zorder + 1;
        else copiedElement.zorder = 0;

        this.detailsCategory.services.push(copiedElement);
      } else {
        if (this.navigate.length + copiedElement.levels > 3) {
          const notification = {
            id: generateId(),
            image: undefined,
            title: "Errore: C'è stato un errore",
            body: 'La categoria che stai copiando ha troppi livelli per incollarla qui, prova in una categoria di livello superiore',
          };

          this.notificationService.addNotification(notification);
          this.disableContextMenu();
          return;
        } else delete copiedElement.levels;
        delete copiedElement.type;
        if (this.detailsCategory.categoriesServices.length > 0)
          copiedElement.zorder =
            this.detailsCategory.categoriesServices[
              this.detailsCategory.categoriesServices.length - 1
            ].zorder + 1;
        else copiedElement.zorder = 0;
        this.detailsCategory.categoriesServices.push(copiedElement);
      }
      if (cut) this.copiedElement = undefined;
      this.updateCategoryTree(this.categoryTree);
    }
    this.disableContextMenu();
  }
  // Gets the list of all vendors, to be able to select one in virtual connection
  getVendorList(page: number) {
    this.adminService.getVedorList(page).subscribe((data) => {
      this.vendorList = data;
    });
  }

  getDepartments() {
    this.departmentService.getDepartments().subscribe((data) => {
      this.departments = data.departments;
    });
  }

  getLabels() {
    this.labelService.getLabels().subscribe((data) => {
      this.labels = data.labels;
    });
  }

  getLanguages() {
    this.categoriesService.getLanguages().subscribe((data) => {
      this.savekeepAllLanguages = [...data.languages];
      this.allLanguages = data.languages;
    });
  }

  getCategotyTypes() {
    this.categoriesService.getCategoryTypes().subscribe((data) => {
      this.categorytypes = data.tipologies;
    });
  }

  getDefaultIcons() {
    this.mediaService.getDefaultIconList().subscribe((data) => {
      this.defaultIcons = data;
    });
  }
  // This was useful? At this point I have no idea why I wrote it and when
  log() {
  }
  // Drop event when a user stops dragging a category or service with his greasy hands, it rearranges the order of the list
  drop(event: CdkDragDrop<string[]>, type: number) {
    let array;
    let parentId;

    if (this.menagingCategories) {
      return;
    }

    switch (type) {
      case 0:
        array = this.categoryTree.categoriesServices;
        break;
      case 1:
        array = this.detailsCategory.categoriesServices;
        parentId = this.detailsCategory.id;
        break;
    }

    moveItemInArray(array, event.previousIndex, event.currentIndex);

    const listCategoriesServices = [];

    array.forEach((cat: any) => {
      listCategoriesServices.push({
        id: cat.id,
        isCategory: cat.iscategory
      });
    });

    this.categoriesService.sortCategory({
      account: localStorage.getItem('HechRomeoAccount'),
      parentcategoryid: parentId,
      categoriesServices: listCategoriesServices
    }).subscribe(() => {
      const notification = {
        id: generateId(),
        image: undefined,
        title: 'Riuscito',
        body: 'Modifica ordinamento',
      };

      this.notificationService.addNotification(notification);

      this.getCategoryTree();
    });
  }

  filterAllLanguages() {
    for (let element of this.activatedLanguages)
      this.allLanguages = this.allLanguages.filter(
        (obj) => obj.abr != element.abr
      );
  }
  addLanguage() {
    let objToAdd = this.allLanguages.find(
      (obj) => obj.abr == this.languageToAdd
    );
    if (!this.menagingServices) {
      this.categoryToBeMenaged.title[this.languageToAdd] = '';
      this.categoryToBeMenaged.description[this.languageToAdd] = '';
    } else {
      this.serviceToBeMenaged.title[this.languageToAdd] = '';
      this.serviceToBeMenaged.description[this.languageToAdd] = '';
      this.serviceToBeMenaged.refidtitle[this.languageToAdd] = '';
      this.serviceToBeMenaged.mlnote[this.languageToAdd] = '';
    }

    this.activatedLanguages.push(objToAdd);

    this.currentLanguage = this.languageToAdd;
    this.languageToAdd = '';
  }

  clearLanguages() {
    this.newElement = undefined;
    this.currentLanguage = '';
    this.activatedLanguages = [];
    this.languageToAdd = '';
    this.allLanguages = [...this.savekeepAllLanguages];
  }

  deleteSelectedLanguage(event?) {
    if (event) this.currentLanguage = event;

    if (this.activatedLanguages.length > 1) {
      this.allLanguages.push(
        this.activatedLanguages.find((obj) => obj.abr == this.currentLanguage)
      );
      this.activatedLanguages = this.activatedLanguages.filter(
        (obj) => obj.abr != this.currentLanguage
      );
      let menaging;
      let newTitle = {};
      let newDescription = {};
      if (!this.menagingServices) {
        menaging = 'categoryToBeMenaged';
      } else {
        menaging = 'serviceToBeMenaged';
      }
      delete this[menaging].title[this.currentLanguage];
      delete this[menaging].description[this.currentLanguage];
      delete this[menaging].refidtitle[this.currentLanguage];
      if (this.menagingServices) {
        delete this[menaging].mlnote[this.currentLanguage];
      }

      this.currentLanguage = this.activatedLanguages[0].abr;
    }
  }

  createOrAddLanguage() {
    this.newElement != undefined
      ? this.createElementWithLanguage()
      : this.addLanguage();
  }

  createElementWithLanguage() {
    switch (this.newElement) {
      case 0:
        document.getElementById('dismissLanguageModalAndOpenModal').click();
        this.editCategory(undefined, true, this.languageToAdd);
        break;
      case 1:
        this.editCategory(undefined, undefined, this.languageToAdd);
        break;
      case 2:
        this.menageService(undefined, this.languageToAdd);
        break;
    }
  }

  getCategoryTree() {
    this.detailsCategory = undefined;
    this.navigate = [];
    sessionStorage.setItem('IgnoreErrorHech', '404');
    this.categoriesService.getVendorTree().subscribe((data) => {
      this.disableSaveButton = false;
      this.idMap = new Map();
      this.categoryTree = data;
    });
  }

  // Gets the category tree of a selected vendor, to be able to choose a category for virtual connection
  getOthersCategoryTree(vendor) {
    this.otherCategoryTree = [];
    this.categoriesService.getVendorTree(vendor).subscribe((data) => {
      let currentPosition = [];
      this.listCategories(data, currentPosition);
      if (this.virtualCategory.parentcategory) {
        this.showPosition();
      }
    });
  }
  // Creates an array with the categories taken from another vendor, to be able to choose one for virtual connection
  listCategories(list, currentPosition) {
    for (let element of list.categoriesServices) {
      currentPosition.push(element.title.it);
      this.otherCategoryTree.push({
        position: JSON.parse(JSON.stringify(currentPosition)),
        title: element.title.it,
        id: element.id,
      });
      this.listCategories(element, currentPosition);
      currentPosition.pop();
    }
  }
  // Finds the position of the selected category in the other vendor's category tree in a virtual connection
  showPosition() {
    let item = this.otherCategoryTree.find(
      (obj) => obj.id == this.virtualCategory.parentcategory
    );
    this.virtualCategory.categoryPosition = '';
    for (let element of item.position) {
      if (this.virtualCategory.categoryPosition)
        this.virtualCategory.categoryPosition += '>';
      this.virtualCategory.categoryPosition += element;
    }
  }
  // Checks that there are no double ids, this was necessary for a bug in the cut-paste function. If you're curious to why we didn't fix this directly in database check checkFalseVirtual function
  checkDoubleId(category?: CategoryService, history?) {
    if (!category) {
      for (let element of this.categoryTree.categoriesServices) {
        let history = '';
        let title = element.title['it'];
        if (!title) {
          for (let item in element.title) {
            title = element.title[item];
          }
        }
        this.idMap.set(element.id, title);
        history = title;
        this.checkDoubleId(element, history);
      }
    } else {
      let oldHistory = history;
      for (let element of category.categoriesServices) {
        let check = this.idMap.get(element.id);
        let title = element.title['it'];
        if (!title) {
          for (let item in element.title) {
            title = element.title[item];
          }
        }
        if (check) {
          alert(`Trovata corrispondenza id: -${history}>${title} e -${check}`);
          const notification = {
            id: generateId(),
            image: undefined,
            title: 'Attenzione: ID doppio',
            body: `Trovata corrispondenza id: -${history}>${title} e -${check}`,
          };

          this.notificationService.addNotification(notification);
        } else {
          this.idMap.set(element.id, history + '>' + title);
        }
      }
      for (let element of category.categoriesServices) {
        history = oldHistory;
        let title = element.title['it'];
        if (!title) {
          for (let item in element.title) {
            title = element.title[item];
          }
        }
        let check = this.idMap.get(element.id);
        if (check) {
          const notification = {
            id: generateId(),
            image: undefined,
            title: 'Attenzione: ID doppio',
            body: `Trovata corrispondenza id: -${history}>${title} e -${check}`,
          };

          this.notificationService.addNotification(notification);
        } else {
          history = history + '>' + title;
          this.idMap.set(element.id, history);
          this.checkDoubleId(element, history);
        }
      }
    }
  }

  // Navigates to selected element
  navigateToElement(item: NavObj) {
    let index = this.navigate.findIndex((obj) => obj.id == item.id);
    let currentPosition;
    let i = 0;
    for (let element of this.navigate) {
      if (i == 0) {
        currentPosition = this.categoryTree.categoriesServices[element.index];
      } else {
        currentPosition = currentPosition.categoriesServices[element.index];
      }

      if (index == i) break;

      i++;
    }
    this.detailsCategory = currentPosition;
    this.navigate = this.navigate.slice(0, index + 1);
  }

  // Opens selected category, duh
  openCategory(category: CategoryService, root?: boolean) {
    if (category.iscategory) {
      if (this.menagingCategories) return;
      if (root) {
        this.isPartnerSelected = false;
        this.partnerCounter = 0;

        this.navigate = [
          {
            id: category.id,
            title: category.title[Object.keys(category.title)[0]],
            index: this.categoryTree.categoriesServices.findIndex(
              (obj) => obj.id == category.id
            ),
            obj: this.categoryTree.categoriesServices.find(
              (obj) => obj.id == category.id
            ),
          },
        ];
        if (category.parentaccount && category.parentaccount !== '') {
          this.isPartnerSelected = true;
          this.partnerCounter = 5;
        }
      } else {
        let currentPosition;
        let i = 0;
        for (let element of this.navigate) {
          if (i == 0) {
            currentPosition = this.categoryTree.categoriesServices[element.index];
          } else {
            currentPosition = currentPosition.categoriesServices[element.index];
          }

          i++;
        }
        this.navigate.push({
          id: category.id,
          title: category.title[Object.keys(category.title)[0]],
          index: currentPosition.categoriesServices.findIndex(
            (obj) => obj.id == category.id
          ),
          obj: currentPosition.categoriesServices.find((obj) => obj.id == category.id),
        });

        if (!this.isPartnerSelected && (category.parentaccount && category.parentaccount !== '')) {
          this.isPartnerSelected = true;
          this.partnerCounter = this.navigate.length + 4;
        }
      }

      this.detailsCategory = category;
    }
  }

  // Prepares to edit or create a service
  menageService(service?: Service, languageToAdd?) {
    if (service) {
      service.zOrder = service.zorder;
      service.mlNote = service.mlnote;
      service.imageType = service.imagetype;
      service.serviceType = service.servicetype;
      service.paymentType = service.paymenttype;
      service.maxQuantity = service.maxquantity;
      service.enableQty = service.enableqty;
      service.decreaseQuantity = service.decreasequantity;
      service.availableDate = service.availabledate;
      service.availableTime = service.availabletime;
      service.expirationDate = service.expirationdate;
      service.expirationTime = service.expirationtime;
      service.cartEnabled = service.cartenabled;
      service.idLabels = service.idlabels;
      service.soldOut = service.soldout;
      service.isAdvanced = service.isadvanced;
      service.isMultiband = service.ismultiband;
      service.liveStream = service.livestream;
    }

    this.serviceToBeMenaged.categoryCalendar =
      this.navigate[this.navigate.length - 1].obj.disablebooking;
    this.callAtServer = [];
    this.backupService = undefined;
    this.loadedNewFile = false;
    this.deletedImage = false;
    this.clearLanguages();
    this.deleteYoutubeVideo();
    if (service != undefined) {
      this.backupService = { ...service };
      this.backupService.description = { ...this.backupService.description };
      if (service.departments == null) {
        service.departments = [];
      }
      if (service.idlabels == null) {
        service.idlabels = [];
      }

      this.serviceToBeMenaged = service;
      if (this.serviceToBeMenaged.isAdvanced) {
        this.categoriesService
          .getAdvancedServiceFullData(this.serviceToBeMenaged.id)
          .subscribe((data) => {
            this.advancedService = data;

            let unavailablePeriodArray = [];

            for (let un of this.advancedService.unavailableperiod) {
              if (un.allday) {
                unavailablePeriodArray.push(un);
              } else {
                for (let unavailableslot of un.unavailableslots) {
                  let objUnavailableslot = JSON.parse(JSON.stringify(un));
                  objUnavailableslot.timeslottext = unavailableslot;
                  unavailablePeriodArray.push(objUnavailableslot);
                }
              }
            }

            this.advancedService.unavailableperiod = unavailablePeriodArray;

            for (let un of this.advancedService.unavailableperiod) {
              for (let p of this.advancedService.periods) {
                let checkbet = moment(un.date, 'DD/MM/YYYY').isBetween(
                  moment(p.from, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                  moment(p.to, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                  undefined,
                  '[]'
                );
                if (checkbet === true) {
                  if (!p.availablity) p.availablity = [];

                  p.availablity.push(un);
                }
              }
            }
          });
      }
      if (
        this.serviceToBeMenaged.refidtitle == null ||
        this.serviceToBeMenaged.refidtitle == undefined
      ) {
        this.serviceToBeMenaged.refidtitle = {};
      }
      if (!this.serviceToBeMenaged.mlnote) {
        this.serviceToBeMenaged.mlnote = {};
        for (let element in this.serviceToBeMenaged.title) {
          this.serviceToBeMenaged.mlnote[element] = '';
        }
      }
    } else {
      this.serviceToBeMenaged = new Service();
      this.advancedService = new AdvancedServiceGet();
      if (languageToAdd) {
        this.serviceToBeMenaged.title = { [languageToAdd]: '' };
        this.serviceToBeMenaged.description = { [languageToAdd]: '' };
        this.serviceToBeMenaged.refidtitle = { [languageToAdd]: '' };
        this.serviceToBeMenaged.mlnote = { [languageToAdd]: '' };
      }
    }
    for (let language in this.serviceToBeMenaged.description) {
      this.activatedLanguages.push(
        this.allLanguages.find((obj) => obj.abr == language)
      );
      this.serviceToBeMenaged.description[language] = decodeURI(
        this.serviceToBeMenaged.description[language]
      );
    }
    this.currentLanguage = this.activatedLanguages[0].abr;
    this.imageToLoad = [{}];
    if (this.serviceToBeMenaged.image && this.serviceToBeMenaged.image != '') {
      if (this.serviceToBeMenaged.image.search('https') >= 0) {
        this.serviceToBeMenaged.imageType = 2;
        this.url = this.serviceToBeMenaged.image;
        this.id = this.getIdFromUrl();
      } else {
        if (this.serviceToBeMenaged.imageType == 1) {
          this.mediaService
            .getImagesBulk(this.serviceToBeMenaged.image)
            .subscribe((data) => {
              this.imageToLoad = [];
              let i = 0;
              let imageArray = this.serviceToBeMenaged.image.split(';');
              for (let element of data) {
                this.imageToLoad.push({
                  image: element,
                  id: imageArray[i],
                });
                i++;
              }

              if (!data || data.length <= 0) {
                this.imageToLoad.push({});
              }
            });
        } else {
          this.mediaService
            .getMediaObject(this.serviceToBeMenaged.image)
            .subscribe((data) => {
              if (data.type == 1) this.imageToLoad = data.mediaobject;
              else this.videoToLoad = data.mediaobject;
              this.serviceToBeMenaged.imagetype = data.type;
            });
        }
      }
    } else {
      this.videoToLoad = undefined;
      this.imageToLoad = [{}];
    }

    this.menagingServices = true;
  }

  // Modification or creation of a service interrupted by the user
  endMenageService() {
    // for (let language in this.serviceToBeMenaged.description) {
    //   this.serviceToBeMenaged.description[language] = encodeURI(
    //     this.serviceToBeMenaged.description[language]
    //   );
    // }
    if (this.backupService)
      Object.assign(this.serviceToBeMenaged, this.backupService);
    // this.serviceToBeMenaged = { ...this.backupService };
    this.backupService = undefined;

    this.serviceToBeMenaged = new Service();

    this.menagingServices = false;
  }

  clearAndSelectTypeLanguages(type, isVirtual?) {
    if (this.menagingCategories) return;
    this.clearLanguages();
    this.newElement = type;
    this.isVirtualCategory = isVirtual;
  }

  toggleMenageCategories() {
    this.menagingCategories = !this.menagingCategories;
  }
  // Prepares to edit or create a category
  editCategory(category?: Category, root?: boolean, choosenLanguage?) {
    if (this.menagingCategories) return;
    if (!this.isPartnerSelected && this.navigate.length >= 5) return;
    if (this.isPartnerSelected && this.navigate.length >= this.partnerCounter) return;
    if (this.isVirtualCategory || (category && (category.isvirtual || category.isVirtual))) {
      this.virtualCategory = {
        parentaccount: undefined,
        parentcategory: undefined,
        categoryPosition: undefined,
        isdiscount: false,
        parentfee: 0,
        categorystartid: undefined,
      };
      this.getVendorList(0);
    }

    this.loadedNewIcon = false;
    this.loadedNewFile = false;
    this.deletedImage = false;
    this.deleteYoutubeVideo();
    this.clearLanguages();
    this.backupcategory = undefined;
    if (category) {
      this.categoryToBeMenaged = category;
      if (this.categoryToBeMenaged.isvirtual || this.categoryToBeMenaged.isVirtual) {
        this.getOthersCategoryTree(this.categoryToBeMenaged.parentAccount);

        this.virtualCategory.parentaccount = this.categoryToBeMenaged.parentAccount;
        this.virtualCategory.parentcategory = this.categoryToBeMenaged.parentCategory;
        this.virtualCategory.parentfee = this.categoryToBeMenaged.parentFee;
        this.virtualCategory.isdiscount = this.categoryToBeMenaged.isDiscount;
        this.virtualCategory.categorystartid = this.categoryToBeMenaged.categorystartid;
      }
      this.backupcategory = { ...category };
      this.backupcategory.description = { ...this.backupcategory.description };
    } else {
      this.categoryToBeMenaged = new Category();
      if (choosenLanguage) {
        this.categoryToBeMenaged.title = { [choosenLanguage]: '' };
        this.categoryToBeMenaged.description = { [choosenLanguage]: '' };
      }
    }
    for (let language in this.categoryToBeMenaged.description) {
      this.activatedLanguages.push(
        this.allLanguages.find((obj) => obj.abr == language)
      );
      this.categoryToBeMenaged.description[language] = decodeURI(
        this.categoryToBeMenaged.description[language]
      );
    }
    this.currentLanguage = this.activatedLanguages[0].abr;
    this.imageToLoad = [{}];
    if (
      this.categoryToBeMenaged.image &&
      this.categoryToBeMenaged.image != ''
    ) {
      if (this.categoryToBeMenaged.image.search('https') >= 0) {
        this.categoryToBeMenaged.imagetype = 2;
        this.url = this.categoryToBeMenaged.image;
        this.id = this.getIdFromUrl();
      } else {
        if (
          this.categoryToBeMenaged.imagetype == 1 ||
          this.categoryToBeMenaged.image.search(';') != -1
        ) {
          this.mediaService
            .getImagesBulk(this.categoryToBeMenaged.image)
            .subscribe((data) => {
              this.imageToLoad = [];
              let i = 0;
              let imageArray = this.categoryToBeMenaged.image.split(';');
              for (let element of data) {
                this.imageToLoad.push({
                  image: element,
                  id: imageArray[i],
                });
                i++;
              }
              this.imageToLoad.push({});
              this.categoryToBeMenaged.imagetype = 1;
            });
        } else {
          this.mediaService
            .getMediaObject(this.categoryToBeMenaged.image)
            .subscribe((data) => {
              if (data.type == 1)
                this.imageToLoad = [{ image: data.mediaobject }, {}];
              else this.videoToLoad = data.mediaobject;
              this.categoryToBeMenaged.imagetype = data.type;
            });
        }
      }
    } else {
      this.videoToLoad = undefined;
      this.imageToLoad = [{}];
    }
    if (this.categoryToBeMenaged.icon && this.categoryToBeMenaged.icon != '') {
      this.mediaService
        .getMediaObject(this.categoryToBeMenaged.icon)
        .subscribe((data) => {
          this.iconToLoad = data.mediaobject;
          this.standardIcon = false;
          let substring = this.categoryToBeMenaged.icon.slice(0, 3);
          if (substring == '201') {
            this.standardIcon = true;
          }
        });
    } else {
      this.iconToLoad = undefined;
      this.standardIcon = undefined;
    }

    if (!root) this.toggleMenageCategories();
  }
  // Modification or creation of a category interrupted by the user
  cancelEditCategory(root?: boolean) {
    for (let language in this.categoryToBeMenaged.description) {
      this.categoryToBeMenaged.description[language] = encodeURI(
        this.categoryToBeMenaged.description[language]
      );
    }

    if (this.backupcategory) {
      Object.assign(this.categoryToBeMenaged, this.backupcategory);
    }
    this.categoryToBeMenaged = new Category();
    this.backupcategory = undefined;
    if (!root) this.toggleMenageCategories();
  }
  // Saves a root category
  async saveRootCategory() {
    this.disableSaveButton = true;

    if (
      this.imageToLoad &&
      this.imageToLoad.length > 0 &&
      (this.loadedNewFile || this.deletedImage) &&
      this.categoryToBeMenaged.imagetype == 1
    ) {
      if (!this.loadedNewFile && this.deletedImage) {
        this.categoryToBeMenaged.image = '';
        for (let element of this.imageToLoad) {
          if (element && element.id) {
            if (this.categoryToBeMenaged.image != '') {
              this.categoryToBeMenaged.image =
                this.categoryToBeMenaged.image.concat(';');
            }
            this.categoryToBeMenaged.image =
              this.categoryToBeMenaged.image.concat(element.id);
          }
        }
      } else if (this.imageToLoad[0].image != undefined) {
        let images = [];
        for (let element of this.imageToLoad) {
          if (element.image) images.push(element.image);
        }
        await this.mediaService
          .addImagesBulk(localStorage.getItem('HechRomeoAccount'), images)
          .then((data) => {
            this.categoryToBeMenaged.image = '';
            for (let element of data) {
              if (this.categoryToBeMenaged.image != '') {
                this.categoryToBeMenaged.image =
                  this.categoryToBeMenaged.image.concat(';');
              }
              this.categoryToBeMenaged.image =
                this.categoryToBeMenaged.image.concat(element);
            }
          });
      }
    } else if (this.categoryToBeMenaged.imagetype == 2) {
      if (this.savedUrl && this.playerLoaded) {
        this.categoryToBeMenaged.image = this.savedUrl;
      } else if (this.videoToLoad && this.loadedNewFile) {
        await this.mediaService
          .addMediaContent(
            localStorage.getItem('HechRomeoAccount'),
            this.categoryToBeMenaged.imagetype,
            this.videoToLoad
          )
          .then((data) => {
            this.categoryToBeMenaged.image = data.id;
          });
      }
    }
    if (this.iconToLoad && this.loadedNewIcon) {
      await this.mediaService
        .addMediaContent(
          localStorage.getItem('HechRomeoAccount'),
          3,
          this.iconToLoad
        )
        .then((data) => {
          this.categoryToBeMenaged.icon = data.id;
        });
    }
    for (let language in this.categoryToBeMenaged.description) {
      this.categoryToBeMenaged.description[language] = encodeURI(
        this.categoryToBeMenaged.description[language]
      );
    }

    if (this.isVirtualCategory) {
      if (this.categoryToBeMenaged.id == '') {
        let biggestIndex = 0;
        for (let element of this.categoryTree.categoriesServices) {
          if (element.zorder > biggestIndex) biggestIndex = element.zorder;
        }
        this.categoryToBeMenaged.zorder = biggestIndex + 1;

        let data = {
          account: localStorage.getItem('HechRomeoAccount'),
          zorder: this.categoryToBeMenaged.zorder,
          title: this.categoryToBeMenaged.title,
          description: this.categoryToBeMenaged.description,
          image: this.categoryToBeMenaged.image,
          icon: this.categoryToBeMenaged.icon,
          categorytype: this.categoryToBeMenaged.categoryType,
          visible: true,
          alignment: this.categoryToBeMenaged.alignment,
          parentaccount: this.virtualCategory.parentaccount,
          parentcategory: this.virtualCategory.parentcategory,
          parentfee: this.virtualCategory.parentfee,
          isdiscount: this.virtualCategory.isdiscount,
          parentcategoryid: this.virtualCategory.categorystartid,
          isvirtual: true,
          disablebooking: this.categoryToBeMenaged.disableBooking,
          scroll: this.categoryToBeMenaged.scroll ? this.categoryToBeMenaged.scroll : 0
        };
        this.categoriesService.createCategory(data).subscribe(() => {
          const notification = {
            id: generateId(),
            image: undefined,
            title: 'Riuscito',
            body: 'Creazione categoria',
          };

          this.notificationService.addNotification(notification);

          this.isVirtualCategory = false;
          this.closeButton.nativeElement.click();
          this.getCategoryTree();
        });
      } else {
        let data = {
          id: this.categoryToBeMenaged.id,
          account: localStorage.getItem('HechRomeoAccount'),
          zorder: this.categoryToBeMenaged.zorder,
          title: this.categoryToBeMenaged.title,
          description: this.categoryToBeMenaged.description,
          image: this.categoryToBeMenaged.image,
          icon: this.categoryToBeMenaged.icon,
          categorytype: this.categoryToBeMenaged.categoryType,
          visible: this.categoryToBeMenaged.visible,
          alignment: this.categoryToBeMenaged.alignment,
          parentaccount: this.virtualCategory.parentaccount,
          parentcategory: this.virtualCategory.parentcategory,
          parentfee: this.virtualCategory.parentfee,
          isdiscount: this.virtualCategory.isdiscount,
          isvirtual: true,
          disablebooking: this.categoryToBeMenaged.disableBooking,
          scroll: this.categoryToBeMenaged.scroll ? this.categoryToBeMenaged.scroll : 0
        };
        this.categoriesService.updateCategory(data).subscribe(() => {
          const notification = {
            id: generateId(),
            image: undefined,
            title: 'Riuscito',
            body: 'Modifica categoria',
          };

          this.notificationService.addNotification(notification);

          this.isVirtualCategory = false;
          this.closeButton.nativeElement.click();
          this.getCategoryTree();
        });
      }
    } else {
      if (this.categoryToBeMenaged.id == '') {
        let biggestIndex = 0;
        for (let element of this.categoryTree.categoriesServices) {
          if (element.zorder > biggestIndex) biggestIndex = element.zorder;
        }
        this.categoryToBeMenaged.zorder = biggestIndex + 1;
        this.categoryToBeMenaged.categorytype = this.categoryToBeMenaged.categoryType;
        this.categoryToBeMenaged.disablebooking = this.categoryToBeMenaged.disableBooking;
        this.categoryToBeMenaged.scroll = this.categoryToBeMenaged.scroll ? this.categoryToBeMenaged.scroll : 0;

        this.addRootCategory(this.categoryToBeMenaged);
      } else {
        this.categoryToBeMenaged.account = localStorage.getItem('HechRomeoAccount');
        this.categoryToBeMenaged.parentcategoryid = this.categoryTree.id;
        this.categoryToBeMenaged.categorytype = this.categoryToBeMenaged.categoryType;
        this.categoryToBeMenaged.disablebooking = this.categoryToBeMenaged.disableBooking;
        this.categoryToBeMenaged.scroll = this.categoryToBeMenaged.scroll ? this.categoryToBeMenaged.scroll : 0;

        this.categoriesService.updateCategory(this.categoryToBeMenaged)
          .subscribe(() => {
            const notification = {
              id: generateId(),
              image: undefined,
              title: 'Riuscito',
              body: 'Modifica categoria',
            };

            this.notificationService.addNotification(notification);

            this.closeButton.nativeElement.click();
            this.getCategoryTree();
          });
      }
    }
  }
  // Saves an edit or a new service
  async saveMenageService(event) {
    if (this.serviceToBeMenaged.idlabels) {
      if (this.serviceToBeMenaged.idlabels.length == 0) {
        this.serviceToBeMenaged.idlabels = null;
      }
    }
    if (this.serviceToBeMenaged.departments)
      if (this.serviceToBeMenaged.departments.length == 0) {
        this.serviceToBeMenaged.departments = null;
      }

    if (
      event.imageToLoad &&
      event.imageToLoad.length > 0 &&
      (event.loadedNewFile || event.deletedImage) &&
      this.serviceToBeMenaged.imageType == 1
    ) {
      if (!event.loadedNewFile && event.deletedImage) {
        this.serviceToBeMenaged.image = '';
        for (let element of event.imageToLoad) {
          if (element && element.id) {
            if (this.serviceToBeMenaged.image != '') {
              this.serviceToBeMenaged.image =
                this.serviceToBeMenaged.image.concat(';');
            }
            this.serviceToBeMenaged.image =
              this.serviceToBeMenaged.image.concat(element.id);
          }
        }
      } else if (event.imageToLoad[0].image != undefined) {
        let images = [];
        for (let element of event.imageToLoad) {
          if (element.image) images.push(element.image);
        }
        await this.mediaService
          .addImagesBulk(localStorage.getItem('HechRomeoAccount'), images)
          .then((data) => {
            this.serviceToBeMenaged.image = '';
            for (let element of data) {
              if (this.serviceToBeMenaged.image != '') {
                this.serviceToBeMenaged.image =
                  this.serviceToBeMenaged.image.concat(';');
              }
              this.serviceToBeMenaged.image =
                this.serviceToBeMenaged.image.concat(element);
            }
          });

        // await this.mediaService
        //   .addMediaContent(
        //     localStorage.getItem('HechRomeoAccount'),
        //     this.serviceToBeMenaged.imagetype,
        //     event.imageToLoad
        //   )
        //   .then((data) => {
        //     this.serviceToBeMenaged.image = data.id;
        //   });
      }
    } else if (this.serviceToBeMenaged.imageType == 2) {
      if (event.savedUrl) {
        this.serviceToBeMenaged.image = event.savedUrl;
      } else if (event.videoToLoad && event.loadedNewFile) {
        await this.mediaService
          .addMediaContent(
            localStorage.getItem('HechRomeoAccount'),
            this.serviceToBeMenaged.imagetype,
            event.videoToLoad
          )
          .then((data) => {
            this.serviceToBeMenaged.image = data.id;
          });
      }
    }
    for (let language in this.serviceToBeMenaged.description) {
      this.serviceToBeMenaged.description[language] = encodeURI(
        this.serviceToBeMenaged.description[language]
      );
    }

    if (this.serviceToBeMenaged.id == '') {
      this.serviceToBeMenaged.account = localStorage.getItem('HechRomeoAccount');
      this.serviceToBeMenaged.parentcategoryid = this.detailsCategory.id;
      this.serviceToBeMenaged.zorder = this.serviceToBeMenaged.zOrder;
      this.serviceToBeMenaged.mlnote = this.serviceToBeMenaged.mlNote;
      this.serviceToBeMenaged.imagetype = this.serviceToBeMenaged.imageType;
      this.serviceToBeMenaged.servicetype = this.serviceToBeMenaged.serviceType;
      this.serviceToBeMenaged.paymenttype = this.serviceToBeMenaged.paymenttype;
      this.serviceToBeMenaged.maxquantity = this.serviceToBeMenaged.maxQuantity;
      this.serviceToBeMenaged.enableqty = this.serviceToBeMenaged.enableQty;
      this.serviceToBeMenaged.decreasequantity = this.serviceToBeMenaged.decreaseQuantity;
      this.serviceToBeMenaged.availabledate = this.serviceToBeMenaged.availableDate;
      this.serviceToBeMenaged.availabletime = this.serviceToBeMenaged.availableTime;
      this.serviceToBeMenaged.expirationdate = this.serviceToBeMenaged.expirationDate;
      this.serviceToBeMenaged.expirationtime = this.serviceToBeMenaged.expirationTime;
      this.serviceToBeMenaged.cartenabled = this.serviceToBeMenaged.cartEnabled;
      this.serviceToBeMenaged.idlabels = this.serviceToBeMenaged.idLabels;
      this.serviceToBeMenaged.ismultiband = this.serviceToBeMenaged.isMultiband;
      this.serviceToBeMenaged.livestream = this.serviceToBeMenaged.liveStream;
      this.serviceToBeMenaged.isadvanced = this.serviceToBeMenaged.isAdvanced;

      if (this.serviceToBeMenaged.isadvanced) {
        this.serviceToBeMenaged.amount = 0.0;
        this.serviceToBeMenaged.maxquantity = 0;
        this.serviceToBeMenaged.maxQuantity = 0;
        this.serviceToBeMenaged.enableqty = false;
        this.serviceToBeMenaged.enableQty = false;
        this.serviceToBeMenaged.decreasequantity = false;
        this.serviceToBeMenaged.decreaseQuantity = false;
        this.serviceToBeMenaged.availabledate = '';
        this.serviceToBeMenaged.availableDate = '';
        this.serviceToBeMenaged.availabletime = '';
        this.serviceToBeMenaged.availableTime = '';
        this.serviceToBeMenaged.expirationdate = '';
        this.serviceToBeMenaged.expirationDate = '';
        this.serviceToBeMenaged.expirationtime = '';
        this.serviceToBeMenaged.expirationTime = '';
        // this.serviceToBeMenaged.paymenttype = 0;
        // this.serviceToBeMenaged.paymentType = 0;
        this.serviceToBeMenaged.servicetype = 1;
        this.serviceToBeMenaged.serviceType = 1;
        if (this.serviceToBeMenaged.cartenabled || this.serviceToBeMenaged.cartEnabled) {
          this.serviceToBeMenaged.periodBooking = true;
        }
      }

      this.categoriesService.createService(this.serviceToBeMenaged).subscribe(() => {
        if (this.serviceToBeMenaged.isadvanced || this.serviceToBeMenaged.isAdvanced) {
          this.advancedService = event.advancedService;

          this.categoriesService.getVendorTree().subscribe((data) => {
            let position: any = data;

            for (const element of this.navigate) {
              position = position.categoriesServices.find((obj) => obj.id == element.id);
            }

            const serviceDetail = position.categoriesServices[position.categoriesServices.length - 1];

            this.saveAdvancedService(serviceDetail.id);

            this.getCategoryTree();
            this.menagingServices = false;
          });
        } else {
          this.getCategoryTree();
          this.menagingServices = false;
        }

        const notification = {
          id: generateId(),
          image: undefined,
          title: 'Riuscito',
          body: 'Creazione servizio',
        };

        this.notificationService.addNotification(notification);
      });
    } else {
      this.serviceToBeMenaged.account = localStorage.getItem('HechRomeoAccount');
      this.serviceToBeMenaged.parentcategoryid = this.detailsCategory.id;
      this.serviceToBeMenaged.zorder = this.serviceToBeMenaged.zOrder;
      this.serviceToBeMenaged.mlnote = this.serviceToBeMenaged.mlNote;
      this.serviceToBeMenaged.imagetype = this.serviceToBeMenaged.imageType;
      this.serviceToBeMenaged.servicetype = this.serviceToBeMenaged.serviceType;
      this.serviceToBeMenaged.paymenttype = this.serviceToBeMenaged.paymenttype;
      this.serviceToBeMenaged.paymentType = this.serviceToBeMenaged.paymentType;
      this.serviceToBeMenaged.maxquantity = this.serviceToBeMenaged.maxQuantity;
      this.serviceToBeMenaged.enableqty = this.serviceToBeMenaged.enableQty;
      this.serviceToBeMenaged.decreasequantity = this.serviceToBeMenaged.decreaseQuantity;
      this.serviceToBeMenaged.availabledate = this.serviceToBeMenaged.availableDate;
      this.serviceToBeMenaged.availabletime = this.serviceToBeMenaged.availableTime;
      this.serviceToBeMenaged.expirationdate = this.serviceToBeMenaged.expirationDate;
      this.serviceToBeMenaged.expirationtime = this.serviceToBeMenaged.expirationTime;
      this.serviceToBeMenaged.cartenabled = this.serviceToBeMenaged.cartEnabled;
      this.serviceToBeMenaged.idlabels = this.serviceToBeMenaged.idLabels;
      this.serviceToBeMenaged.ismultiband = this.serviceToBeMenaged.isMultiband;
      this.serviceToBeMenaged.livestream = this.serviceToBeMenaged.liveStream;
      this.serviceToBeMenaged.isadvanced = this.serviceToBeMenaged.isAdvanced;

      if (this.serviceToBeMenaged.isadvanced) {
        this.serviceToBeMenaged.amount = 0.0;
        this.serviceToBeMenaged.maxquantity = 0;
        this.serviceToBeMenaged.maxQuantity = 0;
        this.serviceToBeMenaged.enableqty = false;
        this.serviceToBeMenaged.enableQty = false;
        this.serviceToBeMenaged.decreasequantity = false;
        this.serviceToBeMenaged.decreaseQuantity = false;
        this.serviceToBeMenaged.availabledate = '';
        this.serviceToBeMenaged.availableDate = '';
        this.serviceToBeMenaged.availabletime = '';
        this.serviceToBeMenaged.availableTime = '';
        this.serviceToBeMenaged.expirationdate = '';
        this.serviceToBeMenaged.expirationDate = '';
        this.serviceToBeMenaged.expirationtime = '';
        this.serviceToBeMenaged.expirationTime = '';
        // this.serviceToBeMenaged.paymenttype = 0;
        // this.serviceToBeMenaged.paymentType = 0;
        this.serviceToBeMenaged.servicetype = 1;
        this.serviceToBeMenaged.serviceType = 1;
        if (this.serviceToBeMenaged.cartenabled || this.serviceToBeMenaged.cartEnabled) {
          this.serviceToBeMenaged.periodBooking = true;
        }
      }

      this.categoriesService.updateService(this.serviceToBeMenaged).subscribe(() => {
        if (this.serviceToBeMenaged.isadvanced || this.serviceToBeMenaged.isAdvanced) {
          this.advancedService = event.advancedService;
          this.saveAdvancedService(this.serviceToBeMenaged.id);
        }

        this.menagingServices = false;
        this.getCategoryTree();

        const notification = {
          id: generateId(),
          image: undefined,
          title: 'Riuscito',
          body: 'Modifica servizio',
        };

        this.notificationService.addNotification(notification);
      });
    }
  }
  // Saves an edit or a new category
  async saveCategory() {
    this.disableSaveButton = true;

    if (
      this.imageToLoad &&
      this.imageToLoad.length > 0 &&
      (this.loadedNewFile || this.deletedImage) &&
      this.categoryToBeMenaged.imagetype == 1
    ) {
      if (!this.loadedNewFile && this.deletedImage) {
        this.categoryToBeMenaged.image = '';
        for (let element of this.imageToLoad) {
          if (element && element.id) {
            if (this.categoryToBeMenaged.image != '') {
              this.categoryToBeMenaged.image =
                this.categoryToBeMenaged.image.concat(';');
            }
            this.categoryToBeMenaged.image =
              this.categoryToBeMenaged.image.concat(element.id);
          }
        }
      } else if (this.imageToLoad[0].image != undefined) {
        let images = [];
        for (let element of this.imageToLoad) {
          if (element.image) images.push(element.image);
        }
        await this.mediaService
          .addImagesBulk(localStorage.getItem('HechRomeoAccount'), images)
          .then((data) => {
            this.categoryToBeMenaged.image = '';
            for (let element of data) {
              if (this.categoryToBeMenaged.image != '') {
                this.categoryToBeMenaged.image =
                  this.categoryToBeMenaged.image.concat(';');
              }
              this.categoryToBeMenaged.image =
                this.categoryToBeMenaged.image.concat(element);
            }
          });
      }
    } else if (this.categoryToBeMenaged.imagetype == 2) {
      if (this.savedUrl && this.playerLoaded) {
        this.categoryToBeMenaged.image = this.savedUrl;
      } else if (this.videoToLoad && this.loadedNewFile) {
        await this.mediaService
          .addMediaContent(
            localStorage.getItem('HechRomeoAccount'),
            this.categoryToBeMenaged.imagetype,
            this.videoToLoad
          )
          .then((data) => {
            this.categoryToBeMenaged.image = data.id;
          });
      }
    }
    if (this.iconToLoad && this.loadedNewIcon) {
      await this.mediaService
        .addMediaContent(
          localStorage.getItem('HechRomeoAccount'),
          3,
          this.iconToLoad
        )
        .then((data) => {
          this.categoryToBeMenaged.icon = data.id;
        });
    }
    for (let language in this.categoryToBeMenaged.description) {
      this.categoryToBeMenaged.description[language] = encodeURI(
        this.categoryToBeMenaged.description[language]
      );
    }

    if (this.isVirtualCategory) {
      if (this.categoryToBeMenaged.id == '') {
        let biggestIndex = 0;
        for (let element of this.detailsCategory.categoriesServices) {
          if (element.zorder > biggestIndex) biggestIndex = element.zorder;
        }
        this.categoryToBeMenaged.zorder = biggestIndex + 1;

        let data = {
          account: localStorage.getItem('HechRomeoAccount'),
          zorder: this.categoryToBeMenaged.zorder,
          title: this.categoryToBeMenaged.title,
          description: this.categoryToBeMenaged.description,
          image: this.categoryToBeMenaged.image,
          imagetype: this.categoryToBeMenaged.imagetype,
          icon: this.categoryToBeMenaged.icon,
          categorytype: this.categoryToBeMenaged.categorytype,
          visible: true,
          alignment: this.categoryToBeMenaged.alignment,
          parentaccount: this.virtualCategory.parentaccount,
          parentcategory: this.virtualCategory.parentcategory,
          parentfee: this.virtualCategory.parentfee,
          isdiscount: this.virtualCategory.isdiscount,
          parentcategoryid: this.detailsCategory.id,
          isvirtual: true,
          scroll: this.categoryToBeMenaged.scroll ? this.categoryToBeMenaged.scroll : 0
        };

        this.categoriesService.createCategory(data).subscribe(() => {
          const notification = {
            id: generateId(),
            image: undefined,
            title: 'Riuscito',
            body: 'Creazione categoria',
          };

          this.notificationService.addNotification(notification);

          this.isVirtualCategory = false;
          this.menagingCategories = false;
          this.fileToLoad = undefined;
          this.imageToLoad = undefined;
          this.videoToLoad = undefined;
          this.iconToLoad = undefined;
          this.loadedNewIcon = false;
          this.loadedNewFile = false;
          this.deletedImage = false;
          this.categoryToBeMenaged = new Category();
          this.getCategoryTree();
        });
      } else {
        let data = {
          id: this.categoryToBeMenaged.id,
          account: localStorage.getItem('HechRomeoAccount'),
          zorder: this.categoryToBeMenaged.zorder,
          title: this.categoryToBeMenaged.title,
          description: this.categoryToBeMenaged.description,
          image: this.categoryToBeMenaged.image,
          imagetype: this.categoryToBeMenaged.imagetype,
          icon: this.categoryToBeMenaged.icon,
          categorytype: this.categoryToBeMenaged.categorytype,
          visible: this.categoryToBeMenaged.visible,
          alignment: this.categoryToBeMenaged.alignment,
          parentaccount: this.virtualCategory.parentaccount,
          parentcategory: this.virtualCategory.parentcategory,
          parentfee: this.virtualCategory.parentfee,
          isdiscount: this.virtualCategory.isdiscount,
          isvirtual: true,
          scroll: this.categoryToBeMenaged.scroll ? this.categoryToBeMenaged.scroll : 0
        };
        this.categoriesService.updateCategory(data).subscribe(() => {
          const notification = {
            id: generateId(),
            image: undefined,
            title: 'Riuscito',
            body: 'Modifica categoria',
          };

          this.notificationService.addNotification(notification);

          this.isVirtualCategory = false;
          this.menagingCategories = false;
          this.fileToLoad = undefined;
          this.imageToLoad = undefined;
          this.videoToLoad = undefined;
          this.iconToLoad = undefined;
          this.loadedNewIcon = false;
          this.loadedNewFile = false;
          this.deletedImage = false;
          this.categoryToBeMenaged = new Category();
          this.getCategoryTree();
        });
      }
    } else {
      if (this.categoryToBeMenaged.id == '') {
        this.categoryToBeMenaged.account = localStorage.getItem('HechRomeoAccount');
        this.categoryToBeMenaged.parentcategoryid = this.detailsCategory.id;
        this.categoryToBeMenaged.scroll = this.categoryToBeMenaged.scroll ? this.categoryToBeMenaged.scroll : 0;

        this.categoriesService.createCategory(this.categoryToBeMenaged)
          .subscribe(() => {
            const notification = {
              id: generateId(),
              image: undefined,
              title: 'Riuscito',
              body: 'Creazione categoria',
            };

            this.notificationService.addNotification(notification);

            this.menagingCategories = false;
            this.fileToLoad = undefined;
            this.imageToLoad = undefined;
            this.videoToLoad = undefined;
            this.iconToLoad = undefined;
            this.loadedNewIcon = false;
            this.loadedNewFile = false;

            this.getCategoryTree();
          });
      } else {
        this.categoryToBeMenaged.account = localStorage.getItem('HechRomeoAccount');
        this.categoryToBeMenaged.parentcategoryid = this.detailsCategory.id;
        this.categoryToBeMenaged.scroll = this.categoryToBeMenaged.scroll ? this.categoryToBeMenaged.scroll : 0;

        this.categoriesService.updateCategory(this.categoryToBeMenaged)
          .subscribe(() => {
            const notification = {
              id: generateId(),
              image: undefined,
              title: 'Riuscito',
              body: 'Modifica categoria',
            };

            this.notificationService.addNotification(notification);

            this.menagingCategories = false;
            this.fileToLoad = undefined;
            this.imageToLoad = undefined;
            this.videoToLoad = undefined;
            this.iconToLoad = undefined;
            this.loadedNewIcon = false;
            this.loadedNewFile = false;

            this.getCategoryTree();
          });
      }
    }
  }

  deleteImgOrVideo() {
    this.fileToLoad = undefined;
    this.categoryToBeMenaged.imagetype = undefined;
    this.categoryToBeMenaged.image = undefined;
  }

  deleteRootCategory(element) {
    this.categoriesService.deleteCategory(localStorage.getItem('HechRomeoAccount'), '', element.id)
      .subscribe(() => {
        const notification = {
          id: generateId(),
          image: undefined,
          title: 'Riuscito',
          body: 'Eliminazione categoria',
        };

        this.notificationService.addNotification(notification);

        this.getCategoryTree();
      });
  }

  deleteElement(element, type) {
    switch (type) {
      case 'categories':
        this.categoriesService.deleteCategory(localStorage.getItem('HechRomeoAccount'), this.detailsCategory.id, element.id)
          .subscribe(() => {
            const notification = {
              id: generateId(),
              image: undefined,
              title: 'Riuscito',
              body: 'Eliminazione categoria',
            };

            this.notificationService.addNotification(notification);

            this.getCategoryTree();
          });
        break;
      case 'services':
        this.categoriesService.deleteService(localStorage.getItem('HechRomeoAccount'), this.detailsCategory.id, element.id)
          .subscribe(() => {
            const notification = {
              id: generateId(),
              image: undefined,
              title: 'Riuscito',
              body: 'Eliminazione servizio',
            };

            this.notificationService.addNotification(notification);

            this.getCategoryTree();
          });
        break;
    }
  }
  // Toggles visibility in widget
  toggleVisibility(item, isCategory: boolean) {
    item.visible = !item.visible;

    this.categoriesService.updateVisibleCategory({
        id: item.id,
        visible: item.visible,
        isCategory,
        account: localStorage.getItem('HechRomeoAccount')
    }).subscribe(() => {
      const notification = {
        id: generateId(),
        image: undefined,
        title: 'Riuscito',
        body: 'Aggiornamento visibilità',
      };

      this.notificationService.addNotification(notification);

      this.getCategoryTree();
    });
  }

  addRootCategory(category) {
    category.account = localStorage.getItem('HechRomeoAccount');
    this.categoriesService.createCategory(category).subscribe((data) => {
      const notification = {
        id: generateId(),
        image: undefined,
        title: 'Riuscito',
        body: 'Creazione categoria',
      };

      this.notificationService.addNotification(notification);

      this.closeButton.nativeElement.click();
      this.categoryToBeMenaged = new Category();
      this.getCategoryTree();
    });
  }
  // Updates an edited category tree
  updateCategoryTree(tree: CategoryTree, saveAdvancedServices?: boolean) {
    this.categoriesService.updateCategoryTree(tree).subscribe((data) => {
      const notification = {
        id: generateId(),
        image: undefined,
        title: 'Riuscito',
        body: 'Modifica categoria',
      };

      this.notificationService.addNotification(notification);

      this.categoryTree = data;
      if (this.navigate.length > 0)
        this.navigateToElement(this.navigate[this.navigate.length - 1]);
      this.menagingServices = false;
      this.menagingCategories = false;
      this.fileToLoad = undefined;
      this.imageToLoad = undefined;
      this.videoToLoad = undefined;
      this.iconToLoad = undefined;
      this.loadedNewIcon = false;
      this.loadedNewFile = false;



      this.deletedImage = false;
      this.serviceToBeMenaged = new Service();
      this.categoryToBeMenaged = new Category();
    });
  }
  // Saves the rest of the data for advanced services, this because it was required that data for normal services wouldn't be changed with the addition of advanced ones, so this data is saved separatly
  saveAdvancedService(serviceId: string) {
    let editedPeriods = this.advancedService.periods?.filter(
      (obj) => obj.edited == true && !obj.delete && obj.periodid
    );
    let toBeCreated = this.advancedService.periods?.filter(
      (obj) => obj.edited == true && !obj.periodid
    );
    let toBeDeleted = this.advancedService.periods?.filter(
      (obj) => obj.delete == true
    );

    let count = 0;
    if (editedPeriods.length > 0) {
      count += 1;
    }

    if (toBeCreated.length > 0) {
      count += 1;
    }

    if (toBeDeleted.length > 0) {
      count += 1;
    }

    if (count === 0) {
      this.saveAvaibilityAdvancedServices(serviceId);
    }

    if (editedPeriods.length > 0) {
      let updateVar = {
        account: localStorage.getItem('HechRomeoAccount'),
        serviceperiods: [],
      };
      for (let element of editedPeriods) {
        let period: Period = new Period();
        period.period = {
          serviceid: serviceId,
          id: element.periodid,
          account: localStorage.getItem('HechRomeoAccount'),
          from: element.from,
          to: element.to,
          hideendtime: element.hideendtime,
          closereservationtime: element.closereservationtime
        };
        period.servicedata = element.perioddata;
        period.servicedata.id = element.perioddata.id;
        period.servicedata.periodid = element.periodid;
        updateVar.serviceperiods.push(period);
      }
      this.categoriesService
        .advancedServicesBulkUpdate(updateVar)
        .subscribe((data) => {

          count -= 1;
          this.saveAvaibilityAdvancedServices(serviceId, count);
        });
    }
    if (toBeCreated.length > 0) {
      let advancedService: AdvancedService = new AdvancedService();
      advancedService.account = localStorage.getItem('HechRomeoAccount');
      for (let element of toBeCreated) {
        let obj: Period = new Period();
        obj.period = {
          serviceid: serviceId,
          from: element.from,
          to: element.to,
          hideendtime: element.hideendtime,
        };
        obj.servicedata = element.perioddata;
        advancedService.serviceperiods.push(obj);
      }
      this.categoriesService
        .createAdvancedServiceFeatures(advancedService)
        .subscribe((data) => {
          count -= 1;
          this.saveAvaibilityAdvancedServices(serviceId, count);
        });
    }
    if (toBeDeleted.length > 0) {
      let deleteVar = {
        account: localStorage.getItem('HechRomeoAccount'),
        periods: [],
      };
      for (let element of toBeDeleted) {
        deleteVar.periods.push({
          periodid: element.periodid,
          serviceid: serviceId,
        });
      }
      this.categoriesService
        .advancedServicesBulkDelete(deleteVar)
        .subscribe((data) => {
          count -= 1;
          this.saveAvaibilityAdvancedServices(serviceId, count);
        });
    }
  }
  // Deletes data for an advanced service, ATTENTION doesn't delete the service, but just the data saved separatly for advanced services
  deleteAdvancedService(serviceId, periodId) {
    this.categoriesService
      .deleteAdvancedService(serviceId, periodId)
      .subscribe((data) => {});
  }

  encodeImageFileAsURL(element, type: number, i?) {
    this.fileToLoad = undefined;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      this.getPureB64(reader.result.toString(), type, i);
    };
    reader.readAsDataURL(file);
  }
  getPureB64(element: string, type: number, i?) {
    let index = element.indexOf(',');
    element = element.slice(index + 1, element.length);
    switch (type) {
      case 1:
        let item = this.imageToLoad[i];
        if (this.imageToLoad.length < 8 && item.image == undefined) {
          this.imageToLoad.push({});
        }
        item.image = element;
        item.new = true;
        this.loadedNewFile = true;
        break;
      case 2:
        this.id = '';
        if (this.playerLoaded) {
          this.player.destroy();
          this.playerLoaded = false;
        }

        this.videoToLoad = element;
        this.loadedNewFile = true;
        break;
      case 3:
        this.iconToLoad = element;
        this.loadedNewIcon = true;
        break;
    }
  }

  deleteYoutubeVideo() {
    this.id = '';
    this.url = '';
    this.urlError = false;
    this.savedUrl = '';
    if (this.playerLoaded) {
      this.player.destroy();
      this.playerLoaded = false;
    }
    this.categoryToBeMenaged.imagetype = undefined;
    this.categoryToBeMenaged.image = undefined;
  }
  loadPlayer(root?) {
    const idPlayer: string = root ? 'player' : 'playerCategory';
    if (this.id != '' && this.id != undefined) {
      this.player = new YT.Player(idPlayer, {
        width: '480px',
        videoId: this.id,
      });
      this.playerLoaded = true;
    }
  }
  loadNewYoutubeVideo(root?) {
    let newId = this.getIdFromUrl();
    this.id = newId;
    if (this.playerLoaded == false) {
      this.loadPlayer(root);
    } else {
      this.player.loadVideoById(newId);
    }
  }
  getIdFromUrl() {
    try {
      let _url = new URL(this.url);

      const splitUrl = _url.pathname.split('/');

      let id = this.url?.includes('embed') ? splitUrl[splitUrl.length - 1] : _url.searchParams.get('v');

      this.savedUrl = this.url;
      this.urlError = false;
      return id;
    } catch (e) {
      this.urlError = true;
    }
  }

  deleteImage(i) {
    this.deletedImage = true;
    this.imageToLoad.splice(i, 1);
    if (this.imageToLoad.length == 7) {
      this.imageToLoad.push({});
    }
    if (
      this.imageToLoad.lenght == 1 &&
      this.imageToLoad[0].image == undefined
    ) {
      this.categoryToBeMenaged.image = undefined;
      this.categoryToBeMenaged.imagetype = undefined;
    }
  }

  setAvailablity(objAvailablityParam: availablityAdvancedServices, idservice) {
    objAvailablityParam.account = localStorage.getItem('HechRomeoAccount');
    objAvailablityParam.serviceid = idservice;

    if (objAvailablityParam) {
      this.categoriesService.setAvailablity(objAvailablityParam).subscribe(
        (resp: any) => console.log(resp),
        (err) => console.log(err)
      );
    }
  }

  saveAvaibilityAdvancedServices(serviceid: string, count?: number) {
    if ((count === 0 || count === undefined) && this.callAtServer.length > 0) {
      for (let callServer of this.callAtServer) {
        callServer.serviceid = serviceid;
      }
      let call = {
        account: localStorage.getItem('HechRomeoAccount'),
        services: this.callAtServer,
      };
      this.categoriesService
        .setAvailabilityForAdvancedServices(call)
        .subscribe((data) => {});
    }
  }
  // Trying to fix a bug in the Tiny editor, with not much success sadly
  onInitEditor(): void {
    $('button[title="Align left"]').trigger('click');
  }

  editComponent(element) {
    if (element.iscategory) {
      this.isVirtualCategory = element.isVirtual;
      this.editCategory(element);
    } else {
      this.menageService(element);
    }
  }
}
