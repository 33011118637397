<div class="container-fluid my-2">
  <h3 class="color-theme font-weight-bold mb-4">
    {{ "Contatti" | translate }}
  </h3>

  <div class="my-4">
    <div class="contact">
      <div class="centered">{{ "Sito assistenza" | translate }}</div>
      <div class="centered"><a href="https://interfaceglobe.com/assistenza/" target="_blank"
          rel="noopener noreferrer">https://interfaceglobe.com/assistenza/</a></div>
    </div>
    <div class="contact">
      <div class="centered">{{ "Mail assistenza" | translate }}</div>
      <div class="centered"><a href="mailto:vendite@intechitalia.it">vendite@intechitalia.it</a> - 02 84144318</div>
    </div>
  </div>

  <div style="background-color: #d7e6b6; border: 5px solid #33793d; padding: 20px; text-align: center;">
    <h3>
      <font color="#000000">{{ "Banner academy 1" | translate }}</font>
    </h3>
    <font color="#000000"><span style="font-family: arial, helvetica, sans-serif; font-size: small;"><span
          style="font-family: arial, helvetica, sans-serif; font-size: small;"><span
            style="font-family: arial, helvetica, sans-serif; font-size: small;"><br />{{ "Banner academy 2" | translate }} <strong>{{ "Banner academy 3" | translate }}</strong>{{ "Banner academy 4" | translate }}<font face="Arial">{{ "Banner academy 5" | translate }}<br /><br /></font></span></span></span></font>
    <hr /><span style="font-family: arial, helvetica, sans-serif; font-size: small;"><span
        style="font-family: arial, helvetica, sans-serif; font-size: small;">
        <font color="#4d4d4f" face="Arial"><br />
          <font color="#000000"><strong>{{ "Banner academy 6" | translate }}</strong></font><br /><span
            style="font-family: verdana, geneva, sans-serif; font-size: small;"><br /></span>
        </font>
      </span></span>
    <div style="background-color: #33793d; border-radius: 10px; padding: 10px; color: white; text-align: center;"><span
        style="font-family: verdana, geneva, sans-serif; font-size: small;"><a
          style="text-decoration: none; color: white;" href="https://interfaceglobe.com/configuratore/" target="_blank"
          rel="noopener noreferrer">{{ "Banner academy 7" | translate }}<br />{{ "Banner academy 8" | translate }}</a></span></div>
  </div>
</div>