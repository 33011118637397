<app-menage-services
  (endMenageServices)="endMenageService()"
  (saveMenageServices)="saveMenageService($event)"
  (filterAllLanguages)="filterAllLanguages()"
  (deleteSelectedLanguage)="deleteSelectedLanguage($event)"
  [service]="serviceToBeMenaged"
  [advancedService]="advancedService"
  [activatedLanguages]="activatedLanguages"
  [currentLanguage]="currentLanguage"
  [fileToLoad]="fileToLoad"
  [loadedNewFile]="loadedNewFile"
  [videoToLoad]="videoToLoad"
  [imageToLoad]="imageToLoad"
  [deletedImage]="deletedImage"
  [id]="id"
  [url]="url"
  [savedUrl]="savedUrl"
  [labels]="labels"
  [callAtServer]="callAtServer"
  [departments]="departments"
  *ngIf="menagingServices"
></app-menage-services>

<!-- Context menu -->
<div *ngIf="contextmenu && detailsCategory && !menagingCategories">
  <div
    class="contextmenu"
    [ngStyle]="{ 'left.px': contextmenuX, 'top.px': contextmenuY }"
  >
    <div *ngIf="contextmenu == 'copy'" (click)="copyCategory(false)">
      {{ "Copia" | translate }}
    </div>
    <div *ngIf="contextmenu == 'copy'" (click)="copyCategory(true)">
      {{ "Taglia" | translate }}
    </div>
    <div *ngIf="contextmenu == 'paste'" (click)="pasteCategory()">
      {{ "Incolla" | translate }}
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Creazione categoria root" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeButton
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body body-category-root" style="">
        <form>
          <div></div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <div class="form-group" style="width: 49%">
              <label for="button">{{ "Aggiungi Lingua" | translate }}</label>
              <button
                data-toggle="modal"
                data-target="#languageModal"
                class="pl-4 pr-4 btn btn-secondary"
                style="font-size: 12px; width: 100%; height: 38px"
                (click)="filterAllLanguages()"
              >
                + {{ "Aggiungi Lingua" | translate }}
              </button>
            </div>

            <div class="form-group" style="width: 49%">
              <label for="categorytype">{{
                "Seleziona lingua" | translate
              }}</label>
              <div style="display: flex; flex-direction: row">
                <select
                  [(ngModel)]="currentLanguage"
                  class="form-control"
                  name="categorytype"
                >
                  <option
                    *ngFor="let element of activatedLanguages"
                    [selected]="element.abr === currentLanguage"
                    [value]="element.abr"
                  >
                    {{ element.language }}
                  </option>
                </select>
                <button
                  (click)="deleteSelectedLanguage()"
                  class="btn btn-danger ml-2"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div>
            <label>ID: </label><span>{{ " " + categoryToBeMenaged.id }}</span>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <div class="form-group" style="width: 49%">
              <label for="title">{{ "Nome" | translate }}</label>
              <input
                [(ngModel)]="categoryToBeMenaged.title[currentLanguage]"
                type="text"
                class="form-control"
                id="title"
                name="title"
                placeholder="Inserici il nome della categoria"
              />
            </div>

            <div class="form-group" style="width: 49%">
              <label for="categorytype">{{ "Tipo" | translate }}</label>
              <select
                class="form-control"
                [(ngModel)]="categoryToBeMenaged.categoryType"
                name="categorytype"
              >
                <option *ngFor="let element of categorytypes" [value]="element">
                  {{ element }}
                </option>
              </select>
              <small>{{
                "Abbina a questa categoria il Tag più appropriato per l’indicizzazione nel motore di ricerca"
                  | translate
              }}</small>
            </div>
          </div>

          <!-- mod icona -->
          <div>
            <div>
              <label>{{ "Icona" | translate }}</label>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="standardIcon"
                  (click)="iconToLoad = undefined"
                  type="radio"
                  class="form-check-input"
                  id="standardIcon"
                  name="standardIcon"
                />
                <label for="form-check-label">{{
                  "Usa Icona di default" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="standardIcon"
                  (click)="iconToLoad = undefined"
                  type="radio"
                  class="form-check-input"
                  id="standardIcon"
                  name="standardIcon"
                />
                <label for="form-check-label">{{
                  "Carica Icona" | translate
                }}</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <!-- titpi 1: immagine, 2: video, 3: icona e string in b64-->
            <div *ngIf="standardIcon">
              <div *ngIf="iconToLoad" class="mb-3">
                <img
                  class="backGroundImg"
                  [src]="
                    _DomSanitizationService.bypassSecurityTrustUrl(
                      'data:image/svg+xml;base64,' + iconToLoad
                    )
                  "
                  style="width: 100px"
                  alt=""
                />
              </div>
              <div style="display: flex; flex-direction: row">
                <button
                  data-toggle="modal"
                  data-target="#iconsModal"
                  class="btn btn-secondary"
                >
                  {{ "Scegli un icona" | translate }}
                </button>
                <button
                  [disabled]="!iconToLoad"
                  type="button"
                  (click)="
                    iconToLoad = undefined;
                    categoryToBeMenaged.icon = undefined;
                    standardIcon = undefined
                  "
                  class="btn btn-danger ml-2"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>
            <div *ngIf="standardIcon == false">
              <div *ngIf="iconToLoad" class="mb-3">
                <img
                  class="backGroundImg"
                  [src]="
                    _DomSanitizationService.bypassSecurityTrustUrl(
                      'data:image/svg+xml;base64,' + iconToLoad
                    )
                  "
                  style="width: 100px"
                  alt=""
                />
              </div>

              <div style="display: flex; flex-direction: row">
                <input
                  type="file"
                  accept=".svg"
                  (change)="encodeImageFileAsURL($event.target.files, 3)"
                />
                <button
                  type="button"
                  (click)="
                    iconToLoad = undefined;
                    categoryToBeMenaged.icon = undefined;
                    standardIcon = undefined
                  "
                  class="btn btn-danger ml-2"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>
          </div>

          <!-- fine mod icona -->

          <div class="form-group">
            <label for="description">{{ "Descrizione" | translate }}</label>
            <br />
            <small>{{
              "Inserisci il testo che apparirà nel progetto" | translate
            }}</small>
            <editor
              name="description"
              apiKey="1sdv0g430kwfa3fbpe8x98vr7eyu3x9il416pnmm3mjo3s52"
              [init]="{
                font_formats: 'Barlow=Barlow; Roboto=Roboto; Barlow Condensed=Barlow-condensed; Gilda Display=Gilda-display; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MSX=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                plugins: ['link image hr ']
              }"
              [(ngModel)]="categoryToBeMenaged.description[currentLanguage]"
              (onInit)="onInitEditor()"
            ></editor>
          </div>

          <div class="form-group">
            <label for="image">{{ "Foto o Video" | translate }}</label>
            <small>(Max 50Mb)</small>
            <div>
              <!-- titpi 1: immagine, 2: video, 3: icona e string in b64-->
              <div>
                <div class="form-check">
                  <input
                    [value]="1"
                    [(ngModel)]="categoryToBeMenaged.imagetype"
                    type="radio"
                    class="form-check-input"
                    id="imagetype"
                    name="imagetype"
                  />
                  <label for="form-check-label">{{
                    "Immagine" | translate
                  }}</label>
                </div>
                <div class="form-check">
                  <input
                    [value]="2"
                    [(ngModel)]="categoryToBeMenaged.imagetype"
                    type="radio"
                    class="form-check-input"
                    id="imagetype"
                    name="imagetype"
                  />
                  <label for="form-check-label">{{
                    "Video" | translate
                  }}</label>
                </div>
              </div>
              <div *ngIf="categoryToBeMenaged.imagetype == 1">
                <div *ngFor="let element of imageToLoad; index as i">
                  <div class="mb-2">
                    <img
                      *ngIf="element.image"
                      [src]="'data:image/jpg;base64,' + element.image"
                      style="width: 450px"
                      alt=""
                    />
                  </div>
                  <div class="mb-3" style="display: flex; flex-direction: row">
                    <input
                      type="file"
                      accept=".jpg"
                      (change)="encodeImageFileAsURL($event.target.files, 1, i)"
                    />
                    <button
                      [disabled]="!element.image"
                      type="button"
                      (click)="deleteImage(i)"
                      class="btn btn-danger ml-2"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>
                  <small style="font-size: 14px">{{
                    "Max 8 immagini" | translate
                  }}</small>
                </div>
              </div>
            </div>
            <div *ngIf="categoryToBeMenaged.imagetype == 2">
              <small style="font-size: 14px">Max 1 video</small>
              <div class="mb-3">
                <youtube-player
                  [ngClass]="{ hideElement: id == '' || id == undefined }"
                  id="player"
                  (ready)="loadPlayer(true)"
                ></youtube-player>

                <video
                  [ngClass]="{ hideElement: id != '' && id != undefined }"
                  width="450px"
                  controls
                  *ngIf="videoToLoad"
                  [src]="'data:video/mp4;base64,' + videoToLoad"
                >
                  <!-- <source
                                    type="video/webm"
                                    [src]="'data:video/webm;base64,' + fileToLoad"
                                  />
                                  <source
                                    type="video/mp4"
                                    [src]="'data:video/mp4;base64,' + fileToLoad"
                                  /> -->
                </video>
              </div>
              <div class="youtubeInput">
                <input
                  [(ngModel)]="url"
                  type="text"
                  class="form-control"
                  id="url"
                  name="url"
                  placeholder="Inserisci un link di youtube"
                />
                <button
                  [disabled]="url == ''"
                  type="button"
                  class="ml-2 mr-2 btn btn-secondary"
                  (click)="loadNewYoutubeVideo(true)"
                >
                  {{ "Carica" | translate }}
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="deleteYoutubeVideo()"
                  [disabled]="playerLoaded == false"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
              <small *ngIf="urlError" class="errorUrl">
                {{ "L'url non è valida" | translate }}</small
              >
              <div class="mt-2 mb-2">
                <span>{{ "Oppure" | translate }}</span>
              </div>

              <div style="display: flex; flex-direction: row">
                <input
                  type="file"
                  accept=".mp4"
                  (change)="encodeImageFileAsURL($event.target.files, 2)"
                />
                <button
                  [disabled]="!videoToLoad"
                  type="button"
                  (click)="
                    videoToLoad = undefined;
                    categoryToBeMenaged.imagetype = undefined;
                    categoryToBeMenaged.image = undefined
                  "
                  class="btn btn-danger ml-2"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
              <div>
                <label for="image">{{ "Live Stream" | translate }}</label>
                <div class="form-check">
                  <input
                    [value]="true"
                    [(ngModel)]="categoryToBeMenaged.liveStream"
                    type="radio"
                    class="form-check-input"
                    id="livestream"
                    name="livestream"
                  />
                  <label for="form-check-label">{{ "Sì" | translate }}</label>
                </div>
                <div class="form-check">
                  <input
                    [value]="false"
                    [(ngModel)]="categoryToBeMenaged.liveStream"
                    type="radio"
                    class="form-check-input"
                    id="livestream"
                    name="livestream"
                  />
                  <label for="form-check-label">{{ "No" | translate }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-0 w-100">
            <div class="col-12 p-0">
              <label for="orientamento">{{
                "Orientamento media desktop" | translate
              }}</label>
              <div>
                <span>{{
                  "Orientamento immagini e video nella versione desktop"
                    | translate
                }}</span>

                <div class="d-flex">
                  <div class="col-6 p-0">
                    <div class="form-check">
                      <input
                        [value]="0"
                        [(ngModel)]="categoryToBeMenaged.alignment"
                        type="radio"
                        class="form-check-input"
                        id="orientamento"
                        name="orientamento"
                      />
                      <label for="form-check-label">{{
                        "Sinistra" | translate
                      }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="1"
                        [(ngModel)]="categoryToBeMenaged.alignment"
                        type="radio"
                        class="form-check-input"
                        id="orientamento"
                        name="orientamento"
                      />
                      <label for="form-check-label">{{
                        "Destra" | translate
                      }}</label>
                    </div>
                  </div>
                  <div class="col-6 p-0">
                    <div class="form-check">
                      <input
                        [value]="2"
                        [(ngModel)]="categoryToBeMenaged.alignment"
                        type="radio"
                        class="form-check-input"
                        id="orientamento"
                        name="orientamento"
                      />
                      <label for="form-check-label">{{
                        "Sopra" | translate
                      }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="3"
                        [(ngModel)]="categoryToBeMenaged.alignment"
                        type="radio"
                        class="form-check-input"
                        id="orientamento"
                        name="orientamento"
                      />
                      <label for="form-check-label">{{
                        "Sotto" | translate
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- attento -->

          <!--           <div>
            <div>
              <label>Icona</label>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="standardIcon"
                  (click)="iconToLoad = undefined"
                  type="radio"
                  class="form-check-input"
                  id="standardIcon"
                  name="standardIcon"
                />
                <label for="form-check-label">{{ "Usa Icona di default" | translate }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="standardIcon"
                  (click)="iconToLoad = undefined"
                  type="radio"
                  class="form-check-input"
                  id="standardIcon"
                  name="standardIcon"
                />
                <label for="form-check-label">{{ "Carica Icona" | translate }}</label>
              </div>
            </div>
          </div> -->
          <div class="form-group">
            <!--             <div *ngIf="standardIcon">
              <div *ngIf="iconToLoad" class="mb-3">
                <img
                  class="backGroundImg"
                  [src]="
                    _DomSanitizationService.bypassSecurityTrustUrl(
                      'data:image/svg+xml;base64,' + iconToLoad
                    )
                  "
                  style="width: 100px"
                  alt=""
                />
              </div>
              <div style="display: flex; flex-direction: row">
                <button
                  data-toggle="modal"
                  data-target="#iconsModal"
                  class="btn btn-secondary"
                >
                  {{ "Scegli un icona" | translate }}
                </button>
                <button
                  [disabled]="!iconToLoad"
                  type="button"
                  (click)="
                    iconToLoad = undefined;
                    categoryToBeMenaged.icon = undefined;
                    standardIcon = undefined
                  "
                  class="btn btn-danger ml-2"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div> -->
            <div *ngIf="standardIcon == false">
              <div *ngIf="iconToLoad" class="mb-3">
                <img
                  class="backGroundImg"
                  *ngIf="iconToLoad"
                  [src]="
                    _DomSanitizationService.bypassSecurityTrustUrl(
                      'data:image/svg+xml;base64,' + iconToLoad
                    )
                  "
                  style="width: 100px"
                  alt=""
                />
              </div>

              <div style="display: flex; flex-direction: row">
                <input
                  type="file"
                  accept=".svg"
                  (change)="encodeImageFileAsURL($event.target.files, 3)"
                />
                <button
                  type="button"
                  (click)="
                    iconToLoad = undefined;
                    categoryToBeMenaged.icon = undefined;
                    standardIcon = undefined
                  "
                  class="btn btn-danger ml-2"
                >
                  {{ "Elimina" | translate }}
                </button>
              </div>
            </div>
          </div>

          <!-- attwento -->

          <div class="form-group" *ngIf="!isVirtualCategory">
            <label for="disablebooking">{{
              "Questa categoria prevede limitazione di disponibilità in base al periodo e alle prenotazioni"
                | translate
            }}</label>
            <div>
              <div>
                <div class="form-check">
                  <input
                    [value]="false"
                    [(ngModel)]="categoryToBeMenaged.disableBooking"
                    type="radio"
                    class="form-check-input"
                    id="disablebooking"
                    name="disablebooking"
                  />
                  <label for="form-check-label">{{
                    "Categoria con Calendario" | translate
                  }}</label>
                </div>
                <div class="form-check">
                  <input
                    [value]="true"
                    [(ngModel)]="categoryToBeMenaged.disableBooking"
                    type="radio"
                    class="form-check-input"
                    id="disablebooking"
                    name="disablebooking"
                  />
                  <label for="form-check-label">{{
                    "Categoria senza Calendario" | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="scroll">{{
              "Orientamento sottocategorie e servizi widget tv"
                | translate
              }}</label>
            <div>
              <div>
                <div class="form-check">
                  <input
                    [value]="0"
                    [(ngModel)]="categoryToBeMenaged.scroll"
                    type="radio"
                    class="form-check-input"
                    id="scroll"
                    name="scroll"
                  />
                  <label for="form-check-label">{{
                    "A Scorrimento Orizzontale" | translate
                    }}</label>
                </div>
                <div class="form-check">
                  <input
                    [value]="1"
                    [(ngModel)]="categoryToBeMenaged.scroll"
                    type="radio"
                    class="form-check-input"
                    id="scroll"
                    name="scroll"
                  />
                  <label for="form-check-label">{{
                    "A Lista" | translate
                    }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="isVirtualCategory">
            <div></div>
            <label for="disablebooking">{{
              "Selezionare la categoria da collegare" | translate
            }}</label>
            <div *ngIf="virtualCategory.categoryPosition">
              {{ virtualCategory.categoryPosition }}
            </div>
            <div>
              <div class="d-flex">
                <div class="form-group col-6">
                  <label for="Fornitore">{{ "Fornitore" | translate }}</label>
                  <select
                    class="form-control"
                    [(ngModel)]="virtualCategory.parentaccount"
                    name="Fornitore"
                    (change)="
                      getOthersCategoryTree(virtualCategory.parentaccount)
                    "
                  >
                    <option
                      *ngFor="let element of vendorList"
                      [value]="element.account"
                    >
                      {{ element.account }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-6">
                  <label for="categoryVirtual">{{
                    "Categoria" | translate
                  }}</label>
                  <select
                    class="form-control"
                    [(ngModel)]="virtualCategory.parentcategory"
                    name="categoryVirtual"
                    (change)="showPosition()"
                  >
                    <option
                      *ngFor="let element of otherCategoryTree"
                      [value]="element.id"
                    >
                      {{ element.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="isVirtualCategory">
            <div class="d-flex">
              <div class="form-group col-6">
                <label for="title">{{ "Fee per ordine" | translate }}</label>
                <br />
                <span>{{
                  "Fee per ogni prenotazione effettuata" | translate
                }}</span>
                <input
                  [(ngModel)]="virtualCategory.parentfee"
                  type="number"
                  class="form-control"
                  id="fee"
                  name="fee"
                  placeholder="Inserici la fee"
                />
              </div>
              <div class="form-group col-6">
                <label for="taxType">{{ "Tipo di fee" | translate }}</label>
                <br />
                <select
                  class="form-control"
                  [(ngModel)]="virtualCategory.isdiscount"
                  name="taxType"
                  (change)="showPosition()"
                >
                  <option [ngValue]="false">
                    {{ "Prezzo fisso" | translate }}
                  </option>
                  <option [ngValue]="true">
                    {{ "Percentuale" | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="cancelEditCategory(true)"
        >
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="disableSaveButton"
          (click)="saveRootCategory()"
        >
          {{ "Salva" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="languageModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="languageModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Seleziona lingua" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div class="form-group" style="width: 100%">
            <label for="addnewlanguageselect">{{
              "Seleziona lingua" | translate
            }}</label>
            <select
              [(ngModel)]="languageToAdd"
              class="form-control"
              name="addnewlanguageselect"
              id="addnewlanguageselect"
            >
              <option
                *ngFor="let element of allLanguages"
                [value]="element.abr"
              >
                {{ element.language }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "Annulla" | translate }}
        </button>
        <div>
          <button
            data-toggle="modal"
            data-target="#exampleModal"
            id="dismissLanguageModalAndOpenModal"
            type="button"
            class="d-none"
          ></button>
        </div>

        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="createOrAddLanguage()"
        >
          {{ "Aggiungi" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="iconsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="iconsModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Scegli un'icona" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          style="
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-height: 600px;
            overflow-y: auto;
          "
        >
          <img
            [ngClass]="{
              defaultIconImg: true,
              selected: icon.mediaid == selectedDefaultIcon?.mediaid
            }"
            *ngFor="let icon of defaultIcons"
            [src]="
              _DomSanitizationService.bypassSecurityTrustUrl(
                'data:image/svg+xml;base64,' + icon.webaddress
              )
            "
            (click)="selectedDefaultIcon = icon"
            alt=""
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="selectedDefaultIcon = undefined"
        >
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="
            iconToLoad = selectedDefaultIcon.webaddress;
            categoryToBeMenaged.icon = selectedDefaultIcon.mediaid
          "
        >
          {{ "Scegli" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="delModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="objConfirmDel = undefined"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Sei sicuro di voler eliminare questo oggetto? Non sarà possibile revertire il cambiamento una volta fatto"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="objConfirmDel = undefined; typeToDel = undefined"
        >
          {{ "Annulla" | translate }}
        </button>
        <button
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#delConfirmModal"
          type="button"
          class="btn btn-primary"
        >
          {{ "Elimina" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="delConfirmModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delConfirmModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="objConfirmDel = undefined"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Continuare con l'operazione? Ricordiamo che i dati eliminati non si potranno recuperare"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="objConfirmDel = undefined; typeToDel = undefined"
        >
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="
            typeToDel
              ? deleteElement(objConfirmDel, typeToDel)
              : deleteRootCategory(objConfirmDel);
            objConfirmDel = undefined;
            typeToDel = undefined
          "
        >
          {{ "Conferma" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="delServicesModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="delServicesModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Confermare eliminazione" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="objConfirmDel = undefined"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{
          "Sei sicuro di voler eliminare questo oggetto? Non sarà possibile revertire il cambiamento una volta fatto"
            | translate
        }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="objConfirmDel = undefined; typeToDel = undefined"
        >
          {{ "Annulla" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="
            deleteElement(objConfirmDel, typeToDel);
            objConfirmDel = undefined;
            typeToDel = undefined
          "
        >
          {{ "Elimina" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Bloccare al terzo livello di categoria, partendo dalla root come uno, nell'ultima categoria permettere solo il salvataggio di servizi -->
<!-- aggiungere richiesta di conferma per eliminazione -->

<div
  (click)="disableContextMenu()"
  *ngIf="!menagingServices"
  class="container-fluid d-flex align-items-xl-center vh-100"
>
  <div class="row mx-0 w-100">
    <div class="col-12 col-xl-6 my-2">
      <div class="row mx-0 w-100">
        <div class="col-12 p-0">
          <span>{{ "Categorie e Servizi" | translate }}</span>
          <h3 class="color-theme font-weight-bold">
            {{ "Elenco categorie e servizi" | translate }}
          </h3>
        </div>
      </div>

      <div class="categoriesWindow" style="max-height: 500px">
        <div
          cdkDropList
          style="padding: 10px 20px; height: 400px; overflow-y: auto"
          (cdkDropListDropped)="drop($event, 0)"
        >
          <div
            *ngFor="let category of categoryTree.categoriesServices"
            cdkDrag
            cdkDragLockAxis="y"
            class="mb-2 listElement"
          >
            <div
              *ngIf="admin"
              class="card"
              style="
                width: 20%;
                margin-right: 1%;
                display: flex;
                flex-direction: row;
              "
            >
              <div class="moveAndHide">
                <img
                  *ngIf="category.visible"
                  (click)="toggleVisibility(category, category.iscategory)"
                  src="assets/img/Pittogramma Visibile.png"
                  alt=""
                  style="width: 30px"
                />
                <img
                  *ngIf="!category.visible"
                  (click)="toggleVisibility(category, category.iscategory)"
                  src="assets/img/Pittogramma Nascondi.png"
                  alt=""
                  style="width: 30px"
                />
              </div>
              <div class="moveAndHide" cdkDragHandle>
                <img
                  src="assets/img/Pittogramma Sposta.png"
                  alt=""
                  style="width: 30px"
                />
              </div>
            </div>
            <div
              class="card"
              style="height: 50px; cursor: pointer; width: 100%"
            >
              <div
                style="
                  height: 100%;
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div *ngIf="!category.isvirtual && category.iscategory" class="type">
                  <span>{{ "categoria" | translate }}</span>
                </div>
                <div
                  *ngIf="category.isvirtual && category.iscategory"
                  class="type"
                  style="background-color: #e18335; text-align: center"
                >
                  <span>{{ "categoria partner" | translate }}</span>
                </div>
                <div class="d-flex flex-center">
                  <span
                    class="category"
                    (click)="openCategory(category, true)"
                    >{{
                      category.title[object.keys(category.title)[0]].length > 27
                        ? (category.title[object.keys(category.title)[0]]
                            | slice: 0:27) + "..."
                        : category.title[object.keys(category.title)[0]]
                    }}</span
                  >
                </div>
                <div class="icons">
                  <div
                    *ngIf="admin"
                    class="modifyImg"
                    data-toggle="modal"
                    [attr.data-target]="
                      menagingCategories ? '' : '#exampleModal'
                    "
                    (click)="
                      editCategory(category, true);
                      isVirtualCategory = category.isVirtual
                    "
                  >
                    <img
                      src="assets/img/Pittogramma modifica.png"
                      style="width: 30px"
                      alt=""
                    />
                  </div>
                  <div
                    *ngIf="admin"
                    class="deleteImg"
                    (click)="objConfirmDel = category; typeToDel = undefined"
                    data-toggle="modal"
                    [attr.data-target]="menagingCategories ? '' : '#delModal'"
                  >
                    <img
                      src="assets/img/Pittogramma elimina - cestino.png"
                      style="width: 30px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-3">
          <div class="tools">
            <button
              data-toggle="modal"
              [attr.data-target]="menagingCategories ? '' : '#languageModal'"
              *ngIf="admin"
              style="font-size: 12px"
              type="button"
              class="pl-4 pr-4 m-2 btn btn-secondary"
              (click)="clearAndSelectTypeLanguages(0)"
            >
              + {{ "Aggiungi categoria root" | translate }}
            </button>
            <button
              data-toggle="modal"
              [attr.data-target]="menagingCategories ? '' : '#languageModal'"
              *ngIf="admin"
              style="
                font-size: 12px;
                background-color: #e18335;
                border-color: #e18335;
              "
              type="button"
              class="pl-4 pr-4 m-2 btn btn-secondary"
              (click)="clearAndSelectTypeLanguages(0, true)"
            >
              + {{ "Aggiungi una categoria partner" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-6 my-2">
      <div class="d-flex align-items-end" style="height: 65.5px">
        <ng-container *ngFor="let element of navigate; let last = last">
          <span
            class="navElement"
            (click)="navigateToElement(element)"
            [ngClass]="last ? 'color-theme font-weight-bold' : ''"
            >{{ element.title }}</span
          >
          <span *ngIf="!last">&nbsp;&gt;&nbsp;</span>
        </ng-container>
      </div>

      <div
        class="categoriesWindow"
        oncontextmenu="return false;"
        (contextmenu)="onrightClick($event)"
      >
        <div style="padding: 10px 20px; height: 400px; overflow-y: auto">
          <div *ngIf="detailsCategory && !menagingCategories">
            <div (cdkDropListDropped)="drop($event, 1)" cdkDropList>
              <div
                cdkDrag
                *ngFor="let element of detailsCategory.categoriesServices"
                class="mb-2 listElement"
              >
                <div
                  class="card"
                  style="
                    width: 20%;
                    margin-right: 1%;
                    display: flex;
                    flex-direction: row;
                  "
                >
                  <div class="moveAndHide">
                    <img
                      *ngIf="element.visible"
                      (click)="toggleVisibility(element, element.iscategory)"
                      src="assets/img/Pittogramma Visibile.png"
                      alt=""
                      style="width: 30px"
                    />
                    <img
                      *ngIf="!element.visible"
                      (click)="toggleVisibility(element, element.iscategory)"
                      src="assets/img/Pittogramma Nascondi.png"
                      alt=""
                      style="width: 30px"
                    />
                  </div>
                  <div class="moveAndHide" cdkDragHandle>
                    <img
                      src="assets/img/Pittogramma Sposta.png"
                      alt=""
                      style="width: 30px"
                    />
                  </div>
                </div>
                <div
                  oncontextmenu="return false;"
                  (contextmenu)="
                    onrightClick($event, element, 'category');
                    $event.stopPropagation()
                  "
                  class="card"
                  style="height: 50px; cursor: pointer; width: 79%"
                >
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <div *ngIf="!element.isvirtual && element.iscategory" class="type">
                      <span>{{ "categoria" | translate }}</span>
                    </div>

                    <div *ngIf="!element.isvirtual && !element.iscategory" class="type">
                      <span>{{ "servizio" | translate }}</span>
                    </div>

                    <div
                      *ngIf="element.isvirtual && element.iscategory"
                      class="type"
                      style="background-color: #e18335; text-align: center"
                    >
                      <span>{{ "categoria partner" | translate }}</span>
                    </div>

                    <div class="d-flex flex-center">
                      <span class="category" (click)="openCategory(element)">{{
                        element.title[object.keys(element.title)[0]].length > 27
                          ? (element.title[object.keys(element.title)[0]]
                              | slice: 0:27) + "..."
                          : element.title[object.keys(element.title)[0]]
                      }}</span>
                    </div>
                    <div class="icons">
                      <div
                        class="modifyImg"
                        (click)="editComponent(element)"
                      >
                        <img
                          src="assets/img/Pittogramma modifica.png"
                          style="width: 30px"
                          alt=""
                        />
                      </div>
                      <div
                        data-toggle="modal"
                        data-target="#delModal"
                        class="deleteImg"
                        (click)="
                          objConfirmDel = element; typeToDel = element.iscategory ? 'categories' : 'services'
                        "
                      >
                        <img
                          src="assets/img/Pittogramma elimina - cestino.png"
                          style="width: 30px"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="menagingCategories">
            <form>
              <div></div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div class="form-group" style="width: 49%">
                  <label for="button">{{
                    "Aggiungi Lingua" | translate
                  }}</label>
                  <button
                    data-toggle="modal"
                    data-target="#languageModal"
                    class="pl-4 pr-4 btn btn-secondary"
                    style="font-size: 12px; width: 100%; height: 38px"
                    (click)="filterAllLanguages()"
                  >
                    + {{ "Aggiungi Lingua" | translate }}
                  </button>
                </div>

                <div class="form-group" style="width: 49%">
                  <label for="categorytype">{{
                    "Seleziona lingua" | translate
                  }}</label>
                  <div style="display: flex; flex-direction: row">
                    <select
                      [(ngModel)]="currentLanguage"
                      class="form-control"
                      name="categorytype"
                    >
                      <option
                        *ngFor="let element of activatedLanguages"
                        [selected]="element.abr === currentLanguage"
                        [value]="element.abr"
                      >
                        {{ element.language }}
                      </option>
                    </select>
                    <button
                      (click)="deleteSelectedLanguage()"
                      class="btn btn-danger ml-2"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <label>ID: </label
                ><span>{{ " " + categoryToBeMenaged.id }}</span>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div class="form-group" style="width: 49%">
                  <label for="title">{{ "Nome" | translate }}</label>
                  <input
                    [(ngModel)]="categoryToBeMenaged.title[currentLanguage]"
                    type="text"
                    class="form-control"
                    id="title"
                    name="title"
                    placeholder="Inserici il nome della categoria"
                  />
                </div>

                <div class="form-group" style="width: 49%">
                  <label for="categorytype">{{ "Tipo" | translate }}</label>
                  <select
                    class="form-control"
                    [(ngModel)]="categoryToBeMenaged.categoryType"
                    name="categorytype"
                  >
                    <option
                      *ngFor="let element of categorytypes"
                      [value]="element"
                    >
                      {{ element }}
                    </option>
                  </select>

                  <small>{{
                    "Abbina a questa categoria il Tag più appropriato per l’indicizzazione nel motore di ricerca"
                      | translate
                  }}</small>
                </div>
              </div>

              <!--  -->

              <div>
                <div>
                  <label>{{ "Icona" | translate }}</label>
                  <div class="form-check">
                    <input
                      [value]="true"
                      [(ngModel)]="standardIcon"
                      (click)="iconToLoad = undefined"
                      type="radio"
                      class="form-check-input"
                      id="standardIcon"
                      name="standardIcon"
                    />
                    <label for="form-check-label">{{
                      "Usa Icona di default" | translate
                    }}</label>
                  </div>
                  <div class="form-check">
                    <input
                      [value]="false"
                      [(ngModel)]="standardIcon"
                      (click)="iconToLoad = undefined"
                      type="radio"
                      class="form-check-input"
                      id="standardIcon"
                      name="standardIcon"
                    />
                    <label for="form-check-label">{{
                      "Carica Icona" | translate
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div *ngIf="standardIcon">
                  <div *ngIf="iconToLoad" class="mb-3">
                    <img
                      class="backGroundImg"
                      [src]="
                        _DomSanitizationService.bypassSecurityTrustUrl(
                          'data:image/svg+xml;base64,' + iconToLoad
                        )
                      "
                      style="width: 100px"
                      alt=""
                    />
                  </div>
                  <div style="display: flex; flex-direction: row">
                    <button
                      data-toggle="modal"
                      data-target="#iconsModal"
                      class="btn btn-secondary"
                    >
                      {{ "Scegli un icona" | translate }}
                    </button>
                    <button
                      [disabled]="!iconToLoad"
                      type="button"
                      (click)="
                        iconToLoad = undefined;
                        categoryToBeMenaged.icon = undefined;
                        standardIcon = undefined
                      "
                      class="btn btn-danger ml-2"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>
                </div>
                <div *ngIf="standardIcon == false">
                  <div *ngIf="iconToLoad" class="mb-3">
                    <img
                      class="backGroundImg"
                      [src]="
                        _DomSanitizationService.bypassSecurityTrustUrl(
                          'data:image/svg+xml;base64,' + iconToLoad
                        )
                      "
                      style="width: 100px"
                      alt=""
                    />
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <input
                      type="file"
                      accept=".svg"
                      (change)="encodeImageFileAsURL($event.target.files, 3)"
                    />
                    <button
                      type="button"
                      (click)="
                        iconToLoad = undefined;
                        categoryToBeMenaged.icon = undefined;
                        standardIcon = undefined
                      "
                      class="btn btn-danger ml-2"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>
                </div>
              </div>

              <!--  -->

              <div class="form-group">
                <label for="description">{{ "Descrizione" | translate }}</label>
                <br />
                <small>{{
                  "Inserisci il testo che apparirà nel progetto" | translate
                }}</small>
                <editor
                  name="description"
                  apiKey="1sdv0g430kwfa3fbpe8x98vr7eyu3x9il416pnmm3mjo3s52"
                  [init]="{
                    font_formats: 'Barlow=Barlow; Roboto=Roboto; Barlow Condensed=Barlow-condensed; Gilda Display=Gilda-display; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MSX=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                    plugins: ['link image hr ']
                  }"
                  [(ngModel)]="categoryToBeMenaged.description[currentLanguage]"
                  (onInit)="onInitEditor()"
                ></editor>
              </div>
              <div class="form-group">
                <label for="image">{{ "Foto o Video" | translate }} </label>
                <small>(Max 50Mb)</small>
                <div>
                  <!-- titpi 1: immagine, 2: video, 3: icona e string in b64-->
                  <div>
                    <div class="form-check">
                      <input
                        [value]="1"
                        [(ngModel)]="categoryToBeMenaged.imagetype"
                        type="radio"
                        class="form-check-input"
                        id="imagetype"
                        name="imagetype"
                      />

                      <label for="form-check-label">{{
                        "Immagine" | translate
                      }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="2"
                        [(ngModel)]="categoryToBeMenaged.imagetype"
                        type="radio"
                        class="form-check-input"
                        id="imagetype"
                        name="imagetype"
                      />

                      <label for="form-check-label">{{
                        "Video" | translate
                      }}</label>
                    </div>
                  </div>
                  <div *ngIf="categoryToBeMenaged.imagetype == 1">
                    <div *ngFor="let element of imageToLoad; index as i">
                      <div class="mb-2">
                        <img
                          *ngIf="element.image"
                          [src]="'data:image/jpg;base64,' + element.image"
                          style="width: 450px"
                          alt=""
                        />
                      </div>
                      <div
                        class="mb-3"
                        style="display: flex; flex-direction: row"
                      >
                        <input
                          type="file"
                          accept=".jpg"
                          (change)="
                            encodeImageFileAsURL($event.target.files, 1, i)
                          "
                        />

                        <button
                          [disabled]="!element.image"
                          type="button"
                          (click)="deleteImage(i)"
                          class="btn btn-danger ml-2"
                        >
                          {{ "Elimina" | translate }}
                        </button>

                        <br />
                      </div>

                      <small>{{ "Max 8 immagini" | translate }}</small>
                    </div>
                  </div>
                </div>
                <div *ngIf="categoryToBeMenaged.imagetype == 2">
                  <small style="font-size: 14px">Max 1 video</small>
                  <div class="mb-3">
                    <youtube-player
                      [ngClass]="{ hideElement: id == '' || id == undefined }"
                      id="playerCategory"
                      (ready)="loadPlayer()"
                    >
                    </youtube-player>

                    <video
                      [ngClass]="{ hideElement: id != '' && id != undefined }"
                      width="450px"
                      controls
                      *ngIf="videoToLoad"
                      [src]="'data:video/mp4;base64,' + videoToLoad"
                    >
                    </video>
                  </div>
                  <div class="youtubeInput">
                    <input
                      [(ngModel)]="url"
                      type="text"
                      class="form-control"
                      id="url"
                      name="url"
                      placeholder="Inserisci un link di youtube"
                    />
                    <button
                      [disabled]="url == ''"
                      type="button"
                      class="ml-2 mr-2 btn btn-secondary"
                      (click)="loadNewYoutubeVideo()"
                    >
                      {{ "Carica" | translate }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      (click)="deleteYoutubeVideo()"
                      [disabled]="playerLoaded == false"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>
                  <small *ngIf="urlError" class="errorUrl">
                    {{ "L'url non è valida" | translate }}</small
                  >
                  <div class="mt-2 mb-2">
                    <span>{{ "Oppure" | translate }}</span>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <input
                      type="file"
                      accept=".mp4"
                      (change)="encodeImageFileAsURL($event.target.files, 2)"
                    />
                    <button
                      [disabled]="!videoToLoad"
                      type="button"
                      (click)="
                        videoToLoad = undefined;
                        categoryToBeMenaged.imagetype = undefined;
                        categoryToBeMenaged.image = undefined
                      "
                      class="btn btn-danger ml-2"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>
                  <div>
                    <label for="image">{{ "Live Stream" | translate }}</label>
                    <div class="form-check">
                      <input
                        [value]="true"
                        [(ngModel)]="categoryToBeMenaged.liveStream"
                        type="radio"
                        class="form-check-input"
                        id="livestream"
                        name="livestream"
                      />
                      <label for="form-check-label">{{
                        "Sì" | translate
                      }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="false"
                        [(ngModel)]="categoryToBeMenaged.liveStream"
                        type="radio"
                        class="form-check-input"
                        id="livestream"
                        name="livestream"
                      />
                      <label for="form-check-label">{{
                        "No" | translate
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mx-0 w-100">
                <div class="col-12 p-0">
                  <label for="orientamento">{{
                    "Orientamento media desktop" | translate
                  }}</label>
                  <div>
                    <span>{{
                      "Orientamento immagini e video nella versione desktop"
                        | translate
                    }}</span>

                    <div class="d-flex">
                      <div class="col-6 p-0">
                        <div class="form-check">
                          <input
                            [value]="0"
                            [(ngModel)]="categoryToBeMenaged.alignment"
                            type="radio"
                            class="form-check-input"
                            id="orientamento"
                            name="orientamento"
                          />
                          <label for="form-check-label">{{
                            "Sinistra" | translate
                          }}</label>
                        </div>
                        <div class="form-check">
                          <input
                            [value]="1"
                            [(ngModel)]="categoryToBeMenaged.alignment"
                            type="radio"
                            class="form-check-input"
                            id="orientamento"
                            name="orientamento"
                          />
                          <label for="form-check-label">{{
                            "Destra" | translate
                          }}</label>
                        </div>
                      </div>
                      <div class="col-6 p-0">
                        <div class="form-check">
                          <input
                            [value]="2"
                            [(ngModel)]="categoryToBeMenaged.alignment"
                            type="radio"
                            class="form-check-input"
                            id="orientamento"
                            name="orientamento"
                          />
                          <label for="form-check-label">{{
                            "Sopra" | translate
                          }}</label>
                        </div>
                        <div class="form-check">
                          <input
                            [value]="3"
                            [(ngModel)]="categoryToBeMenaged.alignment"
                            type="radio"
                            class="form-check-input"
                            id="orientamento"
                            name="orientamento"
                          />
                          <label for="form-check-label">{{
                            "Sotto" | translate
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- attento 2 -->

              <!-- <div>
                <div>
                  <label>Icona</label>
                  <div class="form-check">
                    <input
                      [value]="true"
                      [(ngModel)]="standardIcon"
                      (click)="iconToLoad = undefined"
                      type="radio"
                      class="form-check-input"
                      id="standardIcon"
                      name="standardIcon"
                    />
                    <label for="form-check-label">{{ "Usa Icona di default" | translate }}</label>
                  </div>
                  <div class="form-check">
                    <input
                      [value]="false"
                      [(ngModel)]="standardIcon"
                      (click)="iconToLoad = undefined"
                      type="radio"
                      class="form-check-input"
                      id="standardIcon"
                      name="standardIcon"
                    />
                    <label for="form-check-label">{{ "Carica Icona" | translate }}</label>
                  </div>
                </div>
              </div>
              <div class="form-group">

                <div *ngIf="standardIcon">
                  <div *ngIf="iconToLoad" class="mb-3">
                    <img
                      class="backGroundImg"
                      [src]="
                        _DomSanitizationService.bypassSecurityTrustUrl(
                          'data:image/svg+xml;base64,' + iconToLoad
                        )
                      "
                      style="width: 100px"
                      alt=""
                    />
                  </div>
                  <div style="display: flex; flex-direction: row">
                    <button
                      data-toggle="modal"
                      data-target="#iconsModal"
                      class="btn btn-secondary"
                    >
                      {{ "Scegli un icona" | translate }}
                    </button>
                    <button
                      [disabled]="!iconToLoad"
                      type="button"
                      (click)="
                        iconToLoad = undefined;
                        categoryToBeMenaged.icon = undefined;
                        standardIcon = undefined
                      "
                      class="btn btn-danger ml-2"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>
                </div>
                <div *ngIf="standardIcon == false">
                  <div *ngIf="iconToLoad" class="mb-3">
                    <img
                      class="backGroundImg"
                      [src]="
                        _DomSanitizationService.bypassSecurityTrustUrl(
                          'data:image/svg+xml;base64,' + iconToLoad
                        )
                      "
                      style="width: 100px"
                      alt=""
                    />
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <input
                      type="file"
                      accept=".svg"
                      (change)="encodeImageFileAsURL($event.target.files, 3)"
                    />
                    <button
                      type="button"
                      (click)="
                        iconToLoad = undefined;
                        categoryToBeMenaged.icon = undefined;
                        standardIcon = undefined
                      "
                      class="btn btn-danger ml-2"
                    >
                      {{ "Elimina" | translate }}
                    </button>
                  </div>
                </div>
              </div> -->

              <!-- fine attento 2 -->

              <div *ngIf="!isVirtualCategory" class="form-group">
                <label for="disablebooking">{{
                  "Questa categoria prevede limitazione di disponibilità in base al periodo e alle prenotazioni"
                    | translate
                }}</label>
                <div>
                  <div>
                    <div class="form-check">
                      <input
                        [value]="false"
                        [(ngModel)]="categoryToBeMenaged.disableBooking"
                        type="radio"
                        class="form-check-input"
                        id="disablebooking"
                        name="disablebooking"
                      />
                      <label for="form-check-label">{{
                        "Categoria con Calendario" | translate
                      }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="true"
                        [(ngModel)]="categoryToBeMenaged.disableBooking"
                        type="radio"
                        class="form-check-input"
                        id="disablebooking"
                        name="disablebooking"
                      />
                      <label for="form-check-label">{{
                        "Categoria senza Calendario" | translate
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="scroll">{{
                  "Orientamento sottocategorie e servizi widget tv"
                    | translate
                  }}</label>
                <div>
                  <div>
                    <div class="form-check">
                      <input
                        [value]="0"
                        [(ngModel)]="categoryToBeMenaged.scroll"
                        type="radio"
                        class="form-check-input"
                        id="scroll"
                        name="scroll"
                      />
                      <label for="form-check-label">{{
                        "A Scorrimento Orizzontale" | translate
                        }}</label>
                    </div>
                    <div class="form-check">
                      <input
                        [value]="1"
                        [(ngModel)]="categoryToBeMenaged.scroll"
                        type="radio"
                        class="form-check-input"
                        id="scroll"
                        name="scroll"
                      />
                      <label for="form-check-label">{{
                        "A Lista" | translate
                        }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" *ngIf="isVirtualCategory">
                <div></div>
                <label for="disablebooking">{{
                  "Selezionare la categoria da collegare" | translate
                }}</label>
                <div *ngIf="virtualCategory.categoryPosition">
                  {{ virtualCategory.categoryPosition }}
                </div>
                <div>
                  <div class="d-flex">
                    <div class="form-group col-6">
                      <label for="Fornitore">{{
                        "Fornitore" | translate
                      }}</label>
                      <select
                        class="form-control"
                        [(ngModel)]="virtualCategory.parentaccount"
                        name="Fornitore"
                        (change)="
                          getOthersCategoryTree(virtualCategory.parentaccount)
                        "
                      >
                        <option
                          *ngFor="let element of vendorList"
                          [value]="element.account"
                        >
                          {{ element.account }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-6">
                      <label for="categoryVirtual">{{
                        "Categoria" | translate
                      }}</label>
                      <select
                        class="form-control"
                        [(ngModel)]="virtualCategory.parentcategory"
                        name="categoryVirtual"
                        (change)="showPosition()"
                      >
                        <option
                          *ngFor="let element of otherCategoryTree"
                          [value]="element.id"
                        >
                          {{ element.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isVirtualCategory">
                <div class="d-flex">
                  <div class="form-group col-6">
                    <label for="title">{{
                      "Fee per ordine" | translate
                    }}</label>
                    <br />
                    <span>{{
                      "Fee per ogni prenotazione effettuata" | translate
                    }}</span>
                    <input
                      [(ngModel)]="virtualCategory.parentfee"
                      type="number"
                      class="form-control"
                      id="fee"
                      name="fee"
                      placeholder="Inserici la fee"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="taxType">{{ "Tipo di fee" | translate }}</label>
                    <br />
                    <select
                      class="form-control"
                      [(ngModel)]="virtualCategory.isdiscount"
                      name="taxType"
                      (change)="showPosition()"
                    >
                      <option [ngValue]="false">
                        {{ "Prezzo fisso" | translate }}
                      </option>
                      <option [ngValue]="true">
                        {{ "Percentuale" | translate }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="p-3">
          <div *ngIf="!menagingCategories && detailsCategory" class="tools">
            <button
              data-toggle="modal"
              data-target="#languageModal"
              class="pl-4 pr-4 m-2 btn btn-secondary"
              style="font-size: 12px"
              (click)="clearAndSelectTypeLanguages(1)"
              [disabled]="!isPartnerSelected ? navigate.length >= 5 : navigate.length >= partnerCounter"
            >
              + {{ "Aggiungi una categoria" | translate }}
            </button>
            <button
              data-toggle="modal"
              [attr.data-target]="menagingCategories ? '' : '#languageModal'"
              *ngIf="admin"
              style="
                font-size: 12px;
                background-color: #e18335;
                border-color: #e18335;
              "
              type="button"
              class="pl-4 pr-4 m-2 btn btn-secondary"
              (click)="clearAndSelectTypeLanguages(1, true)"
              [disabled]="!isPartnerSelected ? navigate.length >= 5 : navigate.length >= partnerCounter"
            >
              + {{ "Aggiungi una categoria partner" | translate }}
            </button>
            <button
              data-toggle="modal"
              data-target="#languageModal"
              class="pl-4 pr-4 m-2 btn btn-secondary"
              style="font-size: 12px"
              (click)="clearAndSelectTypeLanguages(2)"
            >
              + {{ "Aggiungi un servizio" | translate }}
            </button>
          </div>
          <div *ngIf="menagingCategories" class="tools">
            <button
              class="pl-4 pr-4 m-2 btn btn-primary"
              style="font-size: 12px"
              [disabled]="disableSaveButton"
              (click)="saveCategory()"
            >
              {{ "Salva" | translate }}
            </button>
            <button
              class="pl-4 pr-4 btn btn-primary"
              style="font-size: 12px"
              (click)="cancelEditCategory()"
            >
              {{ "Annulla" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
