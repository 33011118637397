import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Header } from 'src/app/models/signage/signage';

@Component({
  selector: 'app-header-footer',
  templateUrl: './header-footer.component.html',
  styleUrls: ['./header-footer.component.scss'],
})
export class HeaderFooterComponent implements OnInit {
  @Input() data: Header;
  @Input() layout: string;
  @Input() isMedia: boolean;
  @Input() iconToLoad: string;
  months: string[] = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];
  minutes: number;
  hours: number;
  date: string;
  dateStarted: boolean = false;
  dateUpdated: boolean = false;
  NslideTIme: number = 20;

  constructor(public _DomSanitizationService: DomSanitizer) {}

  ngOnInit(): void {
    this.setDate(this);
  }

  setDate(instance: any) {
    const date = new Date();
    const h = date.getHours();
    const m = date.getMinutes();

    if (this.hours != h || this.minutes != m) {
      this.hours = h;
      this.minutes = m;

      if ((this.hours == 0 && this.minutes == 0) || !this.dateStarted) {
        if (!this.dateUpdated) {
          this.dateUpdated = true;
          if (!this.dateStarted) this.dateStarted = true;
          this.date = `${date.getDate()} ${
            this.months[date.getMonth()]
          } ${date.getFullYear()}`;
        }
      } else {
        this.dateUpdated = false;
      }
    }

    setTimeout(() => {
      instance.setDate(instance);
    }, 1000);
  }

  getTimeFormattedValue(val: number): string {
    if (val < 10) return `0${val}`;
    else return val.toString();
  }

  getRoundedWeather(): number {
    // return Math.round(this.data.weather.current.temp);
    return 20;
  }

  getScrollTime(): string {
    return `${
      (this.NslideTIme * this.data.ticker.length) / 100 + this.NslideTIme
    }s`;
  }

  isOneContent(): boolean {
    let i = 0;

    if (this.data.logo) i++;
    if (this.data.weatherforecast) i++;
    if (this.data.date || this.data.time) i++;

    if (i > 1) return false;
    else return true;
  }

  toReverse() {
    return (
      !this.data.logo &&
      this.data.weatherforecast &&
      (this.data.date || this.data.time)
    );
  }

  getContentHeight(): string {
    if (this.layout == 'Horizontal' && this.data.ticker) {
      return '75%';
    } else if (this.layout == 'Vertical') {
      return 'auto';
    }
  }

  getTickerHeight(): string {
    if (this.layout == 'Horizontal') {
      return '25%';
    } else if (this.layout == 'Vertical') {
      return 'auto';
    }
  }
}
