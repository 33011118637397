import { Language } from '../languages/language';

export class Clone {
  account?: string = '';
  id?: string = '';
  name: string = '';
  file: File = undefined;
}

export class Channel {
  account?: string = '';
  id?: string = '';
  name: string = '';
  languages: string[] = [];
  defaultlanguage: string = '';
  details: ChannelDetail[] = [];
  file: File = undefined;
  categoriesFile?: File = undefined;
}

export class ChannelDetail {
  id: number = undefined;
  major: number = undefined;
  name: string = '';
  broadcastStandard: string = '';
  channelType: string = '';
  programNumber: string = '';
  frequency: number = undefined;
  modulationType: string = '';
  bandwidth: string = '';
  symbolRate: number = undefined;
  ip: string = '';
  polarization: string = '';
  satelliteId: number = undefined;
  language: string = '';
}

export class Frimware {
  id?: string = '';
  name: string = '';
  link: string = '';
}

export class TvSettings {
  id?: string = '';
  account?: string = '';
  applications: Applications = new Applications();
  defaultlanguage: string = '';
  isliteproject: boolean = false;
  languages: Array<string> = [];
  locationsettings: string = '';
  name: string = '';
  projectcolors: string = '';
  welcomeproject: string = '';
  imageType?: number = 1;
  primarycolor: string = '';
  secondarycolor: string = '';
  textprimarycolor: string = '';
  textsecondarycolor: string = '';
  texttertiarycolor: string = '';
  texttertiarycolorselected: string = '';
  newchromecast: boolean = false;
  bannerimage: string = '';
  bannerfileImage: string = '';
  bannerImageDetail: string = '';
}

export class Applications {
  chromecast: boolean = false;
  hdmichromecastnumber: string = '';
  sky: boolean = false;
  typesky: number = 0;
  hdmisky: string = '';
  channelsky: string = '';
  wifi: boolean = false;
  browser: boolean = false;
  youtube: boolean = false;
  airtime: boolean = false;
  additionalsettings: string = '';
}


export class Tv {
  account?: string = '';
  id?: string = '';
  roomnumber: string = '';
  tvlocation: string = '';
  macaddress: string = '';
  model: string = '';
  serial: string = '';
  channelid: string = '';
  cloneid: string = '';
  firmwareid: string = '';
  settingid: string = '';
  checkin?: string = '';
  checkout?: string = '';
  nameguest?: '';
  surnameguest?: '';
  message?: '';
  visible?: boolean = false
}


