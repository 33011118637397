import { AuthService } from 'src/app/services/auth/auth.service';
import { ChangePwdObj } from 'src/app/models/account/account';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TYPOGRAPHY } from 'src/app/constants/typography.enum';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Output('login') login: EventEmitter<any> = new EventEmitter();
  @Input() oldPassword;
  @Input() account;
  changePwdObj: ChangePwdObj = new ChangePwdObj();
  confirmNewPwd = '';
  TYPOGRAPHY = TYPOGRAPHY;
  changePasswordForm: FormGroup;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      newpassword: new FormControl(this.changePwdObj.newpwd, [
        Validators.required,
        Validators.pattern(/^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/)
      ]),
      confirmnewpassword: new FormControl(this.confirmNewPwd, [
        Validators.required,
        Validators.pattern(/^(?=[a-zA-Z]+)(?=.*[A-Z])(?=.*\d)(?=.*[.\-_@$!%*?&])[A-Za-z\d@.\-_$!%*?&]{8,}$/)
      ]),
    });
  }

  changePassword() {
    if (this.changePasswordForm.valid && (this.changePasswordForm.controls['newpassword'].value === this.changePasswordForm.controls['confirmnewpassword'].value)) {
      this.changePwdObj.oldpwd = this.oldPassword;
      this.changePwdObj.newpwd = this.changePasswordForm.controls['newpassword'].value;
      this.changePwdObj.email = this.account;
      this.authService.changePassword(this.changePwdObj).subscribe(() => {
        this.login.emit(this.changePwdObj.newpwd);
      });
    } else {
      alert('Le password non corrispondono');
    }
  }
}
