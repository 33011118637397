<div class="container pt-5">
  <div>
    <div class="section-title mb-3">{{ "Impostazioni base" | translate }}</div>
    <div class="section-content">
      <div class="element">
        <div class="col-12 p-0 form-group">
          <label for="title">{{ "Nome" | translate }}</label>
          <input
            [(ngModel)]="project.name"
            type="text"
            class="form-control"
            id="title"
            name="title"
            placeholder="{{ 'Inserici il nome del progetto' | translate }}"
          />
        </div>
      </div>
      <div class="element">
        <div class="col-12 p-0 form-group">
          <label for="exampleFormControlSelect1">Layout</label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            [(ngModel)]="project.layout"
          >
            <option [value]="'Vertical'">{{ "Verticale" | translate }}</option>
            <option [value]="'Horizontal'">
              {{ "Orizzontale" | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="element">
        <div class="col-12 p-0 form-group">
          <label for="exampleFormControlSelect1">{{
            "Tema" | translate
          }}</label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            [(ngModel)]="project.theme"
          >
            <option [value]="'Light'">{{ "Chiaro" | translate }}</option>
            <option [value]="'Dark'">{{ "Scuro" | translate }}</option>
          </select>
        </div>
      </div>
      <div class="element">
        <div class="col-12 p-0 form-group">
          <label for="positionHeadertle">{{ "Località" | translate }}</label>
          <div>
            <span>{{
              "Inserire la localita per le previsioni meteo." | translate
            }}</span>
            <br />
            <span>{{
              "In caso la località fosse remota inserire 'nome località: latitudine, longitudine'"
                | translate
            }}</span>
            <input
              [(ngModel)]="project.header.position"
              (change)="
                checkValues(); project.footer.position = project.header.position
              "
              type="text"
              class="form-control"
              id="positionHeader"
              name="positionHeader"
              placeholder="{{
                'Es: Milano oppure Milano: 45.4642, 9.1900' | translate
              }}"
            />
            <small id="errorPositionHeader" class="text-danger d-none">{{
              "È necessario inserire una località" | translate
            }}</small>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- INIZIO -->
      <!-- 

            horizontal

           -->
      <div
        class="render-box-h render-box"
        *ngIf="project.layout == 'Horizontal'"
        [ngClass]="{
          light: project.theme == 'Light',
          dark: project.theme == 'Dark'
        }"
      >
        <div class="w-45">
          <div class="media-container h-100 media-cut">
            <img
              src="assets/img/demoImageSignage.png"
              alt=""
              class="img-stretch"
            />
          </div>
        </div>
        <div class="w-55">
          <div
            class="header-h d-flex justify-content-center align-items-center"
          >
            <div class="text-center">HEADER</div>
          </div>

          <div
            class="d-flex justify-content-end flex-column"
            style="height: 68%"
          >
            <div class="texttitle-h">Lorem ipsum</div>
            <div
              class="h-100 overflow-hidden description-box description-box-h"
            >
              <div class="text-h">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Distinctio corrupti, illo nostrum, tenetur maiores architecto
                dolores atque quas odit molestiae repudiandae officia
                consectetur unde recusandae suscipit ab, ex labore deleniti?
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque,
                provident quod atque ad, velit tenetur soluta ullam, doloremque
                necessitatibus modi id! Consectetur assumenda impedit aut labore
                quis, quia ad sed.
              </div>
            </div>
          </div>
          <div
            class="footer-h d-flex justify-content-center align-items-center"
          >
            <div class="text-center">FOOTER</div>
          </div>
        </div>
      </div>
      <!-- 

            vertical

           -->
      <div
        class="render-box-v render-box"
        *ngIf="project.layout == 'Vertical'"
        [ngClass]="{
          light: project.theme == 'Light',
          dark: project.theme == 'Dark'
        }"
      >
        <div class="d-flex flex-column h-100">
          <div
            class="header-v d-flex justify-content-center align-items-center"
          >
            <div class="text-center">HEADER</div>
          </div>
          <div class="content" style="height: 80%">
            <div class="media-container v-50 media-cut">
              <img src="assets/img/demoImageSignage.png" class="img-stretch" />
            </div>
            <div class="text v-50">
              <div class="h-100 d-flex justify-content-end flex-column">
                <div class="texttitle-v">Lorem ipsum</div>
                <div class="h-100 overflow-hidden description-box">
                  <div class="text-v">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. A,
                    eius et. Soluta voluptates velit aspernatur rerum alias.
                    Nesciunt recusandae excepturi eius, iure amet veniam
                    perferendis, fugiat corrupti cum molestias fugit? Lorem
                    ipsum dolor sit amet consectetur adipisicing elit. Pariatur
                    tempore error molestias et iure quod animi necessitatibus
                    nostrum quasi id est illo debitis autem, quidem ipsa minus
                    iusto non in?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="footer-v d-flex justify-content-center align-items-center"
          >
            <div class="text-center">FOOTER</div>
          </div>
        </div>
      </div>
      <!-- FINE -->
    </div>
  </div>
  <hr />
  <div>
    <div class="section-title">Header</div>
    <div class="section-content">
      <div class="element">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Data" | translate }}</label>
            <div>
              <span>
                {{ "Selezionare se mostrare la data" | translate }}
              </span>

              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="project.header.date"
                  type="radio"
                  class="form-check-input"
                  id="isdate1"
                  name="isdate1"
                />
                <label for="isdate1">{{ "Disattivata" | translate }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="project.header.date"
                  type="radio"
                  class="form-check-input"
                  id="isdate2"
                  name="isdate2"
                />
                <label for="isdate2">{{ "Attivata" | translate }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="element">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Orario" | translate }}</label>
            <div>
              <span>
                {{ "Selezionare se mostrare l'orario" | translate }}
              </span>

              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="project.header.time"
                  type="radio"
                  class="form-check-input"
                  id="istime1"
                  name="istime1"
                />
                <label for="istime1">{{ "Disattivato" | translate }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="project.header.time"
                  type="radio"
                  class="form-check-input"
                  id="istime2"
                  name="istime2"
                />
                <label for="istime2">{{ "Attivato" | translate }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="element">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Previsioni del tempo" | translate }}</label>
            <div>
              <span>
                {{
                  "Selezionare se mostrare le previsioni del tempo" | translate
                }}
              </span>

              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="project.header.weatherforecast"
                  (change)="checkValues()"
                  type="radio"
                  class="form-check-input"
                  id="isweatherforecast1"
                  name="isweatherforecast1"
                />
                <label for="isweatherforecast1">{{
                  "Disattivate" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="project.header.weatherforecast"
                  (change)="checkValues()"
                  type="radio"
                  class="form-check-input"
                  id="sweatherforecast2"
                  name="sweatherforecast2"
                />
                <label for="sweatherforecast2">{{
                  "Attivate" | translate
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="element">
        <div class="col-12 p-0 form-group">
          <label for="tickerHeader">{{ "Ticker" | translate }}</label>
          <div>
            <span>{{
              "Testo informativo da mostrare a scorrimento" | translate
            }}</span>
            <input
              [(ngModel)]="project.header.ticker"
              type="text"
              class="form-control"
              id="tickerHeader"
              name="tickerHeader"
              placeholder="{{ 'Inserici testo' | translate }}"
            />
          </div>
        </div>
      </div>

      <div class="element">
        <div class="col-12 p-0 form-group">
          <label for="inputGroupFile01">{{ "Logo" | translate }}</label>
          <br />
          <span>{{
            "Scegli un'immagine in formato png di massimo 50Mb" | translate
          }}</span>
          <div class="d-flex">
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
                accept=".png"
                (change)="encodeImageFileAsURL($event.target.files, 3)"
              />
              <label class="custom-file-label" for="inputGroupFile01">{{
                "Scegli un file" | translate
              }}</label>
            </div>
            <button
              [disabled]="!iconToLoad"
              type="button"
              (click)="deleteImage(false)"
              class="btn btn-danger ml-2"
            >
              {{ "Elimina" | translate }}
            </button>
          </div>

          <div *ngIf="iconToLoad" class="mt-3">
            <img
              class="backGroundImg"
              [src]="
                _DomSanitizationService.bypassSecurityTrustUrl(
                  'data:image/png;base64,' + iconToLoad
                )
              "
              style="width: 100px"
              alt=""
            />
          </div>
        </div>
        <div
          class="example-header-footer"
          [ngClass]="{
            light: project.theme == 'Light',
            dark: project.theme == 'Dark'
          }"
        >
          <app-header-footer
            [data]="project.header"
            [layout]="'Vertical'"
            [isMedia]="false"
            [iconToLoad]="iconToLoad"
          ></app-header-footer>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div>
    <div class="section-title">Footer</div>
    <div class="section-content">
      <div class="element">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Data" | translate }}</label>
            <div>
              <span>
                {{ "Selezionare se mostrare la data" | translate }}
              </span>

              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="project.footer.date"
                  type="radio"
                  class="form-check-input"
                  id="isdatefooter1"
                  name="isdatefooter1"
                />
                <label for="isdatefooter1">{{
                  "Disattivata" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="project.footer.date"
                  type="radio"
                  class="form-check-input"
                  id="isdatefooter2"
                  name="isdatefooter2"
                />
                <label for="isdatefooter2">{{ "Attivata" | translate }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="element">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Orario" | translate }}</label>
            <div>
              <span>
                {{ "Selezionare se mostrare l'orario" | translate }}
              </span>

              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="project.footer.time"
                  type="radio"
                  class="form-check-input"
                  id="istimefooter1"
                  name="istimefooter1"
                />
                <label for="istimefooter1">{{
                  "Disattivato" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="project.footer.time"
                  type="radio"
                  class="form-check-input"
                  id="istimefooter2"
                  name="istimefooter2"
                />
                <label for="istimefooter2">{{ "Attivato" | translate }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="element">
        <div class="col-12 p-0 form-group">
          <div>
            <label for="">{{ "Previsioni del tempo" | translate }}</label>
            <div>
              <span>
                {{
                  "Selezionare se mostrare le previsioni del tempo" | translate
                }}
              </span>

              <div class="form-check">
                <input
                  [value]="false"
                  [(ngModel)]="project.footer.weatherforecast"
                  (change)="checkValues()"
                  type="radio"
                  class="form-check-input"
                  id="isweatherforecastfooter1"
                  name="isweatherforecastfooter1"
                />
                <label for="isweatherforecastfooter1">{{
                  "Disattivate" | translate
                }}</label>
              </div>
              <div class="form-check">
                <input
                  [value]="true"
                  [(ngModel)]="project.footer.weatherforecast"
                  (change)="checkValues()"
                  type="radio"
                  class="form-check-input"
                  id="sweatherforecastfooter2"
                  name="sweatherforecastfooter2"
                />
                <label for="sweatherforecastfooter2">{{
                  "Attivate" | translate
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="element">
        <div class="col-12 p-0 form-group">
          <label for="tickerFooter">{{ "Ticker" | translate }}</label>
          <div>
            <span>{{
              "Testo informativo da mostrare a scorrimento" | translate
            }}</span>
            <input
              [(ngModel)]="project.footer.ticker"
              type="text"
              class="form-control"
              id="tickerFooter"
              name="tickerFooter"
              placeholder="{{ 'Inserici testo' | translate }}"
            />
          </div>
        </div>
      </div>

      <div class="element">
        <div class="col-12 p-0 form-group">
          <label for="inputGroupFile01">{{ "Logo" | translate }}</label>
          <br />
          <span>{{
            "Scegli un'immagine in formato png di massimo 50Mb" | translate
          }}</span>
          <div class="d-flex">
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFilefooter01"
                aria-describedby="inputGroupFileAddonfooter01"
                accept=".png"
                (change)="
                  encodeImageFileAsURL($event.target.files, 3, null, true)
                "
              />
              <label class="custom-file-label" for="inputGroupFilefooter01">{{
                "Scegli un file" | translate
              }}</label>
            </div>
            <button
              [disabled]="!iconToLoadfooter"
              type="button"
              (click)="deleteImage(true)"
              class="btn btn-danger ml-2"
            >
              {{ "Elimina" | translate }}
            </button>
          </div>
          <div *ngIf="iconToLoadfooter" class="mt-3">
            <img
              class="backGroundImg"
              [src]="
                _DomSanitizationService.bypassSecurityTrustUrl(
                  'data:image/png;base64,' + iconToLoadfooter
                )
              "
              style="width: 100px"
              alt=""
            />
          </div>
        </div>
        <div
          class="example-header-footer"
          [ngClass]="{
            light: project.theme == 'Light',
            dark: project.theme == 'Dark'
          }"
        >
          <app-header-footer
            [data]="project.footer"
            [layout]="'Vertical'"
            [isMedia]="false"
            [iconToLoad]="iconToLoad"
          ></app-header-footer>
        </div>
      </div>

      <!-- <div class="element">
        <div class="col-12 p-0 form-group">
          <label for="positionFooter">{{ "Località" | translate }}</label>
          <div>
            <span>{{
              "Inserire la localita per data, orario e previsioni meteo."
                | translate
            }}</span>
            <br />
            <span>{{
              "In caso la località fosse remota inserire 'nome località: latitudine, longitudine'"
                | translate
            }}</span>
            <input
              [(ngModel)]="project.footer.position"
              (change)="checkValues()"
              type="text"
              class="form-control"
              id="positionFooter"
              name="positionFooter"
              placeholder="{{
                'Es: Milano oppure Milano: 45.4642, 9.1900' | translate
              }}"
            />
            <small id="errorPositionFooter" class="text-danger d-none">{{
              "È necessario inserire una località" | translate
            }}</small>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <hr />
  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-secondary m-2" (click)="cancel()">
      {{ "Annulla" | translate }}
    </button>
    <button type="button" class="btn btn-primary m-2" (click)="saveProject()">
      {{ "Salva" | translate }}
    </button>
  </div>
</div>
