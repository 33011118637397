import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guests-functionalities',
  templateUrl: './guests-functionalities.component.html',
  styleUrls: ['./guests-functionalities.component.scss']
})
export class GuestsFunctionalitiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
