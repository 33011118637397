
  <div class="container-category-selection">

    <div>
      <a class="btn btn-link" (click)="goBack()">
        << {{ "Torna ai progetti" | translate }}
      </a>
    </div>

    <div class="project-name mb-2">
      <label class="mr-3" for="projectName"> {{ "Dai il nome del tuo progetto" | translate }}</label>
      <input [(ngModel)]="projectName" type="text" name="projectName">
    </div>
    <div class="project-name mb-2">
      <label  class="mr-3 align-top" for="projectDescr">{{ "Descrizione" | translate}}</label>
      <textarea id="projectDescr" [(ngModel)]="projectDescr" name="projectDescr" rows="1" cols="50"></textarea>
    </div>
    <div class="row">
      <div class="col p-1 border border-dark">
        <div *ngFor="let category of categories?.categories" class="mb-1 d-flex flex-column border border-secondary">
          <ng-container
            [ngTemplateOutlet]="categoryTemplate"
            [ngTemplateOutletContext]="{ $implicit: {category: category, level: 0} }">
          </ng-container>
        </div>
      </div>
      <div class="col p-1 border border-dark">
        <ng-container *ngIf="subcategories">
          <div *ngFor="let category of subcategories[0]" class="mb-1 d-flex flex-column border border-secondary">
            <ng-container
              [ngTemplateOutlet]="categoryTemplate"
              [ngTemplateOutletContext]="{ $implicit: {category: category, level: 1} }">
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="col p-1 border border-dark">
        <ng-container *ngIf="subcategories">
          <div *ngFor="let category of subcategories[1]" class="mb-1 d-flex flex-column border border-secondary">
            <ng-container
              [ngTemplateOutlet]="categoryTemplate"
              [ngTemplateOutletContext]="{ $implicit: {category: category, level: 2} }">
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="col p-1 border border-dark">
        <ng-container *ngIf="subcategories">
          <div *ngFor="let category of subcategories[2]" class="mb-1 d-flex flex-column border border-secondary">
            <ng-container
              [ngTemplateOutlet]="categoryTemplate"
              [ngTemplateOutletContext]="{ $implicit: {category: category, level: 3} }">
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="col p-1 border border-dark">
        <ng-container *ngIf="subcategories">
          <div *ngFor="let category of subcategories[3]" class="mb-1 d-flex flex-column border border-secondary">
            <ng-container
              [ngTemplateOutlet]="categoryTemplate"
              [ngTemplateOutletContext]="{ $implicit: {category: category, level: 4} }">
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="buttons d-flex justify-content-end mt-2">
      <!-- <button class="btn btn-primary mr-2" (click)="downloadSelectCategories()"> {{ "scarica" | translate}} </button> -->
      <button class="btn btn-primary" (click)="saveProject()"> {{ "Salva" | translate}} </button>
    </div>
  </div>

<ng-template #categoryTemplate let-item>
    <div class="d-flex justify-content-between" >
      <div class="d-flex align-items-center element-style w-100" (click)="selectItem(item.category, item.level)">
        <span
          class="type d-flex align-items-center"
          [ngClass]="{'selected': item.category.isSelected }">
          {{ item.category.type ? ("Servizio" | translate) : ("Categoria" | translate) }}
        </span>
        <span class="category ml-2">{{ item.category.title.it }}</span>
      </div>
      <div class="d-flex align-items-center mx-2">
        <input type="checkbox" [checked]="item.category.isDownloadable" (change)="checkValue($event.target.checked, item.category)">
      </div>
    </div>
</ng-template>
