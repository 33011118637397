export class AdminVendor {
  email: string;
  password: string;
  account: string;
  successMessage: string;
  referrername: string;
  referrerlastname: string;
  factoryname: string;
  piva: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  banner: string;
  billingcode: string;
  accepttermpolicy: boolean;
  acceptdatapolicy: boolean;

  constructor(admin: boolean) {
    if (admin) {
      this.referrername = '';
      this.referrerlastname = '';
      this.factoryname = '';
      this.piva = '';
      this.address = '';
      this.city = '';
      this.state = '';
      this.zipcode = '';
      this.country = '';
      this.banner = '';
      this.billingcode = '';
      this.accepttermpolicy = true;
      this.acceptdatapolicy = true;
    }
  }
}
