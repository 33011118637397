<div
  *ngIf="spinner"
  class="spinner-container d-flex justify-content-center align-items-center"
>
  <div>
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
