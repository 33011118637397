import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  spinner: boolean = false;

  constructor() {}

  toggleSpinner() {
    this.spinner = !this.spinner;
  }
  showSpinner() {
    this.spinner = true;
  }
  hideSpinner() {
    this.spinner = false;
  }
}
