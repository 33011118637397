import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountDetailService {
  public changeAccount: BehaviorSubject<string>;

  constructor() {
    this.changeAccount = new BehaviorSubject<string>(undefined);
  }

  getAccount(): string {
    return this.changeAccount.value;
  }

  setAccount(account: string): void {
    this.changeAccount.next(account);
  }

  haveAccount(): boolean {
    return this.changeAccount.value && this.changeAccount.value.length > 0;
  }
}
