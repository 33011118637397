export class Reservation {
  serviceid = '';
  account = '';
  servicedate = '';
  serviceenddate? = '';
  servicetime = '';
  decreasequantity = false;
  name = '';
  lastname = '';
  email = '';
  reservationdate = '';
  reservationtime = '';
  transactionId = '';
  quantity = 1;
  reservationid = '';
  labelinfos: Array<LabelInfo> = [];
  isfrompartner = false;
  partner = '';
  note: string = '';
}

export class LabelInfo {
  labelid = '';
  message = '';
  name?: any;
  title?: any;
}
