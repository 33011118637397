export class Project {
  id?: string;
  account: string;
  name: string;
  layout: string = 'Vertical';
  theme: string = 'Light';
  header: Header = new Header();
  footer: Header = new Header();
  pages?: Array<Page> = [];
}

export class Header {
  date: boolean = false;
  time: boolean = false;
  weatherforecast: boolean = false;
  ticker: string;
  logo: string;
  position: string;
  weather?: any;
}

export class Page {
  id?: string;
  visible: boolean = true;
  media: Media = new Media();
  title: string = '';
  text: string = '';
  timetype: number = 1;
  time: number = 0;
  header: boolean = false;
  footer: boolean = false;
  categoryid: string = '';
  serviceid: string = '';
  category?;
  service?;
  timeforimage: number = 0;
  index: number;
  starttime: string = '';
  endtime: string = '';
  startdate: string = '';
  enddate: string = '';
  texttitle: string = '';
  overwritetext: false;
  overwritemedia: false;
}

class Media {
  type: number = 0;
  data: string = '';
  layout: string = 'Cut';
  imagetype: number = 0;
  videorestart: boolean = false;
}
