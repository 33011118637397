import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Channel, ChannelDetail } from 'src/app/models/tv/tv';
import { TvService } from 'src/app/services/tv/tv.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, ValueParserParams } from 'ag-grid-community';
import { Language } from '../../../models/languages/language';
import { CategoriesService } from '../../../services/categories/categories.service';
import { DeleteButtonRendererComponent } from '../../../components/delete-button-renderer/delete-button-renderer';
import { BANDWIDTH, BROADCAST, CHANNELTYPE, MODULATION, POLARIZATION, SATELLITE } from '../../../constants/channel-detail-constant';
import { element } from 'protractor';
import { ExportExcelService } from 'src/app/services/utility/export-excel.service';

@Component({
  selector: 'app-tv-channels-details',
  templateUrl: './tv-channels-details.component.html',
  styleUrls: ['./tv-channels-details.component.scss'],
})
export class TvChannelsDetailsComponent implements OnInit {
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  BROADCAST = BROADCAST;
  CHANNELTYPE = CHANNELTYPE;
  BANDWIDTH = BANDWIDTH;
  MODULATION = MODULATION;
  SATELLITE = SATELLITE;
  POLARIZATION = POLARIZATION;

  id: string;
  channel: Channel = new Channel();
  newChannelDetail: ChannelDetail = new ChannelDetail();
  selectedElement;
  arraySelectedLanguages: any = [];
  selectedLanguage: string;
  languages: Array<Language> = [];
  columnWidth = {
    major: 55,
    name: 180,
    broadcastStandard: 82,
    channelType: 56,
    programNumber: 90,
    frequency: 86,
    modulationType: 102,
    bandwidth: 87,
    symbolRate: 70,
    url: 50,
    polarization: 113,
    satelliteId: 118,
  };
  initialPosition = { mouse: 0, element: 0, type: '' };
  mouse;
  resizing = false;
  selectedChannels = [];
  columnDefs: ColDef[] = [
    {
      field: 'major',
      headerName: 'Major',
      rowDrag: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 117,
      valueParser: this.numberParser
    },
    { field: 'name', headerName: 'Nome', width: 196 },
    {
      field: 'broadcastStandard',
      headerName: 'Broadcast',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [undefined, 'ATSC', 'DVB', 'ISDB'],
      },
      width: 99,
    },
    {
      field: 'channelType',
      headerName: 'Canale',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [undefined, 'DTV', 'SDTV', 'CDTV', 'IPTV'],
      },
      width: 77,
    },
    {
      field: 'programNumber', headerName: 'Programma', width: 112,
      valueParser: this.numberParser
    },
    {
      field: 'frequency', headerName: 'Frequenza', width: 99,
      valueParser: this.numberParser
    },
    {
      field: 'modulationType',
      headerName: 'Modulazione',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [
          undefined,
          'UNKNOWN',
          'QAM16',
          'QAM64',
          'QAM128',
          'QAM256',
          'VSB8',
          'VSB16',
          'NTSC',
          'QPSK',
          'PAL',
          'DMBT',
          'OFDM',
          'ISDB_T',
          'ISDB_S',
          'GB_S',
          'GB_M',
          'QAM32',
          '8PSK',
          '16APSK',
          '32APSK',
          'OFDM',
          'ATSC3',
          'AUTO_QAM',
        ],
      },
      width: 116,
    },
    {
      field: 'bandwidth',
      headerName: 'Bandwidth',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [undefined, '6Mhz', '7Mhz', '8Mhz'],
      },
      width: 103,
    },
    {
      field: 'symbolRate', headerName: 'SymbolRate', width: 110,
      valueParser: this.numberParser
    },
    { field: 'ip', headerName: 'Ip', width: 139 },
    {
      field: 'polarization',
      headerName: 'Polarizzazione',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [undefined, 'POL_VR', 'POL_HL'],
      },
      width: 125,
    },
    {
      field: 'satelliteId',
      headerName: 'Satellite',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [
          undefined,
          'ASTRA_19_2E',
          'ASTRA_28_2E',
          'ASTRA_23_5E',
          'ASTRA_4A_/_SES 5 5E',
          'HOTBIRD_13E',
          'EUTELSAT_25_5E',
          'EUTELSAT_33E',
          'EUTELSAT_9E',
          'EUTELSAT_10E',
          'EUTELSAT_16E',
          'EUTELSAT_7E',
          'EUTELSAT_36E',
          'EUTELSAT_70_5E',
          'EUTELSAT_21B',
          'EXPRESS_AM22',
          'TURKSAT_42E',
          'HISPASAT_30W',
          'BADR_26E',
          'ARABSAT_30_5E',
          'ASTRA_31_5E',
          'PAKSAT_38E',
          'HELLAS_39E',
          'EXPRESSAM1_40E',
          'INTELSAT_45E',
          'EXPRESS_AT1_56E',
          'THOR_INTELSAT_0_8W',
          'AMOS_2/3_4W',
          'EUTELSAT_5_WEST_A 5W',
          'EUTELSAT_7/NILESAT_201',
          'EUTELSAT_8_WEST_A',
          'EUTELSAT_12_WEST_A 12W',
          'EXPRESS_AM8_14W',
          'TELSTAR12_15W',
          'INTELSAT901_18W',
          'SES_4_22W',
          'INTELSAT905_24_5W',
          'INTELSAT907_27_5W',
          'INTELSAT801_31_5W',
          'INTELSAT903_34_5W',
          'TELSTAR_11N_37_5W',
          'NSS806_40_5W',
          'INTELSAT3R_43W',
          'INTELSAT1R_45W',
          'INTELSAT705_50W',
          'INTELSAT707_53W',
          'INTELSAT805_55_5W',
          'INTELSAT9_58W',
          'AMAZONAS_61W',
          'NSS12_57E',
          'INTELSAT_20_68_5E',
          'YAMAL201_90E',
          'EUTELSAT_3E',
          'OPTUS_D1_160E',
          'SES_7/SES_9_108_2E',
          'GSAT_15',
          'INTELSAT_904_60E',
          'ABS_2A_75E',
          'INTELSAT15_85_2E',
          'EXPRESS_AM5_140E',
          'AZERSPACE1/AFRICASAT',
          'ARABSAT_5C_20E',
          'MEASAT_3_91_5E',
          'YAHSAT_1A',
          'EXPRESS_AM44',
          'THAICOM',
          'ST2',
          'ASIASAT_7',
          'INTELSAT_902',
          'EUTELSAT_8_WEST_C',
          'ASIASAT_5',
          'INTELSAT_10',
          'APSTAR',
          'AFGHANSAT_1',
          'YAMAL_202',
          'ABS_7',
          'SES8',
          'KAZSAT3_58_5E',
          'KAZSAT2_86_5E',
          'BSAT_3A/3C_JCSAT_110R/15',
          'USER_SAT_1'
        ],
      },
      width: 133,
    },
    {
      field: 'language',
      headerName: 'Lingua',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.languages
      },
      width: 100
    },
    {
      headerName: 'Elimina',
      cellRenderer: DeleteButtonRendererComponent,
      cellRendererParams: {
        onClick: this.onDeleteClick.bind(this)
      }
    }
  ];
  defaultColDef: ColDef = {
    sortable: true,
    // filter: true,
    editable: true,
    resizable: true,
  };

  constructor(
    private route: ActivatedRoute,
    private tvService: TvService,
    private categoriesService: CategoriesService,
    private router: Router,
    private exportExcelService: ExportExcelService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.getChannel();
      } else {
        this.getLanguages();
      }
    });
  }

  getChannel() {
    this.tvService.getChannel(this.id).subscribe((data) => {
      this.channel = data;

      this.getLanguages();
    });
  }

  saveChannel() {
    this.channel.account = localStorage.getItem('HechRomeoAccount');
    let fileElement = document.getElementById('clone') as HTMLInputElement;
    if (this.id) {
      if (this.channel === undefined || this.channel.name === undefined || this.channel.name.length <= 0
        || this.channel.languages === undefined || this.channel.languages.length <= 0 || this.channel.defaultlanguage === undefined
        || this.channel.defaultlanguage.length <= 0) {
        alert('Errore inserire tutte le informazioni');
      } else {
        let allRows = [];

        this.agGrid.api.forEachNodeAfterFilter((rowNode) => allRows.push(rowNode.data));
        console.log(this.agGrid.api)

        this.channel.details = allRows;

        this.updateChannel();
      }
    } else {
      if (this.channel === undefined || this.channel.name === undefined || this.channel.name.length <= 0
        || this.channel.languages === undefined || this.channel.languages.length <= 0 || this.channel.defaultlanguage === undefined
        || this.channel.defaultlanguage.length <= 0 || fileElement === undefined || fileElement.files === undefined
        || fileElement.files.length <= 0 || fileElement.files[0].size <= 0) {
        alert('Errore inserire tutte le informazioni');
      } else {
        let channel = new FormData();

        channel.append('file', fileElement.files[0]);
        channel.append('name', this.channel.name);
        channel.append('account', this.channel.account);
        channel.append('languages', this.channel.languages.join(','));
        channel.append('defaultlanguage', this.channel.defaultlanguage);
        
        this.createChannel(channel); 
      }
    }
  }

  createChannel(channel) {
    this.tvService.createChannel(channel).subscribe((data) => {
      this.router.navigate(['tvChannels']);
    });
  }

  updateChannel() {
    
    if (this.channel.details.length) {
      this.tvService.updateChannel(this.channel).subscribe((data) => {
        this.router.navigate(['tvChannels']);
      });
    }
  }

  uploadNewFile() {
    let fileElement = document.getElementById('clone') as HTMLInputElement;

    let channel = new FormData();

    channel.append('file', fileElement.files[0]);
    channel.append('id', this.channel.id);

    this.tvService.updateChannelDetails(channel).subscribe(() => {
      this.router.navigate(['tvChannels']);
    });
  }

  clickedTableElement(element, id) {
    this.selectedElement = { element, id };
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouse = {
      x: event.clientX,
      y: event.clientY,
    };
    if (this.resizing) {
      let difference = this.mouse.x - this.initialPosition.mouse;

      this.columnWidth[this.initialPosition.type] =
        this.initialPosition.element + difference;
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event) {
    if (this.resizing) {
      this.resizing = false;
    }
  }

  startResizing(element) {
    this.initialPosition = {
      mouse: this.mouse.x,
      element: this.columnWidth[element],
      type: element,
    };
    this.resizing = true;
  }

  selectChannel(event, id) {
    if (event.target.checked) {
      document
        .getElementById(`channels-table-row-${id}`)
        .classList.add('channels-table-row-selected');
      this.selectedChannels.push(id);
    } else {
      document
        .getElementById(`channels-table-row-${id}`)
        .classList.remove('channels-table-row-selected');
      this.selectedChannels = this.selectedChannels.filter(
        (element) => element != id
      );
    }
  }

  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
  }

  numberParser(params: ValueParserParams): number | string {
    return Number.isNaN(Number(params.newValue)) ? params.oldValue : Number(params.newValue);
  }

  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

  getLanguages() {
    this.categoriesService.getLanguages().subscribe((data) => {
      this.languages = data.languages;

      let languagesTable: any = {};

      if (
        this.channel &&
        this.channel.languages &&
        this.channel.languages.length > 0
      ) {
        this.channel.languages.forEach((language) => {
          const element = this.languages.find((obj) => obj.abr === language);
          this.arraySelectedLanguages.push(element);
          languagesTable[language] = element.language;
        });
      }

      if (this.id) {
        this.columnDefs.forEach(col => {
          if (col.field === 'language') {
            col.cellEditorParams.values = this.channel.languages;
            col.refData = languagesTable;
          }
        });

        this.agGrid.api.setColumnDefs(this.columnDefs);
      }
    });
  }

  removeLanguage(abr) {
    const arrayTmp = [];
    this.channel.languages = this.channel.languages.filter((obj) => obj !== abr);

    if (
      this.channel &&
      this.channel.languages &&
      this.channel.languages.length > 0
    ) {
      this.channel.languages.forEach((language) => {
        this.arraySelectedLanguages.forEach((selectedLanguage) => {
          if (language === selectedLanguage.abr) {
            arrayTmp.push(selectedLanguage);
          }
        });
      });
      this.arraySelectedLanguages = arrayTmp;
    }
  }

  getLanguageName(abr) {
    const element = this.languages.find((obj) => obj.abr === abr);
    if (element) {
      return element.language;
    } else {
      return '';
    }
  }

  addLanguage() {
    const selected = this.languages.find(
      (obj) => obj.abr === this.selectedLanguage
    );

    if (!this.channel.languages) {
      this.channel.languages = [];
    }

    this.channel.languages.push(selected.abr);
    this.arraySelectedLanguages.push(selected);

    this.selectedLanguage = undefined;
  }

  filterLanguage(abr) {
    return !this.channel.languages || !this.channel.languages.find((obj) => obj == abr);
  }

  onDeleteClick(event: any, row: any) {
    this.agGrid.api.removeItems([row]);
  }

  saveChannelDetail() {
    let isCorrect: boolean = true;
    let errors: string[] = [];

    if (!this.newChannelDetail.major || this.newChannelDetail.major <= 0) {
      errors.push('major');
      isCorrect = false;
    }

    if (!this.newChannelDetail.name || this.newChannelDetail.name.length <= 0) {
      errors.push('nome');
      isCorrect = false;
    }

    if (!this.newChannelDetail.broadcastStandard || this.newChannelDetail.broadcastStandard.length <= 0) {
      errors.push('broadcast');
      isCorrect = false;
    }

    if (!this.newChannelDetail.language || this.newChannelDetail.language.length <= 0) {
      errors.push('lingua');
      isCorrect = false;
    }

    switch (this.newChannelDetail.channelType) {
      case 'IPTV':
        if (!this.newChannelDetail.ip || this.newChannelDetail.channelType.length <= 0) {
          errors.push('indirizzo ip');
          isCorrect = false;
        }

        if (!this.newChannelDetail.language || this.newChannelDetail.language.length <= 0) {
          errors.push('lingua');
          isCorrect = false;
        }

        this.newChannelDetail.frequency = undefined;
        this.newChannelDetail.modulationType = undefined;
        this.newChannelDetail.bandwidth = undefined;
        this.newChannelDetail.symbolRate = undefined;
        this.newChannelDetail.polarization = undefined;
        this.newChannelDetail.satelliteId = undefined;
        break;
      case 'DTV':
      case 'ATV':
        if (!this.newChannelDetail.frequency || this.newChannelDetail.frequency <= 0) {
          errors.push('frequenza');
          isCorrect = false;
        }

        if (!this.newChannelDetail.bandwidth || this.newChannelDetail.bandwidth.length <= 0) {
          errors.push('bandwidth');
          isCorrect = false;
        }

        if (!this.newChannelDetail.modulationType || this.newChannelDetail.modulationType.length <= 0) {
          errors.push('modulazione');
          isCorrect = false;
        }

        if (!this.newChannelDetail.symbolRate || this.newChannelDetail.symbolRate <= 0) {
          errors.push('symbol rate');
          isCorrect = false;
        }

        this.newChannelDetail.ip = undefined;
        this.newChannelDetail.polarization = undefined;
        this.newChannelDetail.satelliteId = undefined;
        break;
      case 'SDTV':
        if (!this.newChannelDetail.frequency || this.newChannelDetail.frequency <= 0) {
          errors.push('frequenza');
          isCorrect = false;
        }

        if (!this.newChannelDetail.symbolRate || this.newChannelDetail.symbolRate <= 0) {
          errors.push('symbol rate');
          isCorrect = false;
        }

        this.newChannelDetail.ip = undefined;
        this.newChannelDetail.polarization = undefined;
        this.newChannelDetail.satelliteId = undefined;
        break;
      default:
        if (!this.newChannelDetail.frequency || this.newChannelDetail.frequency <= 0) {
          errors.push('frequenza');
          isCorrect = false;
        }

        if (!this.newChannelDetail.symbolRate || this.newChannelDetail.symbolRate <= 0) {
          errors.push('symbol rate');
          isCorrect = false;
        }

        this.newChannelDetail.ip = undefined;
    }

    if (isCorrect) {
      this.newChannelDetail.id = this.newChannelDetail.major;
      this.newChannelDetail.programNumber = this.newChannelDetail.programNumber ? this.newChannelDetail.programNumber : undefined;

      const allRows = [];

      this.agGrid.api.forEachNodeAfterFilter((rowNode) => allRows.push(rowNode.data));
      this.agGrid.api.setRowData([...allRows, this.newChannelDetail]);

      this.newChannelDetail = new ChannelDetail();
    } else {
      alert('Errore inserire ' + errors.join(', '));
    }
  }

  autoSort() {
    this.agGrid.api.setRowData(this.channel.details.sort((a, b) => a.major > b.major ? 1 : -1))
  }

  exportToExcel() {
    this.exportExcelService.exportToExcel(this.agGrid.rowData, this.channel.name);
  }
}
