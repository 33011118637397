import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/models/signage/signage';
import { MediaService } from 'src/app/services/media/media.service';
import { SignageService } from 'src/app/services/signage/signage.service';

@Component({
  selector: 'app-create-signage',
  templateUrl: './create-signage.component.html',
  styleUrls: ['./create-signage.component.scss'],
})
export class CreateSignageComponent implements OnInit {
  id: string;
  project: Project = new Project();
  fileToLoad;
  imageToLoad;
  videoToLoad;
  iconToLoad;
  iconToLoadfooter;
  loadedNewFile;
  loadedNewIcon;
  constructor(
    private route: ActivatedRoute,
    private signageService: SignageService,
    private router: Router,
    public _DomSanitizationService: DomSanitizer,
    private mediaService: MediaService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.getProject();
      }
    });
  }

  getProject() {
    this.signageService.getProject(this.id).subscribe((data) => {
      this.project = data;
      if (this.project.header.logo) {
        this.mediaService
          .getMediaObject(this.project.header.logo)
          .subscribe((data) => {
            this.iconToLoad = data.mediaobject;
            console.log('iconToLoad', this.iconToLoad);
          });
      }
      console.log('footer', this.project.footer.logo);
      if (this.project.footer.logo) {
        this.mediaService
          .getMediaObject(this.project.footer.logo)
          .subscribe((data) => {
            this.iconToLoadfooter = data.mediaobject;
            console.log('iconToLoadfooter', this.iconToLoadfooter);
          });
      }
    });
  }

  cancel() {
    this.router.navigate(['signage']);
  }

  editProject() {
    this.signageService.updateProject(this.project).subscribe((data) => {
      this.router.navigate(['signage']);
    });
  }

  createProject() {
    this.signageService.createProject(this.project).subscribe((data) => {
      this.router.navigate(['signage']);
    });
  }

  async saveProject() {
    if (!this.checkValues()) {
      return;
    }
    if (this.loadedNewIcon) {
      if (this.iconToLoad) {
        await this.mediaService
          .addMediaContent(
            localStorage.getItem('HechRomeoAccount'),
            3,
            this.iconToLoad
          )
          .then((data) => {
            this.project.header.logo = data.id;
          });
      }
      if (this.iconToLoadfooter) {
        await this.mediaService
          .addMediaContent(
            localStorage.getItem('HechRomeoAccount'),
            3,
            this.iconToLoadfooter
          )
          .then((data) => {
            this.project.footer.logo = data.id;
          });
      }
    }
    if (this.id) {
      this.editProject();
    } else {
      this.createProject();
    }
  }

  encodeImageFileAsURL(element, type: number, i?, footer?) {
    this.fileToLoad = undefined;
    console.log(element);
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      this.getPureB64(reader.result.toString(), type, i, footer);
    };
    reader.readAsDataURL(file);
  }

  getPureB64(element: string, type: number, i?, footer?) {
    let index = element.indexOf(',');
    element = element.slice(index + 1, element.length);
    switch (type) {
      case 1:
        let item = this.imageToLoad[i];
        if (this.imageToLoad.length < 8 && item.image == undefined) {
          this.imageToLoad.push({});
        }
        item.image = element;
        item.new = true;
        this.loadedNewFile = true;
        break;
      case 2:
        this.id = '';
        // if (this.playerLoaded) {
        //   this.player.destroy();
        //   this.playerLoaded = false;
        // }

        this.videoToLoad = element;
        this.loadedNewFile = true;
        break;
      case 3:
        if (footer) {
          this.iconToLoadfooter = element;
        } else this.iconToLoad = element;
        this.loadedNewIcon = true;
        break;
    }
  }
  deleteImage(footer?) {
    if (!footer) {
      this.iconToLoad = undefined;
      this.project.header.logo = undefined;
    } else {
      this.iconToLoadfooter = undefined;
      this.project.footer.logo = undefined;
    }
  }

  checkValues() {
    let correct = true;
    if (
      (this.project.header.weatherforecast ||
        this.project.footer.weatherforecast) &&
      !this.project.header.position
    ) {
      correct = false;
      document.getElementById('errorPositionHeader').classList.remove('d-none');
    } else {
      document.getElementById('errorPositionHeader').classList.add('d-none');
    }
    // if (this.project.footer.weatherforecast && !this.project.footer.position) {
    //   correct = false;
    //   document.getElementById('errorPositionFooter').classList.remove('d-none');
    // } else {
    //   document.getElementById('errorPositionFooter').classList.add('d-none');
    // }

    return correct;
  }
}
