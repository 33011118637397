import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from 'src/app/models/languages/language';
import { TvSettings } from 'src/app/models/tv/tv';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { TvService } from 'src/app/services/tv/tv.service';
import { MediaService } from '../../../services/media/media.service';

@Component({
  selector: 'app-tv-settings-details',
  templateUrl: './tv-settings-details.component.html',
  styleUrls: ['./tv-settings-details.component.scss'],
})
export class TvSettingsDetailsComponent implements OnInit {
  id: string;
  tvSettings: TvSettings = new TvSettings();
  languages: Array<Language> = [];
  selectedLanguage: string;
  arraySelectedLanguages: any = [];

  fileToLoad;
  imageToLoad;
  bannerToLoad;
  deletedImage: boolean;
  deletedBanner: boolean;
  playerLoaded: boolean = false;
  player: YT.Player;
  videoToLoad;
  url: string = '';
  urlError: boolean = false;
  savedUrl: string;
  loadedNewFile: boolean = false;
  loadedNewFileBanner: boolean = false;
  isAdmin: boolean;

  constructor(
    private route: ActivatedRoute,
    private tvService: TvService,
    private router: Router,
    private categoriesService: CategoriesService,
    private mediaService: MediaService
  ) { }

  ngOnInit(): void {
    this.tvSettings = new TvSettings();

    this.isAdmin = localStorage.getItem('HechRomeoRole') == 'Manager';

    this.route.queryParams.subscribe((params) => {
      this.tvSettings.id = params.id;
      if (this.tvSettings.id) {
        this.getTvSettings();
      } else {
        this.getAccount();
        this.getLanguages();
      }
    });

    this.imageToLoad = {};
    this.bannerToLoad = [{}];
    this.deletedBanner = false;
  }

  getTvSettings() {
    this.tvService.getSetting(this.tvSettings.id).subscribe((data) => {
      this.tvSettings = data;

      if (this.tvSettings) {
        this.getAccount();
        this.getLanguages();
        this.getBackgroundImage();
        this.getBannerImage();
      }
    });
  }

  getBackgroundImage() {
    if (this.tvSettings) {
      if (this.tvSettings.welcomeproject) {
        if (this.tvSettings.imageType) {
          switch (this.tvSettings.imageType) {
            case 1:
              this.mediaService
                .getMediaObject(this.tvSettings.welcomeproject)
                .subscribe((data) => {
                  if (data.type === 1) {
                    this.imageToLoad = { image: data.mediaobject };
                  } else {
                    this.videoToLoad = data.mediaobject;
                  }

                  this.tvSettings.imageType = data.type;
                });
              break;
            case 2:
              if (this.tvSettings.welcomeproject.includes('youtube')) {
                this.url = this.tvSettings.welcomeproject;
                this.id = this.getIdFromUrl();
              } else {
                this.mediaService
                  .getMediaObject(this.tvSettings.welcomeproject)
                  .subscribe((data) => {
                    if (data.type === 1) {
                      this.imageToLoad = { image: data.mediaobject };
                    } else {
                      this.videoToLoad = data.mediaobject;
                    }

                    this.tvSettings.imageType = data.type;
                  });
              }
              break;
          }
        }
      }
    }
  }

  getBannerImage() {
    if (this.tvSettings) {
      if (this.tvSettings.bannerimage) {
        this.mediaService
          .getImagesBulk(this.tvSettings.bannerimage)
          .subscribe((data) => {
            this.bannerToLoad = [];
            let i = 0;
            let bannerArray = this.tvSettings.bannerimage.split(';');
            for (let element of data) {
              this.bannerToLoad.push({
                image: element,
                id: bannerArray[i]
              });
              i++;
            }

            if (this.bannerToLoad && this.bannerToLoad.length < 8) {
              this.bannerToLoad.push({});
            }

            if (!data || data.length <= 0) {
              this.bannerToLoad.push({});
            }
          });
      }
    }
  }

  getLanguages() {
    this.categoriesService.getLanguages().subscribe((data) => {
      this.languages = data.languages;

      if (
        this.tvSettings &&
        this.tvSettings.languages &&
        this.tvSettings.languages.length > 0
      ) {
        this.tvSettings.languages.forEach((language) => {
          const element = this.languages.find((obj) => obj.abr === language);
          this.arraySelectedLanguages.push(element);
        });
      }
    });
  }

  getAccount() {
    const account = localStorage.getItem('HechRomeoAccount');
    if (account) {
      this.tvSettings.account = account;
    }
  }

  getLanguageName(abr) {
    const element = this.languages.find((obj) => obj.abr === abr);
    if (element) { return element.language; }
    else { return ''; }
  }

  addLanguage() {
    const selected = this.languages.find(
      (obj) => obj.abr === this.selectedLanguage
    );

    this.tvSettings.languages.push(selected.abr);
    this.arraySelectedLanguages.push(selected);

    this.selectedLanguage = undefined;
  }

  removeLanguage(abr) {
    const arrayTmp = [];
    this.tvSettings.languages = this.tvSettings.languages.filter((obj) => obj !== abr);

    if (
      this.tvSettings &&
      this.tvSettings.languages &&
      this.tvSettings.languages.length > 0
    ) {
      this.tvSettings.languages.forEach((language) => {
        this.arraySelectedLanguages.forEach((selectedLanguage) => {
          if (language === selectedLanguage.abr) {
            arrayTmp.push(selectedLanguage);
          }
        });
      });
      this.arraySelectedLanguages = arrayTmp;
    }
  }

  filterLanguage(abr) {
    return !this.tvSettings.languages.find((obj) => obj == abr);
  }

  async saveSettings() {
    if (this.tvSettings === undefined || this.tvSettings.name === undefined || this.tvSettings.name.length <= 0
      || this.tvSettings.languages === undefined || this.tvSettings.languages.length <= 0 || this.tvSettings.defaultlanguage === undefined
      || this.tvSettings.defaultlanguage.length <= 0 || this.tvSettings.applications === undefined
      || this.tvSettings.applications.chromecast === undefined || (this.tvSettings.applications.chromecast === true
        && (this.tvSettings.applications.hdmichromecastnumber === undefined || this.tvSettings.applications.hdmichromecastnumber.length <= 0))
      || this.tvSettings.applications.sky === undefined || (this.tvSettings.applications.sky === true
        && this.tvSettings.applications.typesky === undefined) || (this.tvSettings.applications.sky === true
          && this.tvSettings.applications.typesky === 0 && (this.tvSettings.applications.channelsky === undefined
            || this.tvSettings.applications.channelsky.length <= 0)) || (this.tvSettings.applications.sky === true
              && this.tvSettings.applications.typesky === 1 && (this.tvSettings.applications.hdmisky === undefined
                || this.tvSettings.applications.hdmisky.length <= 0)) || this.tvSettings.applications.youtube === undefined
      || this.tvSettings.applications.browser === undefined) {
      alert('Errore inserire tutte le informazioni');
    } else {
      if (
        this.imageToLoad &&
        (this.loadedNewFile || this.deletedImage) &&
        this.tvSettings.imageType == 1
      ) {
        if (!this.loadedNewFile && this.deletedImage) {
          this.tvSettings.welcomeproject = '';
        } else if (this.imageToLoad.image != undefined) {
          await this.mediaService
            .addMediaContent(localStorage.getItem('HechRomeoAccount'), this.tvSettings.imageType, this.imageToLoad.image)
            .then((data) => {
              this.tvSettings.welcomeproject = data.id;
            });
        }
      }
      else if (this.tvSettings.imageType == 2) {
        if (this.savedUrl && this.playerLoaded) {
          this.tvSettings.welcomeproject = this.savedUrl;
        } else if (this.videoToLoad && this.loadedNewFile) {
          await this.mediaService
            .addMediaContent(
              localStorage.getItem('HechRomeoAccount'),
              this.tvSettings.imageType,
              this.videoToLoad
            )
            .then((data) => {
              this.tvSettings.welcomeproject = data.id;
            });
        }
      }

      if (
        this.bannerToLoad &&
        this.bannerToLoad.length > 0
      ) {
        if (!this.loadedNewFileBanner && this.deletedBanner) {
          let banners = [];
          for (let element of this.bannerToLoad) {
            if (element.image) banners.push(element.image);
          }
          await this.mediaService
            .addImagesBulk(localStorage.getItem('HechRomeoAccount'), banners)
            .then((data) => {
              this.tvSettings.bannerimage = '';
              for (let element of data) {
                if (this.tvSettings.bannerimage != '') {
                  this.tvSettings.bannerimage = this.tvSettings.bannerimage.concat(';');
                }
                this.tvSettings.bannerimage =
                  this.tvSettings.bannerimage.concat(element);
              }
            });
        } else if (this.bannerToLoad[0].image != undefined) {
          let banners = [];
          for (let element of this.bannerToLoad) {
            if (element.image) banners.push(element.image);
          }
          await this.mediaService
            .addImagesBulk(localStorage.getItem('HechRomeoAccount'), banners)
            .then((data) => {
              this.tvSettings.bannerimage = '';
              for (let element of data) {
                if (this.tvSettings.bannerimage != '') {
                  this.tvSettings.bannerimage = this.tvSettings.bannerimage.concat(';');
                }
                this.tvSettings.bannerimage =
                  this.tvSettings.bannerimage.concat(element);
              }
            });
        }
      }

      if (!this.tvSettings.welcomeproject || this.tvSettings.welcomeproject === '') {
        this.tvSettings.imageType = undefined;
      }

      if (!this.tvSettings.primarycolor || this.tvSettings.primarycolor === '') {
        this.tvSettings.primarycolor = '#000000';
      }

      if (!this.tvSettings.secondarycolor || this.tvSettings.secondarycolor === '') {
        this.tvSettings.secondarycolor = '#000000';
      }

      if (!this.tvSettings.textprimarycolor || this.tvSettings.textprimarycolor === '') {
        this.tvSettings.textprimarycolor = '#000000';
      }

      if (!this.tvSettings.textsecondarycolor || this.tvSettings.textsecondarycolor === '') {
        this.tvSettings.textsecondarycolor = '#000000';
      }

      if (!this.tvSettings.texttertiarycolor || this.tvSettings.texttertiarycolor === '') {
        this.tvSettings.texttertiarycolor = '#000000';
      }

      if (!this.tvSettings.texttertiarycolorselected || this.tvSettings.texttertiarycolorselected === '') {
        this.tvSettings.texttertiarycolorselected = '#ffffff';
      }

      if (this.tvSettings.id) {
        console.log('aaa', this.tvSettings)
        this.updateSettings();
      } else {
        console.log('bbb', this.tvSettings)
        this.createSettings();
      }
    }
  }

  createSettings() {
    this.tvService.createSetting(this.tvSettings).subscribe((data) => {
      this.bannerToLoad = undefined;
      this.loadedNewFileBanner = false;
      this.deletedBanner = false;
      this.router.navigate(['tvSettings']);
    });
  }

  updateSettings() {
    this.tvService.updateSetting(this.tvSettings).subscribe((data) => {
      this.bannerToLoad = undefined;
      this.loadedNewFileBanner = false;
      this.deletedBanner = false;
      this.router.navigate(['tvSettings']);
    });
  }

  selectedTypeSkyChange(value: any) {
    if (value) {
      if (value === '0') {
        this.tvSettings.applications.typesky = 0;
      } else {
        this.tvSettings.applications.typesky = 1;
      }
    }
  }

  encodeImageFileAsURL(element, type: number, i?) {
    this.fileToLoad = undefined;
    const file = element[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.getPureB64(reader.result.toString(), type, i);
    };
    reader.readAsDataURL(file);

    switch (type) {
      case 1:
        this.imageToLoad = {};

        this.imageToLoad.image = element;
        this.imageToLoad.new = true;

        this.loadedNewFile = true;

        this.id = '';
        this.url = '';
        break;
      case 2:
        this.id = '';
        if (this.playerLoaded) {
          this.player.destroy();
          this.playerLoaded = false;
        }

        this.videoToLoad = element;
        this.loadedNewFile = true;

        this.url = '';
        break;
    }
  }

  encodeImageFileAsURLBanner(element, i?) {
    this.fileToLoad = undefined;
    const file = element[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.getPureB64Banner(reader.result.toString(), i);
    };
    reader.readAsDataURL(file);
  }

  getPureB64(element: string, type: number, i?) {
    const index = element.indexOf(',');
    element = element.slice(index + 1, element.length);

    switch (type) {
      case 1:
        this.imageToLoad = {};

        this.imageToLoad.image = element;
        this.imageToLoad.new = true;
        break;
      case 2:
        this.id = '';
        if (this.playerLoaded) {
          this.player.destroy();
          this.playerLoaded = false;
        }

        this.videoToLoad = element;
        this.loadedNewFile = true;
        break;
    }
  }

  getPureB64Banner(element: string, i?: number) {
    const index = element.indexOf(',');
    element = element.slice(index + 1, element.length);

    let item = this.bannerToLoad[i];
    if (this.bannerToLoad.length < 8 && item.image == undefined) {
      this.bannerToLoad.push({});
    }
    item.image = element;
    item.new = true;
    this.bannerToLoad.new = true;
  }

  deleteImage(i) {
    this.deletedImage = true;
    this.imageToLoad = {};
    this.tvSettings.welcomeproject = null;
  }

  deleteBanner(i) {
    this.deletedBanner = true;
    this.bannerToLoad.splice(i, 1);
    if (this.bannerToLoad.length == 7) {
      this.bannerToLoad.push({});
    }
    if (
      this.bannerToLoad.lenght == 1 &&
      this.bannerToLoad[0].image == undefined
    ) {
      this.tvSettings.bannerimage = undefined;
    }
  }

  loadPlayer() {
    const idPlayer: string = 'playerService';

    if (this.id != '' && this.id != undefined) {
      this.player = new YT.Player(idPlayer, {
        width: '480px',
        videoId: this.id,
      });
      this.playerLoaded = true;
    }
  }

  loadNewYoutubeVideo() {
    let newId = this.getIdFromUrl();
    this.id = newId;
    if (this.playerLoaded == false) {
      this.loadPlayer();
    } else {
      this.player.loadVideoById(newId);
    }
  }

  getIdFromUrl() {
    try {
      let _url = new URL(this.url);
      //let id = _url.searchParams.get('v');
      let id = this.youtube_parser(this.url);
      this.savedUrl = this.url;
      this.urlError = false;
      return id;
    } catch (e) {
      this.urlError = true;
    }
  }

  youtube_parser(url: string) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : undefined;
  }

  deleteYoutubeVideo() {
    this.id = '';
    this.url = '';
    this.urlError = false;
    this.savedUrl = '';
    this.tvSettings.welcomeproject = '';
    if (this.playerLoaded) {
      this.player.destroy();
      this.playerLoaded = false;
    }
  }
}
