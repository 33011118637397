import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-small-text',
  templateUrl: './small-text.component.html',
  styleUrls: ['./small-text.component.scss']
})
export class SmallTextComponent implements OnInit {

  @Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }

}
