import { Component, OnInit } from '@angular/core';
import { AdminVendor } from '../../../models/admin-vendor';
import { AccountService } from '../../../services/account/account.service';
import { AdminServiceService } from '../../../services/admin/admin-service.service';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss'],
})
export class AdminListComponent implements OnInit {
  searchValue: string;
  adminList: any[];
  adminCreate: AdminVendor;
  adminDeleteEmail: any;
  admin: boolean = localStorage.getItem('HechRomeoRole') == 'Manager';

  constructor(
    private accountService: AccountService,
    private adminService: AdminServiceService
  ) {}

  ngOnInit(): void {
    this.searchValue = '';
    this.adminDeleteEmail = {};

    this.resetCreateAdmin();
    this.getAdminList();
  }

  getAdminList(): void {
    this.adminList = [];

    if (this.admin) {
      this.adminService.getAllAdmin().subscribe((result) => {
        this.adminList = result;
      });
    } else {
      this.accountService.getAccountUser().subscribe((result) => {
        this.adminList = result.users;
      });
    }
  }

  deleteAdmin(): void {
    if (this.admin) {
      this.adminService
        .deleteAdmin(this.adminDeleteEmail)
        .subscribe((result) => {
          this.adminDeleteEmail = {};
          this.getAdminList();
        });
    } else {
      this.accountService
        .deleteAccountUser(this.adminDeleteEmail)
        .subscribe((result) => {
          this.adminDeleteEmail = {};
          this.getAdminList();
        });
    }
  }

  saveAdmin(): void {
    if (this.admin) {
      this.adminService.addManager(this.adminCreate).subscribe((result) => {
        this.resetCreateAdmin();
        this.getAdminList();
      });
    } else {
      this.accountService
        .createAccountUser(this.adminCreate)
        .subscribe((result) => {
          this.resetCreateAdmin();
          this.getAdminList();
        });
    }
  }

  resetCreateAdmin(): void {
    this.adminCreate = new AdminVendor(this.admin);
    this.adminCreate.account = localStorage.getItem('HechRomeoAccount');
  }
}
